import React from 'react';
import { Image, Nav, Navbar, Tab, Row, Col, Container, Button, Collapse } from 'react-bootstrap';
import { useState } from 'react';
import './SubNavigation.scss';
import { Link, NavLink } from 'react-router-dom';
import down from '../../../../images/DownChevron.svg';
import up from '../../../../images/UpChevron.svg';
import SRLogo from '../../../../images/MyServiceRequest_Navy.png';

const SubNavigation = (props) => {
  const [navigationItems, setNavigationItems] = useState(
    props.subNavigationItems &&
      props.subNavigationItems.map((item) => (
        <Nav.Item
          to={item.subNavLink}
          as={NavLink}
          key={item.subNavTitle}
          className="d-flex text-nowrap flex-column justify-content-center align-baseline"
          role="tab"
        >
          {/* <Nav.Link role="link" as={NavLink}> */}
          {item.subNavTitle}
          {/* </Nav.Link> */}
        </Nav.Item>
      )),
  );
  const DesktopSubNav = () => {
    return (
      <Navbar
        className="flex-nowrap desktop submenu text-dark align-items-center justify-content-between"
        activekey="/home"
      >
        <Container className="landingPageWidth">
          <Navbar.Brand className="fs-2">
            <Link to={props.homeURL} className="text-decoration-none">
              <Image data-testid="logo" alt="ServiceRequest Logo" src={SRLogo} height="32px" className="subNavLogo" />
            </Link>
          </Navbar.Brand>
          <Tab.Container defaultActiveKey="first">
            <Row>
              <Col>
                <Nav variant="tabs" role="tablist">
                  {navigationItems}
                </Nav>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Navbar>
    );
  };
  const MobileSubNav = () => {
    const [open, setOpen] = useState(false);
    return (
      <>
        <Navbar
          className="flex-nowrap py-1 top bg-defencewhite px-4 mobile submenu text-dark align-items-center justify-content-between "
          activekey="/home"
        >
          <Navbar.Brand className="fs-2">
            <Link to={props.homeURL} className="text-decoration-none">
              <Image
                data-testid="logo"
                alt="ServiceRequest Logo"
                src={SRLogo}
                height="24"
                className="d-inline-block subNavLogo"
              />
            </Link>
          </Navbar.Brand>
          <Button onClick={() => setOpen(!open)} variant="icon">
            <Image height={11} alt="open menu" src={!open ? down : up} />
          </Button>
        </Navbar>
        <Collapse in={open}>
          <Container className="drop top mobileSubMenu bg-defencewhite">{navigationItems}</Container>
        </Collapse>
        {open && <div className="fade modal-backdrop backdrop"></div>}
      </>
    );
  };
  return (
    <>
      <DesktopSubNav />
      <MobileSubNav />
    </>
  );
};

export default SubNavigation;
