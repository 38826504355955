import React from 'react';
import { Flex, SystemProps, Box } from '@chakra-ui/react';
interface SubContainerProps {
  verticalMargin?: string;
  children: JSX.Element[] | JSX.Element;
  padding?: string;
  wrap?: SystemProps['flexWrap'];
  alignItems?: SystemProps['alignItems'];
  direction?: SystemProps['flexDirection'];
  gap?: SystemProps['gap'];
  flex?: boolean;
}

const ContainerWidth = {
  base: `90%`,
  md: `${100 * (10 / 12)}%`,
  xl: `${100 * (9 / 12)}%`,
};
export const SubContainer = (props: SubContainerProps): JSX.Element => {
  return props.flex ? (
    <Flex
      maxW="1086px"
      gap={props.gap}
      padding={props.padding}
      wrap={props.wrap}
      direction={props.direction}
      align={props.alignItems}
      w={ContainerWidth}
      margin={`${props.verticalMargin ?? 0} auto`}
      justifyContent="space-between"
    >
      {props.children}
    </Flex>
  ) : (
    <Box maxW="1086px" w={ContainerWidth} padding={props.padding} margin={`${props.verticalMargin ?? 0} auto`}>
      {props.children}
    </Box>
  );
};
