import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Overlay from 'react-bootstrap/Overlay';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import { getURL } from '../../../../helper/getAPIUrl';
import './ServReqList.scss';
import { CloneModal } from '../../CloneRequest/CloneModal/CloneModal';

import { useNavigate } from 'react-router-dom';
import { appConstants } from '../../../../helper/client/constant';
import GenericErrorModal from '../../../GenericErrorModal/GenericErrorModal';
import AuthenticationModal from '../../../LandingPage/AuthenticationModal/AuthenticationModal';
import SystemOutageModal from '../../../SystemOutageModal/SystemOutageModal';

import GenericErrorModalProps from '../../../GenericErrorModal/__mocks__/default.json';
import AuthenticationModalProps from '../../../LandingPage/AuthenticationModal/__mocks__/default.json';
import FormsPagination from '../../../LandingPage/Pagination/Pagination';
import SystemOutageModalProps from '../../../SystemOutageModal/__mocks__/default.json';
import { StatusBadge } from '../../StatusBadge/StatusBadge';
import content from '../../Content/Home.json';

const mySRWorkTypeSublist = [appConstants.SR_TYPE_TRANSPORT, appConstants.SR_TYPE_CATERING];

const FormPanel = (props) => {
  const navigate = useNavigate();
  const routeChangetoEdit = (caseId, type) => {
    const path = `/my-service-request/edit-service-request-create/${caseId}?type=${type}`;
    navigate(path);
  };
  const routeChangetoReview = (caseId, type) => {
    const pathForReview = `/my-service-request/edit-service-request-review/${caseId}?type=${type}`;
    navigate(pathForReview);
  };
  const viewRequestHandler = (ReqData) => {
    if (
      ReqData.pyStatusWork === appConstants.SUBMITTED_SERVICE_REQUEST ||
      ReqData.pyStatusWork === appConstants.SUBMITTED_SERVICE_REQUEST_RECEIVED_GEMS ||
      ReqData.pyStatusWork === appConstants.SUBMITTED_SERVICE_REQUEST_COMPLETED_GEMS ||
      ReqData.pyStatusWork === appConstants.SUBMITTED_SERVICE_REQUEST_IN_PROGRESS_GEMS ||
      ReqData.pyStatusWork === appConstants.SUBMITTED_SERVICE_REQUEST_REJECTED_GEMS ||
      ReqData.pyStatusWork === appConstants.SUBMITTED_SERVICE_REQUEST_ERROR_GEMS ||
      ReqData.pyStatusWork === appConstants.CANCELLED_SERVICE_REQUEST
    ) {
      navigate('/my-service-request/view-service-request/' + ReqData.pzInsKey);
    } else {
      const caseId = ReqData.pzInsKey;
      const RequestType =
        ReqData.ServiceRequest.ServiceType === appConstants.SERVICE_REQUEST_TYPE
          ? appConstants.SERVICE_REQUEST_TYPE_MINOR
          : appConstants.SERVICE_REQUEST_TYPE_FULL;
      if (ReqData.pyStatusWork === appConstants.NEW_SERVICE_REQUEST) {
        routeChangetoEdit(caseId, RequestType);
      } else {
        routeChangetoReview(caseId, RequestType);
      }
    }
  };
  return (
    <Container fluid className="rounded bg-defencewhite py-lg-2 mb-3" key={props.key}>
      {/* Mobile layout */}
      <Col className="mobile p-4">
        <Row>
          <StatusBadge status={props.value.pyStatusWork}></StatusBadge>
          <h3 className="fw-bolder text-dark m-0 p-0 request-title-sr">
            {props.value.ServiceRequest.RequestTitle
              ? props.value.ServiceRequest.RequestTitle
              : appConstants.SERVICE_REQUEST_DEFAULT_TITLE}
          </h3>
        </Row>
        <Row>
          <p className="fw-light text-defencedarkgrey p-0 m-0">
            {props.value.ServiceRequest.WorkType !== 'Select' ? `${props.value.ServiceRequest.WorkType}` : ''}
            {props.value.ServiceRequest.WorkType !== 'Select' && props.value.ServiceRequest.WorkSubType
              ? `, ${props.value.ServiceRequest.WorkSubType}`
              : ''}
          </p>
        </Row>
        <Row>
          <p className="fw-light mb-2 text-defencedarkgrey">
            {props.value.ServiceRequest.Location.Address.Area &&
            !mySRWorkTypeSublist.includes(props.value.ServiceRequest.WorkType)
              ? `${props.value.ServiceRequest.Location.Address.Area}`
              : `${props.value.ServiceRequest.Location.Name}`}
          </p>
        </Row>
        <Row>
          <Col lg={6} className=" text-defencedarkgrey">
            Submitted:{' '}
            {props.value.ServiceRequest?.SubmissionDateTime &&
              new Date(props.value.ServiceRequest?.SubmissionDateTime).toDateString()}
          </Col>
          <Col lg={12} className="fw-light text-defencedarkgrey">
            <button
              onClick={() => props.setShowCloneModal(props.value.pzInsKey)}
              className="arrow m-0 float-start fw-bolder p-0 text-defenceorange bg-white border-0"
            >
              Clone
            </button>

            <button
              className="arrow m-0 float-end fw-bolder p-0 text-defenceorange bg-white border-0"
              onClick={() => viewRequestHandler(props.value)}
            >
              {props.value.pyStatusWork === appConstants.NEW_SERVICE_REQUEST ||
              props.value.pyStatusWork === appConstants.REVIEW_SERVICE_REQUEST
                ? 'Continue'
                : 'View'}
            </button>
          </Col>
        </Row>
      </Col>
      <Row className="desktop">
        <Col lg={4} className="px-4">
          <StatusBadge status={props.value.pyStatusWork} />
          <p className="fw-bolder text-dark p-0 mb-2 request-title-sr">
            {props.value.ServiceRequest.RequestTitle
              ? props.value.ServiceRequest.RequestTitle
              : appConstants.SERVICE_REQUEST_DEFAULT_TITLE}
          </p>
          <p className="fw-light text-defencedarkgrey p-0 m-0">
            {props.value.ServiceRequest.WorkType !== 'Select' ? `${props.value.ServiceRequest.WorkType}` : ''}
            {props.value.ServiceRequest.WorkType !== 'Select' && props.value.ServiceRequest.WorkSubType
              ? `, ${props.value.ServiceRequest.WorkSubType}`
              : ''}
          </p>
        </Col>
        <Col className="fw-light m-0 text-defencedarkgrey" lg={3}>
          {props.value.ServiceRequest.Location.Address.Area &&
          !mySRWorkTypeSublist.includes(props.value.ServiceRequest.WorkType)
            ? `${props.value.ServiceRequest.Location.Address.Area}`
            : `${props.value.ServiceRequest.Location.Name}`}
        </Col>
        <Col className="fw-light text-defencedarkgrey" lg={2}>
          {props.value.ServiceRequest?.SubmissionDateTime &&
            new Date(props.value.ServiceRequest?.SubmissionDateTime).toDateString()}
        </Col>
        <Col lg={3} className="pe-4 d-flex justify-content-end align-items-center gap-2">
          <Button onClick={() => props.setShowCloneModal(props.value.pzInsKey)}>Clone</Button>

          <Button
            variant="standard"
            className="float-end sr-button-width"
            onClick={() => viewRequestHandler(props.value)}
          >
            {props.value.pyStatusWork === appConstants.NEW_SERVICE_REQUEST ||
            props.value.pyStatusWork === appConstants.REVIEW_SERVICE_REQUEST
              ? 'Continue'
              : 'View'}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const ServReqList = (props) => {
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [requests, setRequests] = useState([]);
  const [selectedCaseId, setselectedCaseId] = useState('');
  useEffect(() => {
    let URL = process.env.REACT_APP_GET_RECENT_REQUEST;
    URL = getURL(URL);
    axios(URL, {
      withCredentials: true,
    })
      .then((response) => setRequests(response.data.pxResults))
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);

        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  }, []);
  const [direction, setDirection] = useState(false);
  const [filter, setFilter] = useState('date_submitted');
  const CustomToggle = () => {
    return (
      <>
        {errorModal && (
          <GenericErrorModal
            title={GenericErrorModalProps.title}
            ctaText={GenericErrorModalProps.ctaText}
            error={APIError}
          />
        )}

        {serviceDownModal && (
          <SystemOutageModal
            title={SystemOutageModalProps.title}
            description={SystemOutageModalProps.description}
            ctaText={SystemOutageModalProps.ctaText}
          />
        )}

        {showModal && (
          <AuthenticationModal
            title={AuthenticationModalProps.title}
            description={AuthenticationModalProps.description}
            ctaText={AuthenticationModalProps.ctaText}
          />
        )}

        <Dropdown className="sort align-self-end">
          <Dropdown.Toggle className="border-0 pe-1" variant="sort">
            Sort
          </Dropdown.Toggle>

          <Button
            onClick={() => setDirection(!direction)}
            className="directionButton border-0 ps-0 pe-1"
            title="Switch direction"
            split="true"
            variant="sort"
            id="dropdown-split-basic"
          />

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setFilter('location')}>Location</Dropdown.Item>
            <Dropdown.Item onClick={() => setFilter('title')}>Title</Dropdown.Item>
            <Dropdown.Item onClick={() => setFilter('date_submitted')}>Date Submitted</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };
  const TableHeader = () => {
    return (
      <Container fluid className="tableheading">
        <Row>
          <Col className="headerCol" lg={4}></Col>
          <Col className="desktop headerCol" lg={3}>
            Location
          </Col>
          <Col className="desktop headerCol" lg={2}>
            Date Submitted
          </Col>
          <Col lg={3} className="headerCol flex-column">
            <CustomToggle />
          </Col>
        </Row>
      </Container>
    );
  };

  const RequestPanel = (props) => {
    const itemsPerPage = appConstants.SR_LIST_PAGINATION_LIMIT;
    const [trimStart, setTrimStart] = useState(0);
    const [trimEnd, setTrimEnd] = useState(itemsPerPage);
    const updatePagination = (trims) => {
      setTrimStart(trims[0]);
      setTrimEnd(trims[1]);
    };
    const openCloneModel = (caseID) => {
      console.log('case id-->', caseID);
      setselectedCaseId(caseID);
      setShowCloneModal(true);
    };
    const navigate = useNavigate();
    const navigatetoCreatePage = (clonedCaseData) => {
      const RequestType =
        clonedCaseData.ServiceType === appConstants.SERVICE_REQUEST_TYPE
          ? appConstants.SERVICE_REQUEST_TYPE_MINOR
          : appConstants.SERVICE_REQUEST_TYPE_FULL;
      const path = `/my-service-request/edit-service-request-create/${clonedCaseData.CloneSRID}?type=${RequestType}`;
      navigate(path);
    };
    const [show, setShow] = useState(false);
    const target = useRef(null);
    let sortedRequests = [];
    requests
      ? (sortedRequests = requests.sort(function (a, b) {
          let keyA;
          let keyB;
          switch (filter) {
            case 'date_submitted':
              keyA = Date.parse(a.pxUpdateDateTime);
              keyB = Date.parse(b.pxUpdateDateTime);
              if (isNaN(keyA)) {
                keyA = b.pxUpdateDateTime;
                return 1;
              }
              if (isNaN(keyB)) {
                keyB = b.pxUpdateDateTime;
                return 1;
              }
              break;
            case 'title':
              keyA = a.ServiceRequest.RequestTitle;
              if (keyA === undefined) {
                keyA = 'Untitled Request';
              }

              keyA = keyA.toLowerCase();
              keyB = b.ServiceRequest.RequestTitle;
              if (keyB === undefined) {
                keyB = 'Untitled Request';
              }

              keyB = keyB.toLowerCase();
              break;
            case 'location':
              keyA = a.ServiceRequest.Location.Address.Area;
              if (keyA === undefined) {
                keyA = 'Z';
              }
              keyB = b.ServiceRequest.Location.Address.Area;
              if (keyB === undefined) {
                keyB = 'Z';
              }
              break;
            default:
              return null;
          }

          if (keyA === undefined || keyB === undefined) {
            return -1;
          }
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        }))
      : (sortedRequests = null);

    return requests ? (
      <>
        <CloneModal
          show={showCloneModal}
          {...content.cloneModal}
          onClose={() => setShowCloneModal(false)}
          caseID={selectedCaseId}
          onsetAPIError={(data) => setAPIError(data)}
          onsetShowModel={() => setShowModal(true)}
          onsetserviceDownModel={() => setServiceDownModal(true)}
          onsetErrorModel={() => setErrorModal(true)}
          //Place clone behaviour here
          onConfirmSuccess={navigatetoCreatePage}
        />

        <Container fluid className="bg-defencelightgrey">
          <Container className="p-4 p-lg-6 landingPageWidth">
            <h2 className="m-lg-0 mb-3">
              My recent service requests{' '}
              <button title="Tooltip" className="border-0 bg-transparent" ref={target} onClick={() => setShow(!show)}>
                <InfoCircle size={15} />
              </button>
            </h2>

            <Overlay target={target.current} show={show} placement="bottom">
              {(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  Drafted requests will be deleted after 90 days of inactivity.
                </Tooltip>
              )}
            </Overlay>
            {sortedRequests.length > 0 ? (
              <>
                <TableHeader />
                {direction === true
                  ? sortedRequests
                      .map((value, key) => <FormPanel key={key} value={value} setShowCloneModal={openCloneModel} />)
                      .slice(trimStart, trimEnd)
                  : sortedRequests
                      .map((value, key) => <FormPanel key={key} value={value} setShowCloneModal={openCloneModel} />)
                      .reverse()
                      .slice(trimStart, trimEnd)}
              </>
            ) : (
              <p className="mt-2 fw-light w-100">{props.content.formNoText}</p>
            )}
          </Container>
          {requests.length > itemsPerPage && requests.length !== 0 && (
            <FormsPagination update={updatePagination} itemsPerPage={itemsPerPage} items={sortedRequests} />
          )}
        </Container>
      </>
    ) : null;
  };

  return <RequestPanel content={props.data} />;
};

export default ServReqList;
