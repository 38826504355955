import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useIdleTimer } from 'react-idle-timer';
import { useCountdown } from './countdown';
import axios from 'axios';
import { getURL } from '../../../helper/getAPIUrl';
import { getAuthURL } from '../../../helper/getOtherAppURL';
import AuthenticationModal from '../AuthenticationModal/AuthenticationModal';
import SystemOutageModal from '../../SystemOutageModal/SystemOutageModal';
import GenericErrorModal from '../../PlannedSystemOutageModal/PlannedSystemOutageModal';

import AuthenticationModalProps from '../../LandingPage/AuthenticationModal/__mocks__/default.json';
import GenericErrorModalProps from '../../GenericErrorModal/__mocks__/default.json';
import SystemOutageModalProps from '../../SystemOutageModal/__mocks__/default.json';

const IdleModal = (props) => {
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const redirect = setTimeout(() => {
    const loginURL = getAuthURL();
    window.location.href = loginURL;
  }, props.timeUntilRedirect);
  //When OK button is pressed on modal, send heartbeat, resume the idle checks and close the modal
  const handleClick = () => {
    props.setModalOpen(false);
    clearTimeout(redirect);
    let URL = process.env.REACT_APP_HEART_BEAT_API;
    URL = getURL(URL);
    axios
      .get(URL, {
        withCredentials: true,
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };

  return (
    <>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}
      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}
      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
        />
      )}
      <Modal show>
        <Modal.Body>
          You have been inactive for {props.idleTime / 60} minutes. In accordance with Defence ICT Security Policy you
          will be logged out after 15 minutes. If logged out, your progress will not be saved. Press OK to resume
          session or you will be logged out in
          <CountdownTimer targetDate={props.timeUntilRedirect + new Date().getTime()} />
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button onClick={handleClick}>OK</Button>
        </Modal.Footer>
      </Modal>{' '}
    </>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [minutes, seconds] = useCountdown(targetDate);
  return <ShowCounter minutes={minutes} seconds={seconds} />;
};

const ShowCounter = ({ minutes, seconds }) => {
  return (
    <div>
      {minutes}:{seconds}
    </div>
  );
};

export default function SessionTimeout(props) {
  //State for opening/closing the modal
  const [modalOpen, setModalOpen] = useState(false);
  //time until idle is activated. do not change name
  const timeout = props.idleTime * 1000;
  const onIdle = () => {
    setModalOpen(true);
  };
  const onAction = () => {
    reset();
  };

  //Idle timer
  const { reset } = useIdleTimer({
    timeout,
    onIdle,
    onAction,
  });

  return modalOpen ? (
    <IdleModal
      {...props}
      redirectURL={props.redirectURL}
      timeUntilRedirect={props.timeUntilRedirect * 1000}
      setModalOpen={setModalOpen}
    />
  ) : null;
}
