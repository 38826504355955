//IMPORTS
//Hooks/React
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getURL } from '../../helper/getAPIUrl';
//UI
import { Box, Heading, Text } from '@chakra-ui/react';
import { SCForm } from '../../components/ServiceUI/Core/FormElements';
import { Spinner } from '../../components/ServiceUI/Core/Spinner/Spinner';
//Functions
import { appConstants } from '../../helper/client/constant';
import convertSpecialChars from '../../helper/convertSpecialChars';
//Constants
import GenericErrorModal from '../../components/GenericErrorModal/GenericErrorModal';
import GenericErrorModalProps from '../../components/GenericErrorModal/__mocks__/default.json';
import AuthenticationModal from '../../components/LandingPage/AuthenticationModal/AuthenticationModal';
import AuthenticationModalProps from '../../components/LandingPage/AuthenticationModal/__mocks__/default.json';
import SystemOutageModal from '../../components/SystemOutageModal/SystemOutageModal';
import SystemOutageModalProps from '../../components/SystemOutageModal/__mocks__/default.json';
import {
  APPLICANT_TYPE_PEGA_VIEWID,
  EFTConstants,
  PIR_ALLOWED_FILE_EXT_SUPPORTING_DOC_STRING,
  REQUEST_DETAILS_PEGA_VIEWID,
} from './EFTConstant';

//Reducers
import {
  setApplicantTypeFromPega,
  setFieldValue,
  setTypeOFRecordsFromPega,
  setmemberDetailForAdvocate,
  setmemberDetailForFamilyData,
  setmemberDetailForSelf,
  updateSelectedApplicantType,
  updateSelectedTypeofRecord,
} from '../../reducers/eftRequestDetailFormReducer';

import requestContent from './Content/RequestInformation';

import {
  PIRAmend,
  PIRComm,
  PIRComp,
  PIRHistorical,
  PIRMedical,
  //PIROther,
  PIRRequest,
  PIRRoyal,
  PIRVet,
} from '../../components/ServiceUI/Core/icons/Outline';

import { refreshEFTForm } from './API/refreshEFTForm';
import content from './RequestDetailsFormEFT/fieldPropsMapping';

import DeliveryInformation from '../../components/ServiceUI/EFT/DeliveryInformation/DeliveryInformation';
import PIRDynamicFormRender from './RequestDetailsFormEFT/PIRDynamicFormRender';

//Types
import { RequestDetailEftProps, reactStateElements } from '../../components/ServiceUI/EFT/types';

export const RequestDetailEft: React.FC<RequestDetailEftProps> = (props: RequestDetailEftProps) => {
  const dispatch = useDispatch();
  const [ItemRenderedForRoyalCommison, setItemRenderedForRoyalCommison] = useState(false);
  const formData: reactStateElements = useSelector((state: any) => state.EFTRequestMemberForm.formFields);
  const [typeOfRecordsSelected, settypeOfRecordsSelected] = useState([] as Array<string>);
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState({
    isLoading: false,
  });
  const [ApplicantTypeAdvocateDatafromReducer, setApplicantTypeAdvocateDataFromReducer] = useState(
    formData?.ApplicantTypeAdvocateMemData,
  );
  const [ApplicantTypeSelfDataFromReducer, setApplicantTypeSelfDataFromReducer] = useState(
    formData?.ApplicantTypeSelfMemData ?? [],
  );
  const [ApplicantTypeFamilyDataFromReducer, setApplicantTypeFamilyDataFromReducer] = useState(
    formData?.ApplicantTypeFamilyMemData ?? [],
  );
  const [attachmentCatogry, setattachmentCatogry] = useState({
    selectedCategory: 'PersonnelDocuments',
    categories: [],
    categoriesOrig: [],
    selectedCategoryId: 'PersonnelDocuments',
    allowedFileExt: PIR_ALLOWED_FILE_EXT_SUPPORTING_DOC_STRING,
  });
  const [selectedApplicantType, setSelectedApplicantType] = useState('');
  const [showMedicalInfo, setshowMedicalInfo] = useState({
    show: false,
    displayMessage: '',
  });

  const [validated, setValidated] = useState(false);
  const [fieldsProcessed, setFieldsProcessed] = useState(false);

  useEffect(() => {
    if (
      formData?.ServiceType.value === EFTConstants.PIR_ROYAL_SERVICE_TYPE &&
      formData?.TypeofRecords[0] &&
      ItemRenderedForRoyalCommison === false
    ) {
      const personnelObj = formData?.TypeofRecords[0].find((item) => item.fieldID === 'IsPersonnelInformation');

      onTypeOfRecordsSelection(EFTConstants.YES_SELECTED, personnelObj);
      setItemRenderedForRoyalCommison(true);
    }
  }, [formData?.ServiceType]);

  const formSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated((prev) => !prev);
  };

  useEffect(() => {
    setValidated(false);
    setFieldsProcessed(true);
  }, [formData]);

  const dynamicFieldAction = (currValue, fieldID, reference, viewID, actionName) => {
    return new Promise((resolve, reject) => {
      const pageInstructionObj = {
        instruction: 'UPDATE',
        target: '.' + reference.replace('.' + fieldID, ''),
        content: {
          [fieldID]: currValue,
        },
      };
      let pageInstructions = [pageInstructionObj];
      if (fieldID !== 'ServiceType') {
        const serviceTypePegaeinstruction = {
          instruction: 'UPDATE',
          target: '.' + formData.ServiceType.reference.replace('.' + formData.ServiceType.fieldID, ''),
          content: {
            [formData.ServiceType.fieldID]: formData.ServiceType.value,
          },
        };
        pageInstructions = [...pageInstructions, serviceTypePegaeinstruction];
      }

      return refreshEFTForm(pageInstructions, props.nextAssignmentID, props.actionId, viewID).then((fieldList) => {
        dispatch(
          actionName({
            fieldList,
          }),
        );

        resolve('All Done');
      });
    });
  };
  const dynamicFieldActionWithMultipleObject = (currValue, fieldID, reference, viewID, actionName, contentObj) => {
    return new Promise((resolve, reject) => {
      const pageInstructionObj = {
        instruction: 'UPDATE',
        target: '.' + reference.replace('.' + fieldID, ''),
        content: contentObj,
      };

      let pageInstructions = [pageInstructionObj];
      if (fieldID !== 'ServiceType') {
        const serviceTypePegaeinstruction = {
          instruction: 'UPDATE',
          target: '.' + formData.ServiceType.reference.replace('.' + formData.ServiceType.fieldID, ''),
          content: {
            [formData.ServiceType.fieldID]: formData.ServiceType.value,
          },
        };
        pageInstructions = [...pageInstructions, serviceTypePegaeinstruction];
      }

      return refreshEFTForm(pageInstructions, props.nextAssignmentID, props.actionId, viewID)
        .then((fieldList) => {
          dispatch(
            actionName({
              fieldList,
            }),
          );
          resolve('All Done');
        })
        .catch((err) => {
          let error = { ...err };
          error.response = error.response || { response: { data: [] } };
          setAPIError(error);

          if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setErrorModal(true);
          }
        });
    });
  };
  const setShowMessageCheckFunc = (selectedFlag, recordObj) => {
    if (recordObj.fieldID === EFTConstants.PIR_MEDICAL_RECORDS_FIELDID && selectedFlag === EFTConstants.YES_SELECTED) {
      setshowMedicalInfo((prevState) => {
        return {
          ...prevState,
          show: selectedFlag,
          displayMessage: recordObj.control.modes[0].tooltip,
        };
      });
    } else if (
      recordObj.fieldID === EFTConstants.PIR_MEDICAL_RECORDS_FIELDID &&
      selectedFlag === EFTConstants.NO_SELECTED
    ) {
      setshowMedicalInfo((prevState) => {
        return {
          ...prevState,
          show: selectedFlag,
          displayMessage: '',
        };
      });
    } else {
      const isMedicalSelectedItem = formData?.TypeofRecords[0].filter(
        (recordItem: any) =>
          recordItem.fieldID === EFTConstants.PIR_MEDICAL_RECORDS_FIELDID &&
          recordItem.value === EFTConstants.YES_SELECTED,
      );
      setshowMedicalInfo((prevState) => {
        return {
          ...prevState,
          show: isMedicalSelectedItem.length > 0 ? true : false,
          displayMessage: isMedicalSelectedItem.length > 0 ? isMedicalSelectedItem[0].control.modes[0].tooltip : '',
        };
      });
    }
  };
  const onTypeOfRecordsSelection = (selectedVal, recordObj) => {
    // getAndDeleteAllAttachmentinTheCase().then((result) => {
    let ifAllNo = false;
    if (selectedVal === EFTConstants.NO_SELECTED) {
      let contObj = {};
      const arrItemwhichAreSelected = formData?.TypeofRecords[0].filter(
        (item) => item.fieldID !== recordObj.fieldID && item.value === EFTConstants.YES_SELECTED,
      );

      contObj[recordObj.fieldID] = selectedVal;
      if (arrItemwhichAreSelected.length > 0) {
        ifAllNo = false;
        for (let itemSelected = 0; itemSelected < arrItemwhichAreSelected.length; itemSelected++) {
          contObj[arrItemwhichAreSelected[itemSelected].fieldID] = arrItemwhichAreSelected[itemSelected].value;
        }
      } else {
        ifAllNo = true;
        contObj[EFTConstants.PIR_SERVICE_TYPE_FIELDID] = formData.ServiceType.value;
      }
      if (selectedApplicantType.length > 0) {
        contObj[formData.ApplicantType.fieldID] = selectedApplicantType;
      }

      const finalViewId = ifAllNo ? REQUEST_DETAILS_PEGA_VIEWID : APPLICANT_TYPE_PEGA_VIEWID;
      const finalAction = ifAllNo ? setTypeOFRecordsFromPega : setApplicantTypeFromPega;

      dynamicFieldActionWithMultipleObject(
        selectedVal,
        recordObj.fieldID,
        recordObj.reference,
        finalViewId,
        finalAction,
        contObj,
      );
    } else {
      let contObj1 = {};
      const arrItemwhichAreSelected = formData?.TypeofRecords[0].filter(
        (item) => item.fieldID !== recordObj.fieldID && item.value === EFTConstants.YES_SELECTED,
      );

      contObj1[recordObj.fieldID] = selectedVal;
      if (arrItemwhichAreSelected.length > 0) {
        ifAllNo = false;
        for (let itemSelected = 0; itemSelected < arrItemwhichAreSelected.length; itemSelected++) {
          contObj1[arrItemwhichAreSelected[itemSelected].fieldID] = arrItemwhichAreSelected[itemSelected].value;
        }
      } else {
        ifAllNo = true;
        contObj1[EFTConstants.PIR_SERVICE_TYPE_FIELDID] = formData.ServiceType.value;
      }
      if (selectedApplicantType.length > 0) {
        contObj1[formData.ApplicantType.fieldID] = selectedApplicantType;
      }

      dynamicFieldActionWithMultipleObject(
        selectedVal,
        recordObj.fieldID,
        recordObj.reference,
        APPLICANT_TYPE_PEGA_VIEWID,
        setApplicantTypeFromPega,
        contObj1,
      );
    }

    setShowMessageCheckFunc(selectedVal, recordObj);

    if (selectedVal === EFTConstants.YES_SELECTED) {
      const selectedItemArr = typeOfRecordsSelected.concat(recordObj.fieldID);
      settypeOfRecordsSelected(selectedItemArr);
    } else {
      const updatedArr = typeOfRecordsSelected.filter((item) => item !== recordObj.fieldID);
      settypeOfRecordsSelected(updatedArr);
    }
    if (ifAllNo === false) {
      dispatch(
        updateSelectedTypeofRecord({
          reference: recordObj.reference,
          value: selectedVal,
          formData,
          required: recordObj.required,
          visible: recordObj.visible,
        }),
      );
    }
    // });
  };
  const deleteAttachment = (file) => {
    // api call
    return new Promise((resolve, reject) => {
      let URL = process.env.REACT_APP_DELETE_FILE + file.ID;
      URL = getURL(URL);
      axios
        .delete(URL, {
          withCredentials: true,
        })
        .then((_) => {
          resolve('All deleted');
        })
        .catch((err) => {
          let error = { ...err };
          error.response = error.response || { response: { data: [] } };
          setAPIError(error);

          if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setErrorModal(true);
          }
        });
    });
  };
  const getAndDeleteAllAttachmentinTheCase = () => {
    return new Promise((resolve, reject) => {
      setLoading((prevState) => {
        return {
          ...prevState,
          isLoading: true,
        };
      });
      let URL = process.env.REACT_APP_GET_ATTACHMENT_ALL + props.caseIdOrig + '/attachments';
      URL = getURL(URL);
      axios
        .get(URL, {
          withCredentials: true,
        })
        .then((res) => {
          setLoading((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
          if (!!res.data.attachments) {
            const filteredArrayNonCorrespondese = res.data.attachments.filter(
              (file) => file.category !== appConstants.ATTACHMENT_TYPE_CORRESPONDENCE,
            );

            if (filteredArrayNonCorrespondese.length > 0) {
              var promises = filteredArrayNonCorrespondese.map((file) => {
                return deleteAttachment(file);
              });
              Promise.all(promises).then((data) => resolve(data));
            }
          } else {
            resolve('No file found');
          }
        })
        .catch((err) => {
          setLoading((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
          let error = { ...err };
          error.response = error.response || { response: { data: [] } };
          setAPIError(error);

          if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setErrorModal(true);
          }
        });
    });
  };
  const onApplicantTypeSelection = (recordObj) => {
    //on applicant type change we need to delete all attached document
    getAndDeleteAllAttachmentinTheCase().then((result) => {
      let contObj = {
        ApplicantType: recordObj.key,
        ServiceType: formData.ServiceType.value,
      };
      if (!!formData.TypeOfRecordsAdditionalFields[0] && formData.TypeOfRecordsAdditionalFields[0].length > 0) {
        formData.TypeOfRecordsAdditionalFields[0].forEach((item: any) => {
          contObj[item.fieldID] = item.value;
        });
      }

      for (let i = 0; i < typeOfRecordsSelected.length; i++) {
        contObj[typeOfRecordsSelected[i]] = 'Yes';
      }

      const finalViewId =
        recordObj.key === 'Self'
          ? 'PIRContactDetails_Self'
          : recordObj.key === 'Family'
          ? 'PIRContactDetails_Family'
          : 'PIRContactDetails_Advocate';

      const finalAction =
        recordObj.key === 'Self'
          ? setmemberDetailForSelf
          : recordObj.key === 'Family'
          ? setmemberDetailForFamilyData
          : setmemberDetailForAdvocate;

      dynamicFieldActionWithMultipleObject(
        recordObj.key,
        'ApplicantType',
        'PersonalInfoRequest.ApplicantType',
        finalViewId,
        finalAction,
        contObj,
      );

      setSelectedApplicantType(recordObj.key);

      dispatch(
        updateSelectedApplicantType({
          reference: recordObj.key,
          value: recordObj.key,
          formData,
          required: recordObj.required,
          visible: recordObj.visible,
        }),
      );
    });
  };

  const handleCallBackChangeServiceType = (type, valueforpega) => {
    // getAndDeleteAllAttachmentinTheCase().then((result) => {
    setSelectedApplicantType('');
    setshowMedicalInfo((prevState) => {
      return {
        ...prevState,
        show: false,
        message: '',
      };
    });
    dispatch(
      setFieldValue({
        reference: formData?.ServiceType?.reference,
        value: type,
        formData,
        required: formData?.ServiceType?.required,
        visible: formData?.ServiceType?.visible,
      }),
    );
    dynamicFieldAction(
      valueforpega,
      formData?.ServiceType.fieldID,
      formData?.ServiceType.reference,
      REQUEST_DETAILS_PEGA_VIEWID,
      setTypeOFRecordsFromPega,
    ).then((val) => {
      // code needs to be added later
    });
    // });
  };

  // Format Applicant data for Service Area checkbox

  const updatedDataWithCaption = (member) => {
    let updatedData = formData?.[member].map((item) => {
      let returnedFields = {};
      Object.keys(item).forEach((key) => {
        let allFields = item[key].map((newitem) => {
          if (Array.isArray(newitem)) {
            let options: any[] = [];
            Object.values(newitem).map((i: any) => i.type === 'pxCheckbox' && options.push(i));
            const obj = {
              fieldID: 'ServiceArea',
              type: 'multi',
              options: options,
              visible: true,
              label: 'Service Area',
              required: true,
            };

            return [obj];
          } else return newitem;
        });
        returnedFields = { [key]: allFields };
      });
      return returnedFields;
    });
    return updatedData;
  };

  useEffect(() => {
    if (formData?.ApplicantTypeSelfMemData && formData?.ApplicantTypeSelfMemData.length > 0) {
      setApplicantTypeSelfDataFromReducer(updatedDataWithCaption('ApplicantTypeSelfMemData'));
    }
    if (formData?.ApplicantTypeSelfMemData && formData?.ApplicantTypeSelfMemData.length === 0) {
      setApplicantTypeSelfDataFromReducer(formData?.ApplicantTypeSelfMemData);
    }
  }, [formData?.ApplicantTypeSelfMemData]);

  useEffect(() => {
    if (formData?.ApplicantTypeFamilyMemData && formData?.ApplicantTypeFamilyMemData.length > 0) {
      setApplicantTypeFamilyDataFromReducer(updatedDataWithCaption('ApplicantTypeFamilyMemData'));
    }
    if (formData?.ApplicantTypeFamilyMemData && formData?.ApplicantTypeFamilyMemData.length === 0) {
      setApplicantTypeFamilyDataFromReducer(formData?.ApplicantTypeFamilyMemData);
    }
  }, [formData?.ApplicantTypeFamilyMemData]);

  useEffect(() => {
    if (formData?.ApplicantTypeAdvocateMemData && formData?.ApplicantTypeAdvocateMemData.length > 0) {
      setApplicantTypeAdvocateDataFromReducer(updatedDataWithCaption('ApplicantTypeAdvocateMemData'));
    }
    if (formData?.ApplicantTypeAdvocateMemData && formData?.ApplicantTypeAdvocateMemData.length === 0) {
      setApplicantTypeAdvocateDataFromReducer(formData?.ApplicantTypeAdvocateMemData);
    }
  }, [formData?.ApplicantTypeAdvocateMemData]);

  return (
    <Box py="1rem" minH="650px">
      <Heading mt="1rem" as="h2" fontSize="sm" fontWeight="regular">
        Request information
      </Heading>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}
      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}

      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
        />
      )}

      <form onSubmit={(e) => formSubmit(e)} noValidate>
        {formData.ServiceType.fieldID && (
          <>
            <SCForm.Menu
              options={formData?.ServiceType?.control?.modes[0].options}
              value={
                Object.values(formData?.ServiceType?.control?.modes[0].options)?.find(
                  (i: any) => i.key === formData.ServiceType?.value,
                )?.value ?? formData?.ServiceType?.control?.modes[0].options[0].value
              }
              onSelect={(e) => {
                handleCallBackChangeServiceType(e.value, e.key);
              }}
              desktopWidth={8}
              required
              visible
              label={formData?.ServiceType?.label}
              isInvalid={formData?.ServiceType?.error}
              errorMessage={content[formData.ServiceType.fieldID].required.errorMessage}
            />
          </>
        )}
        {formData?.TypeofRecords[0] && formData?.TypeofRecords[0]?.length > 0 && (
          <>
            <Box bg="navy" borderRadius="0.5rem" padding="2rem" my="20px" color="white" minH="220px" maxW="714px">
              {formData.ServiceType?.value === 'Amendment' && <PIRAmend />}
              {formData.ServiceType?.value === 'Commemoration' && <PIRComm />}
              {formData.ServiceType?.value === 'Compensation' && <PIRComp />}
              {formData.ServiceType?.value === 'Historical' && <PIRHistorical />}
              {formData.ServiceType?.value === 'Medical' && <PIRMedical />}
              {/* {formData.ServiceType?.value === 'Other' && <PIROther />} */}
              {formData.ServiceType?.value === 'Other' && <PIRRequest />}
              {formData.ServiceType?.value === 'RoyalCommission' && <PIRRoyal />}
              {formData.ServiceType?.value === 'Veteran' && <PIRVet />}
              <Heading as="h3" size="xs" color="white" mt="7px" fontWeight="medium">
                {
                  Object.values(formData?.ServiceType?.control?.modes[0].options)?.find(
                    (i: any) => i.key === formData.ServiceType?.value,
                  )?.value
                }
              </Heading>
              <Text fontWeight="300" fontSize="18px">
                {/* {bannerCaption[formData.ServiceType.value]} */}
                {requestContent.bannerCaption[formData.ServiceType.value]}
              </Text>
            </Box>
            <SCForm.Multi
              label="Type of Records"
              onCheckboxChange={(e) => {
                onTypeOfRecordsSelection(
                  e.value === EFTConstants.NO_SELECTED ? EFTConstants.YES_SELECTED : EFTConstants.NO_SELECTED,
                  e,
                );
              }}
              desktopWidth={12}
              required
              visible
              options={formData?.TypeofRecords[0]?.map((i) => {
                return {
                  ...i,
                  value: convertSpecialChars(i.value),
                  key: i.label,
                  isChecked: i.value === 'Yes',
                };
              })}
              isInvalid={formData?.TypeofRecords[0]?.every((item) => item.value === 'No')}
              errorMessage="Please select at least one value against Type of Records."
              showTooltip
              tooltipContent="Please see 'RequestInformationTypes' tab for additional text to be
              provided against each selection."
            />
            {showMedicalInfo.show && <Text>{convertSpecialChars(showMedicalInfo.displayMessage)}</Text>}
          </>
        )}

        {formData?.TypeOfRecordsAdditionalFields[0] && formData?.TypeOfRecordsAdditionalFields[0].length > 0 && (
          <PIRDynamicFormRender
            caseID=""
            caseIDOriginal=""
            nextAssignmentId={props.nextAssignmentID}
            actionID={props.actionId}
            isReview={false}
            attachmentCatogry="image"
            formDataTorender={formData?.TypeOfRecordsAdditionalFields[0]}
            currentStateData={formData}
            selectedTypeOfRecords={typeOfRecordsSelected}
            deleteAndUpdateDoc={getAndDeleteAllAttachmentinTheCase}
          ></PIRDynamicFormRender>
        )}
        {formData?.ApplicantType && Object.keys(formData?.ApplicantType).length > 0 && (
          <Box>
            <Heading mt="1rem" as="h2" fontSize="sm" fontWeight="regular">
              Personal information
            </Heading>
            <SCForm.Radio
              label="Applicant Type"
              required
              variant="eft"
              value={formData?.ApplicantType?.value}
              onSelectRadio={(e) => onApplicantTypeSelection(e)}
              options={formData?.ApplicantType?.control.modes[0].options.map((i) => {
                return {
                  ...i,
                  value: convertSpecialChars(i.value),
                };
              })}
              isInvalid={formData?.ApplicantType?.value.length === 0}
              visible
              errorMessage="Please select the type of applicant that represents you."
              showTooltip={true}
            />
          </Box>
        )}

        {ApplicantTypeSelfDataFromReducer?.length > 0 && (
          <>
            <Heading mt="1rem" as="h3" fontSize="sm" fontWeight="regular">
              Member information
            </Heading>
            <PIRDynamicFormRender
              caseID=""
              caseIDOriginal=""
              nextAssignmentId={props.nextAssignmentID}
              actionID={props.actionId}
              isReview={false}
              attachmentCatogry="image"
              formDataTorender={ApplicantTypeSelfDataFromReducer[0].self}
              currentStateData={formData}
              selectedTypeOfRecords={typeOfRecordsSelected}
              deleteAndUpdateDoc={getAndDeleteAllAttachmentinTheCase}
            ></PIRDynamicFormRender>
          </>
        )}

        {ApplicantTypeFamilyDataFromReducer?.length > 0 && (
          <>
            {/* Split into 2 ApplicantTypeFamilyDataFromReduceMember */}

            <Heading mt="1rem" as="h3" fontSize="sm" fontWeight="regular">
              Member information
            </Heading>
            <PIRDynamicFormRender
              caseID=""
              caseIDOriginal={props.caseIdOrig}
              nextAssignmentId={props.nextAssignmentID}
              actionID={props.actionId}
              isReview={false}
              attachmentCatogry={attachmentCatogry}
              formDataTorender={ApplicantTypeFamilyDataFromReducer[0].member}
              currentStateData={formData}
              selectedTypeOfRecords={typeOfRecordsSelected}
              deleteAndUpdateDoc={getAndDeleteAllAttachmentinTheCase}
            ></PIRDynamicFormRender>
            {/* Split into 2 ApplicantTypeFamilyDataFromReducerFamily */}
            <Heading mt="1rem" as="h3" fontSize="sm" fontWeight="regular">
              My information (the family member)
            </Heading>
            <PIRDynamicFormRender
              caseID=""
              caseIDOriginal={props.caseIdOrig}
              nextAssignmentId={props.nextAssignmentID}
              actionID={props.actionId}
              isReview={false}
              attachmentCatogry={attachmentCatogry}
              formDataTorender={ApplicantTypeFamilyDataFromReducer[1].family}
              currentStateData={formData}
              selectedTypeOfRecords={typeOfRecordsSelected}
              deleteAndUpdateDoc={getAndDeleteAllAttachmentinTheCase}
            ></PIRDynamicFormRender>
          </>
        )}
        {ApplicantTypeAdvocateDatafromReducer?.length > 0 && (
          <>
            {/* Split into 2 ApplicantTypeFamilyDataFromReduceMember */}
            <Heading mt="1rem" as="h3" fontSize="sm" fontWeight="regular">
              Member information
            </Heading>
            <PIRDynamicFormRender
              caseID=""
              caseIDOriginal={props.caseIdOrig}
              nextAssignmentId={props.nextAssignmentID}
              actionID={props.actionId}
              isReview={false}
              attachmentCatogry={attachmentCatogry}
              formDataTorender={ApplicantTypeAdvocateDatafromReducer[0].applicant}
              currentStateData={formData}
              selectedTypeOfRecords={typeOfRecordsSelected}
              deleteAndUpdateDoc={getAndDeleteAllAttachmentinTheCase}
            ></PIRDynamicFormRender>
            {ApplicantTypeAdvocateDatafromReducer[1].deceased.length > 0 && (
              <Box bgColor="#F5F5F5" my="30px" py="45px" px="30px">
                <h2>Requesting information of a deceased member</h2>
                <p>
                  To request information for a deceased ADF member as an advocate, you will need to provide identity
                  documentation for a family member.
                </p>
                <h3>Family member details</h3>
                <PIRDynamicFormRender
                  caseID=""
                  caseIDOriginal={props.caseIdOrig}
                  nextAssignmentId={props.nextAssignmentID}
                  actionID={props.actionId}
                  isReview={false}
                  attachmentCatogry={attachmentCatogry}
                  formDataTorender={ApplicantTypeAdvocateDatafromReducer[1].deceased}
                  currentStateData={formData}
                  selectedTypeOfRecords={typeOfRecordsSelected}
                  deleteAndUpdateDoc={getAndDeleteAllAttachmentinTheCase}
                ></PIRDynamicFormRender>
              </Box>
            )}

            <Heading mt="1rem" as="h3" fontSize="sm" fontWeight="regular">
              My information (the advocate)
            </Heading>
            <PIRDynamicFormRender
              caseID=""
              caseIDOriginal={props.caseIdOrig}
              nextAssignmentId={props.nextAssignmentID}
              actionID={props.actionId}
              isReview={false}
              attachmentCatogry={attachmentCatogry}
              formDataTorender={ApplicantTypeAdvocateDatafromReducer[2].advocateDetails}
              currentStateData={formData}
              selectedTypeOfRecords={typeOfRecordsSelected}
              deleteAndUpdateDoc={getAndDeleteAllAttachmentinTheCase}
            ></PIRDynamicFormRender>
          </>
          // Part 2 Requesting information of a deceased member
          // Part 3 My information (the advocate)
        )}

        {/* DELIVERY INFORMATION */}
        {formData?.DeliveryDetailsData &&
          formData?.DeliveryDetailsData.length > 0 &&
          formData?.DeclarationData &&
          formData?.DeclarationData.length > 0 && (
            <DeliveryInformation
              deliveryData={formData?.DeliveryDetailsData[0]}
              declarationData={formData?.DeclarationData[0]}
              caseID=""
              caseIDOriginal={props.caseIdOrig}
            />
          )}
      </form>
      {loading.isLoading && <Spinner />}
    </Box>
  );
};
