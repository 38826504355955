import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Heading,
  RadioGroup,
  Radio,
  FormLabel,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { SCForm } from '../FormElements';
import InfoBox from '../InfoBox/InfoBox';
import { V_Sad, sad, Neutral, happy, V_Happy, Alert, Success as SuccessIcon } from '../icons/Outline';

import { ModalProps } from './Feedback';

const Radios = [
  { value: { title: 'Very Poor', value: '1' }, color: 'red', icon: V_Sad },
  { value: { title: 'Poor', value: '2' }, color: 'orange', icon: sad },
  { value: { title: 'Neutral', value: '3' }, color: 'blue', icon: Neutral },
  { value: { title: 'Good', value: '4' }, color: 'cyan', icon: happy },
  { value: { title: 'Very Good', value: '5' }, color: 'green', icon: V_Happy },
];

export interface Extras {
  isOpen: boolean;
  onClose(): void;
}

export type PropUnion = ModalProps & Extras;

const FeedbackModal: React.FC<PropUnion> = (props: PropUnion): JSX.Element => {
  const submitFeedback = () => {
    const data = {
      service: service,
      rating: rating,
      detail: review,
    };
    props.submitAction &&
      props
        .submitAction(data)
        .then(() => setSubmitted(true))
        .catch(() => setError(true));
  };

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [service, setService] = useState(props.services[0].value);
  const [rating, setRating] = useState('');
  const [review, setReview] = useState('');
  const closeAction = () => {
    setSubmitted(false);
    setError(false);
    setService(props.services[0].value);
    setRating('');
    setReview('');
    props.onClose();
  };
  const Success = () => {
    return (
      <>
        <ModalHeader as="h1">
          <SuccessIcon height="4rem" />
          {props.thankYouHeading}
        </ModalHeader>
        <ModalBody>
          <Text fontWeight="light">{props.thankYouDescription}</Text>
          <InfoBox>
            <Heading as="h1" fontSize="sm">
              {props.usageHeading}
            </Heading>
            <Text fontWeight="light">{props.usageContent}</Text>
          </InfoBox>
        </ModalBody>
      </>
    );
  };

  const Error = () => {
    return (
      <>
        <ModalHeader>
          <Alert height="4rem" />
          <Heading as="h1" fontSize="xxlarge">
            {props.errorHeading}
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Text fontWeight="light">{props.errorDescription}</Text>
        </ModalBody>
      </>
    );
  };
  return (
    <Modal
      data-testid="feedback-modal"
      motionPreset="none"
      variant="feedback"
      size="xlarge"
      isOpen={props.isOpen}
      closeOnOverlayClick={false}
      onClose={closeAction}
    >
      <ModalOverlay />
      <ModalContent padding={{ lg: '2rem', base: '1rem' }}>
        {!submitted && !error && (
          <>
            <ModalHeader as="h1" fontSize="xxlarge">
              {props.modalHeading}
            </ModalHeader>
            <ModalBody>
              <Text fontWeight="light">{props.modalDescription}</Text>
              <Heading as="h3" margin="1rem 0" fontSize="xlarge">
                {props.serviceHeading}
              </Heading>
              <SCForm.Select
                desktopWidth={12}
                visible={true}
                onSelect={(e) => setService(e)}
                options={props.services}
              />
              <Heading as="h3" margin="1rem 0" fontSize="xlarge">
                {props.ratingHeading} {service}?
              </Heading>
              <RadioGroup onChange={setRating} value={rating}>
                <Grid
                  gridTemplateColumns={{
                    lg: 'repeat(5,1fr)',
                    base: 'repeat(2,1fr)',
                  }}
                  gap={3}
                  mb="3rem"
                >
                  {Radios.map((item, idx) => {
                    const Icon = item.icon;
                    return (
                      <GridItem colSpan={1} textAlign={'center'} key={item.value.title}>
                        <Radio
                          w={{ md: '75%', base: '100%' }}
                          variant="smile"
                          value={item.value.value}
                          backgroundColor={`smile_${item.color} !important`}
                          mb="2rem"
                        >
                          {Icon && <Icon />}
                          <FormLabel m="0" textAlign="center" mt="1rem" fontSize="regular">
                            {item.value.title}
                          </FormLabel>
                        </Radio>
                      </GridItem>
                    );
                  })}
                </Grid>
              </RadioGroup>
              <Heading as="h3" mb="1rem" fontSize="xlarge">
                {props.reviewHeading}
              </Heading>
              <SCForm.TextArea desktopWidth={12} value={review} visible={true} onBlur={(e) => setReview(e)} />
            </ModalBody>
          </>
        )}
        {submitted && <Success />}
        {error && <Error />}
        <ModalFooter
          justifyContent={!submitted && !error ? 'space-between' : 'flex-end'}
          gap={{ lg: 0, base: 2 }}
          flexDirection={{ lg: 'row', base: 'column' }}
        >
          <Button w={{ lg: 'auto', base: '100%' }} onClick={closeAction} variant={submitted ? 'primary' : 'secondary'}>
            {props.closeCTAText}
          </Button>
          {!submitted && !error && (
            <Button
              w={{ lg: 'auto', base: '100%' }}
              variant="primary"
              onClick={submitFeedback}
              disabled={review === '' && rating === ''}
            >
              {props.submitCTAText}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FeedbackModal;
