import React, { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';

export interface InfoBox {
  children: ReactElement | ReactElement[];
}

const InfoBox: React.FC<InfoBox> = (props: InfoBox): JSX.Element => {
  return (
    <Box bg="lightblue" padding={{ lg: '2rem', base: '1rem' }}>
      {props.children}
    </Box>
  );
};

export default InfoBox;
