import { Container, Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { StatusBadge } from '../../StatusBadge/StatusBadge';
import content from '../content/ViewServiceRequest.json';
import { appConstants } from '../../../../helper/client/constant';
import { CloneModal } from '../../CloneRequest/CloneModal/CloneModal';
import clonecontent from '../../Content/Home.json';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const requestContent = content.keyrequestinfo;
const statusMap = appConstants.SR_STATUS_MAP;

const KeyRequestInfo = (props) => {
  const navigate = useNavigate();

  const [showCloneModal, setShowCloneModal] = useState(false);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Your ServiceConnect ID can be used to identify a submitted request before it is received and accepted by the BSSC.
      If you experience an issue with your request before it is accepted by the BSSC, please contact 1800DEFENCE (1800
      333 362). You will be asked to provide the ServiceConnect ID for your request. You can disregard your
      ServiceConnect ID once your service request number has been created.
    </Tooltip>
  );
  const renderSRNumberTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Your service request number is the 12-digit number assigned to your submitted base service request. Service
      request numbers are created once your request is received and accepted by the BSSC. You will be asked to provide
      this number if you contact the BSSC for further assistance with your request.
    </Tooltip>
  );
  const navigatetoCreatePage = (clonedCaseData) => {
    console.log(clonedCaseData.CloneSRID, clonedCaseData.ServiceType);
    const RequestType =
      clonedCaseData.ServiceType === appConstants.SERVICE_REQUEST_TYPE
        ? appConstants.SERVICE_REQUEST_TYPE_MINOR
        : appConstants.SERVICE_REQUEST_TYPE_FULL;
    const path = `/my-service-request/edit-service-request-create/${clonedCaseData.CloneSRID}?type=${RequestType}`;

    navigate(path);
  };
  return (
    <Container className="landingPageWidth pt-3 px-4 px-lg-6">
      <Container className="bg-defencelightblue p-2 p-lg-5">
        <CloneModal
          show={showCloneModal}
          {...clonecontent.cloneModal}
          onClose={() => setShowCloneModal(false)}
          caseID={props.caseID}
          onsetAPIError={(data) => props.OnsetAPIError(data)}
          onsetShowModel={() => props.OnsetShowModal()}
          onsetserviceDownModel={() => props.OnsetServiceDownModal()}
          onsetErrorModel={() => props.OnsetErrorModal()}
          onConfirmSuccess={navigatetoCreatePage}
        />
        <h2>{requestContent.heading}</h2>
        <h3>{requestContent.nameLabel}</h3>
        <p>{props.details.data[0].RequestTitle}</p>
        <Container className="d-flex justify-content-start">
          <Container className="w-auto me-4">
            <p className="fw-bold">{requestContent.dateLabel}</p>
            <p>{new Date(props.details.data[0].SubmissionDateTime).toDateString()}</p>
          </Container>
          <Container className="w-auto me-4">
            <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
              <p className="fw-bold">
                {requestContent.IDLabel}
                <InfoCircle size={15} className="d-inline ms-2" />
              </p>
            </OverlayTrigger>

            <p>{props.details.data[0].CaseID}</p>
          </Container>

          {props.notificationId.length > 0 && (
            <Container className="w-auto me-4">
              <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderSRNumberTooltip}>
                <p className="fw-bold">
                  {requestContent.SRNumber}
                  <InfoCircle size={15} />
                </p>
              </OverlayTrigger>
              <p>{props.notificationId}</p>
            </Container>
          )}
        </Container>
        <h3>{requestContent.statusLabel}</h3>

        <StatusBadge status={props.status} />
        <Container className="mb-3">
          {statusMap[props.status]?.map((item, key) => (
            <>{item?.message}</>
          ))}
        </Container>
        <Button onClick={() => setShowCloneModal(true)}>Clone</Button>
      </Container>
    </Container>
  );
};

export default KeyRequestInfo;
