const requestContent = {
  bannerCaption: {
    Amendment:
      'Amendment or annotation to correct personal information that is incomplete, incorrect, out-of-date or misleading',
    Commemoration:
      "Request for a Statement of Service, or eulogy details for a funeral or memorial service that reference an ex-member's ADF service",
    Compensation: 'Compensation or legal claims where evidence of ADF service and health assessments are required',
    Historical:
      'Historical research where details of an ADF service is being referenced for family or personal research activity',
    Medical: 'Request for service and medical information following medical separation from Defence',
    Veteran: 'Membership to veteran organisations where evidence of ADF service is required',
    RoyalCommission:
      'Royal Commission into Defence and Veteran Suicide where applicants are seeking information required by the Royal Commission',
    Other: '',
  },
  businessBannerCaption: {
    Amendment:
      'Personal information requests for amendment or annotation to correct personal information that is incomplete, incorrect, out-of-date or misleading',
    Commemoration:
      "Personal information requests for a Statement of Service, or eulogy details for a funeral or memorial service that reference an ex-member's ADF service",
    Compensation:
      'Personal information requests for compensation or legal claims where evidence of ADF service and health assessments are required',
    Historical:
      'Personal information requests for historical research where details of an ADF service is being referenced for family or personal research activity',
    Medical:
      'Personal information requests for service and medical information following medical separation from Defence',
    Veteran:
      'Personal information requests for Membership to veteran organisations where evidence of ADF service is required',
    RoyalCommission:
      'Personal information requests for Royal Commission into Defence and Veteran Suicide where applicants are seeking information required by the Royal Commission',
    Other: 'Other personal information requests',
  },
};
export default requestContent;
