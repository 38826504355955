import * as React from 'react';
import { SVGProps } from 'react';
const Tick = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="27" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#CF4520"
        fillRule="evenodd"
        d="M10.603 17.26a.372.372 0 0 1-.533 0l-5.197-5.35a.3.3 0 0 0-.428 0l-3.046 3.24a2.352 2.352 0 0 0-.124 2.42c.085.18.2.34.342.47l7.72 7.84c.305.32.714.5 1.143.5.428 0 .837-.18 1.142-.5l7.482-8.13c.143-.16.266-.35.361-.55a2.42 2.42 0 0 0-.209-2.29l-8.653 9.31a.3.3 0 0 1-.428 0l-7.358-7.46a.397.397 0 0 1-.105-.27c0-.11.038-.21.105-.29l1.37-1.47c.058-.06.134-.1.22-.1.085 0 .161.04.218.1l5.436 5.46c.152.15.39.15.533 0l3.76-4.18 1.18-1.35.238-.27 3.17-3.51 5.093-5.46c.637-.7.79-1.75.361-2.62a2.451 2.451 0 0 0-.361-.53L22.302.56c-.323-.36-.78-.57-1.256-.57s-.933.2-1.257.57l-4.245 4.62-5.093 5.46a.3.3 0 0 1-.428 0L7.786 8.46 6.5 7.24l-.638-.73a2.095 2.095 0 0 0-2.132-.53c-.353.11-.667.31-.914.58L1.484 8a2.68 2.68 0 0 0-.523.98 2.41 2.41 0 0 0-.086.64c.019.48.171.95.447 1.33L4.13 7.8a.323.323 0 0 1 .448 0l.657.67L6.52 9.78l3.465 3.64c.123.12.323.12.447 0l5.074-5.46 5.587-6.18 1.733 1.66c.066.08.104.18.104.29 0 .1-.038.2-.104.27l-7.13 7.73-5.093 5.53Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.557 0h23.75v26.38H.557z" />
      </clipPath>
    </defs>
  </svg>
);
export default Tick;
