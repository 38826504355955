import React, { useEffect } from 'react';

import content from '../content/content/Search.json';
import Header from '../LandingPage/Header/Header';
import ThreeCtaPanels from '../LandingPage/ThreeCtaPanels/ThreeCtaPanels';
import SearchUI from '../LandingPage/Search/Search';
import { getBaseURL, getFormsURL } from '../../helper/getOtherAppURL';
import { appConstants } from '../../helper/client/constant';

const Search = () => {
  useEffect(() => {
    document.title = 'Search';
  });
  return (
    <>
      <Header
        mainHeading={content.header.mainHeading}
        subHeading={content.header.subHeading}
        breadcrumbs={content.header.breadcrumbs}
        breadcrumbItems={content.header.breadcrumbItems}
      />

      <SearchUI />

      <ThreeCtaPanels
        heading={content.panels.heading}
        title_TopLeft={content.panels.title_TopLeft}
        description_TopLeft={content.panels.description_TopLeft}
        ctaText_TopLeft={content.panels.ctaText_TopLeft}
        ctaUrl_TopLeft={content.panels.ctaUrl_TopLeft}
        isExternal_TopLeft={content.panels.isExternal_TopLeft}
        title_TopRight={content.panels.title_TopRight}
        description_TopRight={content.panels.description_TopRight}
        ctaText_TopRight={content.panels.ctaText_TopRight}
        ctaUrl_TopRight={
          content.panels.ctaText_TopRight === appConstants.VISIT_MY_FORMS
            ? getFormsURL()
            : content.panels.ctaUrl_TopRight
        }
        isExternal_TopRight={content.panels.isExternal_TopRight}
        title_BottomLeft={content.panels.title_BottomLeft}
        description_BottomLeft={content.panels.description_BottomLeft}
        ctaText_BottomLeft={content.panels.ctaText_BottomLeft}
        ctaUrl_BottomLeft={
          content.panels.ctaText_BottomLeft === appConstants.VISIT_MY_BASE
            ? getBaseURL()
            : content.panels.ctaUrl_BottomLeft
        }
        isExternal_BottomLeft={content.panels.isExternal_BottomLeft}
        alt_BottomLeft={content.panels.alt_BottomLeft}
        alt_TopLeft={content.panels.alt_TopLeft}
        alt_TopRight={content.panels.alt_TopRight}
      />
    </>
  );
};

export default Search;
