import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './RecentItems.scss';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getURL } from '../../../helper/getAPIUrl';

import AuthenticationModal from '../AuthenticationModal/AuthenticationModal';
import SystemOutageModal from '../../SystemOutageModal/SystemOutageModal';
import GenericErrorModal from '../../GenericErrorModal/GenericErrorModal';

import AuthenticationModalProps from '../../LandingPage/AuthenticationModal/__mocks__/default.json';
import GenericErrorModalProps from '../../GenericErrorModal/__mocks__/default.json';
import SystemOutageModalProps from '../../SystemOutageModal/__mocks__/default.json';

import { appConstants } from '../../../helper/client/constant';
import { Heading } from '@chakra-ui/react';

const RecentItems = (props) => {
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const routeChangetoEdit = (caseId, type) => {
    const path = `/my-service-request/edit-service-request-create/${caseId}?type=${type}`;
    navigate(path);
  };
  const routeChangetoReview = (caseId, type) => {
    const pathForReview = `/my-service-request/edit-service-request-review/${caseId}?type=${type}`;
    navigate(pathForReview);
  };

  const viewRequestHandler = (ReqData) => {
    if (
      ReqData.pyStatusWork === appConstants.SUBMITTED_SERVICE_REQUEST ||
      ReqData.pyStatusWork === appConstants.SUBMITTED_SERVICE_REQUEST_RECEIVED_GEMS ||
      ReqData.pyStatusWork === appConstants.SUBMITTED_SERVICE_REQUEST_COMPLETED_GEMS ||
      ReqData.pyStatusWork === appConstants.SUBMITTED_SERVICE_REQUEST_IN_PROGRESS_GEMS ||
      ReqData.pyStatusWork === appConstants.SUBMITTED_SERVICE_REQUEST_REJECTED_GEMS ||
      ReqData.pyStatusWork === appConstants.SUBMITTED_SERVICE_REQUEST_ERROR_GEMS ||
      ReqData.pyStatusWork === appConstants.CANCELLED_SERVICE_REQUEST
    ) {
      navigate('/my-service-request/view-service-request/' + ReqData.pzInsKey);
    } else {
      const caseId = ReqData.pzInsKey;
      const RequestType =
        ReqData.ServiceRequest.ServiceType === appConstants.SERVICE_REQUEST_TYPE
          ? appConstants.SERVICE_REQUEST_TYPE_MINOR
          : appConstants.SERVICE_REQUEST_TYPE_FULL;
      if (ReqData.pyStatusWork === appConstants.NEW_SERVICE_REQUEST) {
        routeChangetoEdit(caseId, RequestType);
      } else {
        routeChangetoReview(caseId, RequestType);
      }
    }
  };
  const [recentList, setRecentList] = useState();

  useEffect(() => {
    props.onrecentListcameFromAPI(true);
    let URL = process.env.REACT_APP_GET_RECENT_REQUEST;
    URL = getURL(URL);
    axios(URL, {
      withCredentials: true,
    })
      .then((response) => {
        props.onrecentListcameFromAPI(false);
        setRecentList(response.data.pxResults.slice(0, 4));
      })
      .catch((err) => {
        props.onrecentListcameFromAPI(false);
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);

        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  }, []);
  return (
    <>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}

      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}

      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
        />
      )}

      <Container fluid className="text-black">
        <Heading as="h2" size="xs" color="black" fontWeight="medium" mb={{ md: '48px', base: '12px' }}>
          {props.mainHeading}
        </Heading>
        <Container className="p-3 bg-defencewhite">
          {recentList && recentList.length > 0 ? (
            recentList.map((value, key) => {
              return (
                <Row key={key} className="child">
                  <Col>
                    <Container className="flex-grow-1 d-flex flex-column justify-content-end">
                      <h3 className="sr-long-name pt-2">
                        {value.ServiceRequest.RequestTitle
                          ? value.ServiceRequest.RequestTitle
                          : appConstants.SERVICE_REQUEST_DEFAULT_TITLE}
                      </h3>
                      <Row>
                        <Col>
                          <Container className="sr-long-name text-black tags">
                            {value.ServiceRequest.WorkType !== 'Select' ? value.ServiceRequest.WorkType : ''}{' '}
                            {/* if worktype is not select AND worksubtype is not select THEN display the subworktype */}
                            {value.ServiceRequest.WorkType !== 'Select' && value.ServiceRequest.WorkSubType !== 'Select'
                              ? value.ServiceRequest.WorkSubType
                              : ''}
                          </Container>
                        </Col>
                        <Col>
                          <Container className="text-defenceorange d-flex align-items-end float-end text-end w-auto">
                            <a
                              onClick={() => viewRequestHandler(value)}
                              className="text-nowrap mb-0 arrow d fw-bold view-btn-cursor text-decoration-none"
                            >
                              View
                            </a>
                          </Container>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              );
            })
          ) : (
            <Container className="py-3 child">
              <h3 className="no-recent-items">{appConstants.NO_RECENT_ITEMS}</h3>
            </Container>
          )}
        </Container>
      </Container>
    </>
  );
};

export default RecentItems;
