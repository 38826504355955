import React from 'react';
import './MoreInfoNotification.scss';
import '../../../sass/custom.scss';
import { Container, Image, Badge, Button } from 'react-bootstrap';
import arrow from '../../../images/backArrow.svg';

const MoreInfoNotification = (props) => {
  const DetermineStatus = (currentStatus) => {
    const upperStatus = currentStatus.toUpperCase();
    if (upperStatus === 'URGENT') {
      return (
        <Badge pill bg="defencealerturgent" className="text-white px-2">
          URGENT
        </Badge>
      );
    } else {
      return (
        <Badge pill bg="white" className=" text-black px-2 pillStyles">
          {upperStatus}
        </Badge>
      );
    }
  };

  const date = new Date(props.infoProps.notificationDate);
  const formattedDate = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();

  return (
    <Container fluid className="px-lg-4" show={props.show}>
      <Container className="py-4 p-lg-0">
        <Button
          data-testid="backButton"
          variant="link"
          className="desktop pb-3 backButton d-flex align-items-center"
          onClick={props.close}
        >
          <Image className="pe-2" src={arrow} alt="arrow" />
          Back
        </Button>
        <Button
          data-testid="backButton"
          variant="outline-dark"
          className="mobile px-4 d-flex align-items-center"
          onClick={props.close}
        >
          <Image className="pe-2" src={arrow} alt="arrow" />
          Back
        </Button>
        <Container className="mobile pt-5">{formattedDate}</Container>
      </Container>
      <p className="desktop badgeSpace float-end">{formattedDate}</p>
      {props.infoProps.tags.map((value, key) => {
        return (key = { key }), (<Container className="badgeSpace pe-2">{DetermineStatus(value.value)}</Container>);
      })}
      <Container className="pt-4">
        <h3>{props.infoProps.notificationTitle}</h3>
      </Container>
      <Container className="text-black" dangerouslySetInnerHTML={{ __html: props.infoProps.longMessage }} />
    </Container>
  );
};

export default MoreInfoNotification;
