import remCalc from '../helper/remCalc';

export const lineHeights = {
  xxxxlarge: remCalc(68),
  xxxlarge: remCalc(56),
  xxlarge: remCalc(36),
  xlarge: remCalc(28),
  large: remCalc(22),
  regular: remCalc(24),
  small: remCalc(14),
  xsmall: remCalc(12),
};
