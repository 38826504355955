import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { TimePicker } from '../../../commonComponents/sc-react-timepicker';
import {
  validateTime,
  getMinutesFromHHMM,
  toHHMM,
} from '../../../commonComponents/sc-react-timepicker/helpers/TimePickerBlurEvent';
import { findByProp } from '../../../../helper/findByProp';
import { setDateValidation, setDayName, setFieldValue } from '../../../../reducers/requestDetailFormReducer';
import { FormFieldCol } from './styles';

const FormDateInput = (props) => {
  const { fieldID, fieldType, required, label, fieldPropMapping, visible, reference, formData, value } = props;
  const dispatch = useDispatch();

  const reqDetailDataFromReduxStore = useSelector((state) => {
    if (!!state) {
      return findByProp(state.requestForm.formFields, 'reference', reference);
    }
  });

  const [displayValue, setDisplayValue] = useState('');
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 10);
  const maxDateFormatted = maxDate.toISOString().slice(0, 10);

  const minDate = new Date();
  minDate.setMonth(minDate.getMonth());
  minDate.setFullYear(minDate.getFullYear());
  minDate.setDate(minDate.getDate() + 7);
  const minDateFormatted = minDate.toISOString().slice(0, 10);

  const convertTimeFrom12To24 = (time) => {
    var timeStr = time.toString().substring(0, 5);
    var secondsValue = Math.max(0, getMinutesFromHHMM(timeStr));

    var ampm = '';
    var timeSuffix = time.match(/\s(.*)$/);
    if (timeSuffix) {
      ampm = timeSuffix[1];
    }

    // reduce to single day
    if (secondsValue > 1440) {
      secondsValue = Math.floor(secondsValue / 1440);
    }

    // < 12:00 is AM
    if (secondsValue < 720 && !ampm) {
      ampm = 'AM';
    }

    // > 12:00 is PM
    if (secondsValue >= 720 && !ampm) {
      ampm = 'PM';
    }

    // 24:00 is AM
    if (secondsValue === 1440) {
      ampm = 'AM';
    }

    if (secondsValue >= 780) {
      secondsValue = secondsValue - 720;
    }

    var targetTimeValue = toHHMM(secondsValue);
    var target12HourTime = `${targetTimeValue} ${ampm}`;
    var isValid = validateTime(target12HourTime);

    if (!isValid) {
      return '12:00 AM';
    }

    return target12HourTime;
  };

  const onTimePickerInputBlur = (time) => {
    dispatch(
      setFieldValue({
        reference,
        value: time,
        formData,
        required,
        visible,
      }),
    );
  };

  useEffect(() => {
    const finalValue = convertValueforDisplay(reqDetailDataFromReduxStore?.value);

    //pickup date
    if (label === 'Pick Up Date') {
      dispatch(
        setDateValidation({
          date: finalValue,
          fieldToUpdate: 'ServiceRequest.PickUpDateValidation',
          isVisible: true,
        }),
      );
    }

    //return date
    if (label === 'Return Date') {
      dispatch(
        setDateValidation({
          date: finalValue,
          fieldToUpdate: 'ServiceRequest.ReturnDateValidation',
          isVisible: true,
        }),
      );
    }
    if (fieldType === 'Date') {
      setDisplayValue(finalValue);
    } else {
      if (finalValue && finalValue.length > 1) {
        const finalValueToDisplay = convertTimeFrom12To24(finalValue);

        setDisplayValue(finalValueToDisplay);
      } else {
        setDisplayValue('');
      }
    }
  }, [fieldID, reqDetailDataFromReduxStore]);

  useEffect(() => {
    dispatch(
      setFieldValue({
        reference,
        value,
        formData,
        required,
        visible,
      }),
    );
  }, [formData.fieldGroup.length]);

  // Created this function to show the saved value selected  on load
  // doing conversion because pega date and time format is different from react input control
  const convertValueforDisplay = (selectedData) => {
    if (fieldType === 'Date') {
      if (selectedData.includes('-')) {
        return selectedData;
      } else {
        const yearVal = selectedData.slice(0, 4);
        const monthVal = selectedData.slice(4, 6);
        const dateVal = selectedData.slice(6, 8);
        const final = yearVal + '-' + monthVal + '-' + dateVal;

        return final;
      }
    } else {
      if (selectedData.includes(':')) {
        return selectedData;
      } else {
        const hourVal = selectedData.slice(0, 2);
        const minVal = selectedData.slice(2, 4);

        const finalTimeVal = hourVal + ':' + minVal;

        return finalTimeVal;
      }
    }
  };

  const onDateBlur = ({ target }) => {
    setDisplayValue(target.value);
    switch (fieldID) {
      case 'PickUpDate':
        dispatch(
          setDayName({
            date: target.value,
            fieldToUpdate: 'ServiceRequest.RequestDetail.PickUpDay',
          }),
        );
        dispatch(
          setDateValidation({
            date: target.value,
            fieldToUpdate: 'ServiceRequest.PickUpDateValidation',
            isVisible: true,
          }),
        );
        break;
      case 'ReturnDate':
        dispatch(
          setDayName({
            date: target.value,
            fieldToUpdate: 'ServiceRequest.RequestDetail.ReturnDay',
          }),
        );
        dispatch(
          setDateValidation({
            date: target.value,
            fieldToUpdate: 'ServiceRequest.ReturnDateValidation',
            isVisible: true,
          }),
        );
        break;
      default:
        break;
    }
    dispatch(
      setFieldValue({
        reference,
        value: target.value,
        formData,
        required,
        visible,
      }),
    );
  };

  return (
    <>
      {visible && (
        <FormFieldCol
          lg={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop}
          sm={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile}
          className="mb-4 pe-lg-4 mobileButton"
        >
          <Form.Group controlId={reference} className={`${fieldPropMapping[fieldID]?.layoutClassName ?? ''}`}>
            <Form.Label className={required ? 'required-field' : ''} dangerouslySetInnerHTML={{ __html: label }} />

            {fieldType === 'Date' ? (
              <Form.Control
                required={required}
                type="date"
                name={reference}
                placeholder={label}
                min={minDateFormatted}
                onChange={(e) => onDateBlur(e)}
                value={displayValue && displayValue.length > 0 ? displayValue : ''}
              />
            ) : (
              <TimePicker
                required={required}
                inputOnBlur={onTimePickerInputBlur}
                onSave={onTimePickerInputBlur}
                value={displayValue}
                setInputValue={setDisplayValue}
                use12Hours
              />
            )}

            <Form.Control.Feedback type="invalid">
              {fieldPropMapping[fieldID]?.required?.errorMessage}
            </Form.Control.Feedback>
          </Form.Group>
        </FormFieldCol>
      )}
    </>
  );
};

export default FormDateInput;
