import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Tabs: ComponentStyleConfig = {
  parts: ['tablist', 'tab', 'tabs'],
  variants: {
    mb_search: {
      tablist: {
        marginBottom: '1rem',
        borderBottom: '1px darkgrey solid',
        overflowX: { lg: 'auto', base: 'scroll' },
      },
      tab: {
        borderRadius: '0.25rem 0.25rem 0 0',
        fontSize: 'regular',
        fontWeight: 'light',
        _selected: {
          bg: 'orange.standard',
          color: 'white',
          fontWeight: 'medium',
        },
      },
      tabpanel: { padding: 0 },
    },
    formpanel: {
      tab: {
        fontSize: 'regular',
        borderRadius: '0.25rem 0.25rem 0 0',
        _selected: { bg: 'white', fontWeight: 'bold' },
      },
    },
    baseNoticeExplorer: {
      tablist: { borderBottom: '1px solid', borderColor: 'mediumgrey' },
      tab: {
        _selected: { bg: 'navy', fontWeight: 'bold', color: 'white' },
      },
    },
  },
};
export default Tabs;
