const findAndReplace = (obj, keyToFind, valueToMatch, keyToReplace, valueToReplace) => {
  return Object.entries(obj).reduce((acc, [key, item]) => {
    item = _findReplace(item, keyToFind, valueToMatch, keyToReplace, valueToReplace);
    return {
      ...acc,
      [key]: item,
    };
  }, []);
};

const _findReplace = (item, keyToFind, valueToMatch, keyToReplace, valueToReplace) => {
  if (Array.isArray(item)) {
    return item.map((itemItr) => {
      const itemRep = _findReplace(itemItr, keyToFind, valueToMatch, keyToReplace, valueToReplace);
      return itemRep;
    });
  } else if (item[keyToFind] && item[keyToFind] === valueToMatch) {
    item = {
      ...item,
      [keyToReplace]: valueToReplace,
    };
  }
  return item;
};

// Find and replace all occurences of a key with a value
// Including nested objects and arrays
export const findAndReplaceAll = (obj, keyToFind, valueToMatch, keyToReplace, valueToReplace) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object') {
      findAndReplaceAll(obj[key], keyToFind, valueToMatch, keyToReplace, valueToReplace);
    } else if (key === keyToFind && obj[key] === valueToMatch) {
      obj[keyToReplace] = valueToReplace;
    }
  });
  return obj;
};

export default findAndReplace;
