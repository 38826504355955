import remCalc from '../helper/remCalc';

export const fontSizes = {
  '6xl': remCalc(52),
  '5xl': remCalc(48),
  '4xl': remCalc(44), //3rem
  '3xl': remCalc(40), //2.5rem
  '2xl': remCalc(36), //2.25rem
  xl: remCalc(32), //2rem
  lg: remCalc(28), //1.75rem (h1)
  md: remCalc(24), //1.5rem  (h2)
  sm: remCalc(20), //1.25rem (h3)
  regular: remCalc(18), //1.125rem
  xs: remCalc(16), //1rem
  xxs: remCalc(14),
  xxxs: remCalc(12),
};
