import React, { useState, useEffect } from 'react';
import { Container, Image, Nav, Navbar, Button, FormControl, Row, InputGroup, Col, Badge } from 'react-bootstrap';
import hamburger from '../../../images/Hamburger.svg';
import search from '../../../images/searchwhite.svg';
import close from '../../../images/close.svg';
import mobilelogo from '../../../images/mobilelogo.svg';

import { getURL } from '../../../helper/getAPIUrl';
import axios from 'axios';

import GenericErrorModal from '../../GenericErrorModal/GenericErrorModal';
import SystemOutageModal from '../../SystemOutageModal/SystemOutageModal';
import AuthenticationModal from '../AuthenticationModal/AuthenticationModal';

import GenericErrorModalProps from '../../GenericErrorModal/__mocks__/default.json';
import AuthenticationModalProps from '../../LandingPage/AuthenticationModal/__mocks__/default.json';
import SystemOutageModalProps from '../../SystemOutageModal/__mocks__/default.json';
import { appConstants } from '../../../helper/client/constant';

import { useSelector } from 'react-redux';

import './MainNav.scss';
import '../../../sass/custom.scss';
import { NavLink } from 'react-router-dom';
import logo from '../../../images/ServiceConnectLogo.png';
import { Transition } from 'react-transition-group';
import { logoutURL } from '../../../helper/getLogoutUrl';

const duration = 300;

const defaultStyle = {
  transition: `width ${duration}ms`,
};

const transitionStyles = {
  entered: { width: '100%' },
};

const Drawer = ({ in: inProp, content }) => (
  <Transition in={inProp} timeout={duration}>
    {(state) => (
      <div
        className="menu mobile bg-defenceblack"
        style={{
          ...defaultStyle,
          ...transitionStyles[state],
        }}
      >
        {content}
      </div>
    )}
  </Transition>
);

const MainNav = (props) => {
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showNotificationCount, setShowNotificationCount] = useState(0);
  const [logoutLink, setlogoutLink] = useState('');
  const reqDetailataFromReduxStore = useSelector((state) => {
    if (!!state) {
      return state;
    }
  });
  const notificationsUnreadCount = reqDetailataFromReduxStore.requestForm.notificationsCount;

  const initialStateNotification = notificationsUnreadCount === null && showNotificationCount === 0;

  useEffect(() => {
    if (!window.location.href.includes(appConstants.SR_PROFILE)) {
      GetNotificationCount();
      if (notificationsUnreadCount !== null) {
        setShowNotificationCount(notificationsUnreadCount);
      }
    }
  }, []);

  //get unread notifications count
  const GetNotificationCount = () => {
    let URL = process.env.REACT_APP_COUNT_NOTIFICATION;
    URL = getURL(URL);
    axios
      .get(URL, {
        withCredentials: true,
      })
      .then((res) => {
        setShowNotificationCount(res.data.notificationCount);
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };

  useEffect(() => {
    const getURL = logoutURL();

    setlogoutLink(getURL);
  }, []);

  const MobileMainNav = () => {
    const [searchOpen, setSearchOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');

    //Disabling scroll on open
    useEffect(() => {
      document.body.style.overflowY = open || searchOpen ? 'hidden' : 'auto';
    }, [open, searchOpen]);

    return (
      <Container>
        <Navbar
          role="navigation"
          className="mobile top justify-content-between pt-3 pb-2 text-defencewhite bg-defencecharcoalgrey"
        >
          <Row className="w-100">
            <Col xs={3} className="d-flex flex-column justify-content-center align-items-center">
              {!searchOpen && (
                <Button
                  onClick={() => setOpen(!open)}
                  variant="icon"
                  className="text-defencewhite p-1 d-flex flex-column"
                >
                  {open ? (
                    <>
                      <Image height={18} src={close} alt="close menu" /> CLOSE
                    </>
                  ) : (
                    <>
                      <Image height={18} src={hamburger} alt="open menu" /> MENU
                    </>
                  )}
                </Button>
              )}
            </Col>
            <Col xs={6} className="justify-content-center align-items-center d-flex">
              <Image height={22} alt={props.logoAltText} src={mobilelogo} className="d-inline-block text-white logo" />
            </Col>
            <Col xs={3} className="d-flex flex-column justify-content-center align-items-center">
              {!open && (
                <Button
                  onClick={() => setSearchOpen(!searchOpen)}
                  variant="icon"
                  className="text-defencewhite p-1 d-flex flex-column"
                >
                  {searchOpen ? (
                    <>
                      <Image height={18} src={close} alt="close search" /> CLOSE
                    </>
                  ) : (
                    <>
                      <Image height={18} src={search} alt="open search " />
                      SEARCH
                    </>
                  )}
                </Button>
              )}
            </Col>
          </Row>
        </Navbar>
        {searchOpen && (
          <>
            <Container className="d-flex bg-defenceblack py-3 mobileSearch position-absolute justify-content-center">
              <InputGroup className="mb-3 mx-4">
                <FormControl
                  type="input"
                  className="searchbar"
                  placeholder="Search ServiceConnect"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
                <Button
                  data-testid="searchButton"
                  aria-label="Search"
                  className="p-0 h-100 bg-defenceorange searchButton"
                  href={`/serviceconnect/search?query=${value}`}
                  variant="search"
                />
              </InputGroup>
            </Container>
            <div className="fade modal-backdrop backdrop show" />
          </>
        )}
        <Drawer
          content={props.navigationItems.map((item) => (
            <Nav.Item className="mainNav text-nowrap" key={item.navTitle}>
              {item.navTitle !== 'Logout' ? (
                <Nav.Link
                  className="menuItem px-3 py-2 py-lg-4"
                  as={NavLink}
                  to={item.navLink}
                  end
                  onClick={() => setOpen(false)}
                >
                  {item.navTitle === 'Notifications' ? (
                    <Container className="d-flex flex-row align-items-center">
                      <Container>{item.navTitle}</Container>
                      {notificationsUnreadCount !== 0 && initialStateNotification === false && (
                        <Badge
                          id="notificationIndicator"
                          aria-live="polite"
                          pill
                          className="px-3 me-2"
                          bg="light"
                          text="defenceorange"
                        >
                          {notificationsUnreadCount !== null ? notificationsUnreadCount : showNotificationCount}
                        </Badge>
                      )}
                    </Container>
                  ) : (
                    item.navTitle
                  )}
                </Nav.Link>
              ) : (
                <Nav.Link className="menuItem px-3 py-2 py-lg-4" href={logoutLink}>
                  Logout
                </Nav.Link>
              )}
            </Nav.Item>
          ))}
          in={open}
        />
      </Container>
    );
  };

  const DesktopMainNav = () => {
    return (
      <Navbar className="desktop flex-nowrap text-white bg-defencecharcoalgrey">
        <Container className="landingPageWidth">
          <Navbar.Brand className="pt-0 text-white" href="/serviceconnect">
            <Image data-testid="logo" alt={props.logoAltText} src={logo} className="d-inline-block text-white logo" />
          </Navbar.Brand>
          <Nav className="navbarHeight d-none p-0 fw-light d-lg-flex flex-lg-row">
            {props.navigationItems.map((item) => (
              <Nav.Item className="mainNav text-nowrap" key={item.navTitle}>
                {item.navTitle !== 'Logout' ? (
                  <Nav.Link className="menuItem px-3 py-2 py-lg-4" as={NavLink} to={item.navLink} end>
                    {item.navTitle === 'Notifications' ? (
                      <>
                        <Container>{item.navTitle}</Container>
                        {notificationsUnreadCount !== 0 && initialStateNotification === false && (
                          <Container className="pb-3 ps-1">
                            <Badge
                              pill
                              id="notificationsIndicator"
                              aria-live="polite"
                              className="px-2"
                              bg="light"
                              text="defenceorange"
                            >
                              {notificationsUnreadCount !== null ? notificationsUnreadCount : showNotificationCount}
                            </Badge>
                          </Container>
                        )}
                      </>
                    ) : (
                      item.navTitle
                    )}
                  </Nav.Link>
                ) : (
                  <></>
                )}
              </Nav.Item>
            ))}
            <Nav.Item className="mainNav text-nowrap">
              <Nav.Link className="menuItem px-3 py-2 py-lg-4" href={logoutLink}>
                Logout
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Container>
      </Navbar>
    );
  };
  return (
    <>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}

      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}

      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
        />
      )}

      <DesktopMainNav />
      <MobileMainNav />
    </>
  );
};

export default MainNav;
