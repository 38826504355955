import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { Alert, Success } from '../../components/ServiceUI/Core/icons/Outline';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { Spinner } from '../../components/ServiceUI/Core/Spinner/Spinner';
import KeyRequestInfo from '../../components/ServiceUI/EFT/KeyRequestInfo/KeyRequestInfo';
import ReadyToDownloadDocuments from '../../components/ServiceUI/EFT/ReadyToDownloadDocuments/ReadyToDownloadDocuments';
import { ViewPIR } from '../../components/ServiceUI/EFT/ViewPIR/ViewPIR';
import { appConstants } from '../../helper/client/constant';
import content from './Content/Complete.json';
import { request } from '../../helper/useAxios';
import { PIRCaseAudit } from './API/PIRCaseAudit';
import { useDispatch } from 'react-redux';
import { setShowError } from '../../reducers/errorHandling';
import { BackLink } from '../../components/ServiceUI/Core/Links/Links';

export const ApplicantDocsReadyToDownload: React.FC = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [savedFiles, setsavedFiles] = useState([]);
  const [pirInfo, setpirInfo] = useState<any>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      request('REACT_APP_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL', 'get', { id })
        .then((res) => {
          if (
            process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PROD_ENV ||
            process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PRE_PROD_ENV
          ) {
            if (res.data.content?.pyErrorCode) {
              dispatch(setShowError({ error: true, code: res.data.content?.pyErrorCode }));
            } else {
              setpirInfo(res.data);
            }
          } else {
            setpirInfo(res.data);
          }
          request('REACT_APP_GET_ATTACHMENT_ALL', 'get', { id: `${id}/attachments` })
            .then((res) => {
              setLoading(false);
              if (res.data.attachments) {
                const filteredArrayOfficialDocsOnly = res.data.attachments.filter(
                  (file) => file.category === appConstants.ATTACHMENT_TYPE_OFFICIAL_DOC,
                );
                if (filteredArrayOfficialDocsOnly.length > 0) {
                  setsavedFiles(filteredArrayOfficialDocsOnly);
                }
              }
              PIRCaseAudit(id, '', 'PIR06')
                .then((_) => {})
                .catch((error) => {
                  dispatch(setShowError({ error: true, code: error.response.status }));
                });
            })
            .catch((error) => {
              dispatch(setShowError({ error: true, code: error.response.status }));
            });
        })
        .catch((error) => {
          dispatch(setShowError({ error: true, code: error.response.status }));
        });
    }
  }, []);
  return (
    <>
      {loading && <Spinner />}
      <Header {...content.header} breadcrumbItems={[{ name: 'Personal information request', url: '.' }]} />
      <SubContainer verticalMargin="1rem">
        <BackLink href=".." text="Back to homepage" />
        <Box mt={{ lg: '1rem', base: '2rem' }} height="2rem">
          <Success height="100%" />
        </Box>
        <Heading as="h2" fontSize={{ lg: 'sm', base: 'sm' }} fontWeight="medium" mt="1rem" mb="2rem">
          {content.subheading}
        </Heading>
        <Heading as="h3" fontSize="regular" fontWeight="normal">
          {content.subcontentheading}
        </Heading>
        <Text fontWeight="light" mb="2rem">
          {content.subcontent}
        </Text>
        <>{savedFiles.length > 0 && <ReadyToDownloadDocuments files={savedFiles} caseID={id ?? ''} />}</>
        <Flex
          mt="1rem"
          bg="whispergrey"
          gap={3}
          alignItems={{ lg: 'center', base: 'flex-start' }}
          p="1rem"
          flexDir={{ lg: 'row', base: 'column' }}
        >
          <Box height="3rem">
            <Alert height="100%" />
          </Box>
          <Text m="0" dangerouslySetInnerHTML={{ __html: content.alert }} />
        </Flex>
        {pirInfo ? (
          <>
            <KeyRequestInfo variant="full" pirInfo={pirInfo} />
            <ViewPIR PIR={pirInfo.content?.PersonalInfoRequest} />
          </>
        ) : (
          <></>
        )}
      </SubContainer>
    </>
  );
};
