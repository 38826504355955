import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SCForm } from '../../../components/ServiceUI/Core/FormElements';
import { setFieldValue, setAcknowledgementCheckbox } from '../../../reducers/eftRequestDetailFormReducer';
const FormCheckbox = ({
  fieldID,
  label,
  required,
  isInvalid,
  fieldPropMapping,
  visible,
  reference,
  value,
  formData,
  readOnly,
}) => {
  const [displayValue, setDisplayValue] = useState(value);
  const dispatch = useDispatch();

  const onCheckboxChange = ({ target }) => {
    setDisplayValue(target.checked);

    // else
    if (reference === 'PersonalInfoRequest.Acknowledged') {
      dispatch(
        setAcknowledgementCheckbox({
          reference,
          value: target.checked,
          required: required,
        }),
      );
    } else {
      dispatch(
        setFieldValue({
          reference,
          value: target.checked,
          formData,
          required: required,
          visible: visible,
        }),
      );
    }
  };

  return (
    <SCForm.Checkbox
      disabled={readOnly}
      isInvalid={isInvalid}
      visible={visible}
      label={label}
      value={displayValue}
      required={required}
      reference={reference}
      errorMessage={fieldPropMapping[fieldID]?.required?.errorMessage}
      desktopWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop}
      mobileWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile}
      onCheckboxChange={(e) => onCheckboxChange(e)}
    />
  );
};

export default FormCheckbox;
