export default {
  AmountOfAssets: {
    maxLength: 4,
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please enter amount of assets',
    },
    validation: {
      regex: '^[0-9]{5}(?:-[0-9]{4})?$',
      errorMessage: 'Please do not enter the following special characters (_`~@#$%^&*()[]{}:;”<>?/)',
    },
  },
  MealLocation: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please select one of the radio button options',
    },
  },
  PickUpDate: {
    fieldColumnWidth: {
      desktop: 4,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please select a pickup date',
    },
  },
  PickUpTime: {
    fieldColumnWidth: {
      desktop: 4,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please select a pickup time',
    },
  },
  PickUpDay: {
    fieldColumnWidth: {
      desktop: 4,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please enter pickup day',
    },
  },
  PickupLocation: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please enter pickup location',
    },
  },
  DropoffLocation: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please enter dropoff location',
    },
  },
  RequestRelatesTo: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
  },
  RequestRelationDetail: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
  },
  VehicleType: {
    fieldColumnWidth: {
      desktop: 4,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please select vehicle type',
    },
  },
  AutoOrManual: {
    fieldColumnWidth: {
      desktop: 4,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please select an option',
    },
  },
  VehicleOptions: {
    fieldColumnWidth: {
      desktop: 8,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please enter the details',
    },
  },
  OtherVehicleType: {
    fieldColumnWidth: {
      desktop: 4,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please enter the details',
    },
  },
  AdditionalInfo: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please enter the details',
    },
    helpText:
      'For multi vehicle and/or multiple leg trips, please complete and attach a transport manifest. The template can be downloaded from the supporting evidence section below, after selecting the relevant attachment category.',
  },
  ReturnDate: {
    fieldColumnWidth: {
      desktop: 4,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please select a return date',
    },
  },
  ReturnDay: {
    fieldColumnWidth: {
      desktop: 4,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please select return day',
    },
  },
  ReturnTime: {
    fieldColumnWidth: {
      desktop: 4,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please select a return time',
    },
  },
  ReturnPickUpTime: {
    fieldColumnWidth: {
      desktop: 4,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please select a return pickup time',
    },
  },
  ReturnPickupLocation: {
    fieldColumnWidth: {
      desktop: 4,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please enter return pickup location',
    },
  },
  ReturnDropOffLocation: {
    fieldColumnWidth: {
      desktop: 4,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please enter dropoff location',
    },
  },
  PassengerNumber: {
    fieldColumnWidth: {
      desktop: 4,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please enter number of pasengers',
    },
  },
};
