import React, { useEffect, useRef, useState } from 'react';
import { Col } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import './PersonalDetailPrimary.scss';

import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import Tooltip from 'react-bootstrap/Tooltip';
import { InfoCircle } from 'react-bootstrap-icons';

const PersonalDetailsPrimary = (props) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const namePersonalInputRef = useRef();
  const [personalDetail, setPersonalDetail] = useState({
    fieldName: props.personalDetail.fieldName,
    fieldValue: props.nopersonal === 'true' ? '' : props.personalDetail.value,
    fieldId: props.personalDetail.fieldID,
  });

  const renderTooltipEmployeeID = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      This is your PMKeys or ODS number, if you have one.
    </Tooltip>
  );

  const renderTooltipContact = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Include details about your preferred contact method and the best time to contact you.
    </Tooltip>
  );

  const inputChangedHandlerPersonalDetail = (event) => {
    event.persist();

    setPersonalDetail((prevState) => {
      return {
        ...prevState,
        fieldValue: event.target.value,
      };
    });

    props.onPersonalInfoChange(
      personalDetail.fieldId,
      personalDetail.fieldName,
      namePersonalInputRef.current.value,
      props.personalDetail.reference,
    );
  };
  const getDropdownPrimaryClass = () => {
    if (props.isReview) {
      return 'customDropdownReview dropDownSepArrow fw-light';
    } else {
      if (props.personalDetail.error) {
        return ' customDropdown dropDownSepArrow fw-light show-error-border';
      } else {
        return 'customDropdown dropDownSepArrow fw-light';
      }
    }
  };
  const changeNameHandler = () => {
    if (props.personalDetail.fieldName === 'FullName') {
      setPersonalDetail((prevState) => {
        return {
          ...prevState,
          fieldName: 'Full name',
        };
      });
    } else if (props.personalDetail.fieldName === 'EmployeeID') {
      setPersonalDetail((prevState) => {
        return {
          ...prevState,
          fieldName: 'Employee ID',
        };
      });
    } else if (props.personalDetail.fieldName === 'Service') {
      setPersonalDetail((prevState) => {
        return {
          ...prevState,
          fieldName: 'Group or entity',
        };
      });
    } else if (props.personalDetail.fieldName === 'Address') {
      setPersonalDetail((prevState) => {
        return {
          ...prevState,
          fieldName: 'Email',
        };
      });
    } else if (props.personalDetail.fieldName === 'Number') {
      setPersonalDetail((prevState) => {
        return {
          ...prevState,
          fieldName: 'Preferred contact number',
        };
      });
    } else if (props.personalDetail.fieldName === 'PreferredContactTimes') {
      setPersonalDetail((prevState) => {
        return {
          ...prevState,
          fieldName: 'How and when can you be contacted?',
        };
      });
    }
  };
  const changeEntityDropDownValue = (text, refCode, id) => {
    props.onEntityChange(text, refCode, id);

    props.onPersonalInfoChange(id, text, refCode, props.personalDetail.reference);
  };
  // test
  useEffect(() => {
    //createNewReq();
    changeNameHandler();
  }, []);
  // test

  // custom toggle for dropdowns
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      className={getDropdownPrimaryClass()}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="dropdownButtons">{children}</div>
    </a>
  ));

  const renderElement = () => {
    if (personalDetail.fieldName === 'Group or entity') {
      return (
        <div>
          <label htmlFor={personalDetail.fieldID}>{personalDetail.fieldName}</label>
          <div className="d-inline w-100">
            {props.isReview ? (
              <p className="fw-light">{props.entityValues.selectedDescription}</p>
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  title="Group or entity"
                  variant="dropdown"
                  id="grouporentity"
                  // disabled={
                  //   props.isNoDisableFlag
                  //     ? false
                  //     : props.isReadonlyPersonalDetails
                  // }
                >
                  {props.entityValues.selectedDescription}
                </Dropdown.Toggle>
                <Dropdown.Menu className=" dropDownMenu">
                  {props.entityValues.dropDownArrayEntity.map((item) => (
                    <Dropdown.Item
                      onClick={(e) =>
                        changeEntityDropDownValue(e.target.textContent, item.code, personalDetail.fieldId)
                      }
                      className="fw-light dropDownItem"
                      as="button"
                      key={item.fieldId}
                    >
                      {item.description}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
      );
    } else if (personalDetail.fieldName === 'Employee ID') {
      return (
        <>
          <label className="w-100">
            <p className="mb-0">
              {personalDetail.fieldName}
              <button title="Tooltip" className="border-0 bg-transparent" ref={target} onClick={() => setShow(!show)}>
                {!props.isReview && (
                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltipEmployeeID}>
                    <InfoCircle size={15} />
                  </OverlayTrigger>
                )}
              </button>
            </p>

            <input
              ref={namePersonalInputRef}
              className={
                'w-100' && props.isReview
                  ? 'd-inline textBoxFieldReview fw-light w-100'
                  : props.personalDetail.error
                  ? 'd-inline textBoxField fw-light w-100 show-error-border'
                  : 'd-inline textBoxField fw-light w-100'
              }
              id={`${personalDetail.fieldName}-${personalDetail.fieldId}`}
              type="text"
              value={props.personalDetail.value}
              // disabled={
              //   props.isNoDisableFlag ? false : props.personalDetail.isReadOnly
              // }
              disabled={props.isNoDisableFlag ? false : props.isReadonlyPersonalDetails}
              onChange={inputChangedHandlerPersonalDetail}
            />
          </label>
        </>
      );
    } else if (personalDetail.fieldName === 'How and when can you be contacted?') {
      return (
        <>
          <label className="w-100">
            <p className="mb-0">
              {personalDetail.fieldName}
              <button title="Tooltip" className="border-0 bg-transparent" ref={target} onClick={() => setShow(!show)}>
                {!props.isReview && (
                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltipContact}>
                    <InfoCircle size={15} />
                  </OverlayTrigger>
                )}
              </button>
            </p>

            <input
              ref={namePersonalInputRef}
              className={
                'w-100' && props.isReview
                  ? 'd-inline textBoxFieldReview fw-light w-100'
                  : props.personalDetail.error
                  ? 'd-inline textBoxField fw-light w-100 show-error-border'
                  : 'd-inline textBoxField fw-light w-100'
              }
              id={`${personalDetail.fieldName}-${personalDetail.fieldId}`}
              type="text"
              value={props.personalDetail.value}
              // disabled={
              //   props.isNoDisableFlag ? false : props.personalDetail.isReadOnly
              // }
              // disabled={
              //   props.isNoDisableFlag ? false : props.isReadonlyPersonalDetails
              // }
              onChange={inputChangedHandlerPersonalDetail}
            />
          </label>
        </>
      );
    } else {
      return (
        <div>
          <label
            className={
              (personalDetail.fieldName === 'Full name' ||
                //personalDetail.fieldName === "Employee ID" ||
                personalDetail.fieldName === 'Defence email address' ||
                personalDetail.fieldName === 'Preferred contact number') &&
              !props.isReview &&
              'required-field'
            }
            htmlFor={`${personalDetail.fieldName}-${personalDetail.fieldId}`}
          >
            {personalDetail.fieldName}
          </label>
          {/* {personalDetail.fieldName === "Full name" ||
            personalDetail.fieldName === "Employee ID" ||
            personalDetail.fieldName === "Defence email address" ||
            (personalDetail.fieldName === "Preferred contact number" && (
              <span className={props.isReview && "required-field"}>*</span>
            ))} */}
          {props.isReview ? (
            <p className="fw-light">{personalDetail.fieldValue}</p>
          ) : (
            <input
              ref={namePersonalInputRef}
              className={
                props.isReview
                  ? 'd-inline textBoxFieldReview fw-light w-100'
                  : props.personalDetail.error
                  ? 'd-inline textBoxField fw-light w-100 show-error-border'
                  : 'd-inline textBoxField fw-light w-100'
              }
              id={`${personalDetail.fieldName}-${personalDetail.fieldId}`}
              type="text"
              // value={personalDetail.fieldValue}
              value={props.personalDetail.value}
              // disabled={
              //   props.isNoDisableFlag ? false : props.personalDetail.isReadOnly
              // }
              disabled={
                personalDetail.fieldName === 'Preferred contact number'
                  ? false
                  : props.isNoDisableFlag
                  ? false
                  : props.isReadonlyPersonalDetails
              }
              onChange={inputChangedHandlerPersonalDetail}
            />
          )}
        </div>
      );
    }
  };
  return <Col className="formField">{renderElement()}</Col>;
};

export default PersonalDetailsPrimary;
