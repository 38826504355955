import { request } from './useAxios';

const data = {
  content: {
    pyGotoStage: 'Withdrawn',
    pyAuditNote: 'User Withdrawn the Case',
  },
  pageInstructions: [],
};

export const deletePIR = (id: string, actionID: string) => {
  let config;
  return request('REACT_APP_SR_DELETE_GET', 'get', { id })
    .then((res) => {
      const finalETagValue = res.data.content.pxSaveDateTime.replace(/[-:Z]/g, '') + ' GMT';
      config = {
        headers: {
          'if-match': finalETagValue,
        },
        withCredentials: true,
      };
    })
    .then(() => request('REACT_APP_PIR_DELETE_PUT', 'put', { id: id + '?actionID=pyChangeStage' , data, config }))
    .catch((err) => {
      throw err;
    });
};
