import { Button, Image, Modal } from 'react-bootstrap';
import greensuccess from '../../../../images/greensuccess.svg';

const SuccessPopup = (props) => {
  return (
    <Modal contentClassName="p-4" show={props.show}>
      <Modal.Header className=" pb-0 bg-defencewhite border-0 flex-column align-items-start">
        <Image src={greensuccess} height={50} alt="greensuccess" className="mb-3" />
      </Modal.Header>
      <Modal.Body>{props.message}</Modal.Body>
      <Modal.Footer className="border-0">
        <Button onClick={props.closeAction}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessPopup;
