import React, { useState, useEffect } from 'react';
import { Col, Form, FormControl } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setFieldValue } from '../../../../reducers/requestDetailFormReducer';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { InfoCircle } from 'react-bootstrap-icons';
import { findByProp } from '../../../../helper/findByProp';
import { FormControlTextInput, FormFieldCol } from './styles';
import convertSpecialChars from '../../../../helper/convertSpecialChars';
const FormTextInput = ({
  fieldID,
  label,
  fieldPropMapping,
  required,
  visible,
  reference,
  isCCCValid,
  formData,
  type,
  value,
  readOnly,
  setCCMessage,
}) => {
  const reqDetailDataFromReduxStore = useSelector((state) => {
    if (!!state) {
      return findByProp(state.requestForm.formFields, 'reference', reference);
    }
  });
  //const [visibleField, setVisibleField] = useState(true);
  const [displayValue, setDisplayValue] = useState(value);
  const dispatch = useDispatch();
  const [showTooltip, setshowTooltip] = useState(false);

  const onTextInputBlur = (e) => {
    setDisplayValue(e.target.value);
  };
  const renderTooltip = (props) => {
    if (fieldID === 'RequestTitle') {
      return (
        <Tooltip id="button-tooltip" {...props}>
          Enter some key details to help you and others easily identify the request.(Only 128 characters allowed)
        </Tooltip>
      );
    } else {
      return (
        <Tooltip id="button-tooltip" {...props}>
          Cost centre codes are validated as active or inactive. You can continue to submit the service request with an
          inactive cost centre code ideally confirmed with your business area.
        </Tooltip>
      );
    }
  };

  useEffect(() => {
    /*  if (fieldID === "AmountOfAssets") {
      setVisibleField(false);
    }
    if (fieldID === "RequestRelationDetail") {
      setVisibleField(false);
    } */
    if (fieldID === 'RequestTitle' || fieldID === 'CostCodeCentre') {
      setshowTooltip(true);
    }
  }, [fieldID]);

  useEffect(() => {
    dispatch(
      setFieldValue({
        reference,
        value: displayValue,
        formData,
        required,
        visible,
      }),
    );
    if (fieldID === 'CostCodeCentre' && displayValue.length >= 6) {
      isCCCValid(displayValue);
    }
  }, [displayValue]);

  useEffect(() => {
    const convertedValue = convertSpecialChars(reqDetailDataFromReduxStore?.value);

    setDisplayValue(convertedValue);
  }, [reqDetailDataFromReduxStore]);

  return (
    <>
      {visible && !showTooltip && (
        <FormFieldCol
          lg={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop ?? 12}
          sm={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile ?? 12}
          className="mb-4 pe-lg-4 mobileButton"
        >
          <Form.Group controlId={reference}>
            <Form.Label className={required ? 'required-field' : ''} dangerouslySetInnerHTML={{ __html: label }} />
            <FormControlTextInput
              onBlur={(e) => onTextInputBlur(e)}
              onChange={(e) => onTextInputBlur(e)}
              //type={type === "pxInteger" ? "number" : "text"}
              type={
                label === 'Quantity' ||
                label === 'Number Attending' ||
                label === 'Number of assets' ||
                label === 'Number of passengers'
                  ? 'number'
                  : 'text'
              }
              onInput={(e) =>
                (e.target.value = fieldPropMapping[fieldID]?.maxLength
                  ? e.target.value.slice(0, fieldPropMapping[fieldID]?.maxLength)
                  : e.target.value)
              }
              required={required}
              value={displayValue}
              min={0}
              maxLength={fieldPropMapping[fieldID]?.maxLength}
              readOnly={readOnly}
            />

            <Form.Control.Feedback type="invalid">
              {fieldPropMapping[fieldID]?.required?.errorMessage}
            </Form.Control.Feedback>
            <Form.Text id={`helptext-${reference}`}>{fieldPropMapping[fieldID]?.helpText}</Form.Text>
          </Form.Group>
        </FormFieldCol>
      )}

      {visible && showTooltip && (
        <FormFieldCol
          lg={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop ?? 12}
          sm={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile ?? 12}
          className="mb-4 pe-4"
        >
          <Form.Group controlId={fieldID}>
            <Form.Label
              className={required ? 'required-field' : ''}
              dangerouslySetInnerHTML={{
                __html: fieldID === 'RequestTitle' ? 'Name the request' : 'Cost centre code',
              }}
            />
            &nbsp;
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
              <InfoCircle size={15} className="d-inline" />
            </OverlayTrigger>
            <FormControl
              onBlur={(e) => onTextInputBlur(e)}
              onChange={(e) => onTextInputBlur(e)}
              type="text"
              required={required}
              value={displayValue}
              maxLength={label === 'Request Title' ? '128' : '7'}
            />
            {fieldID === 'CostCodeCentre' && displayValue.length >= 6 && setCCMessage?.length > 0 && (
              <label className="cost-code-message">{setCCMessage}</label>
            )}
            <Form.Control.Feedback type="invalid">
              {fieldPropMapping[fieldID]?.required?.errorMessage}
            </Form.Control.Feedback>
          </Form.Group>
        </FormFieldCol>
      )}
    </>
  );
};

export default FormTextInput;
