import { Modal, Button, Col, Row } from 'react-bootstrap';
import React from 'react';

const UploadConfirmationSRModal = ({ handleUpdateForms, show, closeAction }) => {
  return (
    <Modal show={show} backdrop="static" keyboard={false}>
      <Modal.Body className="b-0">
        You have added one or more attachments but have not uploaded them. Are you sure you want to continue?
      </Modal.Body>
      <Modal.Footer className="b-0">
        <Row>
          <Col className="pe-2">
            <Button className="uploadbuttonWidth" variant="primary" onClick={handleUpdateForms}>
              Yes
            </Button>
          </Col>
          <Col>
            <Button className="uploadbuttonWidth" variant="standard" onClick={closeAction}>
              No
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadConfirmationSRModal;
