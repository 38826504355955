import React from 'react';
import './CtaPopular.scss';
import { Button, Container, Row, Col } from 'react-bootstrap';
import '../../../../sass/custom.scss';

const CtaPopular = (props) => {
  return (
    <Container fluid className="ctaPopular h-100">
      <Container fluid className=" p-4 p-lg-0 landingPageWidth">
        <Row className="h-100">
          <Col className="py-3 py-lg-5 px-lg-6 infoText col-sm-12 col-lg-7">
            <h2 className="text-defencenavy">{props.title}</h2>
            <p className="fw-light w-100 text-defencenavy" dangerouslySetInnerHTML={{ __html: props.description }} />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
export default CtaPopular;
