const findPath = (obj, keyToFind) => {
  return (
    Object.entries(obj).reduce(
      (acc, [key, value]) =>
        evalCondition(key, keyToFind)
          ? acc.concat(value)
          : typeof value === 'object' && value
          ? acc.concat(findPath(value, keyToFind))
          : acc,
      [],
    ) || []
  );
};

const evalCondition = (key, keyToFind) => {
  return Array.isArray(keyToFind) ? keyToFind.includes(key) : key === keyToFind;
};

export default findPath;
