import React, { useState } from 'react';
import { Badge, Box, Button, Grid, GridItem, Heading, Link, Text, useDisclosure } from '@chakra-ui/react';
import { External_White as ExternalWhite } from './External_White';
import { BaseInductionModal } from './BaseInductionModal';
import { getURL } from '../../../helper/getAPIUrl';
import GenericErrorModal from '../../GenericErrorModal/GenericErrorModal';
import SystemOutageModal from '../../SystemOutageModal/SystemOutageModal';
import AuthenticationModal from '../AuthenticationModal/AuthenticationModal';
import GenericErrorModalProps from '../../GenericErrorModal/__mocks__/default.json';
import AuthenticationModalProps from '../../LandingPage/AuthenticationModal/__mocks__/default.json';
import SystemOutageModalProps from '../../SystemOutageModal/__mocks__/default.json';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';

import axios from 'axios';
import { Pagination } from '../../ServiceUI/Core/Pagination/Pagination';
interface BaseInductionProps {
  baseName: string;
  expiryDate: Date | string;
  caseKey: string;
  onOpen?(): void;
}
export interface BaseInductionsProps {
  heading: string;
  subHeading: string;
  badgeContent: string;
  basesInducted: BaseInductionProps[];
  newInductionHeading: string;
  newInductionSubHeading: string;
  newInductionCTAText: string;
  newInductionCTALink: string;
}

//Component for a singular base induction tile
export const BaseInduction: React.FC<BaseInductionProps> = (props: BaseInductionProps) => {
  //Taking the input date and converting it to a JS date, creating a string version in long date style: "Month date, year"
  const date = new Date(props.expiryDate).toLocaleString('en-US', {
    dateStyle: 'long',
  });

  //Returning item
  return (
    <GridItem
      data-testid="induction"
      bg="white"
      colSpan={{ lg: 1, base: 2 }}
      p="0.5rem 1rem"
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box>
        <Text m="0" fontWeight="bold">
          {props.baseName}
        </Text>
        <Text m="0" fontWeight="light">
          Expires: {date}
        </Text>
      </Box>
      <Button onClick={props.onOpen} variant="secondary" w={{ lg: 'auto', base: '100%' }}>
        View
      </Button>
    </GridItem>
  );
};

export const BaseInductions: React.FC<BaseInductionsProps> = (props: BaseInductionsProps) => {
  const headingTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Completed national base induction records will be visible below if you entered the email address that is linked to
      your ServiceConnect profile when completing the induction.
    </Tooltip>
  );
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  //Is the modal open?
  const { isOpen, onToggle } = useDisclosure();

  const [content, setContent] = useState('');

  //View button handler, sets the case key to be the one corresponding to the tile the view button was pressed on, and then opens the modal
  const handleOpen = (caseKey) => {
    const URL = getURL(process.env.REACT_APP_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL + caseKey);
    axios
      .get(String(URL), { withCredentials: true })
      .then((res) => {
        setContent(res.data.content.InteractionData.pyInput.pyMessage);
        onToggle();
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };

  //Returning all three sections of the component: Heading/Subheading, Badge/induction tiles, and New base induction CTA section
  return (
    <>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
          onClose={() => setErrorModal(false)}
        />
      )}
      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}
      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
        />
      )}
      <Box bg="whispergrey">
        <Box p="2rem" className="landingPageWidth">
          {/* Heading/Subheading */}
          <Heading as="h3" fontSize="xlarge" display="inline">
            {props.heading}
          </Heading>
          <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={headingTooltip}>
            <InfoCircle className="ms-1 d-inline" size={15} />
          </OverlayTrigger>
          <Text fontWeight="light">{props.subHeading}</Text>
          {/* Badge/induction tiles */}
          <Badge bg="badge_green" color="white" borderRadius="1rem" padding="0.25rem 0.5rem">
            {props.badgeContent}
          </Badge>
          <Grid templateColumns="repeat(2,1fr)" gap={3} mt="1rem">
            <Pagination itemsPerPage={6}>
              {props.basesInducted.length > 0 ? (
                props.basesInducted.map((item: BaseInductionProps, key: number) => (
                  <BaseInduction key={key} {...item} onOpen={() => handleOpen(item.caseKey)} />
                ))
              ) : (
                <Text fontStyle="italic">No active base inductions</Text>
              )}
            </Pagination>
          </Grid>
          {/* New base induction CTA section */}
          <Grid templateColumns="repeat(3,1fr)" mt="1rem">
            <GridItem colSpan={3}>
              <Heading fontSize="xlarge">{props.newInductionHeading}</Heading>
            </GridItem>
            <GridItem colSpan={{ lg: 2, base: 3 }}>
              <Text fontWeight="light">{props.newInductionSubHeading}</Text>
            </GridItem>
            <GridItem colSpan={{ lg: 1, base: 3 }}>
              <Button
                as={Link}
                href={props.newInductionCTALink}
                variant="primary"
                float="right"
                display="flex"
                gap={1}
                w={{ lg: 'auto', base: '100%' }}
                target="_blank"
              >
                {props.newInductionCTAText}
                <ExternalWhite height="1rem" />
              </Button>
            </GridItem>
          </Grid>
        </Box>
      </Box>
      <BaseInductionModal isOpen={isOpen} onClose={onToggle} content={content} />
    </>
  );
};
