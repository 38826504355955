import React from 'react';
import { Link as ChakraLink, chakra } from '@chakra-ui/react';
import { NavLink as Link } from 'react-router-dom';
import { Home } from '../icons/Outline';

export interface NavItemProps {
  url: string;
  title: string;
  router?: boolean;
  variant?: 'mainNav' | 'subNav';
  isHome?: boolean;
}

const Navigation = chakra(Link);

const style = (variant) => {
  return {
    bg: variant === 'mainNav' ? 'navy' : 'white',
    color: variant === 'mainNav' ? 'white' : 'black',
    display: 'flex',
    gap: 1,
    alignContent: 'center',
    textDecoration: 'none',
    width: { lg: 'auto', base: '100%' },
    alignItems: 'center',
    padding: '1rem',
    fill: 'navy',
    _activeLink: { color: 'white', bg: 'navy', fill: 'white' },
    _hover: {
      color: 'white',
      bg: variant === 'mainNav' ? 'orange.standard' : 'navy',
      fill: 'white',
    },
  };
};

export const NavItem: React.FC<NavItemProps> = (props: NavItemProps): JSX.Element => {
  return props.router ? (
    <Navigation {...style(props.variant)} to={props.url} end data-testid="navlink">
      {props.isHome === true && <Home fill="inherit" />}
      {props.title}
    </Navigation>
  ) : (
    <ChakraLink {...style(props.variant)} href={props.url}>
      {props.isHome === true && <Home fill="inherit" />}
      {props.title}
    </ChakraLink>
  );
};
