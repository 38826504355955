import React from 'react';
import { Box } from '@chakra-ui/react';
export const CollectDocumentsPage: React.FC = () => {
  return (
    <>
      <Box>Header</Box>
      <Box>Provided information and documents</Box>
      <Box>Expiry warning</Box>
      <Box>Key request information</Box>
      <Box>All request information</Box>
    </>
  );
};
