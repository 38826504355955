import * as React from 'react';
import { SVGProps } from 'react';
const Success = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_203_2)">
      <g>
        <g>
          <g>
            <g>
              <path
                fill="#00795C"
                d="M11.3331 6.57569L7.12476 10.7323C7.00849 10.8486 6.85023 10.9132 6.68552 10.9132H6.68874H6.66614C6.49496 10.9067 6.33348 10.8292 6.22044 10.7L4.76707 9.03673C4.54099 8.77835 4.56682 8.38433 4.8252 8.15825C5.08358 7.93217 5.4776 7.95801 5.70368 8.21638L6.72104 9.37908L10.4578 5.69075C10.7033 5.44852 11.0973 5.45175 11.3395 5.69721C11.5818 5.94267 11.5785 6.33669 11.3331 6.57892V6.57569Z"
              />
              <path
                fill="#00795C"
                d="M8 0C3.58175 0 0 3.58175 0 8C0 12.4182 3.58175 16 8 16C12.415 15.9935 15.9935 12.415 16 8C16 3.58175 12.4182 0 8 0ZM8 14.7533C4.26968 14.7533 1.24667 11.7303 1.24667 8C1.24667 4.26968 4.26968 1.24667 8 1.24667C11.7271 1.2499 14.7501 4.27291 14.7533 8C14.7533 11.7303 11.7303 14.7533 8 14.7533Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath>
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Success;
