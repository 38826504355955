import React from 'react';
import { Table, Tbody, Button, Tr, Td, TableCaption, TableContainer } from '@chakra-ui/react';

export interface SendDocFilesUploadedProps {
  files: any[];
  header?: string;
  ctaAction?: string;
  ctaCallback: (item: any) => void;
  ctaText?: string;
  caseID?: string;
}

const FileList = (props: { header: string; items: File[]; onDelete(e): void }) => {
  return (
    <TableContainer w="60%">
      <Table variant="File">
        <TableCaption placement="top" textAlign="left">
          {props.header}
        </TableCaption>
        <Tbody>
          {props.items.map((item) => (
            <Tr>
              <Td>{item.name}</Td>
              <Td textAlign="right">
                <Button variant="tertiary" onClick={() => props.onDelete(item)}>
                  Delete
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const SendDocFilesUploaded: React.FC<SendDocFilesUploadedProps> = ({
  files,
  header,
  ctaCallback,
}: SendDocFilesUploadedProps): JSX.Element => {
  return (
    <>{files?.length > 0 && <FileList header={header ?? ''} items={files} onDelete={(e) => ctaCallback?.(e)} />}</>
  );
};

export default SendDocFilesUploaded;
