// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { extendTheme } from '@chakra-ui/react';
import Tabs from './components/Tabs';
import Button from './components/Buttons';
import Input from './components/Input';
import Link from './components/Link';
import List from './components/List';
import Breadcrumb from './components/Breadcrumb';
import Modal from './components/Modal';
import Radio from './components/Radio';
import Menu from './components/Menu';
import Card from './components/Card';
import Text from './components/Text';
import Table from './components/Table';
import FormLabel from './components/FormLabel';
import FormError from './components/FormError';
import Checkbox from './components/Checkbox';
import { textareaTheme } from './components/TextArea';
import { colors } from './colors';
import { lineHeights } from './lineHeights';
import { sizes } from './sizes';
import { spacing } from './spacing';
import Divider from './components/Divider';
import { fontSizes } from './fontSizes';
import { Tooltip } from './components/Tooltip';

const fonts = {
  heading: `'Roboto', sans-serif`,
  body: `'Roboto', sans-serif`,
};

const overrides = {
  styles: {
    global: {
      body: { color: 'black' },
      p: { fontSize: { lg: '1.125rem', base: '1rem' } },
      a: { fontSize: { lg: '1.125rem', base: '1rem' } },
    },
  },
  fonts,
  fontSizes,
  colors,
  lineHeights,
  spacing,
  sizes,
  components: {
    Select: Input,
    Divider,
    Checkbox,
    Textarea: textareaTheme,
    FormError,
    FormLabel,
    Text,
    Card,
    Tabs,
    Button,
    Table,
    Input,
    Link,
    List,
    Breadcrumb,
    Modal,
    Radio,
    Menu,
    Tooltip,
  },
};

export const defencetheme = extendTheme(overrides);
