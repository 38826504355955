import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { colors } from '../colors';
const Icon = {
  borderColor: 'success',
  background: 'none',
  backgroundImage: "url('/images/checkbox.svg')",
  backgroundSize: 'cover',
};
const BUTTON_SIZE = '15%';
const Radio: ComponentStyleConfig = {
  parts: ['control', 'container', 'label'],
  variants: {
    primary: {
      label: { fontSize: '1.125rem' },
      control: {
        h: '1.25rem',
        w: '1.25rem',
        borderStyle: 'solid',
        borderColor: 'charcoal',
        borderWidth: '1px',
        _checked: {
          _before: { display: 'none' },
          _hover: {
            ...Icon,
          },
          ...Icon,
        },
        _focus: { boxShadow: 'none' },
      },
      container: {
        _focusWithin: {
          outlineColor: 'darkgrey',
          outlineStyle: 'solid',
          outlineWidth: '1px',
          outlineOffset: '4px',
        },
      },
    },
    eft: {
      label: {
        margin: 0,
        fontSize: 'regular',
        fontWeight: 'bold',
        height: '100%',
        w: {
          lg: '16rem',
          base: 'auto',
        },
      },
      control: { display: 'none' },
      container: {
        alignItems: 'flex-start',
        maxW: { lg: '16rem', base: 'none' },
        p: '1.5rem',
        minH: '8rem',
        w: { lg: '16rem', base: '100%' },
        h: 'auto',
        bg: 'lightblue',
        borderRadius: '0.5rem',
        borderWidth: '1px',
        borderStyle: 'solid',
        _checked: {
          color: 'white',
          backgroundColor: 'navy',
        },
        _focusWithin: {
          border: '2px solid black !important',
        },
        _invalid: { border: `1px solid ${colors.redalert} ` },
      },
    },
    smile: {
      label: {
        marginStart: 0,
        padding: { lg: '1rem', base: '0.25rem' },
        position: 'absolute',
        height: '100%',
        width: '100%',
      },
      container: { w: BUTTON_SIZE, aspectRatio: '1/1' },
      control: {
        w: '100%',
        h: '100%',
        border: '1px black solid !important',
        _focusVisible: { outline: '1px black solid', boxShadow: 'none' },
        _active: { outline: '2px black solid' },
        _checked: {
          _before: { width: 0 },
          outline: '2px black solid',
          border: 'none',
        },
        _hover: {
          bg: 'none !important',
          outline: '2px black solid !important',
        },
      },
    },
  },
};
export default Radio;
