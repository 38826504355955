import React from 'react';
import { Container, Button, Modal, Image } from 'react-bootstrap';
import './AuthenticationModal.scss';
import { useState } from 'react';
import warning from '../../../images/warning.svg';

import { getAuthURL } from '../../../helper/getOtherAppURL';

const AuthenticationModal = (props) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    const loginURL = getAuthURL();
    setShow(false);
    window.location.href = loginURL;
  };

  return (
    <Modal show onHide={handleClose} animation={false} className="modalSize">
      <Image className="py-4 px-5 warningIcon" src={warning} alt="" />
      <Modal.Title className="px-5 modalTitle">{props.title}</Modal.Title>

      <Modal.Body className="px-5">
        {!!props.code && props.code === 403 ? props.descriptionNoPrivilage : props.description}
      </Modal.Body>

      <Container className="px-5 pt-4 pb-6">
        <Button
          data-testid="authenticationButton"
          className="rounded-0 reLoginButton"
          variant="primary"
          onClick={handleClose}
        >
          {props.ctaText}
        </Button>
      </Container>
    </Modal>
  );
};

export default AuthenticationModal;
