import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Container, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import axios from 'axios';
import { getURL } from '../../../helper/getAPIUrl';

import RequestDetailReadOnly from '../ViewServiceRequest/RequestDetailReadOnly/RequestDetailReadOnly';
import LocationReadOnly from '../ViewServiceRequest/LocationReadOnly/LocationReadOnly';
import ContactReadOnly from '../ViewServiceRequest/ContactReadOnly/ContactReadOnly';
import SecondaryContactReadOnly from '../ViewServiceRequest/ContactReadOnly/SecondaryContactReadOnly';
import AuthenticationModal from '../../LandingPage/AuthenticationModal/AuthenticationModal';
import ErrorPopUp from '../CreateRequest/ErrorPopup/ErrorPopup';
import SubNavigation from '../MSRcomponents/SubNavigation/SubNavigation';
import SubNavProps from '../MSRcomponents/SubNavigation/__mocks__/default.json';
import Header from '../../LandingPage/Header/Header';
import content from '../CreateRequest/Content/ReviewRequest.json';
import ReviewSubheader from '../CreateRequest/ReviewSubheader/ReviewSubheader';
import ReviewSubheaderProps from '../CreateRequest//ReviewSubheader/__mocks__/default.json';
import { appConstants } from '../../../helper/client/constant';
import SystemOutageModal from '../../../components/SystemOutageModal/SystemOutageModal';
import GenericErrorModal from '../../../components/GenericErrorModal/GenericErrorModal';

import AuthenticationModalProps from '../../LandingPage/AuthenticationModal/__mocks__/default.json';
import GenericErrorModalProps from '../../GenericErrorModal/__mocks__/default.json';
import SystemOutageModalProps from '../../SystemOutageModal/__mocks__/default.json';

import ServiceConnectSpinner from '../../../components/commonComponents/Spinner/ServiceConnectSpinner';

const EditServiceReviewRequest = () => {
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalHttpCode, setShowModalHttpCode] = useState('');

  const [error, setError] = useState();
  const [showSpinnerApiIsInProgress, setShowSpinnerApiIsInProgress] = useState(false);
  const [showError, setShowError] = useState(false);
  const { id } = useParams(); // this id we might need for future use

  const navigate = useNavigate();

  const [srViewInfoInEdit, setsrViewInfoInEdit] = useState({
    data: [],
  });
  const [assignmentID, setassignmentID] = useState({
    id: '',
  });
  const [serviceDetailForEdit, setserviceDetailForEdit] = useState({
    serviceType: '',
    caseIDFirst: '',
    caseIDSecond: '',
  });
  const [loading, setLoading] = useState({
    isLoading: false,
  });
  const [workType, setworkType] = useState({
    dropdown: [],
    description: '',
  });
  const [workSubType, setSubworkType] = useState({
    dropdown: [],
    description: '',
  });
  const [vessel, setvessel] = useState({
    dropdown: [],
    description: '',
  });
  const [groupPrimary, setgroupPrimary] = useState({
    dropdown: [],
    description: '',
  });
  const [groupSecondary, setgroupSecondary] = useState({
    dropdown: [],
    description: '',
  });
  const getRefDropDownWorkSubType = (currentworkType, selectedCode) => {
    let URL = process.env.REACT_APP_GET_SUB_WORK_TYPE + `?worktype=${currentworkType}`;
    URL = getURL(URL);
    axios
      .get(URL, {
        withCredentials: true,
      })
      .then((response) => {
        let selectedDescription = 'Select';
        selectedDescription = getSelectedDescription(response.data.refValues, selectedCode);
        setSubworkType((prevState) => {
          return {
            ...prevState,
            dropdown: response.data.refValues,
            description: selectedDescription,
          };
        });
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);

        if (error.response.status === 400) {
          setShowError(true);

          setError(error.response.data);
        } else if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
          setShowModalHttpCode(error.response.status);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };
  const getSelectedDescription = (refDataDropdown, selectedCode) => {
    const selectedRefDataDescription = refDataDropdown.filter((item) => item.code === selectedCode)[0].description;
    return selectedRefDataDescription;
  };
  const getRefDropDown = (value, selectedCode, type) => {
    let URL = process.env.REACT_APP_GET_REF_DATA + value;
    URL = getURL(URL);
    axios
      .get(URL, {
        withCredentials: true,
      })
      .then((res) => {
        let selectedDescription = 'Select';
        if (!!res.data) {
          if (value === 'Vessel') {
            setvessel((prevState) => {
              selectedDescription = getSelectedDescription(res.data.refValues, selectedCode);
              return {
                ...prevState,
                dropdown: res.data.refValues,
                description: selectedDescription,
              };
            });
          } else if (value === 'WorkType' || value === 'servicetype') {
            setworkType((prevState) => {
              selectedDescription = getSelectedDescription(res.data.refValues, selectedCode);
              return {
                ...prevState,
                dropdown: res.data.refValues,
                description: selectedDescription,
              };
            });
          } else if (value === 'groupdiv') {
            if (type === '') {
              selectedDescription = getSelectedDescription(res.data.groupDetails, selectedCode);
              setgroupPrimary((prevState) => {
                return {
                  ...prevState,
                  dropdown: res.data.groupDetails,
                  description: selectedDescription,
                };
              });
            } else {
              selectedDescription = getSelectedDescription(res.data.groupDetails, selectedCode);
              setgroupSecondary((prevState) => {
                return {
                  ...prevState,
                  dropdown: res.data.groupDetails,
                  description: selectedDescription,
                };
              });
            }
          }
        }
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);

        if (error.response.status === 400) {
          setShowError(true);

          setError(error.response.data);
        } else if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
          setShowModalHttpCode(error.response.status);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };
  //Call to update status after user submits the request
  const handleUpdateForms = () => {
    setShowSpinnerApiIsInProgress(true);
    const data = {
      content: {},
      pageInstructions: [],
    };
    let URL =
      process.env.REACT_APP_REVIEW_SERVICE_REQUEST_DETAILS +
      assignmentID.id +
      (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV
        ? '?actionID=' + 'ReviewRequest'
        : '/' + 'ReviewRequest');
    URL = getURL(URL);
    axios
      .post(URL, data, {
        withCredentials: true,
      })
      .then((res) => {
        setShowSpinnerApiIsInProgress(false);

        const URL_GET_DATA_FOR_SR = process.env.REACT_APP_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL + id;

        const final_URL_GET_DATA_FOR_SR = getURL(URL_GET_DATA_FOR_SR);

        axios
          .get(final_URL_GET_DATA_FOR_SR, {
            withCredentials: true,
          })
          .then((res) => {
            if (
              process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PROD_ENV ||
              process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PRE_PROD_ENV
            ) {
              if (!!res.data.content?.pyErrorCode) {
                setErrorModal(true);
              } else {
                navigate('/req-completed', {
                  state: [res.data],
                });
              }
            } else {
              navigate('/req-completed', {
                state: [res.data],
              });
            }
          })
          .catch((err) => {
            let error = { ...err };
            error.response = error.response || { response: { data: [] } };
            setAPIError(error);
            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
              setShowModalHttpCode(error.response.status);
            } else if (error.response.status === 500) {
              setServiceDownModal(true);
            } else {
              setErrorModal(true);
            }
          });
      })
      .catch((error) => {
        setShowSpinnerApiIsInProgress(false);
        if (error.response) {
          if (error.response.status === 400) {
            setShowError(true);

            setError(error.response.data);
          }
          setAPIError(error);

          if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
            setShowModalHttpCode(error.response.status);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setErrorModal(true);
          }
        }
      });
  };

  useEffect(() => {
    if (!!id) {
      setLoading((prevState) => {
        return {
          ...prevState,
          isLoading: true,
        };
      });
      let URL = process.env.REACT_APP_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL + id;
      URL = getURL(URL);
      axios
        .get(URL, {
          withCredentials: true,
        })
        .then((res) => {
          setassignmentID((prevState) => {
            return {
              ...prevState,
              id: res.data.assignments[0].ID,
            };
          });

          setserviceDetailForEdit((prevState) => {
            return {
              ...prevState,
              serviceType: res.data.content.ServiceRequest.ServiceType,
              caseIDFirst: res.data.ID,
              caseIDSecond: res.data.assignments[0].ID,
            };
          });

          // test description for ref data

          if (res.data.content.ServiceRequest.ServiceType === appConstants.SERVICE_REQUEST_TYPE) {
            const workTypeCode = res.data.content.ServiceRequest.WorkType;
            getRefDropDown('WorkType', workTypeCode, '');
            const workSubTypeCode = res.data.content.ServiceRequest.WorkSubType;
            getRefDropDownWorkSubType(workTypeCode, workSubTypeCode);
          } else if (res.data.content.ServiceRequest.ServiceType !== appConstants.SERVICE_REQUEST_TYPE) {
            const workTypeCode = res.data.content.ServiceRequest.WorkType;
            getRefDropDown('servicetype', workTypeCode, '');
          } else if (!!res.data.content.ServiceRequest.Location.Vessel.SiteOrWharf) {
            const vesselCode = res.data.content.ServiceRequest.Location.Vessel.SiteOrWharf;
            getRefDropDown('Vessel', vesselCode, '');
          } else if (!!res.data.content.ServiceRequest.PrimaryContact) {
            const groupEntityPrimaryCode = res.data.content.ServiceRequest.PrimaryContact.Service;
            getRefDropDown('groupdiv', groupEntityPrimaryCode, '');
          } else if (!!res.data.content.ServiceRequest?.Contacts[0]?.Service) {
            const groupEntitySecondaryCode = res.data.content.ServiceRequest?.Contacts[0].Service;

            getRefDropDown('groupdiv', groupEntitySecondaryCode, 'sec');
          }

          // end test
          setsrViewInfoInEdit((prevState) => {
            return {
              ...prevState,
              data: [res.data.content.ServiceRequest, ...prevState.data],
            };
          });
          setLoading((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        })
        .catch((err) => {
          let error = { ...err };
          error.response = error.response || { response: { data: [] } };
          setAPIError(error);

          if (error.response.status === 400) {
            setShowError(true);

            setError(error.response.data);
          } else if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
            setShowModalHttpCode(error.response.status);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setErrorModal(true);
          }
        });
    }
  }, []);

  return (
    <>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}

      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}

      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
          descriptionNoPrivilage={AuthenticationModalProps.descriptionWithNoPrivilage}
          code={showModalHttpCode}
        />
      )}

      <div>
        <ErrorPopUp show={showError} error={error} closeAction={() => setShowError(false)} />
        <SubNavigation {...SubNavProps} />
        <Header
          servicerequest
          mainHeading={
            serviceDetailForEdit.serviceType === appConstants.SERVICE_REQUEST_TYPE
              ? 'Minor maintenance request'
              : 'New service request'
          }
          subHeading={content.header.subHeading}
          breadcrumbs={content.header.breadcrumbs}
          breadcrumbItems={content.header.breadcrumbItems}
        />
        <ReviewSubheader
          requestType={
            serviceDetailForEdit.serviceType === appConstants.SERVICE_REQUEST_TYPE
              ? appConstants.SERVICE_REQUEST_TYPE_MINOR
              : appConstants.SERVICE_REQUEST_TYPE_FULL
          }
          caseIdForFirstEditAPI={serviceDetailForEdit.caseIDFirst}
          caseIdForSecondEditAPI={serviceDetailForEdit.caseIDSecond}
          heading={ReviewSubheaderProps.mainHeading}
          subheading={ReviewSubheaderProps.subHeading}
        />
        {loading.isLoading && (
          <Container>
            <h2 style={{ textAlign: 'center', margin: '20px' }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </h2>
          </Container>
        )}

        {!loading.isLoading && srViewInfoInEdit.data.length > 0 && (
          <div className="landingPageWidth">
            <RequestDetailReadOnly
              workTypeDesc={workType.description}
              workSubTypeDesc={workSubType.description}
              reqData={srViewInfoInEdit.data}
              caseID={id}
            ></RequestDetailReadOnly>
            <Container className=" pt-4 pt-lg-6 ps-4 ps-lg-6 pe-4 pe-lg-6 pb-3">
              <div className="">
                <LocationReadOnly
                  vesselNameDesc={vessel.description}
                  reqData={srViewInfoInEdit.data[0]}
                ></LocationReadOnly>
              </div>
            </Container>
            <Container className=" pt-4 pt-lg-6 ps-4 ps-lg-6 pe-4 pe-lg-6 pb-1">
              <div className="">
                <ContactReadOnly
                  groupPrimaryDesc={groupPrimary.description}
                  reqData={srViewInfoInEdit.data[0]}
                ></ContactReadOnly>
              </div>
            </Container>
            {srViewInfoInEdit.data[0].HasSecondaryContact === 'true' && (
              <Container className=" pt-4 pt-lg-6 ps-4 ps-lg-6 pe-4 pe-lg-6 pb-3 pb-lg-1">
                <SecondaryContactReadOnly
                  groupSecondaryDesc={groupSecondary.description}
                  reqData={srViewInfoInEdit.data[0]}
                ></SecondaryContactReadOnly>
              </Container>
            )}
            {showSpinnerApiIsInProgress && (
              <Container>
                <ServiceConnectSpinner></ServiceConnectSpinner>
              </Container>
            )}
            <Row className="buttonContainer ps-4 ps-lg-6 pe-4 pe-lg-6 pt-4 pt-lg-6">
              <Col md className="d-flex justify-content-start pt-xs-2 pt-2 pb-6 col-md-12 col-lg-8 order-2 order-lg-1">
                <Button variant="standard" className="mobileButton">
                  <a className="noURlStyle" href="/my-service-request">
                    Back
                  </a>
                </Button>
              </Col>
              <Col md className="d-xs-inline d-lg-flex pt-2 justify-content-end order-1 order-lg-2">
                <Row>
                  <Col
                    xs={12}
                    className="col-xs-12 col-sm-12 col-md-12 col-lg-8 pt-lg-2 d-flex justify-content-end order-1 order-lg-2"
                  >
                    <Button variant="primary" className="mobileButton" onClick={handleUpdateForms}>
                      Submit Request
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
};

export default EditServiceReviewRequest;
