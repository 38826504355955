import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findByProp } from '../../../helper/findByProp';

import { TimePicker } from '../../../components/commonComponents/sc-react-timepicker';
// @ts-ignore
import { SCForm } from '../../../components/ServiceUI/Core/FormElements';
import { setFieldValue } from '../../../reducers/eftRequestDetailFormReducer';
const FormDateInput = (props) => {
  const {
    fieldID,
    fieldType,
    required,
    label,
    fieldPropMapping,
    visible,
    reference,
    formData,
    value,
    readOnly,
    error,
  } = props;
  const dispatch = useDispatch();
  const reqDetailDataFromReduxStore = useSelector((state: any) => {
    if (state) {
      return findByProp(state.EFTRequestMemberForm.formFields, 'reference', reference);
    }
  });
  const currentDate = new Date();

  const calculateMinMax = (): { min: string; max: string } => {
    let min = '';
    let max = '';
    switch (fieldID) {
      case 'DateOfBirth':
        max = new Date(new Date().setDate(currentDate.getDate() - 1)).toISOString().slice(0, 10);
        min = '';
        break;
      case 'PlannedDischargeDate':
        min = new Date(currentDate).toISOString().slice(0, 10);
        max = '';
        break;
      default:
        break;
    }
    return { min, max };
  };

  const isError = () => {
    const isDateInFutureOrToday = new Date(displayValue) >= new Date();
    const isDateInPast = new Date(displayValue) < new Date(new Date().setDate(currentDate.getDate() - 1));
    switch (fieldID) {
      case 'DateOfBirth':
        return isDateInFutureOrToday;
      case 'PlannedDischargeDate':
        return isDateInPast;
      default:
        break;
    }
  };

  const [displayValue, setDisplayValue] = useState('');

  const onTimePickerInputBlur = (time) => {
    dispatch(
      setFieldValue({
        reference,
        value: time,
        formData,
        required,
        visible,
      }),
    );
  };
  const onDateBlur = ({ target }) => {
    setDisplayValue(target.value);

    dispatch(
      setFieldValue({
        reference,
        value: target.value,
        formData,
        required,
        visible,
      }),
    );
  };
  // Created this function to show the saved value selected  on load
  // doing conversion because pega date and time format is different from react input control
  const convertValueforDisplay = (selectedData) => {
    if (fieldType === 'Date') {
      if (selectedData?.includes('-')) {
        return selectedData;
      } else {
        const yearVal = selectedData?.slice(0, 4);
        const monthVal = selectedData?.slice(4, 6);
        const dateVal = selectedData?.slice(6, 8);
        const final = yearVal + '-' + monthVal + '-' + dateVal;

        return final;
      }
    } else {
      if (selectedData.includes(':')) {
        return selectedData;
      } else {
        const hourVal = selectedData.slice(0, 2);
        const minVal = selectedData.slice(2, 4);

        const finalTimeVal = hourVal + ':' + minVal;

        return finalTimeVal;
      }
    }
  };
  useEffect(() => {
    const finalValue = convertValueforDisplay(reqDetailDataFromReduxStore?.value);
    setDisplayValue(finalValue);
  }, [fieldID, reqDetailDataFromReduxStore]);

  useEffect(() => {
    dispatch(
      setFieldValue({
        reference,
        value,
        formData,
        required,
        visible,
      }),
    );
  }, [formData.ApplicantTypeSelfMemData]);

  return (
    <>
      {fieldType === 'Date' ? (
        <SCForm.DateInput
          disabled={readOnly}
          desktopWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop}
          mobileWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile}
          label={label}
          required={required}
          type="date"
          reference={reference}
          onDateBlur={(e) => onDateBlur(e)}
          value={displayValue && displayValue.length > 0 ? displayValue : ''}
          maxDate={calculateMinMax().max}
          minDate={calculateMinMax().min}
          isInvalid={error || isError()}
          errorMessage={fieldPropMapping[fieldID]?.required.errorMessage}
        />
      ) : (
        <TimePicker
          required={required}
          inputOnBlur={onTimePickerInputBlur}
          onSave={onTimePickerInputBlur}
          value={displayValue}
          setInputValue={setDisplayValue}
          use12Hours
        />
      )}
    </>
  );
};

export default FormDateInput;
