import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, Form, Image, Modal, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { appConstants } from '../../helper/client/constant';
import alertImage from '../../images/OrangeAlert.svg';
import profileImage from '../../images/profile.svg';
import './AuthProfileModal.scss';

import GenericErrorModal from '../../components/GenericErrorModal/GenericErrorModal';
import SystemOutageModal from '../../components/SystemOutageModal/SystemOutageModal';
import AuthenticationModal from '../LandingPage/AuthenticationModal/AuthenticationModal';
import LinkDefenceEmail from './LinkDefenceEmail';

import GenericErrorModalProps from '../../components/GenericErrorModal/__mocks__/default.json';
import AuthenticationModalProps from '../../components/LandingPage/AuthenticationModal/__mocks__/default.json';
import SystemOutageModalProps from '../../components/SystemOutageModal/__mocks__/default.json';
import { getURL } from '../../helper/getAPIUrl';

const AuthProfileModal = (props) => {
  const gotoHomePage = () => {
    props.onComingtoHomePage();
  };
  return (
    <>
      {props.isCookieSet === true && (
        <Modal
          centered
          data-testid="modal"
          dialogClassName="modal-lg modalPadding px-lg-0 py-5"
          contentClassName="p-lg-4 modal-75w"
          show={true}
          backdrop="static"
          backdropClassName="backdrop-profile"
        >
          <AuthProfileModalContent ongoToSCHomePage={gotoHomePage} />
        </Modal>
      )}
    </>
  );
};

const AuthProfileModalContent = (props) => {
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [APIError, setAPIError] = useState({});

  let navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const idpMg = searchParams.get('i') === 'mg' ?? false;

  const [loading, setLoading] = useState({
    isLoading: true,
  });
  const goToServiceConnectHomePage = () => {
    let path = `/`;
    navigate(path);
  };
  const [pmsksField, setPmsksFound] = useState({
    isPmksFound: true,
    disabledPMksField: false,
  });

  const [selectedField, setSelectedField] = useState({
    selectedFieldName: idpMg ? appConstants.PROFILE_USERTYPE_CONTRACTOR : '',
    disableCreateprofileButton: true,
    disableContractorCard: false,
    showMoreDataForm: idpMg ? true : false,
  });
  const [isOpen, setIsOpen] = useState({
    flag: false,
  });
  const [defenceEmail, setdefenceEmail] = useState({
    defenceEmailId: '',
    defenceFirstName: '',
    defenceLastName: '',
  });
  const pmkNumberRef = useRef();
  const DOBRef = useRef();
  const [PMkeysForms, setPMkeysForms] = useState({ PmKeys: '', dob: '' });
  const [PMKerrors, setPMKErrors] = useState({});
  const [pmkeyFormInvalid, setpmkeyFormInvalid] = useState(false);
  const setPMKFields = (field, value) => {
    setPMkeysForms({ ...PMkeysForms, [field]: value });
    if (!!PMKerrors[field]) {
      setPMKErrors({ ...PMKerrors, [field]: null });
    }
  };

  const [createProfileForms, setCreateProfileForms] = useState({
    name: defenceEmail.defenceFirstName,
    surname: '',
    email: '',
    odsId: '',
    dob: '',
    service: '',
    phoneNumber: '',
    prefferedContactNumber: '',
    homeBaseAddress: '',
    alternateBaseLocation: appConstants.PROFILE_LOCSELECT,
    workLocation: '',
    startdateworkinglocation: '',
    enddateworkinglocation: '',
    startdatealternatelocation: '',
    enddatealternatelocation: '',
    defenceEmailAddress: '',
  });
  const [createProfileErrors, setCreateProfileErrors] = useState({});
  const [createProfileIsInvalid, setcreateProfileIsInvalid] = useState(false);
  const [showLocationDropDown, setLocationDropDown] = useState({
    showAlternateLocation: false,
    showWorkLocation: false,
  });
  const [entityDropdown, setEntityDropdown] = useState({
    dropDownArrayEntity: [],
    selectedCode: '',
    selectedDescription: '',
  });

  const [dropDownLocationBaseName, setDropDownLocationBaseName] = useState({
    dropDownArrayBaseName: [],
    selectedValue: appConstants.PROFILE_DEFAULT_BASE,
    selectedWorkingLocation: '',
    selectedAlternateBase: appConstants.PROFILE_LOCSELECT,
  });
  const [dropDownWorkingLocName, setdropDownWorkingLocName] = useState({
    dropDownArrayWorkingLocName: [],
    selectedWorkingLocation: '',
  });
  const [dropDownAlternateLocName, setdropDownAlternateLocName] = useState({
    dropDownArrayAlternateLocName: [],
    selectedAlternateBase: appConstants.PROFILE_LOCSELECT,
  });
  const testbaseValueOpen = () => {
    setIsOpen((prevState) => {
      return {
        ...prevState,
        flag: true,
      };
    });
  };
  const testbaseValueClose = () => {
    setIsOpen((prevState) => {
      return {
        ...prevState,
        flag: false,
      };
    });
  };

  const handleBaseSelect = (text, selectedBaseNameObj) => {
    setCreateProfileForms((prevState) => {
      return {
        ...prevState,
        homeBaseAddress: text,
      };
    });
    setDropDownLocationBaseName((prevState) => {
      return {
        ...prevState,
        selectedValue: text,
      };
    });
  };
  const handleWorkingLocationSelect = (text, selectedBaseNameObj) => {
    setCreateProfileForms((prevState) => {
      return {
        ...prevState,
        workLocation: text,
      };
    });
    setdropDownWorkingLocName((prevState) => {
      return {
        ...prevState,
        selectedWorkingLocation: text,
      };
    });
  };
  const handleAlternateBaseSelect = (text, selectedBaseNameObj) => {
    createProfileForms.alternateBaseLocation = text;
    setCreateProfileForms((prevState) => {
      return {
        ...prevState,
        alternateBaseLocation: text,
      };
    });
    setdropDownAlternateLocName((prevState) => {
      return {
        ...prevState,
        selectedAlternateBase: text,
      };
    });
    if (text === appConstants.PROFILE_LOCSELECT) {
      setCreateProfileForms((prevState) => {
        return {
          ...prevState,
          startdatealternatelocation: '',
          enddatealternatelocation: '',
        };
      });
      createProfileForms.startdatealternatelocation = '';
      createProfileForms.enddatealternatelocation = '';
    }
    const createProfileError = validateCreateProfileForm();
    if (Object.keys(createProfileError).length > 0) {
      setCreateProfileErrors(createProfileError);
    }
  };

  const setCreateProfileFields = (field, value) => {
    setCreateProfileForms({ ...createProfileForms, [field]: value });
    if (!!createProfileErrors[field]) {
      setCreateProfileErrors({ ...createProfileErrors, [field]: null });
    }
  };
  const resetPMKForm = () => {
    setPMkeysForms({ PmKeys: '', dob: '' });

    setPmsksFound((prevState) => {
      return {
        isPmksFound: false,
        disabledPMksField: false,
      };
    });
    setPMKErrors({});
  };
  const resetProfileSearchForm = () => {
    setCreateProfileForms({
      name: defenceEmail.defenceFirstName,
      surname: defenceEmail.defenceLastName,
      email: defenceEmail.defenceEmailId,
      odsId: '',
      dob: '',
      service: '',
      phoneNumber: '',
      prefferedContactNumber: '',
      homeBaseAddress: '',
      alternateBaseLocation: appConstants.PROFILE_LOCSELECT,
      workLocation: '',
      startdatealternatelocation: '',
      enddatealternatelocation: '',
    });

    setDropDownLocationBaseName((prevState) => {
      return {
        ...prevState,
        selectedValue: appConstants.PROFILE_DEFAULT_BASE,
      };
    });
    setLocationDropDown((prevState) => {
      return {
        showAlternateLocation: false,
        showWorkLocation: false,
      };
    });
    setCreateProfileErrors({});
  };

  const validateCreateProfileForm = () => {
    const {
      name,
      surname,
      email,
      odsId,
      dob,
      service,
      phoneNumber,
      prefferedContactNumber,
      homeBaseAddress,
      alternateBaseLocation,
      workLocation,
      startdatealternatelocation,
      enddatealternatelocation,
    } = createProfileForms;
    const emailRegEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;

    const newErrors = {};
    if (!idpMg) {
      if (!name || name === '') {
        newErrors.name = appConstants.PROFILE_ERROR_NAME;
      }
      if (!surname || surname === '') {
        newErrors.surname = appConstants.PROFILE_ERROR_SURNAME;
      }
    }

    // if (!!email && !emailRegEx.test(email)) {
    //   newErrors.email = "Entered email is invalid";
    // }

    if (!phoneNumber || phoneNumber === '') {
      newErrors.phoneNumber = appConstants.PROFILE_ERROR_PH;
    }
    if (!phoneNumber.match('[0-9]{10}')) {
      newErrors.phoneNumber = appConstants.PROFILE_INVALID_PH;
    }
    if (prefferedContactNumber) {
      if (!prefferedContactNumber.match('[0-9]{10}')) {
        newErrors.prefferedContactNumber = appConstants.PROFILE_INVALID_MOBILE;
      }
    }
    if (!homeBaseAddress || homeBaseAddress === appConstants.PROFILE_LOCSELECT) {
      newErrors.homeBaseAddress = appConstants.PROFILE_ERROR_BASE;
    }

    if (alternateBaseLocation !== appConstants.PROFILE_LOCSELECT) {
      let startDate = new Date(startdatealternatelocation);

      let endDate = new Date(enddatealternatelocation);
      if (!startdatealternatelocation || startdatealternatelocation === '') {
        newErrors.startdatealternatelocation = appConstants.PROFILE_ERROR_START_DATE;
      }
      if (!enddatealternatelocation || enddatealternatelocation === '' || endDate < startDate) {
        newErrors.enddatealternatelocation = appConstants.PROFILE_ERROR_END_DATE;
      }
    }

    return newErrors;
  };
  const createProfileHandler = (e) => {
    e.preventDefault();

    const createProfileError = validateCreateProfileForm();

    if (Object.keys(createProfileError).length > 0) {
      setCreateProfileErrors(createProfileError);
    } else {
      const NewProfileValues = preparePageInstructionsForNewProfile();

      const data = {
        content: {},
        pageInstructions: NewProfileValues,
      };

      if (selectedField.selectedFieldName === appConstants.PROFILE_USERTYPE_CONTRACTOR) {
        if (idpMg) {
          submitProfileUpdates();
        } else {
          UpdateProfileDetailsWithCreateProfile();
        }
      } else {
        const URL_UPDATE_PROFILE_SETUP_DETAILS =
          process.env.REACT_APP_UPDATE_PROFILE_SETUP_DETAILS +
          nextAssignmentIdProfileSetpState.NextAssignmentIdProfileSetup +
          (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV
            ? '?actionID=' + 'CollectNewProfile'
            : '/' + 'CollectNewProfile');
        const final_URL_UPDATE_PROFILE_SETUP_DETAILS = getURL(URL_UPDATE_PROFILE_SETUP_DETAILS);
        axios
          .post(final_URL_UPDATE_PROFILE_SETUP_DETAILS, data, {
            withCredentials: true,
          })
          .then((res) => {
            const URL_GET_TOKEN_PROFILE_SETUP = getURL(process.env.REACT_APP_GET_TOKEN_PROFILE_SETUP);
            axios
              .get(URL_GET_TOKEN_PROFILE_SETUP, {
                withCredentials: true,
              })
              .then((res) => {
                if (res.status === 200) {
                  goToServiceConnectHomePage();
                  props.ongoToSCHomePage();
                }
              })
              .catch((err) => {
                let error = { ...err };
                error.response = error.response || { response: { data: [] } };
                setAPIError(error);
                if (error.response.status === 403 || error.response.status === 401) {
                  setShowModal(true);
                } else if (error.response.status === 500) {
                  setServiceDownModal(true);
                } else {
                  setErrorModal(true);
                }
              });
            //goToServiceConnectHomePage();
          })
          .catch((error) => {
            if (error.response) {
              // Request made and server responded
              setAPIError(error);
              if (error.response.status === 403 || error.response.status === 401) {
                setShowModal(true);
              } else if (error.response.status === 500) {
                setServiceDownModal(true);
              } else {
                //alert(appConstants.SR_GENERIC_ERROR);
                setErrorModal(true);
              }
            }
          });
      }
    }
  };
  const validatePmKeysForm = () => {
    const { PmKeys, dob } = PMkeysForms;

    const newErrors = {};
    if (!dob || dob === '') {
      newErrors.dob = appConstants.PROFILE_ERROR_DOB;
    }
    if (!!dob) {
      let selectedDate = new Date(dob);
      let now = new Date();
      if (selectedDate > now) {
        newErrors.dob = appConstants.PROFILE_INVALID_DOB;
      }
    }
    if (!PmKeys || PmKeys === '') {
      newErrors.PmKeys = appConstants.PROFILE_ERROR_PMKEYS;
    }
    return newErrors;
  };
  const afterSetStateFinishedForPMKEYS = () => {
    const PMKeysFormsErrors = validatePmKeysForm();

    if (Object.keys(PMKeysFormsErrors).length > 0) {
      setPMKErrors(PMKeysFormsErrors);
      setpmkeyFormInvalid(true);
    } else {
      setpmkeyFormInvalid(false);
    }
  };

  const afterSetStateFinishedForCreateProfile = () => {
    const createProfileError = validateCreateProfileForm();

    if (Object.keys(createProfileError).length > 0) {
      setCreateProfileErrors(createProfileError);
      setcreateProfileIsInvalid(true);
    } else {
      setcreateProfileIsInvalid(false);
    }
  };
  const pmkSearchHandler = (e) => {
    e.preventDefault();

    const PMKeysFormsErrors = validatePmKeysForm();

    setSelectedField((prevState) => {
      return {
        ...prevState,
        disableContractorCard: true,
      };
    });

    if (Object.keys(PMKeysFormsErrors).length > 0) {
      setPMKErrors(PMKeysFormsErrors);
    } else {
      //TODO: Add document referrence for field names to be sent and received from Pega

      const preparePageInstructionsArr = [
        {
          instruction: appConstants.PAGE_INSTR_UPDATE,
          target: '.ProfileSetup',
          content: {
            PMKeysNumber: pmkNumberRef.current.value,
          },
        },
        {
          instruction: appConstants.PAGE_INSTR_UPDATE,
          target: '.ProfileSetup',
          content: {
            DOB: DOBRef.current.value,
          },
        },
        {
          instruction: appConstants.PAGE_INSTR_UPDATE,
          target: '.ProfileSetup',
          content: {
            IsContractor: false,
          },
        },
        {
          instruction: appConstants.PAGE_INSTR_UPDATE,
          target: '.ProfileSetup',
          content: {
            DefenceEmail: defenceEmail.defenceEmailId,
          },
        },
      ];

      const data = {
        content: {},
        pageInstructions: preparePageInstructionsArr,
      };

      const URL_UPDATE_PROFILE_SETUP_DETAIL =
        process.env.REACT_APP_UPDATE_PROFILE_SETUP_DETAILS +
        nextAssignmentIdProfileSetpState.NextAssignmentIdProfileSetup +
        (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV
          ? '?actionID=' + actionIdIdProfileSetupState.ActionIdProfileSetup
          : '/' + actionIdIdProfileSetupState.ActionIdProfileSetup);
      const final_URL_UPDATE_PROFILE_SETUP_DETAIL = getURL(URL_UPDATE_PROFILE_SETUP_DETAIL);
      axios
        .post(final_URL_UPDATE_PROFILE_SETUP_DETAIL, data, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.nextAssignmentID) {
          } else {
            const URL_GET_TOKEN_PROFILE_SETUP = getURL(process.env.REACT_APP_GET_TOKEN_PROFILE_SETUP);
            axios
              .get(URL_GET_TOKEN_PROFILE_SETUP, {
                withCredentials: true,
              })
              .then((res) => {
                if (res.status === 200) {
                  goToServiceConnectHomePage();
                  props.ongoToSCHomePage();
                }
              })
              .catch((err) => {
                let error = { ...err };
                error.response = error.response || { response: { data: [] } };
                setAPIError(error);
                if (error.response.status === 403 || error.response.status === 401) {
                  setShowModal(true);
                } else if (error.response.status === 500) {
                  setServiceDownModal(true);
                } else {
                  setErrorModal(true);
                }
              });
            //TODO: Call custom API to update SCPS cookie
            //goToServiceConnectHomePage();
            // window.location.href =
            // "https://devpublic.serviceconnect.defence.gov.au/prweb/PRAuth/ServiceConnectAD";
          }
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            setAPIError(error);
            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
            } else if (error.response.status === 500) {
              //PEGA is down - show Modal Here for service down
              setServiceDownModal(true);
            } else {
              //alert(appConstants.SR_GENERIC_ERROR);
              setErrorModal(true);
            }
          }
        });

      setSelectedField((prevState) => {
        return {
          ...prevState,
          showMoreDataForm: true,
        };
      });

      setPmsksFound((prevState) => {
        return {
          ...prevState,
          isPmksFound: false,
          disabledPMksField: true,
        };
      });
    }
  };

  const submitProfileUpdates = () => {
    const NewProfileValues = preparePageInstructionsForNewProfile();
    const data = {
      content: {},
      pageInstructions: NewProfileValues,
    };

    const URL_UPDATE_PROFILE_SETUP_DETAILS =
      process.env.REACT_APP_UPDATE_PROFILE_SETUP_DETAILS +
      nextAssignmentIdProfileSetpState.NextAssignmentIdProfileSetup +
      (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV
        ? '?actionID=' + 'CollectNewProfile'
        : '/' + 'CollectNewProfile');
    const final_URL_UPDATE_PROFILE_SETUP_DETAILS = getURL(URL_UPDATE_PROFILE_SETUP_DETAILS);
    axios
      .post(final_URL_UPDATE_PROFILE_SETUP_DETAILS, data, {
        withCredentials: true,
      })
      .then((res) => {
        const URL_GET_TOKEN_PROFILE_SETUP = getURL(process.env.REACT_APP_GET_TOKEN_PROFILE_SETUP);
        axios
          .get(URL_GET_TOKEN_PROFILE_SETUP, {
            withCredentials: true,
          })
          .then((res) => {
            if (res.status === 200) {
              goToServiceConnectHomePage();
              props.ongoToSCHomePage();
            }
          })
          .catch((err) => {
            let error = { ...err };
            error.response = error.response || { response: { data: [] } };
            setAPIError(error);
            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
            } else if (error.response.status === 500) {
              setServiceDownModal(true);
            } else {
              setErrorModal(true);
            }
          });
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          setAPIError(error);
          if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            alert(appConstants.SR_GENERIC_ERROR);
            setErrorModal(true);
          }
        }
      });
  };

  const UpdateProfileDetailsWithCreateProfile = () => {
    const preparePageInstructionsArrCon = [
      {
        instruction: appConstants.PAGE_INSTR_UPDATE,
        target: '.ProfileSetup',
        content: {
          IsContractor: true,
        },
      },
    ];

    const data = {
      content: {},
      pageInstructions: preparePageInstructionsArrCon,
    };
    const URL_UPDATE_PROFILE_SETUP_DETAILS =
      process.env.REACT_APP_UPDATE_PROFILE_SETUP_DETAILS +
      nextAssignmentIdProfileSetpState.NextAssignmentIdProfileSetup +
      (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV
        ? '?actionID=' + actionIdIdProfileSetupState.ActionIdProfileSetup
        : '/' + actionIdIdProfileSetupState.ActionIdProfileSetup);
    const final_URL_UPDATE_PROFILE_SETUP_DETAILS = getURL(URL_UPDATE_PROFILE_SETUP_DETAILS);
    axios
      .post(final_URL_UPDATE_PROFILE_SETUP_DETAILS, data, {
        withCredentials: true,
      })
      .then(() => {
        submitProfileUpdates();
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          setAPIError(error);
          if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setErrorModal(true);

            alert(appConstants.SR_GENERIC_ERROR);
          }
        }
      });
  };

  const getDiffDropDown = (value) => {
    const URL_GET_REF_DATA_PROFILE_SETUP = process.env.REACT_APP_GET_REF_DATA_PROFILE_SETUP + value;
    const final_GET_REF_DATA_PROFILE_SETUP = getURL(URL_GET_REF_DATA_PROFILE_SETUP);
    const apiRes = axios.get(final_GET_REF_DATA_PROFILE_SETUP, {
      withCredentials: true,
    });
    return apiRes;
  };
  const getGroupDropDown = () => {
    const URL_GET_GROUP_LIST = getURL(process.env.REACT_APP_GET_GROUP_LIST);
    const apiRes = axios.get(URL_GET_GROUP_LIST, {
      withCredentials: true,
    });
    return apiRes;
  };

  const changeEntityDropDownValue = (description, code) => {
    const serviceCode = entityDropdown.dropDownArrayEntity.filter((item) => item.description === description);
    createProfileForms.service = description;
    setEntityDropdown((prevState) => {
      return {
        ...prevState,
        selectedCode: serviceCode[0].code,
        selectedDescription: description,
      };
    });
  };

  const getBaseNameDropDown = () => {
    const URL_GET_BASE_DROPDOWN_PROFILE_SETUP = getURL(process.env.REACT_APP_GET_BASE_DROPDOWN_PROFILE_SETUP);
    axios
      .get(URL_GET_BASE_DROPDOWN_PROFILE_SETUP, {
        withCredentials: true,
      })
      .then((res) => {
        const locDetailsBase = [{ name: 'Select' }].concat(res.data.locDetails);

        //const locDetailsBase = res.data.locDetails;
        setCreateProfileForms((prevState) => {
          return {
            ...prevState,
            homeBaseAddress: appConstants.PROFILE_DEFAULT_BASE,
          };
        });
        setDropDownLocationBaseName((prevState) => {
          return {
            ...prevState,
            dropDownArrayBaseName: locDetailsBase,
            selectedValue: appConstants.PROFILE_DEFAULT_BASE,
          };
        });
        setdropDownAlternateLocName((prevState) => {
          return {
            ...prevState,
            dropDownArrayAlternateLocName: locDetailsBase,
            selectedAlternateBase: appConstants.PROFILE_LOCSELECT,
          };
        });
        setLoading((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };

  const getWorkingLocDropDown = () => {
    const URL_GET_WORKLOC_DROPDOWN_PROFILE_SETUP = getURL(process.env.REACT_APP_GET_WORKLOC_DROPDOWN_PROFILE_SETUP);
    axios
      .get(URL_GET_WORKLOC_DROPDOWN_PROFILE_SETUP, {
        withCredentials: true,
      })
      .then((res) => {
        const locDetailsBase = [{ name: 'Select' }].concat(res.data.locDetails);

        //const locDetailsBase = res.data.locDetails;
        setdropDownWorkingLocName((prevState) => {
          return {
            ...prevState,
            dropDownArrayWorkingLocName: locDetailsBase,
            selectedWorkingLocation: appConstants.PROFILE_LOCSELECT,
          };
        });
        setLoading((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };
  const getPersonalDetails = () => {
    const URL_GET_PROFILEINFO_PROFILE_SETUP =
      process.env.REACT_APP_GET_PROFILEINFO_PROFILE_SETUP +
      (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV ? '?mode=basic' : '');
    const final_URL_GET_PROFILEINFO_PROFILE_SETUP = getURL(URL_GET_PROFILEINFO_PROFILE_SETUP);
    axios
      .get(final_URL_GET_PROFILEINFO_PROFILE_SETUP, {
        withCredentials: true,
      })
      .then((res) => {
        setdefenceEmail((prevState) => {
          return {
            ...prevState,
            defenceEmailId: res.data.emailId,
            defenceFirstName: idpMg ? res.data.firstName : '',
            defenceLastName: idpMg ? res.data.lastName : '',
          };
        });

        createProfileForms.email = defenceEmail.defenceEmailId;
        createProfileForms.name = defenceEmail.defenceFirstName;
        createProfileForms.surname = defenceEmail.defenceLastName;
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };

  const preparePageInstructionsForNewProfile = () => {
    //let firstName = createProfileForms.name.split(" ").slice(0, -1).join(" ");
    //let lastName = createProfileForms.name.split(" ").slice(-1).join(" ");

    const NewProfileArr = [
      {
        instruction: appConstants.PAGE_INSTR_UPDATE,
        target: '.ProfileSetup',
        content: {
          FirstName: idpMg ? defenceEmail.defenceFirstName : createProfileForms.name,
        },
      },
      {
        instruction: appConstants.PAGE_INSTR_UPDATE,
        target: '.ProfileSetup',
        content: {
          LastName: idpMg ? defenceEmail.defenceLastName : createProfileForms.surname,
        },
      },
      {
        instruction: appConstants.PAGE_INSTR_UPDATE,
        target: '.ProfileSetup',
        content: {
          DefenceEmail: defenceEmail.defenceEmailId,
        },
      },
      {
        instruction: appConstants.PAGE_INSTR_UPDATE,
        target: '.ProfileSetup',
        content: {
          PhoneNumber: createProfileForms.phoneNumber,
        },
      },
      {
        instruction: appConstants.PAGE_INSTR_UPDATE,
        target: '.ProfileSetup',
        content: {
          PreferredContactNo: createProfileForms.prefferedContactNumber,
        },
      },
      {
        instruction: appConstants.PAGE_INSTR_UPDATE,
        target: '.ProfileSetup',
        content: {
          HomeBaseName: dropDownLocationBaseName.selectedValue,
        },
      },
      {
        instruction: appConstants.PAGE_INSTR_UPDATE,
        target: '.ProfileSetup',
        content: {
          WorkLocationName: dropDownWorkingLocName.selectedWorkingLocation,
        },
      },
      {
        instruction: appConstants.PAGE_INSTR_UPDATE,
        target: '.ProfileSetup',
        content: {
          AlternateBaseName: dropDownAlternateLocName.selectedAlternateBase,
        },
      },
      {
        instruction: appConstants.PAGE_INSTR_UPDATE,
        target: '.ProfileSetup',
        content: {
          AlternateBaseStartDate: createProfileForms.startdatealternatelocation,
        },
      },
      {
        instruction: appConstants.PAGE_INSTR_UPDATE,
        target: '.ProfileSetup',
        content: {
          AlternateBaseEndDate: createProfileForms.enddatealternatelocation,
        },
      },
      {
        instruction: appConstants.PAGE_INSTR_UPDATE,
        target: '.ProfileSetup',
        content: {
          DefenceEmailAddress: createProfileForms.defenceEmailAddress,
        },
      },
      {
        instruction: appConstants.PAGE_INSTR_UPDATE,
        target: '.ProfileSetup',
        content: {
          PMKeySNumber: PMkeysForms.PmKeys,
        },
      },
    ];
    return NewProfileArr;
  };

  const addWorkLocationClickHandler = () => {
    setLocationDropDown((prevState) => {
      return {
        ...prevState,
        showWorkLocation: true,
      };
    });
  };
  const addAlternateLocationClickHandler = () => {
    setLocationDropDown((prevState) => {
      return {
        ...prevState,
        showAlternateLocation: true,
      };
    });
  };
  const onChangeIdentity = (name) => {
    if (selectedField.disableContractorCard) {
      return;
    } else {
      if (name === 'APSADF') {
        handlerClose();
        resetProfileSearchForm();
        setSelectedField((prevState) => {
          return {
            ...prevState,
            selectedFieldName: name,
            //disableOdsId: true,
            showMoreDataForm: false,
          };
        });
      } else {
        handlerClose();
        resetPMKForm();
        setSelectedField((prevState) => {
          return {
            ...prevState,
            selectedFieldName: name,
            showMoreDataForm: true,
          };
        });
      }
    }
  };

  const PMKsSearchForm = (
    <Form>
      <Row className="py-lg-3 px-lg-2">
        <Col className="md-2 infoText-profile p-lg-0 py-3" sm={6}>
          <Container className="pe-3">
            <Form.Group>
              <Form.Label className="profile-main">
                PMKeys<span style={{ color: 'red' }}>*</span>
              </Form.Label>
              <Form.Control
                ref={pmkNumberRef}
                name="PmKeys"
                className="inputWidth"
                type="text"
                value={PMkeysForms.PmKeys}
                disabled={pmsksField.disabledPMksField}
                placeholder="Enter PMKeys number"
                onChange={(e) => setPMKFields('PmKeys', e.target.value)}
                isInvalid={!!PMKerrors.PmKeys}
              />
              <Form.Control.Feedback type="invalid">{PMKerrors.PmKeys}</Form.Control.Feedback>
            </Form.Group>
          </Container>
        </Col>
        <Col className="infoText-profile p-lg-0 pb-3" sm={6}>
          <Container>
            <Form.Group>
              <Form.Label className="profile-main">
                Date of birth<span style={{ color: 'red' }}>*</span>
              </Form.Label>
              <Form.Control
                type="date"
                name="dob"
                className="inputWidth"
                ref={DOBRef}
                value={PMkeysForms.dob}
                disabled={pmsksField.disabledPMksField}
                onChange={(e) => setPMKFields('dob', e.target.value)}
                isInvalid={!!PMKerrors.dob}
              />
              <Form.Control.Feedback type="invalid">{PMKerrors.dob}</Form.Control.Feedback>
            </Form.Group>
          </Container>
        </Col>
      </Row>
      {idpMg && (
        <Row className="mt-4">
          <Col>
            <LinkDefenceEmail
              defenceEmail={createProfileForms.defenceEmailAddress}
              onChangeEmail={(value) => setCreateProfileFields('defenceEmailAddress', value)}
            />
          </Col>
        </Row>
      )}
      <div className="align-item-button">
        <Button
          className="mobileButton profile-btn-style"
          disabled={pmkeyFormInvalid}
          onClick={pmkSearchHandler}
          variant="primary"
          size="sm"
        >
          Search
        </Button>
      </div>
    </Form>
  );
  const workLocationDropDownUI = showLocationDropDown.showWorkLocation ? (
    <Row>
      <Col>
        <Row>
          <Form.Group>
            <Form.Label className="profile-other-info-text">Working location</Form.Label>
            <br></br>
            <div className="col-margin-right" style={{ display: 'inline-block', width: '100%' }}>
              <Select
                value={
                  isOpen.flag
                    ? [
                        {
                          label: null,
                          value: null,
                        },
                      ]
                    : [
                        {
                          label: dropDownWorkingLocName.selectedWorkingLocation,
                          value: dropDownWorkingLocName.selectedWorkingLocation,
                        },
                      ]
                }
                onChange={(selection, action) => {
                  handleWorkingLocationSelect(selection.label, selection.value);
                }}
                options={dropDownWorkingLocName.dropDownArrayWorkingLocName.map((key, text) => {
                  return {
                    value: key,
                    label: key.name,
                  };
                })}
              />
            </div>
          </Form.Group>
        </Row>
      </Col>
    </Row>
  ) : null;
  const alternareLocationDropDownUI = showLocationDropDown.showAlternateLocation ? (
    <Row>
      <br></br>
      <Col>
        <Row>
          <Form.Group>
            <Form.Label className="profile-other-info-text">Alternate base</Form.Label>
            <br></br>
            <div style={{ display: 'inline-block', width: '100%' }}>
              <Select
                value={{
                  label: dropDownAlternateLocName.selectedAlternateBase,
                  value: dropDownAlternateLocName.selectedAlternateBase,
                }}
                onChange={(selection, action) => {
                  handleAlternateBaseSelect(selection.label, selection.value);
                }}
                options={dropDownAlternateLocName.dropDownArrayAlternateLocName.map((key, text) => {
                  return {
                    value: key,
                    label: key.name,
                  };
                })}
              />
            </div>
            <div className="dropdown-error-msg">{createProfileErrors.alternateBaseLocation}</div>
          </Form.Group>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label className="profile-other-info-text">Date from</Form.Label>
              <Form.Control
                type="date"
                placeholder=""
                name="startdatealternatelocation"
                value={createProfileForms.startdatealternatelocation}
                onChange={(e) => setCreateProfileFields('startdatealternatelocation', e.target.value)}
                isInvalid={!!createProfileErrors.startdatealternatelocation}
              />

              <Form.Control.Feedback type="invalid" className="validation-error">
                {createProfileErrors.startdatealternatelocation}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="col-margin">
            <Form.Group>
              <Form.Label className="profile-other-info-text">Date to</Form.Label>
              <Form.Control
                type="date"
                placeholder=""
                name="enddatealternatelocation"
                value={createProfileForms.enddatealternatelocation}
                onChange={(e) => setCreateProfileFields('enddatealternatelocation', e.target.value)}
                isInvalid={!!createProfileErrors.enddatealternatelocation}
              />

              <Form.Control.Feedback type="invalid" className="validation-error">
                {createProfileErrors.enddatealternatelocation}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Col>
    </Row>
  ) : null;

  const profileCreateForm = (
    <>
      <br></br>
      <Container
        className="bg-defencewhisperlightgrey text-black recordNotfoundText required-field"
        style={{
          display: selectedField.selectedFieldName === 'Contractor' ? 'none' : 'block',
        }}
      >
        <Container className="p-3">
          <Image className="icon-size-medium" src={alertImage} alt="" />
          &nbsp; Your record has not been found in PMKeys
        </Container>
      </Container>
      <div className="profile-container mt-0">
        <Form>
          <Row>
            <Col className="d-flex mobilecolumn p-0">
              <Container className="me-lg-5 me-0">
                <Form.Group>
                  <Form.Label className="profile-other-info-text">
                    Given name(s)<span className="required-field"></span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="name"
                    disabled={idpMg}
                    value={idpMg ? defenceEmail.defenceFirstName : createProfileForms.name}
                    onChange={(e) => {
                      setCreateProfileFields('name', e.target.value);
                    }}
                    isInvalid={!!createProfileErrors.name}
                  />
                  <Form.Control.Feedback type="invalid" className="validation-error">
                    {createProfileErrors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Container>

              <Container>
                <Form.Group>
                  <Form.Label className="profile-other-info-text">
                    Surname<span className="required-field"></span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="surname"
                    disabled={idpMg}
                    value={idpMg ? defenceEmail.defenceLastName : createProfileForms.surname}
                    onChange={(e) => {
                      setCreateProfileFields('surname', e.target.value);
                    }}
                    isInvalid={!!createProfileErrors.surname}
                  />
                  <Form.Control.Feedback type="invalid" className="validation-error">
                    {createProfileErrors.surname}
                  </Form.Control.Feedback>
                </Form.Group>
              </Container>
            </Col>
          </Row>

          <Col className="p-0 pe-lg-0">
            <Form.Group>
              <Form.Label className="profile-other-info-text">
                Email address<span className="required-field"></span>
              </Form.Label>

              <Form.Control
                disabled
                type="text"
                className="emailSize"
                name="email"
                value={defenceEmail.defenceEmailId}
                onChange={(e) => setCreateProfileFields('email', e.target.value)}
              />
            </Form.Group>
          </Col>

          <Row>
            <Col className="d-flex mobilecolumn p-0">
              <Container className="me-lg-5 me-0">
                <Form.Group>
                  <Form.Label className="profile-other-info-text">
                    Phone number
                    <span className="required-field"></span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    maxLength="10"
                    name="phoneNumber"
                    value={createProfileForms.phoneNumber}
                    onChange={(e) => setCreateProfileFields('phoneNumber', e.target.value)}
                    isInvalid={!!createProfileErrors.phoneNumber}
                  />
                  <Form.Control.Feedback type="invalid" className="validation-error">
                    {createProfileErrors.phoneNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Container>

              <Container>
                <Form.Group>
                  <Form.Label className="profile-other-info-text">
                    Mobile number
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    maxLength="10"
                    placeholder=""
                    name="prefferedContactNumber"
                    value={createProfileForms.prefferedContactNumber}
                    onChange={(e) => setCreateProfileFields('prefferedContactNumber', e.target.value)}
                    isInvalid={!!createProfileErrors.prefferedContactNumber}
                  />
                  <Form.Control.Feedback type="invalid" className="validation-error">
                    {createProfileErrors.prefferedContactNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Container>
            </Col>
          </Row>
          {idpMg && (
            <Row className="mt-4">
              <Col>
                <LinkDefenceEmail
                  defenceEmail={createProfileForms.defenceEmailAddress}
                  onChangeEmail={(value) => setCreateProfileFields('defenceEmailAddress', value)}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col className="col-margin-right">
              <Form.Group>
                <Form.Label className="fw-normal location-text pt-lg-5 pt-3">Location Details</Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Container>
                <Form.Group>
                  <Form.Label className="profile-other-info-text">
                    Home base<span className="required-field"></span>
                  </Form.Label>
                  <br></br>
                  <div style={{ display: 'inline-block', width: '100%' }}>
                    <Select
                      style={
                        createProfileErrors.homeBaseAddress?.length > 0
                          ? {
                              borderColor: 'red',
                              borderWidth: '1px',
                              borderStyle: 'solid',
                            }
                          : {
                              borderColor: 'gray',
                              borderWidth: '1px',
                              borderStyle: 'solid',
                            }
                      }
                      value={
                        isOpen.flag
                          ? [
                              {
                                label: null,
                                value: null,
                              },
                            ]
                          : [
                              {
                                label: dropDownLocationBaseName.selectedValue,
                                value: dropDownLocationBaseName.selectedValue,
                              },
                            ]
                      }
                      onMenuOpen={testbaseValueOpen}
                      onMenuClose={testbaseValueClose}
                      onChange={(selection, action) => {
                        handleBaseSelect(selection.label, selection.value);
                      }}
                      options={dropDownLocationBaseName.dropDownArrayBaseName.map((key, text) => {
                        return {
                          value: key,
                          label: key.name,
                        };
                      })}
                      isInvalid={!!createProfileErrors.homeBaseAddress}
                    />
                    {/* <div className="dropdown-error-msg">
                      {createProfileErrors.homeBaseAddress}
                    </div> */}
                  </div>
                </Form.Group>
              </Container>
            </Col>
          </Row>

          {/*  <Row>
            <Form.Group>
              <Form.Label className="fw-normal location-text space-top">
                Working location
              </Form.Label>
            </Form.Group>
          </Row> */}

          {/* <Form.Group>
                <Form.Label className="profile-other-info-text">
                  If you typically work from a Defence location that is not a
                  base(ie, office or wharf)
                  <br></br>please add this information
                </Form.Label>
              </Form.Group> */}

          <Container>
            <Row>
              <h3 className="titleheadings mb-0 pt-4">Working location</h3>
              <Col sm={9} className="profile-other-info-text pb-3">
                If your standard working location is not the same as your home base, search for the location you usually
                work from.
              </Col>

              <Col className="pb-4 pb-lg-3 ps-lg-2">
                <Button
                  onClick={addWorkLocationClickHandler}
                  size="sm"
                  variant="outline-dark"
                  className="mobileButton float-end py-2 buttonAdd"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Container>

          {workLocationDropDownUI}

          {/*           <Row>
            <Form.Group>
              <Form.Label className="fw-normal location-text">
                Alternate base
              </Form.Label>
            </Form.Group>
          </Row>
          <Row>
            <Col className="col-10">
              <Form.Group>
                <Form.Label className="profile-other-info-text">
                  If you often work at or visit an alternate base, please add
                  this information
                </Form.Label>
              </Form.Group>
            </Col>
            <Col className="col-2">
              <Button
                onClick={addAlternateLocationClickHandler}
                size="sm"
                variant="outline-dark"
              >
                Add
              </Button>
            </Col>
          </Row>
          {alternareLocationDropDownUI} */}

          <Container>
            <Row>
              <h3 className="titleheadings mb-0 pt-lg-4">Alternate base</h3>
              <Col sm={9} className="profile-other-info-text pb-3">
                If you are visiting another Defence base or location temporarily, select an alternate base to view base
                information for the duration of your visit
              </Col>

              <Col className="pb-lg-3 ps-lg-2">
                <Button
                  onClick={addAlternateLocationClickHandler}
                  size="sm"
                  variant="outline-dark"
                  className="mobileButton float-end py-2 buttonAdd"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Container>
          {alternareLocationDropDownUI}

          <Row>
            <Form.Group>
              <Form.Label className="profile-other-info-heading"></Form.Label>
            </Form.Group>
          </Row>
          <div className="align-item-button">
            <Button
              className="mobileButton profile-btn-style"
              onClick={createProfileHandler}
              disabled={createProfileIsInvalid}
              variant="primary"
              size="sm"
            >
              Create profile
            </Button>
          </div>
        </Form>
      </div>
    </>
  );

  const [showButton, setShowButton] = useState(idpMg ? false : true);

  const handlerClose = () => {
    setShowButton(false);
  };

  const finalPMKsDom = selectedField.selectedFieldName === 'APSADF' ? PMKsSearchForm : <></>;
  const IdentityElement = (
    <>
      {!idpMg && (
        <>
          <div className="pt-lg-4 info mb-0">What is your employment type? </div>
          <Container>
            <Row className="mobilecolumn">
              <Col className="py-3 pe-lg-3">
                <Card
                  className={
                    selectedField.selectedFieldName === 'APSADF'
                      ? 'bg-defencecharcoalgrey textcharcoalwhite h-100'
                      : 'bg-defencelightblue h-100'
                  }
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => onChangeIdentity('APSADF')}
                >
                  <Container className="p-3">
                    <span className="profile-main">ADF or APS</span>
                    <Container className="profile-sub-info">I have a PMKeys number</Container>
                  </Container>
                </Card>
              </Col>

              <Col className="py-3 ps-lg-3">
                <Card
                  className={
                    selectedField.selectedFieldName === 'Contractor'
                      ? 'bg-defencenavy textcharcoalwhite h-100'
                      : 'bg-defencelightblue h-100'
                  }
                  readOnly={selectedField.disableContractorCard}
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => onChangeIdentity('Contractor')}
                >
                  <Container className="p-3">
                    <span className="profile-main">Contractor </span>
                    <Container className="profile-sub-info">I have an ODS number</Container>
                  </Container>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}

      {showButton ? (
        <>
          <Container className="pb-3 pb-lg-6"></Container>
          <Button className="mobileButton float-end profile-btn-style" disabled variant="primary" size="sm">
            Done
          </Button>{' '}
        </>
      ) : (
        <></>
      )}
    </>
  );
  const isShowProfile = selectedField.showMoreDataForm === true ? profileCreateForm : '';
  const renderElement = () => {
    return (
      <>
        <Modal.Body bsPrefix="body" className="m-3 m-lg-5 mb-2">
          {!idpMg && (
            <Card className="bg-defencewhisperlightgrey  ">
              <Card.Body className="p-lg-5 p-3">
                <Container className="pb-2">
                  <Image className="icon-size" data-testid="test" src={profileImage} />
                </Container>

                <p className="fw-light">
                  <span className="profile-main-heading">Create your ServiceConnect profile</span>
                </p>
                <Container className="profile-sub-info">
                  On your first login, ServiceConnect needs some details so it can pre-fill your forms and service
                  requests and personalise your base information.
                  <p>
                    Your ServiceConnect profile will not update your existing personnel records. So, if your personal
                    details are incorrect, please update PMKeys or the Defence Corporate Directory (DCD) as appropriate.
                  </p>
                </Container>
              </Card.Body>
            </Card>
          )}

          {idpMg && (
            <>
              <div className="profile-main-heading">Create your ServiceConnect profile</div>
              <Container className="profile-sub-info mt-2">
                Please enter your details so ServiceConnect can pre-fill your personal and location information into
                forms and service requests, and show you relevant base information and notifications.
              </Container>
            </>
          )}

          {IdentityElement}
          {finalPMKsDom}
          {isShowProfile}
        </Modal.Body>
      </>
    );
  };

  // New Code
  const [loadingProfileSetup, setLoadingProfileSetup] = useState({
    isLoading: true,
  });

  const [nextAssignmentIdProfileSetpState, setNextAssignmentIdProfileSetup] = useState({
    NextAssignmentIdProfileSetup: '',
  });

  const [actionIdIdProfileSetupState, setActionIdProfileSetup] = useState({
    ActionIdProfileSetup: '',
  });

  //New profile case creation
  const createNewReqProfileSetup = () => {
    const data = {
      caseTypeID: 'DoD-Auth-Work-ProfileSetup',
      content: {},
    };

    setLoadingProfileSetup((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    // INFO: Create case id call
    const URL_CREATE_NEW_REQUEST_API_PROFILE_SETUP = getURL(process.env.REACT_APP_CREATE_NEW_REQUEST_API_PROFILE_SETUP);

    axios
      .post(URL_CREATE_NEW_REQUEST_API_PROFILE_SETUP, data, {
        withCredentials: true,
      })
      .then((res) => {
        // NOTE: call to get the next action which will help us to know hwat is the next action

        // NOTE: This call is to get the next assignment like is it create review or ...
        const URL_NEXT_ACTION_API_PROFILE_SETUP =
          process.env.REACT_APP_NEXT_ACTION_API_PROFILE_SETUP + res.data.nextAssignmentID;
        const final_URL_NEXT_ACTION_API_PROFILE_SETUP = getURL(URL_NEXT_ACTION_API_PROFILE_SETUP);
        axios
          .get(final_URL_NEXT_ACTION_API_PROFILE_SETUP, {
            withCredentials: true,
          })
          .then((res) => {
            //updating the app states this we will use in update call

            setNextAssignmentIdProfileSetup((prevState) => {
              return {
                ...prevState,
                NextAssignmentIdProfileSetup: res.data.ID,
              };
            });
            setActionIdProfileSetup((prevState) => {
              return {
                ...prevState,
                ActionIdProfileSetup: res.data.actions[0].ID,
              };
            });
            //This call is to prepopulate the req data
            const URL_CREATE_ACTION_API_PROFILE_SETUP =
              process.env.REACT_APP_CREATE_ACTION_API_PROFILE_SETUP +
              res.data.ID +
              '/actions/' +
              res.data.actions[0].ID +
              (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV ? '/?flatListOfFields=Basic' : '');
            const final_URL_CREATE_ACTION_API_PROFILE_SETUP = getURL(URL_CREATE_ACTION_API_PROFILE_SETUP);
            axios
              .get(final_URL_CREATE_ACTION_API_PROFILE_SETUP, {
                withCredentials: true,
              })
              .then((res) => {
                getGroupDropDown().then((result) => {
                  const refValuesWithSelectEntityPersonalData = [{ code: '', description: 'Select' }].concat(
                    result.data.groupDetails,
                  );
                  setEntityDropdown((prevState) => {
                    return {
                      ...prevState,
                      dropDownArrayEntity: refValuesWithSelectEntityPersonalData,
                    };
                  });

                  getBaseNameDropDown();
                  getWorkingLocDropDown();
                  getPersonalDetails();
                  return new Promise((resolve, reject) => {
                    resolve('All Done');
                  });
                });
              });
          })
          .catch((err) => {
            let error = { ...err };
            error.response = error.response || { response: { data: [] } };
            setAPIError(error);
            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
            } else if (error.response.status === 500) {
              setServiceDownModal(true);
            } else {
              setErrorModal(true);
            }
          });
      });
  };

  useEffect(() => {
    createNewReqProfileSetup();
  }, []);

  useEffect(() => {
    afterSetStateFinishedForPMKEYS();
  }, [PMkeysForms]);

  useEffect(() => {
    afterSetStateFinishedForCreateProfile();
  }, [createProfileForms]);

  return (
    <>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}

      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}

      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
        />
      )}
      {renderElement()}
    </>
  );
};

export default AuthProfileModal;
