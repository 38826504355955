import React, { useEffect, useRef, useState } from 'react';
import { InputRightAddon, InputGroup, Flex, Input, Button } from '@chakra-ui/react';
import { Portal } from 'react-portal';
import TimePickerSelection from './TimePickerSelection';
import { TimePickerBlurEvent, validateTime } from './helpers/TimePickerBlurEvent';
import TimePickerKeyFilterOnKeyDown from './helpers/TimePickerKeyFilter';
import './react-time-picker.css';

function TimePicker({
  value = null,
  setInputValue = (e) => {},
  cellHeight = 28,
  placeHolder = 'Select Time __:__ AM/PM',
  pickerDefaultValue = '10:00',
  inputOnBlur = (e) => {},
  onChange = () => {},
  onSave = () => {},
  onCancel = () => {},
  disabled = false,
  isOpen: initialIsOpenValue = false,
  required = false,
  cancelButtonText = 'Cancel',
  saveButtonText = 'Save',
  controllers = true,
  seperator = true,
  id = null,
  use12Hours = true,
  onAmPmChange = () => {},
  name = null,
  popupClassName = null,
}) {
  const [isOpen, setIsOpen] = useState(initialIsOpenValue);
  const [height, setHeight] = useState(cellHeight);
  const [bboxInput, setBboxInput] = useState({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    popupTop: 0,
  });
  const timeInputref = useRef(null);
  const popupRef = useRef(null);
  const popupHeight = 220;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  const handleResize = () => {
    let bbox = getRect(timeInputref);
    let copiedBboxInput = { ...bboxInput };
    copiedBboxInput.top = bbox.top;
    copiedBboxInput.left = bbox.left;
    copiedBboxInput.right = bbox.right;
    copiedBboxInput.bottom = bbox.bottom;
    if (bbox.top - popupHeight > 0) {
      copiedBboxInput.popupTop = bbox.top - popupHeight;
    } else {
      copiedBboxInput.popupTop = bbox.bottom;
    }
    setBboxInput((bboxInput) => ({ ...copiedBboxInput }));
  };

  const handleClick = () => {
    handleResize();
    if (!validateTime(value)) {
      setInputValue('12:00 AM');
    }
    setIsOpen(!isOpen);
  };

  const TimePickerOnChange = (e) => {
    var time = '';
    if (e && e.target && e.target.value) {
      time = e.target.value;
    }
    setInputValue(time);
  };

  const onBlur = (e) => {
    TimePickerBlurEvent(e);
    var time = '';
    if (e && e.target && e.target.value) {
      time = e.target.value;
    }
    setInputValue(time);
    inputOnBlur(time);
  };

  const getRect = (inputRef) => {
    if (!inputRef && !inputRef.current) {
      return {
        bottom: 0,
        height: 0,
        left: 0,
        right: 0,
        top: 0,
        width: 0,
      };
    }
    return inputRef.current.getBoundingClientRect();
  };

  let finalValue = value;

  const params = {
    onChange,
    height,
    onSave,
    onCancel,
    cancelButtonText,
    saveButtonText,
    controllers,
    setInputValue,
    setIsOpen,
    seperator,
    use12Hours,
    onAmPmChange,
    initialValue: finalValue,
    pickerDefaultValue,
  };

  return (
    <>
      <InputGroup>
        <Input
          id={id ?? ''}
          variant="primary"
          name={name ?? ''}
          value={value ?? ''}
          type="text"
          placeholder={placeHolder}
          disabled={disabled}
          required={required}
          onChange={TimePickerOnChange}
          onBlur={onBlur}
          onKeyDown={TimePickerKeyFilterOnKeyDown}
          ref={timeInputref}
        />
        <InputRightAddon
          as={Button}
          variant="secondary"
          height="44px"
          borderColor="mediumgrey"
          borderRadius="0 2px 0 2px"
          onClick={handleClick}
          children={
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"></path>
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"></path>
            </svg>
          }
        />
      </InputGroup>
      {isOpen && !disabled && (
        <Portal>
          <div
            ref={popupRef}
            className="sc-react-time-picker-popup"
            style={{
              top: `${bboxInput.popupTop}px`,
              left: `${bboxInput.left}px`,
            }}
          >
            <div
              className={`sc-react-time-picker-popup-overlay ${popupClassName || ''}`}
              onClick={() => setIsOpen(!isOpen)}
            />
            <TimePickerSelection {...params} />
          </div>
        </Portal>
      )}
    </>
  );
}

export default TimePicker;
