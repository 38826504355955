import React from 'react';
import './CtaPopular.scss';
import { Button, Container, Row, Col } from 'react-bootstrap';
import '../../../sass/custom.scss';

const CtaPopular = (props) => {
  return (
    <Container fluid className="bg-defencecharcoalgrey">
      <Container fluid className="p-4 px-lg-6 landingPageWidth">
        <Row>
          <Col className="pt-4 py-lg-3 descText" sm={8}>
            <h2 className="text-white">{props.title}</h2>
            <p className="text-white mb-0 fw-light" dangerouslySetInnerHTML={{ __html: props.description }} />
          </Col>
          <Col className="py-3 py-lg-5 popularArea d-flex align-items-center flex-row-reverse" sm={4}>
            <Button
              data-testid="learnMore"
              className="buttonPopularSC mobileButton"
              variant="standard"
              href={props.ctaUrl}
            >
              {props.ctaText}
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
export default CtaPopular;
