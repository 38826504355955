import { appConstants } from '../helper/client/constant';

const cookie = document.cookie;
const cookieSplit = cookie.split(/[;.\s]/); //array of cookies

export const getURL = (url) => {
  let domain = process.env.REACT_APP_INTERNAL_DOMAIN;

  //check if local, dev, test
  if (
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_LOCAL_ENV ||
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_DEV_ENV ||
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST_ENV ||
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST2_ENV ||
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_BST_ENV
  ) {
    return url;
  } else {
    //check if external (govid)

    if (cookieSplit.includes('IDP=mygovid')) {
      domain = process.env.REACT_APP_EXTERNAL_DOMAIN;
      return domain + url; //external
    } else if (cookieSplit.includes('IDP=defence')) {
      return domain + url; //internal
    } else {
      if (
        !cookieSplit.includes('IDP=mygovid') &&
        !cookieSplit.includes('IDP=defence') &&
        window.location.href.includes(appConstants.SR_PUBLIC_PORTAL)
      ) {
        domain = process.env.REACT_APP_EXTERNAL_DOMAIN;
        return domain + url; //external
      } else {
        return domain + url; //internal
      }
    }
  }
};
