export const disabledField = {
  backgroundColor: 'whispergrey',
  borderColor: 'mediumgrey',
  color: 'black',
  opacity: 1,
  fontWeight: 300,
};

const selectedTabBase = {
  fontWeight: 'bold',
  color: 'white',
  textDecoration: 'none',
};

export const selectedMainNavigationTab = {
  ...selectedTabBase,
  backgroundColor: 'orange.standard',
};

export const selectedSubNavigationTab = {
  ...selectedTabBase,
  backgroundColor: 'navy',
};
