import React from 'react';

import './ViewSubheader.scss';

const ViewSubheader = (props) => {
  return (
    <div className="landingPageWidth pt-4 pt-lg-6 ps-4 ps-lg-6 pe-4 pe-lg-6">
      <h1 className="pb-3" style={{ textAlign: 'start' }}>
        {props.heading}
      </h1>
      <h4 className="fw-light pb-3">{props.subheading}</h4>
    </div>
  );
};

export default ViewSubheader;
