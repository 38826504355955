import React from 'react';
import { Checkbox, Flex, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { FormElement, MultiProps } from '../FormElementType';
import { PrimitiveControl } from '../PrimitiveControl';
import { Text } from '@chakra-ui/react';
import { Tooltip } from '../../Tooltip/Tooltip';

export const Multi: React.FC<FormElement & MultiProps> = (props: FormElement & MultiProps) => {
  return props.visible ? (
    <PrimitiveControl
      disabled={props.disabled}
      required={props.required}
      desktopWidth={props.desktopWidth}
      isInvalid={props.isInvalid}
    >
      {props.label ? (
        <Flex alignItems="center" gap={1} mb={2}>
          <FormLabel>{props.label}</FormLabel>
          {props.showTooltip && props.tooltipContent && <Tooltip content={props.tooltipContent} placement="bottom" />}
        </Flex>
      ) : (
        <></>
      )}
      <Flex gap={3} justifyContent="flex-start" overflowX={{ lg: 'auto', base: 'scroll' }}>
        {props?.options?.map((i, idx) => (
          <Checkbox
            h="auto"
            variant="multi"
            data-testid="checkbox"
            name={props.reference}
            isInvalid={props.isInvalid}
            onChange={() => props.onCheckboxChange(i)}
            isChecked={Boolean(i.isChecked)}
            display="flex"
            alignItems="flex-start"
            key={`${props.id}-${idx}`}
          >
            <Text fontWeight="bold" margin={0} whiteSpace="break-spaces">
              {i.key}
            </Text>
            {i.customAttributes?.subtitle && <Text fontWeight="light"> {i.customAttributes.subtitle}</Text>}
          </Checkbox>
        ))}
      </Flex>
      <FormErrorMessage>{props.errorMessage}</FormErrorMessage>
    </PrimitiveControl>
  ) : null;
};
