import { Accordion, Text } from '@chakra-ui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthURL } from '../../../../helper/getOtherAppURL';
import { setShowError } from '../../../../reducers/errorHandling';
import { GenericAccordionItem } from '../Accordion/GenericAccordionItem';
import Modal from '../Modal/Modal';
import ERRORS from './ErrorModalContent.json';

const SERVER_DOWN = [500];
const AUTHENTICATION_FAILED = [403, 401];

export const ErrorHandler = () => {
  let generic = false;
  const ERROR = useSelector((state: { error: { error: boolean; code: number; errorMessage: string } }) => {
    return state.error;
  });
  const errorDetails = () => {
    if (SERVER_DOWN.includes(ERROR.code)) {
      return ERRORS.SERVER_DOWN;
    }
    if (AUTHENTICATION_FAILED.includes(ERROR.code)) {
      return ERROR.code === 403 ? ERRORS.FORBIDDEN : ERRORS.SESSION_EXPIRED;
    } else {
      generic = true;
      return ERRORS.GENERIC;
    }
  };
  const handleClick = () => {
    generic = false;
    ERROR.code === 401 || ERROR.code === 403
      ? (window.location.href = getAuthURL())
      : dispatch(setShowError({ error: false, code: '' }));
  };
  const dispatch = useDispatch();
  return (
    <Modal
      size={ERROR.code === 400 ? 'large' : 'small'}
      buttonPlacement="left"
      showErrorIcon
      title={errorDetails().title}
      buttonVariant="primary"
      isOpen={ERROR.error}
      onClose={handleClick}
    >
      <>
        {errorDetails().content}
        <Accordion allowToggle>
          {generic && (
            <GenericAccordionItem title="Errors">
              <Text>Code: {ERROR.code}</Text>
              <Text>Message: {ERROR.errorMessage}</Text>
            </GenericAccordionItem>
          )}
        </Accordion>
      </>
    </Modal>
  );
};
