import React, { ReactElement } from 'react';
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  ModalProps as ChakraModalProps,
  Box,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import sanitizeHtml from 'sanitize-html';
import { Alert } from '../icons/Outline';

export interface ModalProps extends ChakraModalProps {
  title: string;
  children: string | ReactElement;
  buttonColor?: string;
  buttonVariant?: string;
  showCloseIcon?: boolean;
  showErrorIcon?: boolean;
  showInfoIcon?: boolean;
  buttonText?: string;
  buttonPlacement?: 'left' | 'right' | 'center';
}

const Modal: React.FC<ModalProps> = ({
  onClose,
  title,
  buttonColor,
  buttonVariant,
  showInfoIcon,
  showErrorIcon,
  showCloseIcon,
  buttonText,
  buttonPlacement,
  size,
  isOpen,
  children,
  closeOnEsc,
  colorScheme,
  id,
}: ModalProps): JSX.Element => {
  let buttonPosition = 'flex-end';
  if (buttonPlacement === 'right') buttonPosition = 'flex-end';
  if (buttonPlacement === 'left') buttonPosition = 'flex-start';
  else if (buttonPlacement === 'center') buttonPosition = 'center';

  return (
    <ChakraModal
      closeOnEsc={closeOnEsc}
      size={size}
      isOpen={isOpen}
      onClose={onClose}
      colorScheme={colorScheme}
      id={id}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {showErrorIcon && (
            <Box mb={3}>
              <Alert height="3rem" />
            </Box>
          )}
          {showInfoIcon && <InfoOutlineIcon className="infoIcon" mb={3} w={8} h={8} color="orange.standard" />}
          <Heading
            as="h2"
            fontSize="x-large"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(title),
            }}
          />
        </ModalHeader>
        {showCloseIcon && <ModalCloseButton />}
        <ModalBody>{children}</ModalBody>

        <ModalFooter justifyContent={buttonPosition}>
          <Button variant={buttonVariant ? buttonVariant : 'solid'} onClick={onClose}>
            {buttonText ? buttonText : 'Close'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
