import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Image, Row, Spinner } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ServiceConnectSpinner from '../../../components/commonComponents/Spinner/ServiceConnectSpinner';
import GenericErrorModal from '../../../components/GenericErrorModal/GenericErrorModal';
import SystemOutageModal from '../../../components/SystemOutageModal/SystemOutageModal';
import { appConstants, cateringCTAs } from '../../../helper/client/constant';
import findAllItems from '../../../helper/findAllItems';
import { getURL } from '../../../helper/getAPIUrl';
import alertImage from '../../../images/OrangeAlert.svg';
import AuthenticationModal from '../../LandingPage/AuthenticationModal/AuthenticationModal';
import Header from '../../LandingPage/Header/Header';
import content from '../CreateRequest/Content/CreateRequest.json';
import DeleteModal from '../CreateRequest/DeleteModal';
import Location from '../CreateRequest/LocationDetails/Location';
import LocationDetailProps from '../CreateRequest/LocationDetails/__mocks__/default.json';
import Other from '../CreateRequest/Other/Other';
import PersonalDetailsPrimary from '../CreateRequest/Personal-Detail/PersonalDetailPrimary';
import PersonalDetailProps from '../CreateRequest/Personal-Detail/__mocks__/default.json';
import RequestDetail from '../CreateRequest/Request-Detail/RequestDetail';
import PersonalDetailsSecondary from '../CreateRequest/SecondaryContactCard/PersonalDetailsSecondary';
import SecondaryContactCardProps from '../CreateRequest/SecondaryContactCard/__mocks__/default.json';
import SuccessPopup from '../CreateRequest/SuccessPopup/SuccessPopup';
import SubNavigation from '../MSRcomponents/SubNavigation/SubNavigation';
import SubNavProps from '../MSRcomponents/SubNavigation/__mocks__/default.json';
import './EditServiceRequestCreate.scss';
import UploadConfirmationSRModal from '../CreateRequest/UploadConfirmationSRModal';

import convertSpecialChars from '../../../helper/convertSpecialChars';
import GenericErrorModalProps from '../../GenericErrorModal/__mocks__/default.json';
import AuthenticationModalProps from '../../LandingPage/AuthenticationModal/__mocks__/default.json';
import SystemOutageModalProps from '../../SystemOutageModal/__mocks__/default.json';

const EditServiceRequestCreate = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalHttpCode, setShowModalHttpCode] = useState('');
  const locationconfig = useLocation();
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const target = useRef(null);
  const reqDetailataFromReduxStore = useSelector((state) => {
    if (!!state) {
      return state;
    }
  });
  const uploadedFile = reqDetailataFromReduxStore.requestForm.uploaded;

  const [loading, setLoading] = useState({
    isLoading: true,
  });
  const [showSpinnerApiIsInProgress, setShowSpinnerApiIsInProgress] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessage, setsuccessMessage] = useState('');
  const [reqDetailwrkTypeSubType, setreqDetailwrkTypeSubType] = useState({
    workType: '',
    workSubType: '',
    description: '',
  });
  const [locationformInvalid, setlocationformInvalid] = useState(false);
  const [secondaryContactformInvalid, setsecondaryContactformInvalid] = useState(false);
  const [showSecondary, setShowSecondary] = useState({
    isVisible: false,
  });

  const [extraFieldsForPageInstrction, setExtraFieldsForPageInstrction] = useState({
    data: [],
  });

  const [allDataFromAPI, setAllDataFromAPI] = useState({
    allFieldsinState: [],
  });
  const [requestInfo, setRequestInfo] = useState({
    commonFieldsArr: [],
  });
  const [locData, setLocData] = useState({
    location_data_state: [],
  });
  const [personalData, setPersonalData] = useState({
    personal_data_state: [],
    personal_data_secondary_state: [],
  });

  const [otherData, setOtherData] = useState({
    other_data_state: [],
    isPrimarySelected: null,
  });
  // TAdded these states to find out what are changes has been been and on which fields
  const [fieldIDstoUpdated, setfieldIDstoUpdated] = useState({
    fieldIds: [],
  });
  const [vesselCheckboxSelected, setVesselCheckboxSelected] = useState({
    ischecked: false,
  });
  const [nextAssignmentIdState, setNextAssignmentId] = useState({
    nextAssignmentId: '',
  });
  const [caseId, setCaseId] = useState({
    caseId: id,
  });
  const [actionIdIdState, setActionId] = useState({
    actionId: '',
  });
  const [stateDropdown, setStateDropDown] = useState({
    dropDownArrayLocState: [],
    selectedValue: '',
    selectedDescription: '',
  });
  const [locationTypeDropdown, setLocationTypeDropdown] = useState({
    dropDownArrayLocType: [],
    selectedValue: '',
    selectedDescription: '',
  });
  const [vesselDropdown, setVesselDropdown] = useState({
    dropDownArrayVessel: [],
    selectedValue: '',
    selectedDescription: '',
  });

  const [entityDropdown, setEntityDropdown] = useState({
    dropDownArrayEntity: [],
    selectedValue: '',
    selectedDescription: '',
    selectedValueSecondary: '',
    selectedDescriptionSecondary: '',
  });
  const [baseNameDropDown, setbaseNameDropDown] = useState({
    dropDownArrayBaseName: [],
    selectedValue: '',
  });

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showSave, setSave] = useState(false);

  const [requestDetailsRequiredFields, setRequestDetailsRequiredFields] = useState([]);

  const [isDropdownSelected, setIsDropdownSelected] = useState(true);

  const requestTypeAndCaseID = window.location.search;
  const urlParams = new URLSearchParams(requestTypeAndCaseID);
  const type = urlParams.get('type');

  const [isMinorRequest, setIsMinorRequest] = useState(
    type === appConstants.SERVICE_REQUEST_TYPE_FULL ? false : type === appConstants.SERVICE_REQUEST_TYPE_MINOR && true,
  );

  const ViewReqInNewState = () => {
    setLoading((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });
    const URL_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL =
      process.env.REACT_APP_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL + caseId.caseId;
    const final_URL_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL = getURL(URL_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL);
    axios
      .get(final_URL_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL, {
        withCredentials: true,
      })
      .then((res) => {
        // updating the app states this we will use in update call

        setNextAssignmentId((prevState) => {
          return {
            ...prevState,
            nextAssignmentId: res.data.assignments[0].ID,
          };
        });
        setActionId((prevState) => {
          return {
            ...prevState,
            actionId: res.data.assignments[0].actions[0].ID,
          };
        });
        // This call is to prepopulate the req data
        const URL_CREATE_ACTION_API =
          process.env.REACT_APP_CREATE_ACTION_API +
          res.data.assignments[0].ID +
          '/actions/' +
          res.data.assignments[0].actions[0].ID +
          (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV ? '/?flatListOfFields=Basic' : '');
        const final_URL_CREATE_ACTION_API = getURL(URL_CREATE_ACTION_API);
        axios
          .get(final_URL_CREATE_ACTION_API, {
            withCredentials: true,
          })
          .then((res) => {
            getDropDownsAndformatDataForUI(res.data);
          })
          .catch((err) => {
            let error = { ...err };
            error.response = error.response || { response: { data: [] } };
            setAPIError(error);

            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
              setShowModalHttpCode(error.response.status);
            } else if (error.response.status === 500) {
              setServiceDownModal(true);
            } else {
              setErrorModal(true);
            }
          });
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);

        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
          setShowModalHttpCode(error.response.status);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };

  const formatDataHandler = (original) => {
    const obj = {
      reference: original.reference,
      fieldID: original.fieldID,
      fieldName: original.reference.substr(original.reference.lastIndexOf('.') + 1),
      value: convertSpecialChars(original.value),
      isReadOnly: original.readOnly,
      isRequired: original.required,
      error: original.required === true ? (original.value.length > 0 ? false : true) : false,
      errorMessage:
        original.required === true
          ? original.value.length > 0
            ? ''
            : changeNameHandlerForValidation(original.reference, original.fieldID) + ' is required.'
          : '',
    };
    return { ...obj };
  };

  const getDropDownsArr = () => {
    return {
      stateDrodown: [...stateDropdown.dropDownArrayLocState],
      locationTypedropdown: [...locationTypeDropdown.dropDownArrayLocType],
      entityDropdown: [...entityDropdown.dropDownArrayEntity],
      vesselDropdown: [...vesselDropdown.dropDownArrayVessel],
    };
  };
  const setInitialFieldsforExtraItems = (allAPIData) => {
    const formatedData = allAPIData.map((field) => {
      if (!field?.reference) {
        return {};
      }
      return formatDataHandler(field);
    });

    const allExtra = formatedData.filter((item) => {
      if (
        item.fieldID === 'FID' ||
        item.fieldID === 'WorkCenterID' ||
        item.fieldID === 'EBI' ||
        item.fieldID === 'EBIBuilding' ||
        item.fieldID === 'Region' //this one is for initial load
      ) {
        return item;
      }
    });

    setExtraFieldsForPageInstrction((prevState) => {
      return {
        ...prevState,
        data: [...allExtra],
      };
    });
  };
  const setOtherFieldsForUI = (data) => {
    return new Promise((resolve, reject) => {
      // TODO: need to remove thi filter as this is just for demo
      let allAPIData = data.view.groups.map((obj) => {
        if (obj?.field?.reference) {
          return obj.field;
        } else {
          return obj.groups.map((objInner) => objInner?.field);
        }
      });
      allAPIData = allAPIData.flat();
      allAPIData = allAPIData.filter((obj) => obj !== undefined && obj);
      const allIntialData = [...allAPIData];
      setAllDataFromAPI((prevState) => {
        const refinedField = allIntialData.map((field) => {
          if (!field?.reference) {
            return {};
          }
          return formatDataHandler(field);
        });
        return {
          ...prevState,
          allFieldsinState: [...refinedField],
        };
      });
      setInitialFieldsforExtraItems(allAPIData);
      // TODO: Somehow we need to get the selected loc type might be from get ALL API????

      const initialSelectedLocationType = allIntialData.filter((item) =>
        item?.reference?.includes('ServiceRequest.Location.Type'),
      )[0].value;
      const initialFidIDFromPega = allIntialData.find((item) => item.fieldID === 'FID').value;
      let allFields;
      if (initialSelectedLocationType !== 'Other') {
        allFields = allIntialData.filter((item) => {
          if (
            item.fieldID !== 'Line1' &&
            item.fieldID !== 'City' &&
            item.fieldID !== 'Postcode' &&
            item.fieldID !== 'Description' &&
            item.fieldID !== 'Region' &&
            item.fieldID !== 'FID' &&
            item.fieldID !== 'WorkCenterID' &&
            item.fieldID !== 'EBI' &&
            item.fieldID !== 'EBIBuilding'
          ) {
            return item;
          }
        });
      } else {
        allFields = allIntialData.filter((item) => {
          if (
            item.fieldID !== 'Name' &&
            item.fieldID !== 'Region' &&
            item.fieldID !== 'Description' &&
            item.fieldID !== 'FullAddress' &&
            item.fieldID !== 'FID' &&
            item.fieldID !== 'WorkCenterID' &&
            item.fieldID !== 'EBI' &&
            item.fieldID !== 'EBIBuilding' &&
            item.fieldID !== 'SiteOrWharf'
          ) {
            return item;
          }
        });
      }
      const allPersonalDetailsFields = allFields.filter((fields) =>
        fields.reference.includes('ServiceRequest.PrimaryContact'),
      );
      const hasSecondaryFields = allFields.filter((fields) =>
        fields.reference.includes('ServiceRequest.HasSecondaryContact'),
      );
      const allPersonalSecondaryContactDetailsFields = allFields.filter((fields) =>
        fields.reference.includes('ServiceRequest.Contacts(1)'),
      );
      const savedWorkTypeAndSubType = allFields.filter(
        (fields) => fields.fieldID === 'WorkType' || fields.fieldID === 'WorkSubType',
      );
      const reqDetailDescription = allFields.filter((fields) => fields.fieldID === 'pyDescription');
      const restFields = allFields.filter(
        (fields) =>
          !fields.reference.includes('ServiceRequest.PrimaryContact') &&
          !fields.reference.includes('ServiceRequest.Contacts(1)') &&
          !fields.reference.includes('ServiceRequest.Location') &&
          !fields.reference.includes('ServiceRequest.CaseType') &&
          !fields.reference.includes('ServiceRequest.HasSecondaryContact'),
      );

      const refinedrestFields = restFields.map((field) => formatDataHandler(field));

      const allLocationDetailsFields = allFields.filter((fields) =>
        fields.reference.includes('ServiceRequest.Location'),
      );

      const refinedPersonalDetailsFields = allPersonalDetailsFields.map((field) => formatDataHandler(field));

      const refinedLocationDetailsFields = allLocationDetailsFields.map((field) => formatDataHandler(field));
      const refinedPersonalSecondaryContactFields = allPersonalSecondaryContactDetailsFields.map((field) =>
        formatDataHandler(field),
      );

      const finalObj = {
        locationData: [...refinedLocationDetailsFields],
        personalData: [...refinedPersonalDetailsFields],
        other: [...refinedrestFields],
        seondaryContactData: [...refinedPersonalSecondaryContactFields],
      };

      //TODO: START THIS can be improvise!!!!
      const OrigInitialSelectedValState = finalObj.locationData.filter((item) => item.fieldID === 'State')[0].value;
      const initialSelectedValState = convertSpecialChars(OrigInitialSelectedValState);

      const initialSelectedValLocType = finalObj.locationData.filter((item) => item.fieldID === 'Type')[0].value;
      let initialSelectedValBaseName = '';
      if (initialSelectedValLocType !== 'Other') {
        const OrigInitialSelectedVesselName = finalObj.locationData.filter((item) => item.fieldID === 'SiteOrWharf')[0]
          ?.value;
        const initialSelectedVesselName = convertSpecialChars(OrigInitialSelectedVesselName);

        setVesselDropdown((prevState) => {
          const selectedVesselDescription =
            initialSelectedVesselName !== ''
              ? prevState.dropDownArrayVessel.filter((item) => item.code === initialSelectedVesselName)[0].description
              : '';

          return {
            ...prevState,

            selectedValue: initialSelectedVesselName,
            selectedDescription: selectedVesselDescription,
          };
        });
        const OrigInitialSelectedValBaseName = finalObj.locationData.filter((item) => item.fieldID === 'Name')[0]
          ?.value;

        initialSelectedValBaseName = convertSpecialChars(OrigInitialSelectedValBaseName);
        setbaseNameDropDown((prevState) => {
          return {
            ...prevState,
            selectedValue: initialSelectedValBaseName,
          };
        });
      }

      const OrigInitialSelectedValEntityName = finalObj.personalData.filter((item) => item.fieldID === 'Service')[0]
        .value;
      const initialSelectedValEntityName = convertSpecialChars(OrigInitialSelectedValEntityName);
      const OrigInitialSelectedValEntityNameForSecondaryContact = finalObj.seondaryContactData.filter(
        (item) => item.fieldID === 'Service',
      )[0]?.value;

      const initialSelectedValEntityNameForSecondaryContact = convertSpecialChars(
        OrigInitialSelectedValEntityNameForSecondaryContact,
      );
      const initialSelectedValForHasSeconadaryContact = hasSecondaryFields[0]?.value;

      //TODO: END THIS can be improvise!!!!
      setStateDropDown((prevState) => {
        const selectedStateDescription = prevState.dropDownArrayLocState.filter(
          (item) => item.code === initialSelectedValState,
        );

        return {
          ...prevState,
          selectedValue: initialSelectedValState,
          selectedDescription: !!selectedStateDescription ? selectedStateDescription[0].description : '',
        };
      });
      setShowSecondary((prevState) => {
        return {
          isVisible: initialSelectedValForHasSeconadaryContact === 'true',
        };
      });
      setreqDetailwrkTypeSubType((prevState) => {
        return {
          ...prevState,
          workType: savedWorkTypeAndSubType[0].value,
          workSubType: savedWorkTypeAndSubType[1].value,
          description: reqDetailDescription[0].value,
        };
      });

      setLocationTypeDropdown((prevState) => {
        const selectedLocTypeDescription = prevState.dropDownArrayLocType.filter(
          (item) => item.code === initialSelectedValLocType,
        );

        return {
          ...prevState,
          selectedValue: initialSelectedValLocType,
          selectedDescription: !!selectedLocTypeDescription ? selectedLocTypeDescription[0].description : '', // Type: is a field id for location Type
        };
      });
      setEntityDropdown((prevState) => {
        const selectedEntityDescription = prevState.dropDownArrayEntity.filter(
          (item) => item.code === initialSelectedValEntityName,
        );
        const selectedEntityDescriptionSecondary = prevState.dropDownArrayEntity.filter(
          (item) => item.code === initialSelectedValEntityNameForSecondaryContact,
        );

        return {
          ...prevState,
          selectedValue: !!initialSelectedValEntityName ? selectedEntityDescription[0].code : '',
          selectedDescription: !!initialSelectedValEntityName ? selectedEntityDescription[0].description : '', // Type: is a field id for Entity
          selectedValueSecondary: !!initialSelectedValEntityNameForSecondaryContact
            ? selectedEntityDescriptionSecondary[0].code
            : '',
          selectedDescriptionSecondary: !!initialSelectedValEntityNameForSecondaryContact
            ? selectedEntityDescriptionSecondary[0].description
            : '',
        };
      });

      setLocData((prevState) => {
        return {
          ...prevState,
          location_data_state: [...finalObj.locationData],
        };
      });
      setPersonalData((prevState) => {
        return {
          ...prevState,
          personal_data_state: [...finalObj.personalData],

          personal_data_secondary_state: [...finalObj.seondaryContactData],
        };
      });
      setOtherData((prevState) => {
        return {
          ...prevState,
          other_data_state: [...finalObj.other],
          isPrimarySelected: getIsPrimarySelected(finalObj.other),
        };
      });

      const allLoactionDropDowns = { ...getDropDownsArr() };

      finalObj.dropdowns = allLoactionDropDowns;

      resolve([
        finalObj,
        initialSelectedValState,
        initialSelectedValLocType,
        initialFidIDFromPega,
        initialSelectedValEntityName,
        initialSelectedValEntityNameForSecondaryContact,
      ]);
    });
  };
  const getIsPrimarySelected = (state) => {
    const flagforSelectedPrimary = state.filter((item) => item.fieldID === 'OperatorIsPrimary')[0].value;

    return flagforSelectedPrimary;
  };
  // test
  const getBaseNameDropDownURLDEV = (state, location) => {
    let URL = process.env.REACT_APP_GET_BASE_DROPDOWN;
    if (state !== '' && location !== '') {
      URL = URL + '?state=' + state + '&locationtype=' + location;
    } else if (state !== '' && location === '') {
      URL = URL + '?state=' + state;
    } else if (state === '' && location !== '') {
      URL = URL + '?locationtype=' + location;
    }

    return URL;
  };
  const getBaseNameDropDownURLLocal = (state, location) => {
    let URL = process.env.REACT_APP_GET_BASE_DROPDOWN;
    if (state !== '' && location !== '') {
      URL = URL + '/' + state + '/' + location;
    } else if (state !== '' && location === '') {
      URL = process.env.REACT_APP_GET_BASE_DROPDOWN_ONLY_STATE + '/' + state;
    } else if (state === '' && location !== '') {
      URL = process.env.REACT_APP_GET_BASE_DROPDOWN_ONLY_LOCATION + '/' + location;
    }

    return URL;
  };
  const getBaseNameDropDown = (
    state,
    locType,
    initialFidFromPega = 'notRequired', // this field will come only for intial load to prepopulate the base name according to FID
  ) => {
    let URL = '';
    if (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV) {
      URL = getBaseNameDropDownURLDEV(state, locType);
    } else {
      URL = getBaseNameDropDownURLLocal(state, locType);
    }
    const URL_baseName = getURL(URL);
    axios
      .get(URL_baseName, {
        withCredentials: true,
      })
      .then((res) => {
        setbaseNameDropDown((prevState) => {
          let initialSelectedValBaseNameObj = prevState.selectedValue;
          if (initialFidFromPega !== 'notRequired' && initialFidFromPega.length > 0) {
            initialSelectedValBaseNameObj = res.data.locDetails.find((item) => item.fId === initialFidFromPega).name;
          }
          return {
            ...prevState,
            selectedValue: initialSelectedValBaseNameObj,
            dropDownArrayBaseName: [...res.data.locDetails],
          };
        });

        setLoading((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
          setShowModalHttpCode(error.response.status);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };
  const getDiffDropDown = (value) => {
    let URL = process.env.REACT_APP_GET_REF_DATA + value;
    URL = getURL(URL);
    const apiRes = axios.get(URL, {
      withCredentials: true,
    });
    return apiRes;
  };

  const getGroupDropDown = () => {
    let URL = process.env.REACT_APP_GET_GROUP_LIST;
    URL = getURL(URL);
    const apiRes = axios.get(URL, {
      withCredentials: true,
    });
    return apiRes;
  };

  // This method is created to format the res according to the UI component display.
  const getDropDownsAndformatDataForUI = (data) => {
    getDiffDropDown('State').then((result) => {
      const refValuesWithSelectLocState = [{ code: '', description: 'Select' }].concat(result.data.refValues);
      setStateDropDown((prevState) => {
        return {
          ...prevState,
          dropDownArrayLocState: refValuesWithSelectLocState,
        };
      });
      getDiffDropDown('Location').then((result) => {
        /* const refValuesWithSelectLocType = [
          { code: "", description: "Select" },
        ].concat(result.data.refValues);  */
        setLocationTypeDropdown((prevState) => {
          return {
            ...prevState,
            // dropDownArrayLocType: refValuesWithSelectLocType,
            dropDownArrayLocType: result.data.refValues,
            // selectedValue: getSelectedValuenDropDownFromLocState("Type"), // Type: is a field id for location Type
          };
        });
        getDiffDropDown('Vessel').then((result) => {
          const refValuesWithSelectVessel = [{ code: '', description: 'Select' }].concat(result.data.refValues);
          setVesselDropdown((prevState) => {
            return {
              ...prevState,
              dropDownArrayVessel: refValuesWithSelectVessel,
              // selectedValue: getSelectedValuenDropDownFromLocState("Type"), // Type: is a field id for location Type
            };
          });
          getGroupDropDown()
            .then((result) => {
              const refValuesWithSelectEntityPersonalData = [{ code: '', description: 'Select' }].concat(
                result.data.groupDetails,
              );
              setEntityDropdown((prevState) => {
                return {
                  ...prevState,
                  dropDownArrayEntity: refValuesWithSelectEntityPersonalData,
                };
              });

              return new Promise((resolve, reject) => {
                resolve('All Done');
              });
            })
            .then((result) => {
              const apiData = { ...data };
              const promise = setOtherFieldsForUI(apiData);
              promise.then((val) => {
                getBaseNameDropDown(val[1], val[2], val[3]);
              });
            })
            .catch((err) => {
              let error = { ...err };
              error.response = error.response || { response: { data: [] } };
              setAPIError(error);

              if (error.response.status === 403 || error.response.status === 401) {
                setShowModal(true);
                setShowModalHttpCode(error.response.status);
              } else if (error.response.status === 500) {
                setServiceDownModal(true);
              } else {
                setErrorModal(true);
              }
            });
        });
      });
    });
  };
  const insertFieldIdsIfNotAlreadyPresent = (existingArr, item) => {
    if (existingArr.indexOf(item) === -1) {
      existingArr.push(item);
    }

    return existingArr;
  };
  const updateStateValue = (id, name, value, passedState, reference) => {
    const selectedWorkType = requestInfo.commonFieldsArr.find((item) => item.fieldID === 'WorkType')?.value;

    const newState = passedState.map((obj) => {
      let finalObj = obj;
      if (obj.fieldID === id && id !== 'PreferredContactTimes' && id !== 'Service') {
        if (id !== 'EmployeeID' && id !== 'Area' && (value === '' || value === 'Select')) {
          finalObj = {
            ...finalObj,
            error: true,
            value,
            errorMessage: changeNameHandlerForValidation(reference, id) + ' is required.',
          };
        } else if (id === 'Number') {
          if (!value.match('[0-9]{10}')) {
            finalObj = {
              ...finalObj,
              error: true,
              value,
              errorMessage: 'Please provide valid 10 digit ' + changeNameHandlerForValidation(reference, id),
            };
          } else if (value.length > appConstants.PHONE_NUM_MAX_LIMIT) {
            finalObj = {
              ...finalObj,
              error: true,
              value,
              errorMessage: 'Please provide only 10 digit ' + changeNameHandlerForValidation(reference, id),
            };
          } else {
            finalObj = { ...finalObj, error: false, value, errorMessage: '' };
          }
        } else if (id === 'Address') {
          const emailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
          if (!emailRegEx.test(value)) {
            finalObj = {
              ...finalObj,
              error: true,
              value,
              errorMessage: 'Please provide a valid ' + changeNameHandlerForValidation(reference, id),
            };
          } else {
            finalObj = { ...finalObj, error: false, value, errorMessage: '' };
          }
        }
        // test
        else if (id === 'Area') {
          if (
            selectedWorkType === appConstants.SR_TYPE_CATERING ||
            selectedWorkType === appConstants.SR_TYPE_TRANSPORT
          ) {
            finalObj = { ...finalObj, error: false, value, errorMessage: '' };
          }
          if (
            selectedWorkType !== appConstants.SR_TYPE_CATERING &&
            selectedWorkType !== appConstants.SR_TYPE_TRANSPORT
          ) {
            finalObj = {
              ...finalObj,
              error: value.length == 0,
              value,
              errorMessage:
                value.length == 0 ? changeNameHandlerForValidation(obj.reference, obj.fieldID) + ' is required.' : '',
            };
          }
        }
        //test
        else if (id === 'EmployeeID') {
          if (value.length > 9) {
            finalObj = {
              ...finalObj,
              error: true,
              value,
              errorMessage:
                changeNameHandlerForValidation(reference, id) + ' should not exceeds more than 9 characters',
            };
          } else if (value.length > 0 && value.length < 6) {
            finalObj = {
              ...finalObj,
              error: true,
              value,
              errorMessage: changeNameHandlerForValidation(reference, id) + ' should be more than 6 characters.',
            };
          } else if (value.length > 0 && value.length < 9) {
            const specialChars = appConstants.ATTACHMENTS_SPECIAL_CHARACTERS_STRING;

            specialChars.split('').forEach((specialChar) => {
              if (value.includes(specialChar)) {
                finalObj = {
                  ...finalObj,
                  error: true,
                  value,
                  errorMessage: 'Please enter a valid ' + changeNameHandlerForValidation(reference, id),
                };
              } else {
                finalObj = {
                  ...finalObj,
                  error: false,
                  value,
                  errorMessage: '',
                };
              }
            });
          } else {
            finalObj = { ...finalObj, error: false, value, errorMessage: '' };
          }
        } else {
          finalObj = { ...finalObj, error: false, value, errorMessage: '' };
        }
      } else {
        if (obj.fieldID === id && (id === 'PreferredContactTimes' || id === 'Service')) {
          finalObj = {
            ...finalObj,
            error: false,
            value,
            errorMessage: '',
          };
        }
      }
      return finalObj;
    });

    return newState;
  };
  // const updateStateValue = (id, name, value, passedState, reference) => {
  //   const newState = passedState.map((obj) => {
  //     let finalObj = obj;
  //     if (
  //       obj.fieldID === id &&
  //       id !== "PreferredContactTimes" &&
  //       id !== "Service"
  //     ) {
  //       if (value === "" || value === "Select") {
  //         finalObj = {
  //           ...finalObj,
  //           error: true,
  //           value,
  //           errorMessage:
  //             changeNameHandlerForValidation(reference, id) + " is required.",
  //         };
  //       }
  //       if (id === "Number" && !value.match("[0-9]{10}")) {
  //         finalObj = {
  //           ...finalObj,
  //           error: true,
  //           value,
  //           errorMessage:
  //             "Please provide valid 10 digit " +
  //             changeNameHandlerForValidation(reference, id),
  //         };
  //       }
  //       if (
  //         id === "Number" &&
  //         value.length > appConstants.PHONE_NUM_MAX_LIMIT
  //       ) {
  //         finalObj = {
  //           ...finalObj,
  //           error: true,
  //           value,
  //           errorMessage:
  //             "Please provide only 10 digit " +
  //             changeNameHandlerForValidation(reference, id),
  //         };
  //       }
  //       // test
  //       if (id === "EmployeeID" && value.length > 9) {
  //         finalObj = {
  //           ...finalObj,
  //           error: true,
  //           value,
  //           errorMessage:
  //             changeNameHandlerForValidation(reference, id) +
  //             " should not exceeds more than 9 characters",
  //         };
  //       }
  //       if (id === "EmployeeID" && value.length < 6) {
  //         finalObj = {
  //           ...finalObj,
  //           error: true,
  //           value,
  //           errorMessage:
  //             changeNameHandlerForValidation(reference, id) +
  //             " should be more than 6 characters.",
  //         };
  //       }
  //       if (id === "EmployeeID" && value.length > 0 && value.length < 9) {
  //         const specialChars =
  //           appConstants.ATTACHMENTS_SPECIAL_CHARACTERS_STRING;

  //         specialChars.split("").some((specialChar) => {
  //           if (value.includes(specialChar)) {
  //             finalObj = {
  //               ...finalObj,
  //               error: true,
  //               value,
  //               errorMessage:
  //                 "Please enter a valid " +
  //                 changeNameHandlerForValidation(reference, id),
  //             };
  //           } else {
  //             finalObj = {
  //               ...finalObj,
  //               error: false,
  //               value,
  //               errorMessage: "",
  //             };
  //           }
  //         });
  //       }
  //       // test
  //       else {
  //         finalObj = { ...finalObj, error: false, value, errorMessage: "" };
  //       }
  //     } else {
  //       if (
  //         obj.fieldID === id &&
  //         (id === "PreferredContactTimes" || id === "Service")
  //       ) {
  //         finalObj = {
  //           ...finalObj,
  //           error: false,
  //           value,
  //           errorMessage: "",
  //         };
  //       }
  //     }
  //     return finalObj;
  //   });

  //   return newState;
  // };
  const updateErrorFlagifWorkTypeChanged = (workType) => {
    const newLocStateArr = locData.location_data_state.map((obj) => {
      let finalObj = obj;
      if (
        obj.fieldID === 'Area' &&
        (workType === appConstants.SR_TYPE_CATERING || workType === appConstants.SR_TYPE_TRANSPORT)
      ) {
        finalObj = { ...finalObj, error: false, errorMessage: '' };
      }
      if (
        obj.fieldID === 'Area' &&
        workType !== appConstants.SR_TYPE_CATERING &&
        workType !== appConstants.SR_TYPE_TRANSPORT &&
        obj.value.length === 0
      ) {
        finalObj = {
          ...finalObj,
          error: true,
          errorMessage: changeNameHandlerForValidation(obj.reference, obj.fieldID) + ' is required.',
        };
      }
      return finalObj;
    });
    return newLocStateArr;
  };
  const handleRequestDetailsUpdateForCommonFields = (commonFields) => {
    commonFields.forEach((item) => {
      if (item.fieldID === 'WorkType') {
        setLocData((prevState) => {
          const updatedLocationState = updateErrorFlagifWorkTypeChanged(item.value);

          return {
            ...prevState,
            location_data_state: [...updatedLocationState],
          };
        });
      }
    });
    setRequestInfo((prevState) => {
      return {
        ...prevState,
        commonFieldsArr: [...commonFields],
      };
    });
  };

  const handleLocationDataUpdate = (id, name, value, reference) => {
    setLocData((prevState) => {
      const updatedLocationState = updateStateValue(id, name, value, locData.location_data_state, reference);

      return {
        ...prevState,
        location_data_state: [...updatedLocationState],
      };
    });
  };
  const updatelocationFieldsAterBaseNameChanged = (selectedObj) => {
    // update location_data_state::
    // 1.locationtype,state,--DropDown
    //2. label: ,baseaddress,area,addSiteWharf if not blank

    const newArray = [...locData.location_data_state];
    const objIndexState = newArray.findIndex((obj) => obj.fieldID === 'State');

    newArray[objIndexState].value = selectedObj.state;
    newArray[objIndexState].error = selectedObj.state.length > 0 ? false : true;
    setStateDropDown((prevState) => {
      const selectedStateDescription = prevState.dropDownArrayLocState.filter(
        (item) => item.code === selectedObj.state,
      );

      return {
        ...prevState,
        selectedValue: selectedObj.state,
        selectedDescription: !!selectedStateDescription ? selectedStateDescription[0].description : '',
      };
    });

    // update location Type
    const objIndexLocationType = newArray.findIndex((obj) => obj.fieldID === 'Type');

    newArray[objIndexLocationType].value = selectedObj.locationType;
    newArray[objIndexLocationType].error = selectedObj.locationType.length > 0 ? false : true;
    setLocationTypeDropdown((prevState) => {
      const selectedLocTypeDescription = prevState.dropDownArrayLocType.filter(
        (item) => item.code === selectedObj.locationType,
      );

      return {
        ...prevState,
        selectedValue: selectedObj.locationType,
        selectedDescription: !!selectedLocTypeDescription ? selectedLocTypeDescription[0].description : '', // Type: is a field id for location Type
      };
    });
    // //  update region label
    /* const objIndexRegion = newArray.findIndex(
      (obj) => obj.fieldID === "Region"
    ); */

    // newArray[objIndexRegion].value = selectedObj.region;
    // update Base Name

    const objIndexBaseName = newArray.findIndex((obj) => obj.fieldID === 'Name');

    newArray[objIndexBaseName].value = selectedObj.name;
    newArray[objIndexBaseName].error = selectedObj.name.length > 0 ? false : true;
    // update baseAddress
    const objIndexBaseAddress = newArray.findIndex((obj) => obj.fieldID === 'FullAddress');

    newArray[objIndexBaseAddress].value = selectedObj.baseAddress;
    newArray[objIndexBaseAddress].error = selectedObj.baseAddress.length > 0 ? false : true;
    // update Area

    const objIndexArea = newArray.findIndex((obj) => obj.fieldID === 'Area');

    newArray[objIndexArea].value = selectedObj.area;
    const objIndexSiteOrWharf = newArray.findIndex((obj) => obj.fieldID === 'SiteOrWharf');

    newArray[objIndexSiteOrWharf].value = selectedObj.siteOrWharf;

    return newArray;
  };
  const clearFields = (index, array) => {
    if (index !== -1) {
      array[index].value = '';
    }
  };
  const updatebaseAddressDetailsOnchangeOfStateOrLocation = (DropdownName, value) => {
    setbaseNameDropDown((prevState) => {
      return {
        ...prevState,
        selectedValue: '',
      };
    });
    let newLocationArray = [];
    if (DropdownName === 'Location' && value === 'Other') {
      // need to set extra fields as blank except region
      setExtraFieldsForPageInstrction((prevState) => {
        const updatedExtraFieldArr = prevState.data.map((item) => {
          if (item.fieldID === 'FID') {
            return { ...item, value: '' };
          } else if (item.fieldID === 'WorkCenterID') {
            return { ...item, value: '' };
          } else if (item.fieldID === 'EBI') {
            return { ...item, value: '' };
          } else if (item.fieldID === 'EBIBuilding') {
            return { ...item, value: '' };
          }
          // else if (item.fieldID === "Region") {
          //   return { ...item, value: selectedBaseNameObj.region };
          // }
          else {
            return { ...item };
          }
        });

        return {
          ...prevState,
          data: [...updatedExtraFieldArr],
        };
      });
      // ending
      const baseArray = [...locData.location_data_state];

      const p = baseArray.filter((item) => {
        if (item.fieldID !== 'FullAddress' && item.fieldID !== 'Name' && item.fieldID !== 'SiteOrWharf') {
          return item;
        }
      });

      const allDatafromOrig = [...allDataFromAPI.allFieldsinState];
      const otherRelatedExtraFields = allDatafromOrig.filter((item) => {
        if (item.fieldID === 'Line1' || item.fieldID === 'City' || item.fieldID === 'Postcode') {
          return item;
        }
      });
      const areaField = p.filter((item) => {
        if (item.fieldID === 'Area') {
          return item;
        }
      });
      // error fielf

      const dropDownField = p.filter((item) => {
        if (item.fieldID === 'State' || item.fieldID === 'Type') {
          // test
          return item;
          //test
        }
      });
      // setting the location type as other
      const objIndex = dropDownField.findIndex((obj) => obj.fieldID === 'Type');
      if (value === '') {
        dropDownField[objIndex].error = true;
        dropDownField[objIndex].errorMessage =
          changeNameHandlerForValidation(dropDownField[objIndex].reference, dropDownField[objIndex].fieldID) +
          ' is required.';
      } else {
        dropDownField[objIndex].error = false;
        dropDownField[objIndex].errorMessage = '';
      }

      dropDownField[objIndex].value = value;

      newLocationArray = [...dropDownField, ...otherRelatedExtraFields, ...areaField];

      setbaseNameDropDown((prevState) => {
        return {
          ...prevState,
          selectedValue: '',
        };
      });
      setfieldIDstoUpdated((prevState) => {
        const updatedIds = prevState.fieldIds.filter((item) => {
          if (
            item !== 'Name' &&
            item !== 'FullAddress' &&
            //item !== "Region" &&
            item !== 'SiteOrWharf'
          )
            return item;
        });

        ['State', 'Type'].forEach((item) => {
          updatedIds.indexOf(item) === -1 ? updatedIds.push(item) : console.log('This item already exists');
        });

        return {
          ...prevState,
          fieldIds: updatedIds,
        };
      });
    } else if (DropdownName === 'Location' && (value === 'Base' || value === '')) {
      const allorigData = [...allDataFromAPI.allFieldsinState];
      const filteredLocationArray = allorigData.filter((item) => {
        if (
          item.fieldID === 'State' ||
          item.fieldID === 'Type' ||
          item.fieldID === 'Name' ||
          item.fieldID === 'FullAddress' ||
          //item.fieldID === "Region" ||
          item.fieldID === 'Area' ||
          item.fieldID === 'SiteOrWharf'
        ) {
          return item;
        }
      });
      const selectedLoacationStateArr = locData.location_data_state.find((item) => item.fieldID === 'State');

      newLocationArray = filteredLocationArray.map((item) => {
        if (item.fieldID === 'Type' && value === '') {
          return {
            ...item,
            error: true,
            value: '',
            errorMessage: changeNameHandlerForValidation(item.reference, item.fieldID) + ' is required.',
          };
        } else if (item.fieldID === 'Type' && value !== '') {
          return {
            ...item,
            error: false,
            errorMessage: '',
          };
        } else if (item.fieldID === 'State' && selectedLoacationStateArr.error) {
          return {
            ...item,
            error: true,
            errorMessage: changeNameHandlerForValidation(item.reference, item.fieldID) + ' is required.',
          };
        } else {
          return item;
        }
      });
    } else {
      newLocationArray = locData.location_data_state.map((obj) => {
        let finalObj = obj;
        if (obj.fieldID === DropdownName) {
          if (value === '') {
            finalObj = {
              ...finalObj,
              error: true,
              errorMessage: changeNameHandlerForValidation(obj.reference, obj.fieldID) + ' is required.',
            };
          } else {
            finalObj = { ...finalObj, error: false, value, errorMessage: '' };
          }
        }
        return finalObj;
      });
    }
    // test

    // clear all selected data
    // Name,Address.fullAddress,ServiceRequest.Location.Vessel.SiteOrWharf,ServiceRequest.Location.Region,
    //ServiceRequest.Location.Address.Line1,ServiceRequest.Location.Address.City,ServiceRequest.Location.Address.Postcode,
    // ServiceRequest.Location.Description,ServiceRequest.Location.Address.Area

    // clear all the fields

    //  update full Address TODO: check we really want to reset this field!!???
    const objIndexFullAddress = newLocationArray.findIndex((obj) => obj.fieldID === 'FullAddress');
    if (objIndexFullAddress !== -1) {
      newLocationArray[objIndexFullAddress].error = true;
      newLocationArray[objIndexFullAddress].errorMessage =
        changeNameHandlerForValidation(
          newLocationArray[objIndexFullAddress].reference,
          newLocationArray[objIndexFullAddress].fieldID,
        ) + ' is required.';
    }
    const objIndexBase = newLocationArray.findIndex((obj) => obj.fieldID === 'Name');
    if (objIndexBase !== -1) {
      newLocationArray[objIndexBase].error = true;
      newLocationArray[objIndexBase].errorMessage =
        changeNameHandlerForValidation(
          newLocationArray[objIndexBase].reference,
          newLocationArray[objIndexBase].fieldID,
        ) + ' is required.';
    }

    clearFields(objIndexFullAddress, newLocationArray);
    const objIndexSiteOrWharf = newLocationArray.findIndex((obj) => obj.fieldID === 'SiteOrWharf');
    clearFields(objIndexSiteOrWharf, newLocationArray);
    //  update region label
    // const objIndexRegion = newArray.findIndex(
    //   (obj) => obj.fieldID === "Region"
    // );
    // clearFields(objIndexRegion, newArray);
    const objIndexAddressLine1 = newLocationArray.findIndex((obj) => obj.fieldID === 'Line1');
    if (objIndexAddressLine1 !== -1) {
      newLocationArray[objIndexAddressLine1].error = true;
      newLocationArray[objIndexAddressLine1].errorMessage =
        changeNameHandlerForValidation(
          newLocationArray[objIndexAddressLine1].reference,
          newLocationArray[objIndexAddressLine1].fieldID,
        ) + ' is required.';
    }
    clearFields(objIndexAddressLine1, newLocationArray);
    const objIndexCity = newLocationArray.findIndex((obj) => obj.fieldID === 'City');
    if (objIndexCity !== -1) {
      newLocationArray[objIndexCity].error = true;
      newLocationArray[objIndexCity].errorMessage =
        changeNameHandlerForValidation(
          newLocationArray[objIndexCity].reference,
          newLocationArray[objIndexCity].fieldID,
        ) + ' is required.';
    }
    clearFields(objIndexCity, newLocationArray);
    const objIndexPostCode = newLocationArray.findIndex((obj) => obj.fieldID === 'Postcode');
    if (objIndexPostCode !== -1) {
      newLocationArray[objIndexPostCode].error = true;
      newLocationArray[objIndexPostCode].errorMessage =
        changeNameHandlerForValidation(
          newLocationArray[objIndexPostCode].reference,
          newLocationArray[objIndexPostCode].fieldID,
        ) + ' is required.';
    }
    clearFields(objIndexPostCode, newLocationArray);
    const objIndexDescription = newLocationArray.findIndex((obj) => obj.fieldID === 'Description');
    if (objIndexDescription !== -1) {
      newLocationArray[objIndexDescription].error = true;
      newLocationArray[objIndexDescription].errorMessage =
        changeNameHandlerForValidation(
          newLocationArray[objIndexDescription].reference,
          newLocationArray[objIndexDescription].fieldID,
        ) + ' is required.';
    }
    clearFields(objIndexDescription, newLocationArray);
    const objIndexArea = newLocationArray.findIndex((obj) => obj.fieldID === 'Area');
    if (objIndexArea !== -1) {
      newLocationArray[objIndexArea].error = true;
      newLocationArray[objIndexArea].errorMessage =
        changeNameHandlerForValidation(
          newLocationArray[objIndexArea].reference,
          newLocationArray[objIndexArea].fieldID,
        ) + ' is required.';
    }
    clearFields(objIndexArea, newLocationArray);

    return newLocationArray;
  };
  const handleBaseNameDropdownUpdate = (selectedBaseName, selectedBaseNameObj, fieldId) => {
    setExtraFieldsForPageInstrction((prevState) => {
      const updatedExtraFieldArr = prevState.data.map((item) => {
        if (item.fieldID === 'FID') {
          return { ...item, value: selectedBaseNameObj.fId };
        } else if (item.fieldID === 'WorkCenterID') {
          return { ...item, value: selectedBaseNameObj.workCenterID };
        } else if (item.fieldID === 'EBI') {
          return { ...item, value: selectedBaseNameObj.ebi };
        } else if (item.fieldID === 'EBIBuilding') {
          return { ...item, value: selectedBaseNameObj.ebiBuilding };
        } else if (item.fieldID === 'Region') {
          return { ...item, value: selectedBaseNameObj.region };
        } else {
          return { ...item };
        }
      });

      return {
        ...prevState,
        data: [...updatedExtraFieldArr],
      };
    });

    setbaseNameDropDown((prevState) => {
      return {
        ...prevState,
        selectedValue: selectedBaseName,
      };
    });
    // update location_data_state region,locationtype,state,baseaddress,area,addSiteWharf if not blank
    setLocData((prevState) => {
      return {
        ...prevState,
        location_data_state: updatelocationFieldsAterBaseNameChanged(selectedBaseNameObj),
      };
    });

    const fieldsToUpdateonBaseNameChange =
      selectedBaseNameObj.siteOrWharf.length > 0
        ? [
            'State',
            'Type',
            'Name',
            'FullAddress',
            //"Region",
            'Area',
            'SiteOrWharf',
          ]
        : ['State', 'Type', 'Name', 'FullAddress', 'Area'];
    //["State", "Type", "Name", "FullAddress", "Region", "Area"];

    fieldsToUpdateonBaseNameChange.forEach((id) => {
      setfieldIDstoUpdated((prevState) => {
        const updatedIds = prevState.fieldIds.filter((item) => {
          if (
            item !== 'Line1' &&
            item !== 'City' &&
            item !== 'Postcode' &&
            item !== 'Description'
            //&& item !== "Region"
          )
            return item;
        });

        return {
          ...prevState,
          fieldIds: insertFieldIdsIfNotAlreadyPresent(updatedIds, id),
        };
      });
    });
  };
  const handleVesselDropDownUpdate = (value, selectedCode, fieldId) => {
    setVesselDropdown((prevState) => {
      return {
        ...prevState,
        selectedValue: selectedCode,
        selectedDescription: value,
      };
    });
    setfieldIDstoUpdated((prevState) => {
      return {
        ...prevState,
        fieldIds: insertFieldIdsIfNotAlreadyPresent(prevState.fieldIds, fieldId),
      };
    });
  };
  // test
  const handleEntityDropdownUpdate = (value, selectedValueCode, fieldId) => {
    setEntityDropdown((prevState) => {
      return {
        ...prevState,
        selectedValue: selectedValueCode,
        selectedDescription: value,
      };
    });
  };
  const handleEntityDropdownUpdateSecondary = (value, selectedValueCode, fieldId) => {
    setEntityDropdown((prevState) => {
      return {
        ...prevState,

        selectedValueSecondary: selectedValueCode,
        selectedDescriptionSecondary: value,
      };
    });
  };

  const handleStateLocationTypeDropdownUpdate = (dropdownName, value, selectedValueCode, fieldId) => {
    const newlocFieldsDetails = updatebaseAddressDetailsOnchangeOfStateOrLocation(dropdownName, selectedValueCode);

    if (dropdownName === 'State') {
      setExtraFieldsForPageInstrction((prevState) => {
        const updatedExtraFieldArr = prevState.data.map((item) => {
          if (item.fieldID === 'Region') {
            return { ...item, value: selectedValueCode };
          } else {
            return { ...item };
          }
        });

        return {
          ...prevState,
          data: [...updatedExtraFieldArr],
        };
      });
      getBaseNameDropDown(selectedValueCode, locationTypeDropdown.selectedValue);
      setStateDropDown((prevState) => {
        return {
          ...prevState,
          selectedValue: selectedValueCode,
          selectedDescription: value,
        };
      });
    } else if (dropdownName === 'Location') {
      setVesselDropdown((prevState) => {
        return {
          ...prevState,
          selectedValue: '',
          selectedDescription: '',
        };
      });
      setLocationTypeDropdown((prevState) => {
        return {
          ...prevState,
          selectedValue: selectedValueCode,
          selectedDescription: selectedValueCode === 'Other' ? 'Other Location' : 'Defence Location',
        };
      });
      getBaseNameDropDown(stateDropdown.selectedValue, selectedValueCode);
    }
    setLocData((prevState) => {
      return {
        ...prevState,
        location_data_state: [...newlocFieldsDetails],
      };
    });
  };
  const seconadryContactdetailsAccordingoPegaInput = (param) => {
    const input = [...param];

    const updatedsecondaryContactDetails = input.map((item) => {
      return {
        ...item,
        reference: 'ServiceRequest.Contact(1).' + item.fieldID,
      };
    });

    setEntityDropdown((prevState) => {
      return {
        ...prevState,
        selectedValue: '',
        selectedDescription: 'Select', // Type: is a field id for Entity
        selectedValueSecondary: prevState.selectedValue,
        selectedDescriptionSecondary: prevState.selectedDescription,
      };
    });

    return [...updatedsecondaryContactDetails];
  };
  const primaryContactdetailsAccordingoPegaInput = (param) => {
    const input = [...param];

    const updatedPrimaryContactDetails = input.map((item) => {
      let finalObj = item;
      if (item.fieldID === 'Service' || item.fieldID === 'PreferredContactTimes' || item.fieldID === 'EmployeeID') {
        finalObj = { ...finalObj, error: false, errorMessage: '' };
      } else {
        finalObj = {
          ...finalObj,
          value: '',
          error: true,
          errorMessage: changeNameHandlerForValidation(item.reference, item.fieldID) + ' is required.',
        };
      }
      return finalObj;
    });

    return updatedPrimaryContactDetails;
  };
  const updateSecondaryPrimaryStateifotherChanges = () => {
    setPersonalData((prevState) => {
      const updatedPersonalData = prevState.personal_data_secondary_state.map((item) => {
        return {
          ...item,
          reference: item.reference.replace('Contacts(1)', 'PrimaryContact'),
        };
      });

      const updatedSecondaryData = prevState.personal_data_secondary_state.map((item) => {
        return {
          ...item,
          value: '',
        };
      });

      return {
        ...prevState,
        personal_data_secondary_state: [...updatedSecondaryData],
        personal_data_state: [...updatedPersonalData],
      };
    });

    setEntityDropdown((prevState) => {
      const origInitialSelectedValEntityNameinOther = personalData.personal_data_secondary_state.filter(
        (item) => item.fieldID === 'Service',
      )[0].value;
      const initialSelectedValEntityNameInOther = convertSpecialChars(origInitialSelectedValEntityNameinOther);
      const selectedEntityDescriptionInOther = prevState.dropDownArrayEntity.filter(
        (item) => item.code === initialSelectedValEntityNameInOther,
      );

      return {
        ...prevState,
        selectedValue: !!initialSelectedValEntityNameInOther ? selectedEntityDescriptionInOther[0].code : '',
        selectedDescription: !!initialSelectedValEntityNameInOther
          ? selectedEntityDescriptionInOther[0].description
          : '', // Type: is a field id for Entity
        selectedValueSecondary: '',
        selectedDescriptionSecondary: 'Select',
      };
    });
    setShowSecondary((prevState) => {
      return {
        isVisible: false,
      };
    });
  };

  const handleOtherDetailsUpdate = (id, name, value, reference) => {
    if (value === 'true') {
      setPersonalData((prevState) => {
        const updatedPersonalData = prevState.personal_data_secondary_state.map((item) => {
          return {
            ...item,
            reference: item.reference.replace('Contacts(1)', 'PrimaryContact'),
          };
        });

        const updatedSecondaryData = prevState.personal_data_secondary_state.map((item) => {
          return {
            ...item,
            value: '',
          };
        });

        return {
          ...prevState,
          personal_data_secondary_state: [...updatedSecondaryData],
          personal_data_state: [...updatedPersonalData],
        };
      });

      setEntityDropdown((prevState) => {
        const origInitialSelectedValEntityNameinOther = personalData.personal_data_secondary_state.filter(
          (item) => item.fieldID === 'Service',
        )[0].value;
        const initialSelectedValEntityNameInOther = convertSpecialChars(origInitialSelectedValEntityNameinOther);
        const selectedEntityDescriptionInOther = prevState.dropDownArrayEntity.filter(
          (item) => item.code === initialSelectedValEntityNameInOther,
        );

        return {
          ...prevState,
          selectedValue: !!initialSelectedValEntityNameInOther ? selectedEntityDescriptionInOther[0].code : '',
          selectedDescription: !!initialSelectedValEntityNameInOther
            ? selectedEntityDescriptionInOther[0].description
            : '', // Type: is a field id for Entity
          selectedValueSecondary: '',
          selectedDescriptionSecondary: 'Select',
        };
      });
      setShowSecondary((prevState) => {
        return {
          isVisible: false,
        };
      });
    } else {
      setShowSecondary((prevState) => {
        return {
          isVisible: true,
        };
      });
      setPersonalData((prevState) => {
        const finalSecData = seconadryContactdetailsAccordingoPegaInput(prevState.personal_data_state);

        const finalPriData = primaryContactdetailsAccordingoPegaInput(prevState.personal_data_state);

        return {
          ...prevState,
          personal_data_state: finalPriData,
          personal_data_secondary_state: finalSecData,
        };
      });
    }
    setOtherData((prevState) => {
      const updatedotherState = updateStateValue(id, name, value, otherData.other_data_state, reference);
      return {
        ...prevState,
        other_data_state: [...updatedotherState],
        isPrimarySelected: getIsPrimarySelected(updatedotherState),
      };
    });
  };

  const handlePersonalDetailsUpdate = (id, name, value, reference) => {
    setPersonalData((prevState) => {
      const updatedPersonalState = updateStateValue(id, name, value, prevState.personal_data_state, reference);

      return {
        ...prevState,
        personal_data_state: updatedPersonalState,
      };
    });
  };

  const handlePersonalSecondaryDetailsUpdate = (secondaryForm, secondaryFormError, updatedFormData) => {
    const selectedServiceInSecondary = updatedFormData.filter((item) => item.fieldID === 'Service')[0]?.value;
    // !!!dont change description to code
    const selectedcodeforService =
      selectedServiceInSecondary !== ''
        ? entityDropdown.dropDownArrayEntity.filter((item) => item.description === selectedServiceInSecondary)[0]?.code
        : '';
    const updatedArr = updatedFormData.map((item) => {
      if (item.fieldID === 'Service') {
        return { ...item, value: selectedcodeforService };
      } else {
        return item;
      }
    });
    setPersonalData((prevState) => {
      return {
        ...prevState,
        personal_data_secondary_state: [...updatedArr],
      };
    });
  };

  const preparePageInstructions = (updatedItemArr) => {
    const preparePageInstructionsArr = updatedItemArr
      .filter(
        (item) => item.fieldID !== cateringCTAs.addNewMeal.fieldID && item.fieldID !== cateringCTAs.removeMeal.fieldID,
      )
      .map((item) => {
        return {
          instruction: 'UPDATE',
          target: '.' + item.reference.replace('.' + item.fieldID, ''),
          content: {
            [item.fieldID]: item.value,
          },
        };
      });
    return preparePageInstructionsArr;
  };
  const updateFieldIdsForSecondaryContact = () => {
    personalData.personal_data_secondary_state.forEach((item) => {
      setfieldIDstoUpdated((prevState) => {
        return {
          ...prevState,
          fieldIds: insertFieldIdsIfNotAlreadyPresent(fieldIDstoUpdated.fieldIds, item.fieldID),
        };
      });
    });
  };
  // const checkValueStoredInRedux = (value) => {
  //   if (typeof value === "boolean") {
  //     return true;
  //   } else {
  //     return value.length > 0;
  //   }
  // };

  const checkifItHasArray = (valueObj, array) => {
    if (Array.isArray(valueObj)) {
      valueObj.forEach((item) => {
        checkifItHasArray(item, array);
      });
    } else {
      array.push(valueObj);
    }
  };

  const getDataFromReduxStore = () => {
    const wholeObject = Object.entries(reqDetailataFromReduxStore.requestForm.formFields);

    let requestInformationData = [];

    for (const [key, value] of wholeObject) {
      checkifItHasArray(value, requestInformationData);
    }

    const filteringdataWhichHasReferenceField = requestInformationData.filter(
      (item) => !!item.reference && item.value !== undefined,
    );

    // const filteredArra = filteringdataWhichHasReferenceField.filter((item) =>
    //   checkValueStoredInRedux(item.value)
    // );
    // const reqDetailsALLFields = [
    //   ...requestInfo.commonFieldsArr,
    //   ...filteredArra,
    // ];

    // console.log("filteredArra-->", filteredArra);
    const reqDetailsALLFields = [
      ...requestInfo.commonFieldsArr,
      ...filteringdataWhichHasReferenceField, // changed this bcz while editing we migh need to send some value as blank
    ];

    return reqDetailsALLFields;
  };
  const getDataForSeconadaryForPageInstruction = () => {
    return [
      {
        fieldID: 'HasSecondaryContact',
        value: showSecondary.isVisible,
        reference: 'ServiceRequest.HasSecondaryContact',
      },
    ];
  };
  // const CheckLocationFieldsValidity = () => {
  //   const locArraytocheckforvalidity = locData.location_data_state.filter(
  //     (item) => {
  //       if (vesselCheckboxSelected.isChecked) {
  //         return item.fieldID !== "Area";
  //       } else {
  //         return item.fieldID !== "Area" && item.fieldID !== "SiteOrWharf";
  //       }
  //     }
  //   );

  //   setLocData((prevState) => {
  //     return {
  //       ...prevState,
  //       location_data_state: prevState.location_data_state.map((item) => {
  //         if (
  //           item.fieldID !== "Area" &&
  //           item.fieldID !== "SiteOrWharf" &&
  //           !vesselCheckboxSelected.isChecked &&
  //           item.value.length === 0
  //         ) {
  //           return {
  //             ...item,
  //             error: true,
  //           };
  //         } else if (
  //           item.fieldID !== "Area" &&
  //           vesselCheckboxSelected.isChecked &&
  //           item.value.length === 0
  //         ) {
  //           return {
  //             ...item,
  //             error: true,
  //           };
  //         } else {
  //           return {
  //             ...item,
  //             error: false,
  //           };
  //         }
  //       }),
  //     };
  //   });

  //   const even = (element) => element.value.length === 0;
  //   const isInvalid = locArraytocheckforvalidity.some((item) => even(item));
  //   return isInvalid;
  // };
  // const CheckPrimaryContactFieldsValidity = () => {
  //   const primaryContactArraytocheckforvalidity =
  //     personalData.personal_data_state.filter(
  //       (item) => item.fieldID !== "PreferredContactTimes"
  //     );

  //   const even = (element) => element.value.length === 0;
  //   const isInvalid = primaryContactArraytocheckforvalidity.some((item) =>
  //     even(item)
  //   );
  //   return isInvalid;
  // };
  const isFormInvalid = () => {
    const isInvalid = locData.location_data_state.some((item) => item.error === true);

    const isInvalidPersonal = personalData.personal_data_state.some((item) => item.error === true);

    const reqDetailsFields = findAllItems(reqDetailataFromReduxStore?.requestForm.formFields, 'fieldID');

    const isInvalidPickUpDate = reqDetailataFromReduxStore?.requestForm.PickUpDate.some((item) => item.error === true);

    const isInvalidReturnDate = reqDetailataFromReduxStore?.requestForm.ReturnDate.some((item) => item.error === true);

    const isInvalidReqDetails = reqDetailsFields?.some((item) => item.error);

    if (showSecondary.isVisible) {
      return (
        isInvalid ||
        isInvalidPersonal ||
        secondaryContactformInvalid ||
        isInvalidReqDetails ||
        isDropdownSelected ||
        isInvalidPickUpDate ||
        isInvalidReturnDate
      );
    } else {
      return (
        isInvalid ||
        isInvalidPersonal ||
        isInvalidReqDetails ||
        isDropdownSelected ||
        isInvalidPickUpDate ||
        isInvalidReturnDate
      );
    }
  };

  // test task 13303 - delete SR
  const deleteServiceRequestHandler = () => {
    setShowSpinnerApiIsInProgress(true);

    // NOTE: Here we are sending all fields for pageinstruction of location and
    // personal details but for request details we will be sending only those which are updated by user
    /* const onlyOperatorIsPrimaryDetails = otherData.other_data_state.filter(
      (item) => item.fieldID === "OperatorIsPrimary"
    );
    const hasSecondarySelected = getDataForSeconadaryForPageInstruction();
    const requestDetailsDatafromStore = getDataFromReduxStore();
    updateFieldIdsForSecondaryContact();
    const getUpdatedValueForLocationBasedOnTypeSelection =
      locationFilteredValueBasedOnType(); */
    /*  const allUpdatedValue = [
      ...getUpdatedValueForLocationBasedOnTypeSelection,
      ...locData.location_data_state,
      ...personalData.personal_data_state,
      ...personalData.personal_data_secondary_state,
      ...requestDetailsDatafromStore,
      ...onlyOperatorIsPrimaryDetails,
      ...hasSecondarySelected,
      ...extraFieldsForPageInstrction.data,
    ]; */
    //const finalInput = preparePageInstructions(allUpdatedValue);

    const data = {
      content: {
        pyStatusWork: 'Withdrawn',
      },
      pageInstructions: [],
      //pageInstructions: finalInput,
    };

    //DELETE api calls
    const URL_SR_DELETE_GET = getURL(process.env.REACT_APP_SR_DELETE_GET);
    axios
      .get(URL_SR_DELETE_GET + caseId.caseId, {
        withCredentials: true,
      })
      .then((res) => {
        const finalETagValue = res.data.content.pxSaveDateTime.replace(/[-:Z]/g, '') + ' GMT';

        // update Delete data
        setTimeout(() => {
          const config = {
            headers: {
              'if-match': finalETagValue,
            },
            withCredentials: true,
          };
          const URLForUpdate = process.env.REACT_APP_SR_DELETE_PUT;
          const URL_SR_DELETE_PUT = URLForUpdate + caseId.caseId;
          const final_URL_SR_DELETE_PUT = getURL(URL_SR_DELETE_PUT);
          axios
            .put(final_URL_SR_DELETE_PUT, data, config)
            .then((res) => {
              setShowSpinnerApiIsInProgress(false);
              navigate('/my-service-request');

              //Success delete message
              setshowSuccess(true);
              setsuccessMessage(appConstants.SERVICE_REQUEST_DELETE_SUCCESS_MESSAGE);
            })
            .catch((err) => {
              let error = { ...err };
              setShowSpinnerApiIsInProgress(false);
              error.response = error.response || { response: { data: [] } };
              setAPIError(error);
              if (error.response.status === 403 || error.response.status === 401) {
                setShowModal(true);
                setShowModalHttpCode(error.response.status);
              } else if (error.response.status === 500) {
                setServiceDownModal(true);
              } else {
                setErrorModal(true);
              }
            });
        }, 1000);
      })
      .catch((err) => {
        setShowSpinnerApiIsInProgress(false);
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);

        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
          setShowModalHttpCode(error.response.status);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
    //test
  };

  const saveServiceRequestHandler = (flag) => {
    setShowSpinnerApiIsInProgress(true);
    // NOTE: Here we are sending all fields for pageinstruction of location and
    // personal details but for request details we will be sending only those which are updated by user

    const onlyOperatorIsPrimaryDetails = otherData.other_data_state.filter(
      (item) => item.fieldID === 'OperatorIsPrimary',
    );
    const hasSecondarySelected = getDataForSeconadaryForPageInstruction();
    const requestDetailsDatafromStore = getDataFromReduxStore();
    updateFieldIdsForSecondaryContact();
    const getUpdatedValueForLocationBasedOnTypeSelection = locationFilteredValueBasedOnType();
    const allUpdatedValue = [
      ...getUpdatedValueForLocationBasedOnTypeSelection,
      ...locData.location_data_state,
      ...personalData.personal_data_state,
      ...personalData.personal_data_secondary_state,
      ...requestDetailsDatafromStore,
      ...onlyOperatorIsPrimaryDetails,
      ...hasSecondarySelected,
      ...extraFieldsForPageInstrction.data,
    ];

    let finalInput = preparePageInstructions(allUpdatedValue);
    finalInput = [...reqDetailataFromReduxStore.requestForm.pageInstructions, ...finalInput];

    const data = {
      content: {},
      pageInstructions: finalInput,
    };

    const URL_SR_SAVE1 = getURL(process.env.REACT_APP_SR_SAVE1);
    axios
      .get(URL_SR_SAVE1 + caseId.caseId, {
        withCredentials: true,
      })
      .then((res) => {
        const finalETagValue = res.data.content.pxSaveDateTime.replace(/[-:Z]/g, '') + ' GMT';

        setTimeout(() => {
          const config = {
            headers: {
              'if-match': finalETagValue,
            },
            withCredentials: true,
          };
          let URLForUpdate = process.env.REACT_APP_SR_SAVE2;
          const URL = URLForUpdate + caseId.caseId;
          const URL_SR_SAVE2 = getURL(URL);
          axios
            .put(URL_SR_SAVE2, data, config)
            .then((res) => {
              setShowSpinnerApiIsInProgress(false);

              //if save is selected
              if (flag === true) {
                setSave(true);
              } else {
                //save and close is selected
                setSave(false);
              }
              setshowSuccess(true);
              setsuccessMessage(appConstants.SERVICE_REQUEST_SAVE_SUCCESS_MESSAGE);
            })
            .catch((err) => {
              setShowSpinnerApiIsInProgress(false);
              let error = { ...err };
              error.response = error.response || { response: { data: [] } };
              setAPIError(error);

              if (error.response.status === 403 || error.response.status === 401) {
                setShowModal(true);
                setShowModalHttpCode(error.response.status);
              } else if (error.response.status === 500) {
                setServiceDownModal(true);
              } else {
                setErrorModal(true);
              }
            });
        }, 1000);

        // test
      })
      .catch((err) => {
        setShowSpinnerApiIsInProgress(false);
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);

        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
          setShowModalHttpCode(error.response.status);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
    //test
  };
  const locationFilteredValueBasedOnType = () => {
    const isDefenseLocation = locData.location_data_state.some(
      (item) => item.fieldID === 'Type' && item.value === 'Base',
    );

    if (isDefenseLocation) {
      // if locationtype == base (defence location then we need to send other related field as blank )
      const filteredArr = locData.location_data_state.filter(
        (item) => item.fieldID !== 'Line1' || item.fieldID !== 'City' || item.fieldID !== 'Postcode',
      );
      const otherRelFieldsArr = [
        {
          reference: 'ServiceRequest.Location.Address.Line1',
          value: '',
          fieldID: 'Line1',
        },
        {
          reference: 'ServiceRequest.Location.Address.City',
          value: '',
          fieldID: 'City',
        },
        {
          reference: 'ServiceRequest.Location.Address.Postcode',
          value: '',
          fieldID: 'Postcode',
        },
      ];
      return [...filteredArr, ...otherRelFieldsArr];
    } else {
      // if locationtype == Other ( then we need to send defence location related field as blank )
      const filteredArr = locData.location_data_state.filter(
        (item) => item.fieldID !== 'FullAddress' || item.fieldID !== 'Name' || item.fieldID !== 'SiteOrWharf',
      );
      const defenceLocationRelFieldsArr = [
        {
          reference: 'ServiceRequest.Location.Address.FullAddress',
          value: '',
          fieldID: 'FullAddress',
        },
        {
          reference: 'ServiceRequest.Location.Name',
          value: '',
          fieldID: 'Name',
        },
        {
          reference: 'ServiceRequest.Location.Vessel.SiteOrWharf',
          value: '',
          fieldID: 'SiteOrWharf',
        },
      ];
      return [...filteredArr, ...defenceLocationRelFieldsArr];
    }
  };
  const handleUploadPopUp = () => {
    if (uploadedFile > 0) {
      setShowUploadModal(true);
    } else {
      handleCallBackUpdateForms();
    }
  };

  const handleCallBackUpdateForms = () => {
    // NOTE: Here we are sending all fields for pageinstruction of location and
    // personal details but for request details we will be sending only those which are updated by user

    setShowSpinnerApiIsInProgress(true);
    const onlyOperatorIsPrimaryDetails = otherData.other_data_state.filter(
      (item) => item.fieldID === 'OperatorIsPrimary',
    );
    const hasSecondarySelected = getDataForSeconadaryForPageInstruction();
    const requestDetailsDatafromStore = getDataFromReduxStore();
    updateFieldIdsForSecondaryContact();
    const getUpdatedValueForLocationBasedOnTypeSelection = locationFilteredValueBasedOnType();

    const allUpdatedValue = [
      ...getUpdatedValueForLocationBasedOnTypeSelection,
      ...personalData.personal_data_state,
      ...personalData.personal_data_secondary_state,
      ...requestDetailsDatafromStore,
      ...onlyOperatorIsPrimaryDetails,
      ...hasSecondarySelected,
      ...extraFieldsForPageInstrction.data,
    ];

    let finalInput = preparePageInstructions(allUpdatedValue);
    finalInput = [...reqDetailataFromReduxStore.requestForm.pageInstructions, ...finalInput];

    const data = {
      content: {},
      pageInstructions: finalInput,
    };
    let URL =
      process.env.REACT_APP_UPDATE_SERVICE_REQUEST_DETAILS +
      nextAssignmentIdState.nextAssignmentId +
      (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV
        ? '?actionID=' + actionIdIdState.actionId
        : '/' + actionIdIdState.actionId);
    URL = getURL(URL);

    axios
      .post(URL, data, {
        withCredentials: true,
      })
      .then((res) => {
        setShowSpinnerApiIsInProgress(false);
        navigate('/my-service-request/review-request/' + nextAssignmentIdState.nextAssignmentId, {
          state: [
            {
              nextAssignmentIdState,
              locData,
              personalData,
              otherData,
              requestInfo,
              stateDropdown,
              locationTypeDropdown,
              baseNameDropDown,
              entityDropdown,
              vesselDropdown,
              showSecondary,
              isMinorRequest,
              caseId,
            },
          ],
        });
      })
      .catch((error) => {
        setShowSpinnerApiIsInProgress(false);
        if (error.response) {
          // Request made and server responded
          setAPIError(error);

          if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
            setShowModalHttpCode(error.response.status);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setErrorModal(true);
          }
        } else if (error.response.status === 500) {
          //PEGA is down - show Modal Here for service down
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
    window.scrollTo(0, 0); //scroll to top of review page once everything is done
  };
  const addSecondaryContactHandler = () => {
    if (showSecondary.isVisible) {
      if (personalData.personal_data_secondary_state.length > 0) {
        setPersonalData((prevState) => {
          const updatedSecondaryData = prevState.personal_data_secondary_state.map((item) => {
            return {
              ...item,
              value: '',
            };
          });

          return {
            ...prevState,
            personal_data_secondary_state: [...updatedSecondaryData],
          };
        });

        setEntityDropdown((prevState) => {
          return {
            ...prevState,

            selectedValueSecondary: '',
            selectedDescriptionSecondary: 'Select',
          };
        });
      }
    }
    setShowSecondary((prevState) => {
      return {
        isVisible: !showSecondary.isVisible,
      };
    });
  };
  useEffect(() => {
    ViewReqInNewState();

    //testDataMock();
  }, []);

  // test
  const handleVesselCheckBox = (isChecked) => {
    setVesselCheckboxSelected((prevState) => {
      return {
        isChecked: isChecked,
      };
    });
    setVesselDropdown((prevState) => {
      return {
        ...prevState,
        selectedValue: '',
        selectedDescription: '',
      };
    });
    if (!isChecked) {
      setLocData((prevState) => {
        return {
          ...prevState,
          location_data_state: prevState.location_data_state.map((item) => {
            if (item.fieldID === 'SiteOrWharf') {
              return {
                ...item,
                error: false,
                value: '',
                errorMessage: '',
              };
            } else {
              return {
                ...item,
              };
            }
          }),
        };
      });
    } else {
      setLocData((prevState) => {
        return {
          ...prevState,
          location_data_state: prevState.location_data_state.map((item) => {
            if (item.fieldID === 'SiteOrWharf') {
              return {
                ...item,
                error: true,
                value: '',
                errorMessage: changeNameHandlerForValidation(item.reference, item.fieldID) + ' is required.',
              };
            } else {
              return {
                ...item,
              };
            }
          }),
        };
      });
    }
  };
  const onSuccespopupClose = () => {
    setshowSuccess(false);
    if (showSave === false) {
      navigate('/my-service-request');
    }
    // if (locationconfig.pathname !== "/my-service-request") {
    //   navigate("/my-service-request");
    // }
  };
  const getValidationForDropdown = (isError) => {
    setIsDropdownSelected(isError);
  };

  const changeNameHandlerForValidation = (reference, fieldName) => {
    if (reference === 'ServiceRequest.Location.Type') {
      return 'Location type';
    } else if (reference === 'ServiceRequest.Location.Name') {
      return 'Defence Location';
    } else if (reference === 'ServiceRequest.Location.Address.FullAddress') {
      return 'Address';
    } else if (reference === 'ServiceRequest.Location.Address.Area') {
      return 'Impacted area';
    } else if (reference === 'ServiceRequest.Location.Description') {
      return 'Location Description';
    } else if (reference === 'ServiceRequest.Location.Address.Line1') {
      return 'Address';
    } else if (reference === 'ServiceRequest.PrimaryContact.Email(1).Address') {
      return 'Personal email address';
    } else if (reference === 'ServiceRequest.PrimaryContact.Phone(1).Number') {
      return 'Personal contact number';
    } else if (reference === 'ServiceRequest.Location.Vessel.SiteOrWharf') {
      return 'Vessel name';
    } else if (reference === 'ServiceRequest.PrimaryContact.Service') {
      return "Primary contact's group or entity";
    } else if (reference === 'ServiceRequest.PrimaryContact.FullName') {
      return 'Full name';
    } else {
      return fieldName;
    }
  };

  //<-------------------------TODO: !!!!!!!!IMPORTANT: If any change in props below then change in review req page as well!! TODO:------------>
  return (
    <>
      <DeleteModal
        show={showDeleteModal}
        closeAction={() => setShowDeleteModal(false)}
        deleteAction={() => deleteServiceRequestHandler()}
      />
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}

      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}

      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
          descriptionNoPrivilage={AuthenticationModalProps.descriptionWithNoPrivilage}
          code={showModalHttpCode}
        />
      )}

      {showUploadModal && (
        <UploadConfirmationSRModal
          handleUpdateForms={handleCallBackUpdateForms}
          show={showUploadModal}
          closeAction={() => setShowUploadModal(false)}
        />
      )}

      <div>
        <SuccessPopup show={showSuccess} message={successMessage} closeAction={() => onSuccespopupClose()} />
        <SubNavigation {...SubNavProps} />
        <div className="newRequestBackground ">
          <Header
            servicerequest
            mainHeading={isMinorRequest ? 'Minor maintenance request' : 'New service request'}
            subHeading={content.header.subHeading}
            breadcrumbs={content.header.breadcrumbs}
            breadcrumbItems={content.header.breadcrumbItems}
          />
          {loading.isLoading && (
            <Container>
              <h2 style={{ textAlign: 'center', margin: '20px' }}>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </h2>
            </Container>
          )}
          {!loading.isLoading && (
            <div className="landingPageWidth">
              <RequestDetail
                isminorReqFlag={isMinorRequest}
                handleUpdate={handleRequestDetailsUpdateForCommonFields}
                caseID={nextAssignmentIdState.nextAssignmentId.slice(
                  nextAssignmentIdState.nextAssignmentId.indexOf('S-'),
                  nextAssignmentIdState.nextAssignmentId.indexOf('!'),
                )}
                caseIDOriginal={caseId.caseId}
                nextAssignmentId={nextAssignmentIdState.nextAssignmentId}
                actionID={actionIdIdState.actionId}
                fromview={true}
                workType={reqDetailwrkTypeSubType.workType}
                workSubType={reqDetailwrkTypeSubType.workSubType}
                description={reqDetailwrkTypeSubType.description}
                dropdownError={getValidationForDropdown}
              />

              {/* LOCATION DETAILS COMPONENT */}
              <Container className=" py-3 pt-lg-4 px-4 px-lg-6">
                <h2>{LocationDetailProps.mainHeading}</h2>
                <h4 className="fw-light">
                  {LocationDetailProps.subHeading}{' '}
                  <button
                    title="Tooltip"
                    className="border-0 bg-transparent"
                    ref={target}
                    onClick={() => setShow(!show)}
                  >
                    <InfoCircle size={15} />
                  </button>
                </h4>

                <Overlay target={target.current} show={show} placement="right">
                  {(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      These details can be edited, however if pre-filled details are not correct you may need to visit
                      the Profile tab and update your home base or working location.
                    </Tooltip>
                  )}
                </Overlay>
                <div className="fieldsContainer">
                  {locData.location_data_state.map((item, index) => {
                    if (item.error) {
                      return (
                        <div className="error-msg" key={index + 'error-msg-loc'}>
                          <span className="align-center-error">
                            <Image alt="error" className="icon-size-small" src={alertImage} />
                          </span>
                          <span className="align-center-error-message">
                            {/* {changeNameHandlerForValidation(
                            item.reference,
                            item.fieldID
                          ) + " is required."} */}
                            {item.errorMessage}
                          </span>
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="fieldsContainer">
                  {locData.location_data_state.length > 0 &&
                    locData.location_data_state.map((item, index) => (
                      <Location
                        key={index + 'loc'}
                        check={locData.location_data_state}
                        reqInfo={requestInfo}
                        locationDetail={item}
                        stateValues={stateDropdown}
                        VesselValues={vesselDropdown}
                        locationTypeValues={locationTypeDropdown}
                        baseNameValues={baseNameDropDown}
                        onLocationInfoChange={handleLocationDataUpdate}
                        onStateLocationTypeDroppDownChange={handleStateLocationTypeDropdownUpdate}
                        onAddVesselSelected={handleVesselCheckBox}
                        onBaseNameDropDownChange={handleBaseNameDropdownUpdate}
                        onVesselDropDownChange={handleVesselDropDownUpdate}
                        isReadonlyLocation={false}
                      />
                    ))}
                </div>
              </Container>

              {/* PERSONAL DETAILS COMPONENT */}
              <Container className="pt-4 pt-lg-6 ps-4 ps-lg-6 pe-4 pe-lg-6">
                <h2>{PersonalDetailProps.mainHeading}</h2>

                <Container style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  {otherData.other_data_state.length > 0 &&
                    otherData.other_data_state.map((item, index) => (
                      <Other
                        key={index + 'other'}
                        otherDetail={item}
                        onOtherInfoChange={handleOtherDetailsUpdate}
                        isReadonlyOther={false}
                      />
                    ))}
                </Container>
                <h3 className="fw-light">{PersonalDetailProps.subHeading}</h3>
                <div className="fieldsContainer">
                  {personalData.personal_data_state.map((item, index) => {
                    if (item.error) {
                      return (
                        <div className="error-msg" key={index + 'error-msg-personal'}>
                          <span className="align-center-error">
                            <Image alt="error" className="icon-size-small" src={alertImage} />
                          </span>
                          <span className="align-center-error-message">
                            {/* {changeNameHandlerForValidation(
                            item.reference,
                            item.fieldID
                          ) + " is required."} */}
                            {item.errorMessage}
                          </span>
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="fieldsContainer">
                  {personalData.personal_data_state.length > 0 &&
                    otherData.other_data_state[3].value === 'false' &&
                    personalData.personal_data_state.map((item, index) => (
                      <>
                        <PersonalDetailsPrimary
                          nopersonal="true"
                          key={index + 'false'}
                          isNoDisableFlag={true}
                          entityValues={entityDropdown}
                          onEntityChange={handleEntityDropdownUpdate}
                          personalDetail={item}
                          onPersonalInfoChange={handlePersonalDetailsUpdate}
                          isReadonlyPersonalDetails={true}
                        />
                      </>
                    ))}
                </div>
                <div className="fieldsContainer">
                  {otherData.isPrimarySelected === 'true' &&
                    personalData.personal_data_state.map((item, index) => (
                      <>
                        <PersonalDetailsPrimary
                          key={index + 'true'}
                          nopersonal="false"
                          entityValues={entityDropdown}
                          onEntityChange={handleEntityDropdownUpdate}
                          personalDetail={item}
                          isNoDisableFlag={otherData.isPrimarySelected === 'false'}
                          onPersonalInfoChange={handlePersonalDetailsUpdate}
                          isReadonlyPersonalDetails={true}
                        />
                      </>
                    ))}
                </div>
              </Container>
              {/* SECONDARY CARD SELECTOR COMPONENT */}
              <Container className="pt-6 ps-4 ps-lg-6 pe-4 pe-lg-6">
                <div className="secondaryContactCard bg-defencelightblue">
                  <Container fluid className="p-5">
                    <h3 className="pb-2">{SecondaryContactCardProps.mainHeading}</h3>
                    <h3 className="fw-light">{SecondaryContactCardProps.subHeading}</h3>
                    <Form.Check
                      type="switch"
                      name="addSecondary"
                      checked={showSecondary.isVisible}
                      onChange={addSecondaryContactHandler}
                      disabled={otherData.isPrimarySelected === 'false'}
                      className="secondarySwitch"
                      label="Add contact details"
                    />
                  </Container>
                </div>
              </Container>
              {/* SECONDARY PERSONAL DETAILS COMPONENT */}
              <Container className="ps-4 ps-lg-6 pe-4 pe-lg-6 pt-4 pt-lg-6">
                {showSecondary.isVisible && otherData.isPrimarySelected === 'true' && (
                  <>
                    <h2>Alternate point of contact</h2>

                    <PersonalDetailsSecondary
                      allDetailsOnSecondary={personalData.personal_data_secondary_state}
                      onIsInvalidsecondaryForm={(flag) => setsecondaryContactformInvalid(flag)}
                      entityValuesSecondary={entityDropdown}
                      onEntityChangeSecondary={handleEntityDropdownUpdateSecondary}
                      onPersonalSeconadryInfoChange={handlePersonalSecondaryDetailsUpdate}
                      isReadonlyPersonalSecondaryDetails={false}
                    />
                  </>
                )}
              </Container>
              <Container className=" ps-4 ps-lg-6 pe-4 pe-lg-6 pb-4 pb-lg-6">
                {otherData.isPrimarySelected === 'false' && (
                  <>
                    <h2>Alternate point of contact</h2>

                    <PersonalDetailsSecondary
                      entityValuesSecondary={entityDropdown}
                      onEntityChangeSecondary={handleEntityDropdownUpdateSecondary}
                      onIsInvalidsecondaryForm={(flag) => setsecondaryContactformInvalid(flag)}
                      allDetailsOnSecondary={personalData.personal_data_secondary_state}
                      onPersonalSeconadryInfoChange={handlePersonalSecondaryDetailsUpdate}
                      isReadonlyPersonalSecondaryDetails={true}
                      //isReview={true}
                    />
                  </>
                )}
              </Container>
              {showSpinnerApiIsInProgress && (
                <Container>
                  <ServiceConnectSpinner></ServiceConnectSpinner>
                </Container>
              )}
              <Row className="buttonContainer ps-4 ps-lg-6 pe-4 pe-lg-6">
                <Col
                  md
                  className="d-flex justify-content-start pt-xs-2 pt-2 pb-6 col-md-12 col-lg-8 order-2 order-lg-1"
                >
                  <Button variant="standard" className="mobileButton" onClick={() => saveServiceRequestHandler(false)}>
                    Save & close
                  </Button>
                  <Container className="ps-3">
                    <Button variant="standard" className="mobileButton" onClick={() => saveServiceRequestHandler(true)}>
                      Save
                    </Button>
                  </Container>
                </Col>
                <Col md className="d-xs-inline d-lg-flex pt-2 justify-content-end order-1 order-lg-2">
                  <Row>
                    <Col
                      xs={12}
                      className="col-xs-12 col-sm-12 col md-12 col-lg-4 pt-2 pb-lg-0 pe-xs-0 pe-lg-3 d-flex justify-content-end order-2 order-lg-1"
                    >
                      <Button
                        variant="secondary"
                        className="mobileButton"
                        //onClick={saveServiceRequestHandler}
                        onClick={() => setShowDeleteModal(true)}
                      >
                        Delete
                      </Button>
                    </Col>
                    <Col
                      xs={12}
                      className="col-xs-12 col-sm-12 col-md-12 col-lg-8 pt-lg-2 d-flex justify-content-end order-1 order-lg-2"
                    >
                      <Button
                        disabled={isFormInvalid()}
                        variant="primary"
                        className="mobileButton"
                        onClick={handleUploadPopUp}
                      >
                        Review request
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditServiceRequestCreate;
