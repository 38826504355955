import React from 'react';
import './Footer.scss';
import '../../../sass/custom.scss';
import { Container, Image, Col, Row } from 'react-bootstrap';
import logo from '../../../images/ServiceConnectLogo.png';
import { getBaseURL, getFormsURL } from '../../../helper/getOtherAppURL';
import { appConstants } from '../../../helper/client/constant';

const Footer = (props) => {
  const newArrForQuickAccess = props.quickAccess.map((item) => {
    return {
      ...item,
      url:
        item.name === appConstants.MY_BASE
          ? getBaseURL()
          : item.name === appConstants.MY_FORMS
          ? getFormsURL()
          : item.url,
    };
  });

  return (
    <Container className="px-0 pagefooter d-flex align-items-center bg-defencecharcoalgrey">
      <Container fluid className="landingPageWidth">
        <Container fluid className="px-4 px-lg-0 p-lg-6">
          <Row className="d-flex justify-content-between mobileflex">
            {/* FIRST HALF */}
            <Col lg={6} className="pt-3 pt-lg-0 pe-lg-6">
              <Container>
                <Image data-testid="Logo" className="footerlogo" src={logo} alt={props.logoAltText}></Image>
              </Container>

              <Container className="text-white pt-2 fw-light">
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.acknowledgmentText,
                  }}
                />
                <p className="app-version">v{process.env.REACT_APP_VERSION}</p>
              </Container>
            </Col>
            {/* SECOND HALF */}
            <Col lg={5} className="d-flex mobilecolumn gap-3 pt-5 pt-lg-0 ">
              <Container>
                <h3 className="fw-bold m-0 text-white">{props.quickAccessTitle}</h3>
                <ul className="ps-0">
                  {newArrForQuickAccess.map((value, key) => {
                    return (
                      <li key={key} className="text-white list-unstyled pb-1">
                        <a href={value.url}>{value.name}</a>
                      </li>
                    );
                  })}
                </ul>
              </Container>
              <Container>
                <h3 className="fw-bold m-0 pt-3 p-lg-0 text-white">{props.moreInfoTitle}</h3>
                <ul className="ps-0">
                  {props.moreInformation.map((value, key) => {
                    return (
                      <li key={key} className="text-white list-unstyled text-nowrap pb-1">
                        <a
                          rel="noreferrer"
                          href={value.url}
                          className={value.isExternal ? 'externalLink' : ''}
                          target={value.isExternal ? '_blank' : ''}
                        >
                          {value.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </Container>
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  );
};

export default Footer;
