import React from 'react';
import { useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import './Feedback.scss';
import '../../../sass/custom.scss';
import FeedBackModal from './FeedbackModal';

const Feedback = (props) => {
  const data = props;
  const [showModal, setShowModal] = useState(false);
  return (
    <Container
      fluid
      className={`${props.variant === 'servicerequest' ? 'bg-defencenavy feedbackImg' : 'bg-defenceorange'}`}
    >
      <Container fluid className="p-lg-3 py-4 px-4 landingPageWidth">
        <Row className="text-defencewhite">
          <Col className="d-flex align-items-center" lg={4}>
            <h3 className="w-75 m-lg-0">
              {props.variant === 'servicerequest'
                ? 'Please tell us what you need from My Service Request'
                : 'Please tell us what you need from ServiceConnect'}
            </h3>
          </Col>
          <Col className="fw-light d-flex align-items-center" lg={5}>
            <p className="m-lg-0">{data.description}</p>
          </Col>
          <Col className="d-flex align-items-center flex-row-reverse" lg={3}>
            <Button
              data-testid="provideFeedback"
              className="buttonFeedback border-0 mobileButton"
              variant="standard"
              onClick={() => setShowModal(true)}
            >
              {data.ctaText}
            </Button>
          </Col>
        </Row>
      </Container>
      <FeedBackModal modalProps={data} close={() => setShowModal(false)} show={showModal} />
    </Container>
  );
};

export default Feedback;
