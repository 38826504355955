import React from 'react';
import { Tooltip as CTooltip, Box } from '@chakra-ui/react';
import { Information } from '../icons/Outline';
import { PlacementWithLogical } from '@chakra-ui/react';

interface TooltipProps {
  content: string;
  placement: PlacementWithLogical;
}
export const Tooltip = (props: TooltipProps) => {
  return (
    <CTooltip label={props.content} placement={props.placement}>
      <Box _hover={{ cursor: 'pointer' }} tabIndex={0}>
        <Information />
      </Box>
    </CTooltip>
  );
};
