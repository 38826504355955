import React, { useRef, useState, useEffect } from 'react';
import { Container, Form } from 'react-bootstrap';
import './Other.scss';

const Other = (props) => {
  const nameOtherInputRef = useRef();
  const [nameOther, setNameOther] = useState({
    fieldName: props.otherDetail.fieldName,
    fieldValue: props.otherDetail.value,
    fieldId: props.otherDetail.fieldID,
  });
  const inputChangedHandlerOther = (event) => {
    event.persist();

    setNameOther((prevState) => {
      return {
        ...prevState,
        fieldValue: event.target.value,
      };
    });

    props.onOtherInfoChange(
      nameOther.fieldId,
      nameOther.fieldName,
      nameOtherInputRef.current.value,
      props.otherDetail.reference,
    );
  };
  const onChangeisPrimary = (id, name) => {
    const selectedValue = nameOther.fieldValue === 'true' ? 'false' : 'true';

    setNameOther((prevState) => {
      return {
        ...prevState,
        fieldValue: selectedValue,
      };
    });
    props.onOtherInfoChange(id, name, selectedValue, props.otherDetail.reference);
  };
  const changeNameHandler = () => {
    if (props.otherDetail.fieldName === 'OperatorIsPrimary') {
      setNameOther((prevState) => {
        return {
          ...prevState,
          fieldName: 'Are you the primary contact?',
        };
      });
    }
  };

  useEffect(() => {
    changeNameHandler();
  }, []);

  const renderElement = () => {
    if (props.otherDetail.fieldName === 'OperatorIsPrimary') {
      return (
        <>
          <Container onChange={(e) => onChangeisPrimary(props.otherDetail.fieldID, props.otherDetail.fieldName)}>
            <Form.Group>
              <Form.Label className="d-block">{nameOther.fieldName}</Form.Label>
              {props.isReview ? (
                <p className="fw-light">{nameOther.fieldValue === 'true' ? 'Yes' : 'No'}</p>
              ) : (
                <>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    id={props.otherDetail.fieldID}
                    value="true"
                    name="Are you the primary contact?"
                    checked={nameOther.fieldValue === 'true'}
                    disabled={props.isReadonlyOther}
                    className={props.isReview && nameOther.fieldValue === 'true' ? 'unselected_button' : 'ms-2'}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    value="false"
                    name="primaryContact"
                    checked={nameOther.fieldValue === 'false'}
                    disabled={props.isReadonlyOther}
                  />
                </>
              )}
            </Form.Group>
          </Container>
        </>
        // test
      );
    } else {
      return (
        <></>
        // <Container>
        //   <label style={{ padding: "5px" }} htmlFor={props.otherDetail.fieldID}>
        //     {nameOther.fieldName}
        //   </label>
        //   <input
        //     ref={nameOtherInputRef}
        //     style={{ margin: "10px" }}
        //     id={nameOther.fieldId}
        //     type="text"
        //     value={nameOther.fieldValue}
        //     readOnly={props.otherDetail.isReadOnly}
        //     onChange={inputChangedHandlerOther}
        //   />
        // </Container>
      );
    }
  };
  return <>{renderElement()}</>;
};

export default Other;
