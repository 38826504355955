import { Dropdown, Form, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import './SearchDropdown.scss';

const SearchDropdown = (props) => {
  const options = props.options;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const filterFunc = (e) => {
    return e.name.toLowerCase().includes(value.toLowerCase());
  };
  const handleClick = (e) => {
    setValue(e.name);
    props.handleChange(e);
  };
  const filteredForms = value ? options.filter((option) => filterFunc(option)) : options;

  const handleChange = (e) => {
    setValue(e);
  };
  useEffect(() => {
    setValue(props.selectedValue);
  }, [props]);
  return (
    <Col className="formField ps-0">
      {/*  <label htmlFor={props.id} className="m-0">
        {props.label}
      </label> */}
      {props.isReview ? (
        <p className="fw-light">{value}</p>
      ) : (
        <Dropdown
          show={open}
          onClick={() => {
            setOpen(!open);
          }}
          onKeyDown={(e) => {
            e.key === 'Enter' && setOpen(!open);
          }}
          autoComplete="nope"
        >
          <Dropdown.Toggle
            id={props.id}
            role="combobox"
            className={`dropdown fw-light m-0"
          ${props.error && 'fieldContainer show-error-border'}`}
            disabled={props.isReview}
            as={Form.Control}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
          ></Dropdown.Toggle>
          <Dropdown.Menu
            onBlur={() => {
              setOpen(false);
            }}
            className="w-100 dropdownHeight"
          >
            {filteredForms.map((key, idx) => {
              return (
                <Dropdown.Item
                  className="fw-light"
                  id={`d-item-${idx}`}
                  key={idx}
                  value={key}
                  onClick={() => handleClick(key)}
                  onKeyDown={(e) => e.key === 'Enter' && handleClick(key)}
                >
                  {key.name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Col>
  );
};

export default SearchDropdown;
