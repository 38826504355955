import React from 'react';
import { Table, Tbody, Button, Tr, Td, TableCaption, TableContainer } from '@chakra-ui/react';

export interface FileListProps {
  header: string;
  files: File[];
  onClick(e): void;
}

export const FileList: React.FC<FileListProps> = (props: FileListProps): JSX.Element => {
  return (
    <TableContainer w={{ lg: '60%', base: '100%' }} mb="1rem">
      <Table variant="File">
        <TableCaption placement="top" textAlign="left">
          {props.header}
        </TableCaption>
        <Tbody>
          {props.files?.map((file, index) => (
            <Tr key={index}>
              <Td>{file.name}</Td>
              <Td textAlign="right">
                <Button variant="tertiary" onClick={() => props.onClick(file)}>
                  Delete
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
