import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Container, Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap';
import GenericErrorModal from '../../components/GenericErrorModal/GenericErrorModal';
import SystemOutageModal from '../../components/SystemOutageModal/SystemOutageModal';
import { getURL } from '../../helper/getAPIUrl';
import { logoutURL } from '../../helper/getLogoutUrl';
import GenericErrorModalProps from '../GenericErrorModal/__mocks__/default.json';
import AuthenticationModal from '../LandingPage/AuthenticationModal/AuthenticationModal';
import AuthenticationModalProps from '../LandingPage/AuthenticationModal/__mocks__/default.json';
import SystemOutageModalProps from '../SystemOutageModal/__mocks__/default.json';
import content from '../content/content/Privacy.json';

const PrivacyModal = (prop) => {
  const [logoutLink, setlogoutLink] = useState('');
  const [showModal, setShowModal] = useState(true);
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    const getURL = logoutURL();
    setlogoutLink(getURL);
  }, []);

  const updatePrivacyHandler = (e) => {
    const objPhone = [];
    if (prop.profileData.phone[0]) {
      objPhone.push({
        IsPrimary: prop.profileData.phone[0].isPrimary,
        Type: prop.profileData.phone[0].type,
        Number: prop.profileData.phone[0].number,
      });
    }
    if (prop.profileData.phone[1]) {
      objPhone.push({
        IsPrimary: prop.profileData.phone[1].isPrimary,
        Type: prop.profileData.phone[1].type,
        Number: prop.profileData.phone[1].number,
      });
    }
    const param = { phone: objPhone, privacyFlag: true };
    let URL = process.env.REACT_APP_GET_USER_PROFILE_DETAILS;
    URL = getURL(URL);
    axios
      .put(URL, param, {
        withCredentials: true,
      })
      .then(() => {
        setShowModal(false);
        prop.onPrivacyFlagUpdated(true);
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          setAPIError(error);
          if (error.response.status === 403 || error.response.status === 401) {
            setShowErrorModal(true);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setErrorModal(true);
            //alert(appConstants.SR_GENERIC_ERROR);
          }
        }
      });
  };
  return (
    <>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}

      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}

      {showErrorModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
        />
      )}

      <Modal show={showModal} dialogClassName="mh-75 modal-70w" contentClassName="p-6">
        <ModalHeader className="border-0 p-0">
          <h1>Privacy notice</h1>
        </ModalHeader>
        <ModalBody className="fw-light bg-defencelightgrey text-defencedarkgrey">
          <Container
            // className="landingPageWidth"
            dangerouslySetInnerHTML={{
              __html: content.content,
            }}
          />
        </ModalBody>
        <ModalFooter className="mt-4 p-0 justify-content-between border-0">
          <Button variant="standard" href={logoutLink}>
            Reject and logout
          </Button>
          <Button onClick={updatePrivacyHandler}>Accept and continue</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PrivacyModal;
