import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { Spinner } from '../../components/ServiceUI/Core/Spinner/Spinner';
import { ViewPIR } from '../../components/ServiceUI/EFT/ViewPIR/ViewPIR';
import { appConstants } from '../../helper/client/constant';
import { getURL } from '../../helper/getAPIUrl';
import content from './Content/ReviewRequest.json';

import { PIRCaseAudit } from './API/PIRCaseAudit';
//error handling
import GenericErrorModal from '../../components/GenericErrorModal/GenericErrorModal';
import GenericErrorModalProps from '../../components/GenericErrorModal/__mocks__/default.json';
import AuthenticationModal from '../../components/LandingPage/AuthenticationModal/AuthenticationModal';
import AuthenticationModalProps from '../../components/LandingPage/AuthenticationModal/__mocks__/default.json';
import ErrorPopUp from '../../components/MyServiceRequest/CreateRequest/ErrorPopup/ErrorPopup';
import InfoBox from '../../components/ServiceUI/Core/InfoBox/InfoBox';
import { ReviewARequest } from '../../components/ServiceUI/Core/icons/Outline';
import SystemOutageModal from '../../components/SystemOutageModal/SystemOutageModal';
import SystemOutageModalProps from '../../components/SystemOutageModal/__mocks__/default.json';

export const NewRequestReviewPage: React.FC = () => {
  //states
  const [showSpinnerApiIsInProgress, setShowSpinnerApiIsInProgress] = useState(false);
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalHttpCode, setShowModalHttpCode] = useState('');
  const [error, setError] = useState();
  const [pirInfo, setPIRInfo] = React.useState();
  const [attachmentInfo, setAttachmentInfo] = useState([]);
  const [showError, setShowError] = useState(false);

  //variables
  const { id } = useParams();
  let location = useLocation();
  const navigate = useNavigate();
  const caseID = location.state[0].caseId.caseId; // This will be used later

  //API calls
  useEffect(() => {
    if (caseID) {
      setShowSpinnerApiIsInProgress(true);
      let URL = process.env.REACT_APP_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL + caseID;
      URL = getURL(URL);
      axios
        .get(URL, {
          withCredentials: true,
        })
        .then((res) => {
          setPIRInfo(res.data.content.PersonalInfoRequest);
          setShowSpinnerApiIsInProgress(false);
        })
        .catch((err) => {
          setShowSpinnerApiIsInProgress(false);
          let error = { ...err };
          error.response = error.response || { response: { data: [] } };
          if (error.response.status === 400) {
            setShowError(true);

            setError(error.response.data);
          } else if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
            setShowModalHttpCode(error.response.status);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setAPIError(error);
            setErrorModal(true);
          }
        });
      //Attachments API call
      let newURL = process.env.REACT_APP_GET_ATTACHMENT_ALL + caseID + '/attachments';
      newURL = getURL(newURL);
      axios
        .get(newURL, {
          withCredentials: true,
        })
        .then((res) => {
          const finalArr = res.data.attachments.filter(
            (file) =>
              file.category !== appConstants.ATTACHMENT_TYPE_CORRESPONDENCE &&
              file.category !== appConstants.ATTACHMENTS_FILE_CATEGORY,
          );
          setAttachmentInfo(finalArr);
          setShowSpinnerApiIsInProgress(false);
        })
        .catch((err) => {
          setShowSpinnerApiIsInProgress(false);
          let error = { ...err };
          error.response = error.response || { response: { data: [] } };
          if (error.response.status === 400) {
            setShowError(true);
            setError(error.response.data);
          } else if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
            setShowModalHttpCode(error.response.status);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setAPIError(error);
            setErrorModal(true);
          }
        });
    }
  }, []);

  const editRequestHandler = () => {
    const URL_EDIT_SELECT = process.env.REACT_APP_EDIT_SELECT + caseID;
    const final_URL_EDIT_SELECT = getURL(URL_EDIT_SELECT);
    axios
      .put(
        final_URL_EDIT_SELECT,
        {},
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        if (id) {
          const URL_EDIT_FORWARDS =
            process.env.REACT_APP_EDIT_FORWARDS +
            id +
            (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV
              ? '?actionID=ReviewPIRInformation'
              : '/' + 'ReviewPIRInformation');
          const final_URL_EDIT_FORWARDS = getURL(URL_EDIT_FORWARDS);
          axios
            .post(
              final_URL_EDIT_FORWARDS,
              {},
              {
                withCredentials: true,
              },
            )
            .then((res) => {
              const path = `/personal-information-request/new/${caseID}?cameFrom=editReq`;
              navigate(path);
            })
            .catch((err) => {
              let error = { ...err };
              error.response = error.response || { response: { data: [] } };
              setAPIError(error);

              if (error.response.status === 403 || error.response.status === 401) {
                setShowModal(true);
              } else if (error.response.status === 500) {
                setServiceDownModal(true);
              } else {
                setErrorModal(true);
              }
            });
        }
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);

        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };
  //Call to update status after user submits the request
  const submitPIRHandler = () => {
    setShowSpinnerApiIsInProgress(true);
    const data = {
      content: {},
      pageInstructions: [],
    };
    if (id) {
      let URL =
        process.env.REACT_APP_REVIEW_SERVICE_REQUEST_DETAILS +
        id +
        (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV
          ? '?actionID=' + 'ReviewPIRInformation'
          : '/' + 'ReviewPIRInformation');
      URL = getURL(URL);
      axios
        .post(URL, data, {
          withCredentials: true,
        })
        .then((res) => {
          const URL_GET_DATA_FOR_SR = process.env.REACT_APP_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL + caseID;
          const final_URL_GET_DATA_FOR_SR = getURL(URL_GET_DATA_FOR_SR);

          axios
            .get(final_URL_GET_DATA_FOR_SR, {
              withCredentials: true,
            })
            .then((res) => {
              setShowSpinnerApiIsInProgress(false);
              if (
                process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PROD_ENV ||
                process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PRE_PROD_ENV
              ) {
                if (res.data.content?.pyErrorCode) {
                  setErrorModal(true);
                } else {
                  navigate(`/personal-information-request/complete/${caseID.split(' P-')[1]}`, {
                    state: [res.data],
                  });
                }
              } else {
                navigate(`/personal-information-request/complete/${caseID.split(' P-')[1]}`, {
                  state: [res.data],
                });
              }
              PIRCaseAudit(caseID, '', 'PIR01')
                .then((_) => {})
                .catch((error) => {
                  if (error.response) {
                    setAPIError(error);
                    if (error.response.status === 403 || error.response.status === 401) {
                      setShowModal(true);
                    } else if (error.response.status === 500) {
                      setServiceDownModal(true);
                    } else {
                      setErrorModal(true);
                    }
                  }
                });
            })
            .catch((err) => {
              let error = { ...err };
              error.response = error.response || { response: { data: [] } };
              setAPIError(error);
              if (error.response.status === 403 || error.response.status === 401) {
                setShowModal(true);
                setShowModalHttpCode(error.response.status);
              } else if (error.response.status === 500) {
                setServiceDownModal(true);
              } else {
                setErrorModal(true);
              }
            });
        })
        .catch((error) => {
          setShowSpinnerApiIsInProgress(false);
          if (error.response) {
            if (error.response.status === 400) {
              setShowError(true);

              setError(error.response.data);
            }
            setAPIError(error);

            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
              setShowModalHttpCode(error.response.status);
            } else if (error.response.status === 500) {
              setServiceDownModal(true);
            } else {
              setErrorModal(true);
              // alert("Something went wrong! Please try again later.");
            }
          }
        });
    }
  };
  return (
    <>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}

      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}

      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
          descriptionNoPrivilage={AuthenticationModalProps.descriptionWithNoPrivilage}
          code={showModalHttpCode}
        />
      )}
      <ErrorPopUp show={showError} error={error} closeAction={() => setShowError(false)} />

      <Header {...content.header} />
      <SubContainer verticalMargin="1rem">
        <InfoBox>
          <ReviewARequest height="3rem" />
          <Heading mt="1rem" as="h2" fontSize="md" fontWeight="medium">
            {content.infobox.heading}
          </Heading>
          <Text fontWeight="light">{content.infobox.content}</Text>
          <Button mt="1rem" variant="secondary_white" onClick={() => editRequestHandler()}>
            Edit Request
          </Button>
        </InfoBox>
        <>{showSpinnerApiIsInProgress && <Spinner />}</>
        <ViewPIR PIR={pirInfo} Attachments={attachmentInfo} />

        <Flex justifyContent="space-between" flexDir={{ lg: 'row', base: 'column' }} gap={3} mb="3rem">
          <Flex gap={3} flexDir={{ lg: 'row', base: 'column' }}>
            <Button variant="tertiary" onClick={() => navigate('/personal-information-request')}>
              Cancel
            </Button>
          </Flex>
          <Button variant="primary" onClick={() => submitPIRHandler()}>
            Submit
          </Button>
        </Flex>
      </SubContainer>
    </>
  );
};
