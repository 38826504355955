import { configureStore } from '@reduxjs/toolkit';
import eftRequestDetailFormReducer from './reducers/eftRequestDetailFormReducer';
import errorHandling from './reducers/errorHandling';
import requestDetailFormReducer from './reducers/requestDetailFormReducer';

export const store = configureStore({
  reducer: {
    requestForm: requestDetailFormReducer,
    EFTRequestMemberForm: eftRequestDetailFormReducer,
    error: errorHandling,
  },
});
