import React from 'react';
import { Select, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { FormElement, Option, SelectProps } from '../FormElementType';
import { PrimitiveControl } from '../PrimitiveControl';

const FormSelectInput: React.FC<FormElement & SelectProps> = (props: FormElement & SelectProps) => {
  return (
    <>
      {props.visible && (
        <PrimitiveControl
          disabled={props.disabled}
          isInvalid={props.isInvalid}
          required={props.required}
          desktopWidth={props.desktopWidth}
          mobileWidth={props.mobileWidth}
        >
          <FormLabel variant="primary">{props.label}</FormLabel>
          <Select
            placeholder="Please select an option"
            variant="primary"
            data-testid="select"
            name={props.reference}
            onChange={(e) => props.onSelect(e.target.value)}
            defaultValue="default"
            isInvalid={props.isInvalid}
          >
            {props.options?.map((item: Option) => (
              <option key={item.key} value={item.value}>
                {item.value}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{props.errorMessage}</FormErrorMessage>
        </PrimitiveControl>
      )}
    </>
  );
};

export default FormSelectInput;
