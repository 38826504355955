import React from 'react';
import { Container } from 'react-bootstrap';
import './Header.scss';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

const Header = (props) => {
  return (
    <Container
      fluid
      className={`py-lg-2 text-white ${
        props.servicerequest ? 'bg-defencenavy' : 'bg-defenceorange'
      } servicebackgroundImg`}
      data-testid="header"
    >
      <Container className="p-4 p-lg-6 landingPageWidth">
        {props.breadcrumbs === true && <Breadcrumbs breadcrumbItems={props.breadcrumbItems} />}
        <h1 className={props.breadcrumbs === false && 'mainHeading'}>{props.mainHeading}</h1>
        {props.subHeading && (
          <p
            className="mt-2 fw-light w-lg-75"
            dangerouslySetInnerHTML={{
              __html: props.subHeading,
            }}
          />
        )}
      </Container>
    </Container>
  );
};

export default Header;
