import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { findByProp } from '../../../../helper/findByProp';
import { setFieldValue, setReturnLeg, setDateValidation } from '../../../../reducers/requestDetailFormReducer';

const FormCheckbox = ({ fieldID, control, required, fieldPropMapping, visible, reference, value, formData, label }) => {
  const dispatch = useDispatch();

  // const [checkedValue, setCheckedValue] = useState("");

  const reqDetailDataFromReduxStore = useSelector((state) => {
    if (!!state) {
      return findByProp(state.requestForm.formFields, 'reference', reference);
    }
  });
  const [displayValue, setDisplayValue] = useState('');

  const onCheckboxChange = ({ target }) => {
    dispatch(
      setDateValidation({
        fieldToUpdate: 'ServiceRequest.ReturnDateValidation',
        isVisible: target.checked,
      }),
    );
    setDisplayValue(target.checked);
    dispatch(
      setFieldValue({
        reference,
        value: target.checked,
        formData,
        required,
        visible,
      }),
    );
  };

  useEffect(() => {
    if (displayValue !== '') {
      switch (fieldID) {
        case 'IsReturnLegRequired':
          dispatch(setReturnLeg({ fieldID, value: displayValue }));
          break;
        default:
          break;
      }
    }
  }, [displayValue, formData.fieldGroup.length]);

  useEffect(() => {
    setDisplayValue(
      (prevState) =>
        (prevState =
          reqDetailDataFromReduxStore?.value === '' ||
          reqDetailDataFromReduxStore?.value === 'false' ||
          reqDetailDataFromReduxStore?.value === false
            ? false
            : true),
    );
  }, [reqDetailDataFromReduxStore]);

  return (
    <>
      {visible && (
        <Col
          lg={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop ?? 12}
          sm={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile ?? 12}
          className="mb-4 pe-4"
        >
          <Form.Check type="checkbox" id={reference} name={reference}>
            <Form.Check.Input
              className="me-3"
              onChange={(e) => onCheckboxChange(e)}
              required={required}
              type="checkbox"
              checked={displayValue ? displayValue : ''}
            />
            <Form.Label
              className={required ? 'required-field' : ''}
              dangerouslySetInnerHTML={label !== 'Checkbox' ? { __html: label } : { __html: control.label }}
            />
            <Form.Control.Feedback type="invalid">
              {fieldPropMapping[fieldID]?.required?.errorMessage}
            </Form.Control.Feedback>
          </Form.Check>
        </Col>
      )}
    </>
  );
};

export default FormCheckbox;
