import React from 'react';
import {
  AccordionItem,
  AccordionButton,
  Box,
  AccordionItemProps as ChakraAccordionItemProps,
  Text,
  AccordionPanel,
} from '@chakra-ui/react';
import { Chevron_Orange as ChevronOrange } from '../icons/Outline';

export interface ItemProps extends ChakraAccordionItemProps {
  title: string;
  children: React.ReactElement | React.ReactElement[];
}

export const GenericAccordionItem: React.FC<ItemProps> = (props: ItemProps): JSX.Element => {
  return (
    <AccordionItem data-testid="accordion-item" border="none" bg="white" marginBottom="1rem">
      {({ isExpanded }) => (
        <>
          <AccordionButton p="0.5rem" alignItems="center">
            <Text as="h3" flex="1" fontWeight="500" textAlign="left" display="flex" my="1rem">
              <Box as="span" flex="1" textAlign="left">
                <Text as="span" flex="1" textColor="#000" fontWeight="500" fontSize="sm" textAlign="left">
                  {props.title}
                </Text>
              </Box>
              {isExpanded ? (
                <ChevronOrange style={{ height: '1.125rem', rotate: '180deg' }} />
              ) : (
                <ChevronOrange style={{ height: '1.125rem' }} />
              )}
            </Text>
          </AccordionButton>
          <AccordionPanel px="0.5rem" pb="1rem">
            {props.children}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};
