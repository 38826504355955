import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Image, Modal, Row } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Select from 'react-select';
import GenericErrorModal from '../../components/GenericErrorModal/GenericErrorModal';
import SystemOutageModal from '../../components/SystemOutageModal/SystemOutageModal';
import { appConstants } from '../../helper/client/constant';
import { getURL } from '../../helper/getAPIUrl';
import switchboard from '../../images/switchBoard.svg';
import GenericErrorModalProps from '../GenericErrorModal/__mocks__/default.json';
import AuthenticationModal from '../LandingPage/AuthenticationModal/AuthenticationModal';
import AuthenticationModalProps from '../LandingPage/AuthenticationModal/__mocks__/default.json';
import SystemOutageModalProps from '../SystemOutageModal/__mocks__/default.json';
import './LocationPreviewModal.scss';

const LocationPreviewModal = (props) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      This could be your primary working location, place of residence, and/or the Defence site you visit most
      frequently. When you create forms or request services, ServiceConnect will pre-fill with your nominated home base
      location. If your preferred home base is not listed below, please send this feedback to
      yourcustomer.service@defence.gov.au, and select the Defence base or location nearest to you in the meantime.
    </Tooltip>
  );
  const renderWorkingLocationTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      If your preferred working location is not appearing in the list below, please send this feedback to
      yourcustomer.service@defence.gov.au, and select the Defence base or location nearest to you in the meantime.
    </Tooltip>
  );
  const renderAlternateLocationTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      If your preferred alternate base is not appearing in the list below, please send this feedback to
      yourcustomer.service@defence.gov.au, and select the Defence base or location nearest to you in the meantime.
    </Tooltip>
  );
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [show, setShow] = useState(true);
  const [showchangeDropDown, setchangeDropDown] = useState(false);
  const [showLocationDropDown, setLocationDropDown] = useState(false);
  const [showAlternateDropDown, setAlternateDropDown] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const setField = (field, value) => {
    setForm({ ...form, [field]: value });

    if (!!errors[field]) {
      setErrors({ ...errors, [field]: null });
    }
  };

  const addWorkLocationClickHandler = () => {
    setLocationDropDown(true);
  };

  const addAlternateLocationClickHandler = () => {
    setAlternateDropDown(true);
  };

  const changeClickHandler = () => {
    setchangeDropDown(true);
  };

  const [dropDownLocationBaseName, setDropDownLocationBaseName] = useState({
    dropDownArrayBaseName: [],
    selectedValue: appConstants.PROFILE_DEFAULT_BASE,
    selectedAddress: 'Northcott Dr, Campbell ACT 2600',
  });

  const [dropDownOtherBaseName, setdropDownOtherBaseName] = useState({
    dropDownArrayOtherBaseName: [],
    selectedWorkingLocation: appConstants.PROFILE_LOCSELECT,
    selectedAlternateBase: appConstants.PROFILE_LOCSELECT,
    selectedWorkingLocationAddress: '',
    selectedAlternateBaseAddress: '',
    startdatealternatelocation: new Date(),
    enddatealternatelocation: new Date(),
  });

  const [dropDownWorkingLocName, setdropDownWorkingLocName] = useState({
    dropDownArrayWorkingLocName: [],
    selectedWorkingLocation: '',
  });
  const [dropDownAlternateLocName, setdropDownAlternateLocName] = useState({
    dropDownArrayAlternateLocName: [],
    selectedAlternateBase: appConstants.PROFILE_LOCSELECT,
  });

  const getBaseNameDropDown = () => {
    let URL = process.env.REACT_APP_GET_BASE_DROPDOWN_PROFILE_SETUP;
    URL = getURL(URL);
    axios
      .get(URL, {
        withCredentials: true,
      })
      .then((res) => {
        const locDetailsBase = [{ name: 'Select' }].concat(res.data.locDetails);
        //const locDetailsBase = res.data.locDetails;
        setdropDownOtherBaseName((prevState) => {
          return {
            ...prevState,
            dropDownArrayOtherBaseName: locDetailsBase,
            //selectedValue: "Campbell",
          };
        });

        setDropDownLocationBaseName((prevState) => {
          return {
            ...prevState,
            dropDownArrayBaseName: res.data.locDetails,
          };
        });

        if (props.alternateBaseName !== '') {
          addAlternateLocationClickHandler();

          form.alternateBaseLocation = props.alternateBaseAddress;

          form.startdatealternatelocation = props.alternateBaseStart;
          form.enddatealternatelocation = props.alternateBaseEnd;

          setdropDownAlternateLocName((prevState) => {
            return {
              ...prevState,
              dropDownArrayAlternateLocName: locDetailsBase,
              selectedAlternateBase: props.alternateBaseName,
              startdatealternatelocation: props.alternateBaseStart,
              enddatealternatelocation: props.alternateBaseEnd,
            };
          });
        } else {
          setdropDownAlternateLocName((prevState) => {
            return {
              ...prevState,
              dropDownArrayAlternateLocName: locDetailsBase,
              selectedAlternateBase: appConstants.PROFILE_LOCSELECT,
            };
          });
        }
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };

  const getWorkingLocDropDown = () => {
    let URL = process.env.REACT_APP_GET_WORKLOC_DROPDOWN_PROFILE_SETUP;
    URL = getURL(URL);
    axios
      .get(URL, {
        withCredentials: true,
      })
      .then((res) => {
        const locDetailsBase = [{ name: 'Select' }].concat(res.data.locDetails);
        if (props.workBaseName !== '') {
          addWorkLocationClickHandler();
          setdropDownWorkingLocName((prevState) => {
            return {
              ...prevState,
              dropDownArrayWorkingLocName: locDetailsBase,
              selectedWorkingLocation: props.workBaseName,
            };
          });
        } else {
          setdropDownWorkingLocName((prevState) => {
            return {
              ...prevState,
              dropDownArrayWorkingLocName: locDetailsBase,
              selectedWorkingLocation: appConstants.PROFILE_LOCSELECT,
            };
          });
        }
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };
  const validateForm = () => {
    const { alternateBaseLocation, startdatealternatelocation, enddatealternatelocation } = form;

    const newErrors = {};

    if (!alternateBaseLocation || alternateBaseLocation === appConstants.PROFILE_LOCSELECT) {
      newErrors.alternateBaseLocation = appConstants.PROFILE_ERROR_ALT_BASE;
    }

    if (alternateBaseLocation !== appConstants.PROFILE_LOCSELECT) {
      let startDate = new Date(startdatealternatelocation);

      let endDate = new Date(enddatealternatelocation);

      if (!startdatealternatelocation || startdatealternatelocation === '') {
        newErrors.startdatealternatelocation = appConstants.PROFILE_ERROR_START_DATE;
      }
      if (!enddatealternatelocation || enddatealternatelocation === '' || endDate < startDate) {
        newErrors.enddatealternatelocation = appConstants.PROFILE_ERROR_END_DATE;
      }
    }
    return newErrors;
  };

  const goToServiceConnectHomePage = () => {
    let path = `/`;
    //navigate(path);
  };
  const updateLocationHandler = (e) => {
    e.preventDefault();

    const createError = validateForm();
    if (Object.keys(createError).length > 0) {
      setErrors(createError);
    } else {
    }

    if (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV) {
      let queryString = '?homebasename=' + encodeURIComponent(dropDownLocationBaseName.selectedValue);
      if (
        dropDownAlternateLocName.selectedAlternateBase &&
        dropDownAlternateLocName.selectedAlternateBase !== appConstants.PROFILE_LOCSELECT
      ) {
        queryString =
          queryString +
          '&alternatebasename=' +
          encodeURIComponent(dropDownAlternateLocName.selectedAlternateBase) +
          '&startdate=' +
          form.startdatealternatelocation +
          '&enddate=' +
          form.enddatealternatelocation;
      }
      if (
        dropDownWorkingLocName.selectedWorkingLocation &&
        dropDownWorkingLocName.selectedWorkingLocation !== appConstants.PROFILE_LOCSELECT
      ) {
        queryString =
          queryString + '&workinglocationname=' + encodeURIComponent(dropDownWorkingLocName.selectedWorkingLocation);
      }
      const URL_CONFIRM_USER_PROFILE_LOCATION_DETAILS =
        process.env.REACT_APP_CONFIRM_USER_PROFILE_LOCATION_DETAILS + queryString;
      const final_URL_CONFIRM_USER_PROFILE_LOCATION_DETAILS = getURL(URL_CONFIRM_USER_PROFILE_LOCATION_DETAILS);
      axios
        .post(
          final_URL_CONFIRM_USER_PROFILE_LOCATION_DETAILS,
          {},
          {
            withCredentials: true,
          },
        )
        .then((res) => {
          if (res.status === 200) {
            goToServiceConnectHomePage();
            setShow(false);
            props.onComingtoHomePageFromUpdate();
          }
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            setAPIError(error);
            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
            } else if (error.response.status === 500) {
              setServiceDownModal(true);
            } else {
              setErrorModal(true);
              //alert(appConstants.SR_GENERIC_ERROR);
            }
          }
        });
    } else {
      const finalAlternateValue =
        dropDownAlternateLocName.selectedAlternateBase === appConstants.PROFILE_LOCSELECT
          ? ''
          : dropDownAlternateLocName.selectedAlternateBase;
      const finalWorkingLocation =
        dropDownWorkingLocName.selectedWorkingLocation === appConstants.PROFILE_LOCSELECT
          ? ''
          : dropDownWorkingLocName.selectedWorkingLocation;
      const finalStartdatealternatelocation = !!form.startdatealternatelocation ? form.startdatealternatelocation : '';

      const finalEnddatealternatelocation = !!form.enddatealternatelocation ? form.enddatealternatelocation : '';
      const URL_CONFIRM_USER_PROFILE_LOCATION_DETAILS =
        process.env.REACT_APP_CONFIRM_USER_PROFILE_LOCATION_DETAILS +
        encodeURIComponent(dropDownLocationBaseName.selectedValue) +
        '/' +
        encodeURIComponent(finalAlternateValue) +
        '/' +
        finalStartdatealternatelocation +
        '/' +
        finalEnddatealternatelocation +
        '/' +
        encodeURIComponent(finalWorkingLocation);
      console.log(URL_CONFIRM_USER_PROFILE_LOCATION_DETAILS);

      const final_URL_CONFIRM_USER_PROFILE_LOCATION_DETAILS = getURL(URL_CONFIRM_USER_PROFILE_LOCATION_DETAILS);

      axios
        .post(
          final_URL_CONFIRM_USER_PROFILE_LOCATION_DETAILS,
          {},
          {
            withCredentials: true,
          },
        )
        .then((res) => {
          goToServiceConnectHomePage();
          setShow(false);
          props.onComingtoHomePageFromUpdate();
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            setAPIError(error);
            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
            } else if (error.response.status === 500) {
              setServiceDownModal(true);
            } else {
              setErrorModal(true);
              //alert(appConstants.SR_GENERIC_ERROR);
            }
          }
        });
    }
  };

  const loadLocationDropdowns = () => {
    getBaseNameDropDown();
    getWorkingLocDropDown();
    if (props.baseName !== '') {
      setDropDownLocationBaseName((prevState) => {
        return {
          ...prevState,
          selectedValue: props.baseName,
          selectedAddress: props.baseAddress,
        };
      });
    } else {
      setDropDownLocationBaseName((prevState) => {
        return {
          ...prevState,
          selectedValue: appConstants.PROFILE_DEFAULT_BASE,
        };
      });
    }
  };

  const handleBaseSelect = (text, selectedBaseNameObj) => {
    setDropDownLocationBaseName((prevState) => {
      return {
        ...prevState,
        selectedValue: text,
        selectedAddress: selectedBaseNameObj.baseAddress,
      };
    });
  };
  const handleWorkingLocationSelect = (text, selectedBaseNameObj) => {
    setdropDownWorkingLocName((prevState) => {
      return {
        ...prevState,
        selectedWorkingLocation: text,
      };
    });
  };

  const handleAlternateBaseSelect = (text, selectedBaseNameObj) => {
    form.alternateBaseLocation = text;

    setdropDownAlternateLocName((prevState) => {
      return {
        ...prevState,
        selectedAlternateBase: text,
      };
    });
    if (text === 'Select') {
      form.startdatealternatelocation = '';
      form.enddatealternatelocation = '';
    }
    const createError = validateForm();
    if (Object.keys(createError).length > 0) {
      setErrors(createError);
    }
  };

  useEffect(() => {
    loadLocationDropdowns();
  }, []);

  return (
    <>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}

      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}

      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
        />
      )}

      <Modal
        show={show}
        backdrop="static"
        animation={false}
        dialogClassName="modal-lg modalPadding px-lg-0 py-4"
        contentClassName="p-lg-4 modal-75w"
      >
        <Modal.Title className="px-lg-5 pt-lg-5 pt-4 px-3 locationmodalTitle">{props.title}</Modal.Title>

        <Modal.Body className="px-lg-5 pt-lg-0">
          <p className="pb-lg-3">{props.introDescription}</p>
          <Container className="p-lg-4 p-3 bg-defencemediumlightblue rounded-2">
            <Image className="pb-4 switchBoardIcon" src={switchboard} alt="" />
            <h3 className="subTitleText">{props.reviewLocationTitle}</h3>
            <p className="smallText">{props.description}</p>
            <Container className="pb-3">
              {/* <h5 className="mb-0">
              {props.homeBaseTitle} <InfoCircle size={20} />
            </h5> */}
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                <span>
                  {props.homeBaseTitle}
                  &nbsp;&nbsp;&nbsp;
                  <InfoCircle size={15} />
                </span>
              </OverlayTrigger>

              <p className="pe-lg-3 smallText">{props.homeBaseDescription}</p>

              <Container className="p-3 bg-defencecharcoalgrey rounded-2">
                <Col className="d-flex desktopflex mobilelocationflex">
                  <Container>
                    <h5 className="mb-0 text-white">{dropDownLocationBaseName.selectedValue}</h5>
                    <p className="mb-0 text-white smallText">{dropDownLocationBaseName.selectedAddress}</p>
                  </Container>

                  <Button
                    data-testid="changeLocation"
                    variant="standard bg-white"
                    className="smallText buttonWidthLocation mobileButton"
                    onClick={changeClickHandler}
                  >
                    {props.homeBaseCtaText}
                  </Button>
                </Col>

                {showchangeDropDown && (
                  <Container>
                    <Form.Group>
                      <Form.Label className="text-white profile-other-info-text">
                        Home base address
                        <span className="required-field"></span>
                      </Form.Label>
                      <br></br>
                      <div style={{ display: 'inline-block', width: '100%' }}>
                        <Select
                          style={{
                            borderColor: 'gray',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                          }}
                          value={[
                            {
                              label: dropDownLocationBaseName.selectedValue,
                              value: dropDownLocationBaseName.selectedValue,
                            },
                          ]}
                          onChange={(selection, action) => {
                            handleBaseSelect(selection.label, selection.value);
                          }}
                          options={dropDownLocationBaseName.dropDownArrayBaseName.map((key, text) => {
                            return {
                              value: key,
                              label: key.name,
                            };
                          })}
                        />
                      </div>
                    </Form.Group>
                  </Container>
                )}
              </Container>
            </Container>

            <Container className="pb-1">
              <Col className="d-flex desktopflex mobilelocationflex pe-lg-3">
                <Container>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderWorkingLocationTooltip}
                  >
                    <span>
                      {props.workingLocationtitle}
                      &nbsp;&nbsp;&nbsp;
                      <InfoCircle size={15} />
                    </span>
                  </OverlayTrigger>
                  <p className="pe-lg-3 smallText">{props.workingLocationdescription}</p>
                </Container>
                {(dropDownWorkingLocName.selectedWorkingLocation.length === 0 ||
                  dropDownWorkingLocName.selectedWorkingLocation === appConstants.PROFILE_LOCSELECT) && (
                  <Button
                    data-testid="addLocation"
                    variant="standard bg-white"
                    className="smallText buttonWidthLocation mobileButton"
                    onClick={addWorkLocationClickHandler}
                  >
                    {props.workingLocationctaText}
                  </Button>
                )}
              </Col>

              {showLocationDropDown && (
                <Container>
                  <Form.Group>
                    <Form.Label className="profile-other-info-text">Working location</Form.Label>
                    <Select
                      style={{
                        borderColor: 'gray',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                      }}
                      value={[
                        {
                          label: dropDownWorkingLocName.selectedWorkingLocation,
                          value: dropDownWorkingLocName.selectedWorkingLocation,
                        },
                      ]}
                      onChange={(selection, action) => {
                        handleWorkingLocationSelect(selection.label, selection.value);
                      }}
                      options={dropDownWorkingLocName.dropDownArrayWorkingLocName.map((key, text) => {
                        return {
                          value: key,
                          label: key.name,
                        };
                      })}
                    />
                  </Form.Group>
                </Container>
              )}
            </Container>
            <Container className="pb-1">
              <Col className="d-flex desktopflex mobilelocationflex pe-lg-3">
                <Container>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderAlternateLocationTooltip}
                  >
                    <span>
                      {props.alternateBasetitle}
                      &nbsp;&nbsp;&nbsp;
                      <InfoCircle size={15} />
                    </span>
                  </OverlayTrigger>
                  <p className="pe-lg-3 smallText">{props.alternateBasedescription}</p>
                </Container>
                {(dropDownAlternateLocName.selectedAlternateBase.length === 0 ||
                  dropDownAlternateLocName.selectedAlternateBase === appConstants.PROFILE_LOCSELECT) && (
                  <Button
                    data-testid="addLocation"
                    variant="standard bg-white"
                    className="smallText buttonWidthLocation mobileButton"
                    onClick={addAlternateLocationClickHandler}
                  >
                    {props.alternateBasectaText}
                  </Button>
                )}
              </Col>

              {showAlternateDropDown && (
                <>
                  <Form.Group>
                    <Form.Label className="profile-other-info-text">Alternate Base</Form.Label>
                    <br></br>
                    <div style={{ display: 'inline-block', width: '100%' }}>
                      <Select
                        style={{
                          borderColor: 'gray',
                          borderWidth: '1px',
                          borderStyle: 'solid',
                        }}
                        value={[
                          {
                            label: dropDownAlternateLocName.selectedAlternateBase,
                            value: dropDownAlternateLocName.selectedAlternateBase,
                          },
                        ]}
                        onChange={(selection, action) => {
                          handleAlternateBaseSelect(selection.label, selection.value);
                        }}
                        options={dropDownAlternateLocName.dropDownArrayAlternateLocName.map((key, text) => {
                          return {
                            value: key,
                            label: key.name,
                          };
                        })}
                      />
                    </div>
                    <div className="dropdown-error-msg">{errors.alternateBaseLocation}</div>
                  </Form.Group>
                  <Row>
                    <Col className="mobilelocationflex">
                      <Row>
                        <Col>
                          <Container className="pe-lg-2">
                            <Form.Group>
                              <Form.Label className="profile-other-info-text">Date From</Form.Label>
                              <Form.Control
                                type="date"
                                placeholder=""
                                name="startdatealternatelocation"
                                /* value={
                              dropDownOtherBaseName.startdatealternatelocation
                            } */
                                value={form.startdatealternatelocation}
                                onChange={(e) => setField('startdatealternatelocation', e.target.value)}
                                isInvalid={!!errors.startdatealternatelocation}
                                //onChange={(e) => handledatechange(e.target.value)}
                                //isInvalid={!!createProfileErrors.startdatealternatelocation}
                              />
                              <Form.Control.Feedback type="invalid" className="validation-error">
                                {errors.startdatealternatelocation}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Container>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label className="profile-other-info-text">Date To</Form.Label>
                            <Form.Control
                              type="date"
                              placeholder=""
                              name="enddatealternatelocation"
                              // value={
                              //   dropDownOtherBaseName.enddatealternatelocation
                              // }
                              /*  onChange={(e) =>
                              handleenddatechange(e.target.value)
                            } */
                              value={form.enddatealternatelocation}
                              onChange={(e) => setField('enddatealternatelocation', e.target.value)}
                              isInvalid={!!errors.enddatealternatelocation}
                              //isInvalid={!!createProfileErrors.enddatealternatelocation}
                            />
                            <Form.Control.Feedback type="invalid" className="validation-error">
                              {errors.enddatealternatelocation}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      {/* <Container className="pe-lg-2">
                      <Form.Group>
                        <Form.Label className="smallText">Date From</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder=""
                          name="startdatealternatelocation"
                          value=""
                          onChange=""
                        />
                      </Form.Group>
                    </Container>
                    <Container className="ps-lg-2">
                      <Form.Group>
                        <Form.Label className="smallText">Date To</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder=""
                          name="enddatealternatelocation"
                          value=""
                          onChange=""
                        />
                      </Form.Group>
                    </Container> */}
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          </Container>
        </Modal.Body>

        <Container className="px-lg-5 p-lg-4 p-4">
          <Button
            data-testid="continueLocation"
            className="float-end continueButton mobileButton"
            variant="primary"
            onClick={updateLocationHandler}
          >
            Continue
          </Button>
        </Container>
      </Modal>
    </>
  );
};

export default LocationPreviewModal;
