import { Button, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { appConstants } from '../../../../helper/client/constant';
import convertSpecialCharacters from '../../../../helper/convertSpecialChars';
import { setFieldValue } from '../../../../reducers/eftRequestDetailFormReducer';
import { PIRCaseAudit } from '../../../../pages/EFT/API/PIRCaseAudit';
import { useDispatch } from 'react-redux';
import { EFTConstants } from '../../../../pages/EFT/EFTConstant';
import { FileList } from '../../EFT/FileList/FileList';
import { SCForm } from '../FormElements';
import { getUploadInfo } from './getUploadInfo';
import { request } from '../../../../helper/useAxios';
import { setShowError } from '../../../../reducers/errorHandling';

const FormInputFileUpload = ({ caseIDOriginal, label, required, reference, formData, customAttributes }) => {
  const dispatch = useDispatch();
  const [uploadErrors, setUploadErrors] = useState({
    isInvalid: false,
    fileInfo: [],
  });
  const [uploadedFile, setUploadedFile] = useState<any>({ selectedFile: [] });
  const [uploadedFileInPega, setUploadedFileInPega] = useState({
    selectedFile: [],
  });
  const uploadInfo = getUploadInfo(customAttributes.attachmentCategory);
  const [allFiles, setAllFiles] = useState<any>([]);

  useEffect(() => {
    getAllSavedFiles();
  }, []);

  const deleteAttachment = (file) => {
    setAllFiles(allFiles.filter((item: any) => item.name === file.name));
    const fileFoundinSavedArray = uploadedFileInPega.selectedFile.filter((obj: any) => obj.name === file.name);
    //This runs when deleting on a saved request
    if (fileFoundinSavedArray.length > 0) {
      request('REACT_APP_DELETE_FILE', 'delete', { id: file.attachmentLinkID })
        .then((res) => {
          getAllSavedFiles();
          const el = document.getElementById(EFTConstants.PIR_FILE_UPLOAD_ID + reference) as HTMLInputElement;
          if (el) {
            el.value = '';
          }
        })
        .catch((error) => {
          dispatch(setShowError({ error: true, code: error.response.status }));
        });
    }
  };
  const removeAttachment = (file: File) => {
    setUploadedFile((prevState) => {
      return {
        ...prevState,
        selectedFile: uploadedFile.selectedFile.filter((stateFile: any) => stateFile.name !== file.name),
      };
    });
    const el = document.getElementById(`${EFTConstants.PIR_FILE_UPLOAD_ID}-${reference}`) as HTMLInputElement;
    if (el) {
      el.value = '';
    }
  };
  const getUpdatedFilesFromPega = (responseFromPega) => {
    const filteredArrayNonCorrespondese = responseFromPega.attachments.filter(
      (file) => file.category === customAttributes.attachmentCategory,
    );
    filteredArrayNonCorrespondese.forEach((element) => {
      caseAuditForEachFile(element);
    });

    const fileInfoArray = filteredArrayNonCorrespondese.map((file) => {
      return {
        name: file.name,
        attachmentLinkID: file.ID,
      };
    });

    dispatch(
      setFieldValue({
        reference,
        value: fileInfoArray.length > 0 ? 'contains file' : '',
        formData,
        required: false,
        visible: true,
        error: fileInfoArray.length === 0 ? true : false,
      }),
    );
    setUploadedFileInPega((prevState: any) => {
      return {
        ...prevState,
        selectedFile: [...fileInfoArray],
      };
    });
  };
  const getAllSavedFiles = () => {
    //This is used when the user is continuing a saved request
    request('REACT_APP_GET_ATTACHMENT_ALL', 'get', { id: caseIDOriginal + '/attachments' })
      .then((res) => {
        if (res.data.attachments) {
          getUpdatedFilesFromPega(res.data);
        } else {
          const noFiles = [];
          dispatch(
            setFieldValue({
              reference,
              value: noFiles.length > 0 ? 'contains file' : '',
              formData,
              required: false,
              visible: true,
              error: noFiles.length === 0 ? true : false,
            }),
          );
          setUploadedFileInPega((prevState) => {
            return {
              ...prevState,
              selectedFile: [],
            };
          });
        }
        setUploadedFile((prevState) => {
          return {
            ...prevState,
            selectedFile: [],
          };
        });
      })
      .catch((error) => {
        dispatch(setShowError({ error: true, code: error.response.status }));
      });
  };
  const caseAuditForEachFile = (file) => {
    PIRCaseAudit(caseIDOriginal, file.ID, 'PIR02')
      .then((_) => {})
      .catch((error) => {
        dispatch(setShowError({ error: true, code: error.response.status }));
      });
  };
  const apiCallsForUpload = (data, caseId) => {
    request('REACT_APP_API_FILE_UPLOAD', 'post', { data })
      .then((res) => {
        const attachmentData = uploadedFile.selectedFile.map((item: any) => {
          const obj = {
            type: appConstants.SR_ATTACHMENT_TYPE,
            category: customAttributes.attachmentCategory,
            name: item.name,
            ID: res.data.ID,
          };
          return obj;
        });
        const data = {
          attachments: attachmentData,
        };
        request('REACT_APP_API_FILE_UPLOAD_DOC_PART1', 'post', { id: caseId + '/attachments', data })
          .then(() => {
            getAllSavedFiles();
          })
          .catch((error) => {
            dispatch(setShowError({ error: true, code: error.response.status }));
          });
      })
      .catch((error) => {
        dispatch(setShowError({ error: true, code: error.response.status }));
      });
  };
  const resetErrorArray = () => {
    setUploadErrors({
      isInvalid: false,
      fileInfo: [],
    });
  };
  const addError = (fileName: string, errorMessage: string, reason: string) => {
    setUploadErrors((prevState: any) => {
      return {
        ...prevState,
        isInvalid: true,
        fileInfo: [
          {
            fileName,
            errorMessage,
            reason,
          },
        ],
      };
    });
  };
  const checkFileValidity = (files: File[]) => {
    //Checking that files uploaded does not exceed maximum - if so only upload the first files up to the limit
    if (uploadInfo.maxFiles !== 0 && files.length > uploadInfo.maxFiles) {
      files = [...files.slice(0, uploadInfo.maxFiles)];
      addError('', EFTConstants.PIR_IDENTITY_TWO_FILES_MSG, EFTConstants.PIR_FILE_ERROR_TYPE_COUNT);
    }
    for (let index = 0; index < files.length; index++) {
      let fileToAdd = files[index];
      //Check for 1mb
      if (fileToAdd.size <= EFTConstants.PIR_FILE_SIZE_MAX_LIMIT_IN_BYTES) {
        setAllFiles([...allFiles, fileToAdd]);
        setUploadedFile((prevState: any) => {
          const newArray = [...prevState.selectedFile, files[index]];
          return {
            ...prevState,
            selectedFile: newArray,
          };
        });
      } else
        addError(fileToAdd.name, EFTConstants.PIR_END_USER_FILE_SIZE_ERROR, EFTConstants.PIR_FILE_ERROR_TYPE_COUNT);
    }
  };
  const onFileChange = async (event) => {
    //Clearing errors
    resetErrorArray();
    //Getting array of files from the event
    let filesToUpload: File[] = Object.values(event.target.files);
    //Sending the file array to the validation checker/uploader
    checkFileValidity(filesToUpload);
  };
  const onFileUpload = (event) => {
    // Create an object of formData
    const bodyFormData = new FormData();
    for (let item = 0; item < uploadedFile.selectedFile.length; item++) {
      bodyFormData.append(
        appConstants.SR_ATTACHMENT_FORM_DATA_KEY,
        uploadedFile.selectedFile[item],
        uploadedFile.selectedFile[item].name,
      );
    }
    uploadedFile.selectedFile.length + uploadedFileInPega.selectedFile.length <= uploadInfo.maxFiles &&
      apiCallsForUpload(bodyFormData, caseIDOriginal);
  };

  return (
    <>
      <SCForm.FilePicker
        tooltipContent={uploadInfo.tooltipContent}
        reference={reference}
        label={convertSpecialCharacters(label)}
        content={uploadInfo.caption}
        onClick={onFileChange}
        required={required}
        multiple={uploadInfo.maxFiles > 1}
        ctaText="Add attachment"
        desktopWidth={12}
        disabled={
          uploadInfo.maxFiles !== 0
            ? uploadedFile.selectedFile.length >= uploadInfo.maxFiles ||
              uploadedFileInPega.selectedFile.length >= uploadInfo.maxFiles
            : false
        }
        validFileTypes={EFTConstants.PIR_ALLOWED_FILE_EXT_END_USER}
      />
      {uploadedFile.selectedFile.length > 0 && (
        <Button variant="primary" onClick={onFileUpload}>
          Upload
        </Button>
      )}
      {uploadedFile.selectedFile.length > 0 && (
        <FileList
          header="List of files to be uploaded:"
          onClick={(item) => removeAttachment(item)}
          files={uploadedFile.selectedFile}
        />
      )}
      {uploadedFileInPega.selectedFile.length > 0 && (
        <FileList
          header="Uploaded files:"
          onClick={(item) => deleteAttachment(item)}
          files={uploadedFileInPega.selectedFile}
        />
      )}
      {uploadErrors.isInvalid &&
        uploadErrors.fileInfo.map((item: any, index) => {
          return (
            <Text key={index} color="redalert" fontSize="xxs">
              {item.fileName && `${item.fileName}: `}
              {item.errorMessage ?? ''}
            </Text>
          );
        })}
    </>
  );
};

export default FormInputFileUpload;
