import React from 'react';
import { Outlet } from 'react-router-dom';
import content from './Content/General.json';
import SubNavigation from '../../components/ServiceUI/Core/SubNavigation/SubNavigation';

export const Layout = () => {
  return (
    <>
      <SubNavigation {...content.subnavigation} />
      <Outlet />
    </>
  );
};
