import React from "react";
import { Box, Spinner as ChakraSpinner } from "@chakra-ui/react";

export const Spinner: React.FC = (): JSX.Element => {
  return (
    <Box w="100%" textAlign="center" py="1rem">
      <ChakraSpinner size="lg" />
    </Box>
  );
};
