import * as React from 'react';
import { SVGProps } from 'react';
const PIRRequest = (props: SVGProps<SVGSVGElement>) => (
  <svg width="40" height="45" viewBox="0 0 40 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M25.5409 24.5463C26.3198 24.5463 26.9496 23.9124 26.9496 23.1315L26.9497 22.5017C26.9497 19.5814 24.8213 17.2905 22.0812 17.2905H17.9927C15.0089 17.2905 12.8062 19.5052 12.8062 22.4999V23.1315C12.8062 23.9124 13.436 24.5463 14.2149 24.5463C14.9937 24.5463 15.6235 23.9124 15.6235 23.1315V22.4999C15.6235 21.7764 15.8639 21.1839 16.2733 20.7728C16.6825 20.3617 17.2722 20.1203 17.9924 20.1203H22.0809C23.2038 20.1203 24.1323 21.0825 24.1323 22.5016V23.1315C24.1323 23.9124 24.7621 24.5463 25.5409 24.5463ZM8.40179 28.8241C8.40179 29.6048 9.03143 30.2389 9.80876 30.2389H29.9468C30.7242 30.2389 31.3538 29.6048 31.3538 28.8241C31.3538 28.0433 30.7242 27.4093 29.9468 27.4093H9.80876C9.03143 27.4093 8.40179 28.0433 8.40179 28.8241ZM3.66728 42.7352V3.67944H36.0888V42.7352C36.0888 43.5161 36.7186 44.15 37.4975 44.15C38.2763 44.15 38.9061 43.5161 38.9061 42.7352V2.2648C38.9061 1.4839 38.2763 0.85 37.4975 0.85H2.25864C1.47976 0.85 0.85 1.4839 0.85 2.2648V42.7352C0.85 43.5161 1.47976 44.15 2.25864 44.15C3.03752 44.15 3.66728 43.5161 3.66728 42.7352ZM8.40179 35.7793C8.40179 36.5601 9.03143 37.1941 9.80876 37.1941H29.9468C30.7242 37.1941 31.3538 36.5601 31.3538 35.7793C31.3538 34.9986 30.7242 34.3645 29.9468 34.3645H9.80876C9.03143 34.3645 8.40179 34.9986 8.40179 35.7793ZM8.40179 42.735C8.40179 43.5158 9.03143 44.1498 9.80876 44.1498H19.8778C20.6567 44.1498 21.2864 43.5159 21.2864 42.735C21.2864 41.9541 20.6567 41.3202 19.8778 41.3202H9.80876C9.03143 41.3202 8.40179 41.9543 8.40179 42.735ZM15.3234 11.1181C15.3234 13.6442 17.3622 15.6941 19.8778 15.6941C22.3934 15.6941 24.4322 13.6442 24.4322 11.1181C24.4322 8.59031 22.3934 6.54037 19.8778 6.54037C17.3622 6.54037 15.3234 8.59035 15.3234 11.1181ZM21.6149 11.1181C21.6149 12.0825 20.8372 12.8645 19.8778 12.8645C18.9184 12.8645 18.1406 12.0825 18.1406 11.1181C18.1406 10.1519 18.9185 9.37002 19.8778 9.37002C20.8371 9.37002 21.6149 10.1519 21.6149 11.1181Z"
        fill="#CF4928"
        stroke="#CF4928"
        strokeWidth="0.3"
      />
    </g>
  </svg>
);
export default PIRRequest;
