export const getPageInstructionObj = (ObjectToFormat, currValue = '', fieldID = '', reference = '') => {
  let preparePageInstructionsArr = [] as Array<any>;

  return new Promise((resolve, reject) => {
    //Check objectToFormat has an Array
    const objHasSubarray = ObjectToFormat.some(Array.isArray);

    //If true then get the Array elemnt
    let objwhichHassArrelement: object[] = [];
    if (objHasSubarray) {
      ObjectToFormat.forEach((item: any) => {
        if (Array.isArray(item) && item.length > 0) {
          item.forEach((objectVal) => objwhichHassArrelement.push(objectVal));
        }
      });
    }

    // Get objects with no Array
    const objwithoutArr = ObjectToFormat.filter((item) => !Array.isArray(item));

    //Get Final Array with array element
    const finalArr = objwhichHassArrelement.map((ele: any) => {
      if (ele.fieldID === fieldID) {
        return {
          ...ele,
          value: currValue,
        };
      } else {
        return ele;
      }
    });

    //Final array without array array element
    const finalArrwithOutObj = objwithoutArr.map((ele) => {
      if (ele.fieldID === fieldID) {
        return {
          ...ele,
          value: currValue,
        };
      } else {
        return ele;
      }
    });

    //Prepare for Page Instructions with both arrays
    let finalObjtoPreparePageInstructions = [...finalArr, ...finalArrwithOutObj];
    preparePageInstructionsArr = finalObjtoPreparePageInstructions.map((item) => {
      return {
        instruction: 'UPDATE',
        target: '.' + item.reference.replace('.' + item.fieldID, ''),
        content: {
          [item.fieldID]: item.value,
        },
      };
    });
    resolve([...preparePageInstructionsArr]);
  });
};

export const checkValueStoredInRedux = (value: boolean | any) => {
  if (typeof value === 'boolean') {
    return true;
  } else {
    return value.length > 0;
  }
};
export const checkifItHasArray = (valueObj, array) => {
  if (Array.isArray(valueObj)) {
    valueObj.forEach((item) => {
      checkifItHasArray(item, array);
    });
  } else {
    array.push(valueObj);
  }
};
export const getReduxData = (fieldGroupList) => {
  let requestInfoData = [];
  for (const [key, value] of fieldGroupList) {
    checkifItHasArray(value, requestInfoData);
  }
  const filterDataRefField = requestInfoData.filter((item: any) => item.reference && item.value !== undefined);
  const filteredArray = filterDataRefField.filter((item: any) => checkValueStoredInRedux(item.value));
  const reqDetailsAllFields = [...filteredArray];
  return reqDetailsAllFields;
};

export const prepareFormFields = (reduxStoreFields) => {
  //Acknowledgment checkbox value
  const DeclarationDataFieldData =
    reduxStoreFields.DeclarationData && reduxStoreFields.DeclarationData[0]?.acknowledgement[0]
      ? reduxStoreFields.DeclarationData[0]?.acknowledgement[0]
      : [];
  const DeliveryDataFieldData =
    reduxStoreFields.DeliveryDetailsData && reduxStoreFields.DeliveryDetailsData[0]?.field
      ? reduxStoreFields.DeliveryDetailsData[0]?.field
      : [];
  const serviceTypeList = [['ServiceType', reduxStoreFields.ServiceType]];
  const typeOfRecordsList = [['TypeofRecords', reduxStoreFields.TypeofRecords]];
  const typeOfRecordsAdditionalList = [
    ['TypeOfRecordsAdditionalFields', reduxStoreFields.TypeOfRecordsAdditionalFields],
  ];
  const applicantTypeList = [['ApplicantType', reduxStoreFields.ApplicantType]];
  //fields from dynamicformrender

  const ApplicantTypeSelfMemDataList = [
    ['ApplicantTypeSelfMemData', flattenData(reduxStoreFields.ApplicantTypeSelfMemData)],
  ];
  const applicantTypeFamilyMemList = [
    ['ApplicantTypeFamilyMemData', flattenData(reduxStoreFields.ApplicantTypeFamilyMemData)],
  ];
  const applicantTypeAdvocateList = [
    ['ApplicantTypeAdvocateMemData', flattenData(reduxStoreFields.ApplicantTypeAdvocateMemData)],
  ];
  const acknowledgementList = [['DeclarationData', DeclarationDataFieldData]];
  const DeliveryDataList = [['DeliveryDetailsData', DeliveryDataFieldData]];

  const serviceTypeItems = getReduxData(serviceTypeList);
  const typeOfRecordsItems = getReduxData(typeOfRecordsList);
  const typeOfRecordsAdditionalItems = getReduxData(typeOfRecordsAdditionalList);
  const applicantTypeItems = getReduxData(applicantTypeList);
  //fields from dynamicformrender
  const ApplicantTypeSelfMemDataItems = getReduxData(ApplicantTypeSelfMemDataList);
  const applicantTypeFamilyMemItems = getReduxData(applicantTypeFamilyMemList);
  const applicantTypeAdvocateItems = getReduxData(applicantTypeAdvocateList);
  //acknowledgment checkbox value
  const acknowledgementItems = getReduxData(acknowledgementList);
  const deliveryData = getReduxData(DeliveryDataList);

  //NEED to define pageInstructions for data PUT request
  const allUpdatedValue = [
    ...serviceTypeItems,
    ...typeOfRecordsItems,
    ...typeOfRecordsAdditionalItems,
    ...applicantTypeItems,
    ApplicantTypeSelfMemDataItems,
    applicantTypeFamilyMemItems,
    applicantTypeAdvocateItems,
    acknowledgementItems,
    deliveryData,
  ];

  return allUpdatedValue;
};

export const flattenData = (data) => {
  if (data) {
    return data
      .reduce((acc, curr) => {
        acc.push(...Object.values(curr));
        return acc;
      }, [])
      .flat();
  } else {
    return [];
  }
};
