import React from 'react';
import {
  AccordionItem,
  AccordionButton,
  Box,
  AccordionItemProps as ChakraAccordionItemProps,
  Text,
  AccordionPanel,
} from '@chakra-ui/react';
import { Chevron_Orange as ChevronOrange } from '../icons/Outline';
import sanitizeHtml from 'sanitize-html';

export interface ItemProps extends ChakraAccordionItemProps {
  title: string;
  content: string;
  isExpanded?: boolean;
  toggleExpand?(): void;
}

const Item: React.FC<ItemProps> = ({
  title,
  content,
  isDisabled,
  isExpanded,
  toggleExpand,
}: ItemProps): JSX.Element => {
  return (
    <AccordionItem
      isDisabled={isDisabled}
      data-testid="accordion-item"
      border="1px"
      bg="white"
      borderColor="grey"
      marginBottom="1rem"
      aria-expanded={isExpanded ? 'true' : 'false'}
      aria-controls="expandAndcollapse"
    >
      <Box onClick={() => toggleExpand && toggleExpand()}>
        <AccordionButton p="0">
          <Text as="h3" flex="1" fontWeight="500" textAlign="left" display="flex" px="1.875rem" my="1rem">
            <Box as="span" flex="1" textAlign="left">
              <Text
                as="span"
                flex="1"
                textColor="#000"
                fontWeight="500"
                fontSize="sm"
                textAlign="left"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(title),
                }}
              />
            </Box>
            {isExpanded ? (
              <ChevronOrange style={{ height: '12px', rotate: '180deg' }} />
            ) : (
              <ChevronOrange style={{ height: '12px' }} />
            )}
          </Text>
        </AccordionButton>
      </Box>
      <AccordionPanel
        px="1.875rem"
        pb="1rem"
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(content),
        }}
      />
    </AccordionItem>
  );
};

export default Item;
