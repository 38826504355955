import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Breadcrumbs.scss';

const BreadcrumbsWrapper = (props) => {
  const url = window.location.pathname;
  return <Breadcrumbs url={url} breadcrumbItems={props.breadcrumbItems} />;
};

export const Breadcrumbs = (props) => {
  const urlArray = props.url.split('/');
  let selectUrl = '';
  if (urlArray[1] === 'serviceconnect') {
    selectUrl = urlArray[urlArray.length - 1];
  } else {
    selectUrl = urlArray[urlArray.length - 2];
  }

  if (selectUrl !== '') {
    if (selectUrl.includes('.html')) {
      selectUrl = selectUrl.split('.')[0];
    }
    if (selectUrl.includes('edit-service-request-create')) {
      selectUrl = selectUrl.replace('edit-service-request-create', 'edit-service-request');
    }
    if (selectUrl.includes('-')) {
      selectUrl = selectUrl.replaceAll('-', ' ');
    }
    if (selectUrl.includes('serviceconnect')) {
      selectUrl = selectUrl.replace('serviceconnect', 'ServiceConnect');
    }
  }

  let currentPage = selectUrl
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  if (urlArray.includes('req-completed')) {
    currentPage = 'Submitted';
  }
  const breadcrumbItems = props.breadcrumbItems;
  return (
    <Breadcrumb className="text-white">
      <>
        {breadcrumbItems &&
          breadcrumbItems.map((item) => (
            <Breadcrumb.Item
              linkAs={Link}
              linkProps={{
                to: item.url,
              }}
              key={item.name}
            >
              {item.name}
            </Breadcrumb.Item>
          ))}
        <Breadcrumb.Item active key="currentpage">
          {currentPage}
        </Breadcrumb.Item>
      </>
    </Breadcrumb>
  );
};

export default BreadcrumbsWrapper;
