import * as React from 'react';
import { SVGProps } from 'react';
const PIRMedical = (props: SVGProps<SVGSVGElement>) => (
  <svg width="35" height="43" viewBox="0 0 35 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M7.96839 8.82599H18.6949C19.1016 8.82599 19.4198 8.50951 19.4198 8.14707V4.79844C19.4198 4.39179 19.1033 4.07353 18.6949 4.07353H16.0252V2.71569C16.0252 1.2217 14.8035 0 13.3095 0C11.8155 0 10.5938 1.2217 10.5938 2.71569V4.07353H7.92413C7.56168 4.07353 7.19922 4.39001 7.19922 4.79844V8.14707C7.24342 8.50951 7.60596 8.82599 7.96839 8.82599ZM13.3536 1.72039C13.8964 1.72039 14.349 2.173 14.349 2.71578C14.349 3.25856 13.8964 3.71117 13.3536 3.71117C12.8108 3.71117 12.3582 3.25856 12.3582 2.71578C12.3582 2.173 12.7649 1.72039 13.3536 1.72039Z"
        fill="#CF4928"
      />
      <path
        d="M7.28967 13.8047H19.4202C20.009 13.8047 20.5058 13.3061 20.5058 12.7192C20.5058 12.1304 20.0072 11.6336 19.4202 11.6336L7.28967 11.6318C6.70091 11.6318 6.20412 12.1304 6.20412 12.7174C6.15816 13.3061 6.65497 13.8047 7.28967 13.8047Z"
        fill="#CF4928"
      />
      <path
        d="M7.28966 18.9199H19.4202C20.009 18.9199 20.5057 18.4213 20.5057 17.8344C20.5057 17.2456 20.0071 16.7488 19.4202 16.7488L7.28966 16.7471C6.7009 16.7471 6.20411 17.2457 6.20411 17.8326C6.20234 18.4214 6.65495 18.9199 7.28966 18.9199Z"
        fill="#CF4928"
      />
      <path
        d="M7.28967 24.0342H19.4202C20.009 24.0342 20.5058 23.5356 20.5058 22.9486C20.5058 22.3599 20.0072 21.8631 19.4202 21.8631L7.28967 21.8613C6.70091 21.8613 6.20412 22.3599 6.20412 22.9469C6.15816 23.5356 6.65497 24.0342 7.28967 24.0342Z"
        fill="#CF4928"
      />
      <path
        d="M7.28966 29.1035H14.3514C14.9401 29.1035 15.4369 28.6049 15.4369 28.018C15.4369 27.4292 14.9383 26.9324 14.3514 26.9324L7.28966 26.9307C6.7009 26.9307 6.20411 27.4293 6.20411 28.0162C6.20234 28.605 6.65495 29.1035 7.28966 29.1035Z"
        fill="#CF4928"
      />
      <path
        d="M26.706 25.0743V5.93003C26.706 5.52338 26.3895 5.20512 25.9811 5.20512H21.5911V7.87483H23.3114C23.718 7.87483 24.0363 8.1913 24.0363 8.59973V25.074C20.0989 25.6628 16.9746 28.8311 16.4781 32.8146H3.35044C2.94379 32.8146 2.62553 32.4982 2.62553 32.0897V8.55375C2.62553 8.1471 2.94201 7.82884 3.35044 7.82884H5.07073V5.20508H0.724908C0.318261 5.20508 0 5.52156 0 5.92999V34.7611C0 35.1677 0.316478 35.486 0.724908 35.486H16.5664C17.2913 39.7399 20.9564 43.0003 25.4386 43.0003C30.4173 43.0003 34.4447 38.9728 34.4447 33.9942C34.4465 29.4662 31.052 25.7088 26.706 25.0743ZM25.3941 40.5551C21.7732 40.5551 18.7851 37.6131 18.7851 33.946C18.7851 30.2789 21.773 27.3831 25.3941 27.3831C29.0152 27.3831 32.0032 30.3251 32.0032 33.9922C32.0015 37.6573 29.0595 40.5551 25.3941 40.5551Z"
        fill="#CF4928"
      />
      <path
        d="M28.9691 32.7251H26.706V30.462C26.706 30.2816 26.5699 30.1455 26.3895 30.1455H24.4429C24.2626 30.1455 24.1264 30.2816 24.1264 30.462V32.7251H21.8634C21.683 32.7251 21.5469 32.8612 21.5469 33.0415V34.9881C21.5469 35.1685 21.683 35.3046 21.8634 35.3046H24.1264V37.5677C24.1264 37.748 24.2626 37.8842 24.4429 37.8842H26.3895C26.5699 37.8842 26.706 37.748 26.706 37.5677V35.3046H28.9691C29.1494 35.3046 29.2855 35.1685 29.2855 34.9881V33.0415C29.2855 32.8612 29.1494 32.7251 28.9691 32.7251Z"
        fill="#CF4928"
      />
    </g>
  </svg>
);
export default PIRMedical;
