import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { PegaCaseBadge } from '../../Core/Badge/Badge';
import { Tooltip } from '../../Core/Tooltip/Tooltip';
import content from '../Content/ViewServiceRequest.json';

const requestContent = content.keyrequestinfo;

interface KeyRequestInfoProps {
  pirInfo: any;
  variant: 'submitted' | 'full';
}

const KeyRequestInfo: React.FC<KeyRequestInfoProps> = (props: KeyRequestInfoProps) => {
  const RequestItem = (props: { title: string; value: string; tooltip?: string; width?: string }) =>
    props.value && !props.value.includes('undefined') ? (
      <Box w={{ lg: props.width ?? '30%', base: '100%' }}>
        <Flex alignItems="center" gap={2}>
          <Text display="flex" flexDir="row" m="0">
            {props.title}
          </Text>
          {props.tooltip && <Tooltip content={props.tooltip} placement="right" />}
        </Flex>
        <Text fontWeight="light">{props.value}</Text>
      </Box>
    ) : (
      <></>
    );
  const personalInfoRequest = props?.pirInfo?.content?.PersonalInfoRequest;
  const applicantName = props?.pirInfo?.content?.pxCreateOpName;
  let memberName = '';
  if (personalInfoRequest?.ApplicantType) {
    const applicantType = personalInfoRequest.ApplicantType;
    switch (applicantType) {
      case 'Self':
        const elSelf = personalInfoRequest.SelfApplicantDetails;
        memberName = `${elSelf?.GivenNames} ${elSelf?.Surname}`;
        break;
      case 'Family':
        const elFamily = personalInfoRequest.FamilyMemberApplicantDetails;
        memberName = `${elFamily?.GivenNames} ${elFamily?.Surname}`;
        break;
      case 'Advocate':
        const elAdvocate = personalInfoRequest.AdvocateApplicantDetails;
        memberName = `${elAdvocate?.GivenNames} ${elAdvocate?.Surname}`;
        break;
      default:
        break;
    }
  }
  return (
    <Box p={{ lg: '2rem', base: '1.5rem' }} bg="lightblue" my="1rem">
      <Heading as="h3" fontSize="sm" fontWeight="medium" mb="1rem">
        {requestContent.heading}
      </Heading>
      <RequestItem
        width="100%"
        title={requestContent.nameLabel}
        value={props?.pirInfo?.content?.PersonalInfoRequest?.ServiceTypeValue}
      />
      <Flex flexWrap={{ lg: 'nowrap', base: 'wrap' }}>
        <RequestItem
          title={requestContent.dateLabel}
          value={new Date(props?.pirInfo?.content?.PersonalInfoRequest?.SubmissionDateTime).toLocaleDateString()}
        />
        <RequestItem
          title={requestContent.IDLabel}
          value={props?.pirInfo?.content?.pxInsName}
          tooltip={requestContent.idTooltipContent}
        />
      </Flex>
      {props.variant === 'full' ? (
        <Flex flexWrap={{ lg: 'nowrap', base: 'wrap' }}>
          <RequestItem title={requestContent.applicantNameLabel} value={applicantName ?? ''} />
          <RequestItem title={requestContent.memberNameLabel} value={memberName ?? ''} />
        </Flex>
      ) : (
        <>
          <Text m={0}>{requestContent.statusLabel}</Text>
          <PegaCaseBadge status="PENDING-FULFILLMENT" />
          <Text m={0} fontWeight="light" mt="1rem">
            {requestContent.disclaimer}
          </Text>
        </>
      )}
    </Box>
  );
};

export default KeyRequestInfo;
