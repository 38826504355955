import React from 'react';
import { Button, Grid, GridItem, Text } from '@chakra-ui/react';
import { setShowError } from '../../../../reducers/errorHandling';
import { request } from '../../../../helper/useAxios';
import { useDispatch } from 'react-redux';
import { DownloadableFileItem } from './DownloadableFileItem';
import { PegaFile } from '../types';
import { PIRCaseAudit } from '../../../../pages/EFT/API/PIRCaseAudit';

interface ReadyToDownloadDocumentsProps {
  files: PegaFile[];
  caseID: string;
}

const ReadyToDownloadDocuments: React.FC<ReadyToDownloadDocumentsProps> = (props: ReadyToDownloadDocumentsProps) => {
  const dispatch = useDispatch();
  const caseAuditForEachFile = (file) => {
    if (props.caseID) {
      PIRCaseAudit(props.caseID, file.ID, 'PIR07')
        .then((_) => {})
        .catch((error) => {
          dispatch(setShowError({ error: true, code: error.response.status }));
        });
    }
  };
  const downloadAllDocs = () => {
    props.files.forEach((file) => {
      onClickCTA(file);
    });
  };
  const onClickCTA = (item) => {
    caseAuditForEachFile(item);
    request('REACT_APP_GET_ATTACHMENT', 'get', { id: item.ID })
      .then((res) => {
        let el = document.getElementById('download-attachment') as HTMLAnchorElement;
        el.href = 'data:image/png;base64,' + res.data;
        el.download = item.name;
        el.click();
      })
      .catch((error) => {
        dispatch(setShowError({ error: true, code: error.response.status }));
      });
  };

  return (
    <>
      <Grid gridTemplateColumns="repeat(3,1fr)">
        <Text as={GridItem} colSpan={2} fontSize="m">
          Your documents
        </Text>
        <GridItem colSpan={{ lg: 1, base: 3 }} textAlign="right" rowEnd={{ lg: 'auto', base: 4 }}>
          <Button variant="primary" w={{ lg: 'auto', base: '100%' }} onClick={() => downloadAllDocs()}>
            Download All
          </Button>
        </GridItem>
        <Grid as={GridItem} colSpan={4} gridTemplateColumns="repeat(3,1fr)">
          {props.files.map((file, i) => (
            <DownloadableFileItem key={i} file={file} onClick={onClickCTA} />
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default ReadyToDownloadDocuments;
