import { getAuthDomainURLAfterSSO, getAuthURL } from './getOtherAppURL';

export function getURLWithEncoded(ssoURL, pathName, currentDomain) {
  if (window.location.pathname !== '/') {
    const url = ssoURL + '?callbackurl=' + encodeURIComponent(currentDomain + pathName);
    return url;
  } else {
    return ssoURL;
  }
}
export function redirectSSOCurrentURL() {
  const SSOURL = getAuthURL();
  const currentEnvDomain = getAuthDomainURLAfterSSO();
  const currentpageURL = window.location.pathname;

  const finalURL = getURLWithEncoded(SSOURL, currentpageURL, currentEnvDomain);

  window.location.href = finalURL;
}
