import { Button, Flex, Grid, GridItem, Heading, Show, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PegaCaseBadge } from '../../Core/Badge/Badge';
import { PIR } from '../types';

export type PIRItemProps = PIR & { URL?: string } & {
  handlestatusChangeforPendingReview(val: string): any;
};

export const PIRItem: React.FC<PIRItemProps> = (props: PIRItemProps) => {
  const navigate = useNavigate();
  const viewRequestHandler = () => {
    if (props.pyStatusWork === 'New') {
      navigate(`/personal-information-request/new/${props.pzInsKey}?cameFrom=editReq`); // going create page edit view
    } else if (props.pyStatusWork === 'Pending-Review') {
      navigate(
        '/personal-information-request/review/' + props.pxGotoStage, // going review page
        {
          state: [
            {
              caseId: { caseId: props.pzInsKey },
            },
          ],
        },
      );
    } else if (props.pyStatusWork === 'Pending-Fulfillment') {
      navigate(`/personal-information-request/view?CASEID=${props.pzInsKey}`); // going view page
    } else if (props.pyStatusWork === 'Pending-Download') {
      props.handlestatusChangeforPendingReview(props.pzInsKey);
    } else if (props.pyStatusWork === 'Pending-Completion') {
      // going to doc ready page
      navigate(`/personal-information-request/doc-ready/${props.pzInsKey}`);
    } else {
      // going to doc ready page when props.pyStatusWork==== Pending-Expiration
      navigate(`/personal-information-request/doc-ready/${props.pzInsKey}`);
    }
  };
  const draftable: Boolean = props.pyStatusWork === 'Pending-Review' || props.pyStatusWork === 'New';
  const buttonText = draftable ? 'Continue' : 'View';

  return (
    <Grid
      data-testid="pir-item"
      borderRadius="0.25rem"
      bg="white"
      p="1.25rem"
      gridTemplateColumns="repeat(5,1fr)"
      mt="0.5rem"
      gap={3}
    >
      <GridItem colSpan={{ lg: 3, base: 5 }} as={Flex} alignItems="flex-start" flexWrap="wrap" flexDir="column" gap={2}>
        <PegaCaseBadge status={props.pyStatusWork} />
        <Heading as="h3" m={0} fontSize="regular" fontWeight="light">
          {props.pyTitle ?? 'Untitled request'}
        </Heading>
      </GridItem>
      <GridItem colSpan={{ lg: 1, base: 3 }} as={Flex} alignItems="center">
        <Text m={0} fontWeight="light" color="darkgrey">
          <Show below="lg">Submitted: </Show>
          {new Date(props.pxCreateDateTime).toDateString()}
        </Text>
      </GridItem>
      <GridItem colSpan={{ lg: 1, base: 2 }} as={Flex} justifyContent="flex-end" alignItems="center">
        <Show above="lg">
          <Button onClick={() => viewRequestHandler()} variant="secondary">
            {buttonText}
          </Button>
        </Show>
        <Show below="lg">
          <Button onClick={() => viewRequestHandler()} variant="secondary">
            {buttonText}
          </Button>
        </Show>
      </GridItem>
    </Grid>
  );
};
