import React from 'react';
import { Modal, Container, Image, Form, Button } from 'react-bootstrap';
import OrangeAlert from '../../../../images/OrangeAlert.svg';
import axios from 'axios';

import { getURL } from '../../../../helper/getAPIUrl';

export const CloneModal = (props) => {
  const onConfirmClone = () => {
    let URL = process.env.REACT_APP_CLONE_CONFIRM + props.caseID;

    URL = getURL(URL);
    axios
      .post(URL, {}, { withCredentials: true })
      .then((response) => {
        props.onConfirmSuccess(response.data);
        props.onClose();
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        // setAPIError(error);
        props.onClose();
        props.onsetAPIError(error);

        if (error.response.status === 403 || error.response.status === 401) {
          // setShowModal(true);
          props.onClose();
          props.onsetShowModel();
        } else if (error.response.status === 500) {
          // setServiceDownModal(true);
          props.onClose();
          props.onsetserviceDownModel();
        } else {
          props.onClose();
          // setErrorModal(true);
          props.onsetErrorModel();
        }
      });
  };
  return (
    <Modal data-testid="modal" dialogClassName="modal-70w" contentClassName="p-4 p-lg-6" show={props.show}>
      <Modal.Header className="p-0 d-block border-0 m-0">
        <h2>{props.header}</h2>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Container className="bg-defencelightblue p-2 p-lg-5 my-3">
          <h3>{props.infoboxContent.headingOne}</h3>
          <p className="fw-light">{props.infoboxContent.subheadingOne}</p>
          <h3>{props.infoboxContent.headingTwo}</h3>
          <p className="fw-light">{props.infoboxContent.subheadingTwo}</p>
          <Container className="d-flex gap-3 align-items-start">
            <Image src={OrangeAlert} height={32} alt="" />
            <p className="fw-light" dangerouslySetInnerHTML={{ __html: props.infoboxContent.alert }} />
          </Container>
        </Container>
      </Modal.Body>
      <Modal.Footer className="mt-3 p-0 border-0 justify-content-between">
        <Button variant="standard" onClick={props.onClose}>
          Cancel
        </Button>
        {/* <Button onClick={props.onConfirm}>Confirm</Button> */}
        <Button onClick={onConfirmClone}>Clone and Continue</Button>
      </Modal.Footer>
    </Modal>
  );
};
