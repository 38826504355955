import remCalc from '../helper/remCalc';

export const sizes = {
  xxxxlarge: remCalc(72), //4.5rem
  xxxlarge: remCalc(48), //3rem
  xxlarge: remCalc(32), //2rem
  xlarge: remCalc(20), //1.25rem
  large: remCalc(18), //1.125rem
  regular: remCalc(16), //1rem
  small: remCalc(14), //0.875rem
  xsmall: remCalc(12), //0.75rem
};
