import React from 'react';
import './CtaPanels.scss';
import '../../../../sass/custom.scss';
import { Card, Container, Row, Col, Button, Image } from 'react-bootstrap';
import mybaseicon from '../Images/mybase_icon.svg';
import formsicon from '../Images/forms.svg';

const Cta_Panels = (props) => {
  return (
    <Container className="cta_panels landingPageWidth">
      <Container fluid className="p-4 p-lg-5 requestList">
        <Row lg={2}>
          <Col md className="pe-xs-0 pe-md-4 pb-md-0 pb-md-0 d-flex" style={{ paddingBottom: '1em' }}>
            <Card className="flex-fill card_styles" style={{ width: '18rem' }}>
              <Card.Body className="px-5 py-4">
                <Container className="py-3">
                  <Image src={formsicon} alt="My Forms" />
                </Container>
                <Card.Title>
                  <h3>{props.data.title_left}</h3>
                </Card.Title>
                <Card.Text className="text-dark fw-light">{props.data.description_left}</Card.Text>
                <p>
                  <Button variant="standard" href={props.myFormsUrl} target="_blank">
                    {props.data.ctaText_left}
                  </Button>
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md className=" ps-xs-0 ps-md-4 pt-xs-3 pt-sm-0 d-flex">
            <Card className="flex-fill card_styles" style={{ width: '18rem' }}>
              <Card.Body className="px-5 py-4">
                <Container className="py-3">
                  <Image src={mybaseicon} alt="My Base" />
                </Container>
                <Card.Title>
                  <h3>{props.data.title_right}</h3>
                </Card.Title>
                <Card.Text className="text-dark fw-light">{props.data.description_right}</Card.Text>
                <p>
                  <Button variant="standard" href={props.myBaseUrl} target="_blank">
                    {props.data.ctaText_right}
                  </Button>
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Cta_Panels;
