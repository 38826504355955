import React from 'react';
import { useDispatch } from 'react-redux';
import { SCForm } from '../../../components/ServiceUI/Core/FormElements';
import { setFieldValue } from '../../../reducers/eftRequestDetailFormReducer';
const FormMenu = ({
  fieldID,
  control,
  required,
  fieldPropMapping,
  visible,
  error,
  reference,
  value,
  formData,
  label,
  readOnly,
}) => {
  const dispatch = useDispatch();

  const handleSelect = (e) => {
    dispatch(
      setFieldValue({
        reference,
        value: e.value,
        formData,
        required,
        visible,
      }),
    );
  };

  return (
    <SCForm.Menu
      disabled={readOnly}
      visible={visible}
      required={required}
      isInvalid={error}
      label={label}
      value={value.length > 0 ? value : control.modes[0].options[0].value}
      options={control.modes[0].options}
      errorMessage={fieldPropMapping[fieldID]?.required?.errorMessage}
      desktopWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop}
      mobileWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile}
      onSelect={(e) => {
        handleSelect(e);
      }}
    />
  );
};

export default FormMenu;
