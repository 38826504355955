const convertSpecialChars = (valueFromPega) => {
  // S&amp;amp&#59;EG -- from pega
  // S&amp&#59;EG

  let initialValue = valueFromPega;

  // Not sure why else if is not working Thats Y adding If in all
  if (initialValue.length === 0) {
    return initialValue;
  } else {
    if (initialValue.includes('&amp;')) {
      initialValue = initialValue.replace(/&amp;/g, '&');
    }
    if (initialValue.includes('&#40;')) {
      initialValue = initialValue.replace(/&#40;/g, '(');
    }
    if (initialValue.includes('&#41;')) {
      initialValue = initialValue.replace(/&#41;/g, ')');
    }
    if (initialValue.includes('&#43;')) {
      initialValue = initialValue.replace(/&#43;/g, '+');
    }
    if (initialValue.includes('&#39;')) {
      initialValue = initialValue.replace(/&#39;/g, "'");
    }
    if (initialValue.includes('&#37;')) {
      initialValue = initialValue.replace(/&#37;/g, '%');
    }
    if (initialValue.includes('&quot;')) {
      initialValue = initialValue.replace(/&quot;/g, '"');
    }
    if (initialValue.includes('&#59;')) {
      initialValue = initialValue.replace(/&#59;/g, ';');
    }
    if (initialValue.includes('&lt;')) {
      initialValue = initialValue.replace(/&lt;/g, '<');
    }
    if (initialValue.includes('&gt;')) {
      initialValue = initialValue.replace(/&gt;/g, '>');
    }
    return initialValue;
  }
};

export default convertSpecialChars;
