import { Container, Modal, Button, InputGroup, FormControl, Image, FormSelect } from 'react-bootstrap';
import React, { useState } from 'react';
import './FeedbackModal.scss';
import VSad from '../../../images/Smiles/V_Sad.svg';
import Sad from '../../../images/Smiles/sad.svg';
import Neutral from '../../../images/Smiles/Neutral.svg';
import Happy from '../../../images/Smiles/happy.svg';
import VHappy from '../../../images/Smiles/V_Happy.svg';
import redalert from '../../../images/redalert.svg';
import greensuccess from '../../../images/greensuccess.svg';
import { getURL } from '../../../helper/getAPIUrl';
import { appConstants } from '../../../helper/client/constant';

import axios from 'axios';

const FeedbackModal = (props) => {
  const data = props.modalProps;
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const submitAction = (rating, review, service) => {
    const data = {
      service: service,
      rating: rating,
      detail: review,
    };
    let URL = process.env.REACT_APP_FEEDBACK;
    URL = getURL(URL);
    axios
      .post(URL, data, {
        withCredentials: true,
      })
      .then(() => setSubmitted(true))
      .catch(() => setError(true));
  };
  const reset = () => {
    props.close();
    setSubmitted(false);
    setError(false);
  };
  return (
    <>
      <Modal data-testid="modal" dialogClassName="mh-75 modal-70w" contentClassName="p-4 p-lg-6" show={props.show}>
        {!submitted && !error ? (
          <FeedbackModalContent
            close={props.close}
            data={data}
            submit={(rating, review, service) => submitAction(rating, review, service)}
          />
        ) : !error ? (
          <SubmittedModalContent data={data} close={reset} />
        ) : (
          <ErrorModalContent data={data} close={reset} />
        )}
      </Modal>
    </>
  );
};

const FeedbackModalContent = (props) => {
  const data = props.data;
  const [service, setService] = useState(data.services[0].service);
  const [rating, setRating] = useState('');
  const [review, setReview] = useState('');
  const submitAction = () => {
    props.submit(rating, review, service);
  };
  const Services = () => {
    return data.services.map((item, idx) => <option key={idx}>{item.service}</option>);
  };
  const Smilies = () => {
    const radios = [
      { value: '1', className: 'vsad', image: VSad, description: 'Very Poor' },
      { value: '2', className: 'sad', image: Sad, description: 'Poor' },
      { value: '3', className: 'neutral', image: Neutral, description: 'Neutral' },
      { value: '4', className: 'happy', image: Happy, description: 'Good' },
      { value: '5', className: 'vhappy', image: VHappy, description: 'Very Good' },
    ];
    return (
      <Container id="review" className="mb-5 w-100" name="smiles">
        <div className="row gap-1">
          {radios.map((radio, idx) => (
            <div className="col" key={idx}>
              <Button
                title={`${radio.description}`}
                role="radio"
                aria-checked={`${rating === radio.value ? true : false}`}
                className={`${rating === radio.value ? 'checked' : null} ${radio.className}`}
                id={`radio-${idx}`}
                style={{ width: 110 }}
                variant="smile"
                name="radio"
                value={radio.value}
                onClick={(e) => setRating(radio.value)}
              />
              <div className="mt-1 experience-text text-center">{radio.description}</div>
            </div>
          ))}
        </div>
      </Container>
    );
  };
  return (
    <>
      <Modal.Body className="feedbackModal p-0">
        <h1>{data.modalHeading}</h1>
        <p className="fw-light">{data.modalDescription}</p>
        <label htmlFor="servicepicker">{data.serviceHeading}</label>
        <FormSelect id="servicepicker" className="mb-5" value={service} onInput={(e) => setService(e.target.value)}>
          <Services />
        </FormSelect>
        <label htmlFor="rating">
          {service === 'Other' ? (
            'How was your experience?'
          ) : (
            <>
              {data.ratingHeading} {service}?
            </>
          )}
        </label>
        <Smilies />
        <label htmlFor="review">{data.reviewHeading}</label>
        <InputGroup>
          <FormControl
            id="review"
            className="feedbackTextArea"
            as="textarea"
            aria-label="With textarea"
            onChange={(e) => setReview(e.target.value)}
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer className="mt-3 p-0 border-0">
        <div className="fw-light">
          {appConstants.EMAIL_SERVICECONNECT_TECHSUPPORTP1}
          <a href={`mailto:${appConstants.EMAIL_SERVICECONNECT_TECHSUPPORT}`}>
            {appConstants.EMAIL_SERVICECONNECT_TECHSUPPORT}
          </a>
          {appConstants.EMAIL_SERVICECONNECT_TECHSUPPORTP2}
        </div>
      </Modal.Footer>
      <Modal.Footer className="mt-3 p-0 d-flex justify-content-between gap-3 flex-lg-nowrap border-0">
        <Button onClick={props.close} className="mobileButton" data-testid="cta" variant="standard">
          Close
        </Button>
        <Button
          disabled={review === '' || rating === ''}
          className="mobileButton"
          data-testid="cta"
          variant="primary"
          onClick={submitAction}
        >
          Submit Feedback
        </Button>
      </Modal.Footer>
    </>
  );
};
const SubmittedModalContent = (props) => {
  const data = props.data;
  return (
    <>
      <Modal.Body className="feedbackModal p-0">
        <Image height={70} src={greensuccess} alt="success" />
        <h1>{data.thankYouHeading}</h1>
        <p className="fw-light">{data.thankYouDescription}</p>
        <Container className="p-5 bg-defencemediumlightblue">
          <h3>{data.usageHeading}</h3>
          <p>{data.usageContent}</p>
        </Container>
      </Modal.Body>
      <Modal.Footer className="justify-content-end flex-nowrap mt-3 p-0 border-0">
        <Button onClick={props.close} className="modalBtn" data-testid="cta" variant="primary">
          Close
        </Button>
      </Modal.Footer>
    </>
  );
};
const ErrorModalContent = (props) => {
  return (
    <>
      <Modal.Body className="feedbackModal p-0">
        <Image height={70} src={redalert} alt="error" />
        <h1 className="mt-2">Something went wrong</h1>
        <p className="fw-light">Please try again</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-end flex-nowrap mt-3 p-0 border-0">
        <Button onClick={props.close} className="modalBtn" data-testid="cta" variant="primary">
          Close
        </Button>
      </Modal.Footer>
    </>
  );
};
export default FeedbackModal;
