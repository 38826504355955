/*******************************************************
 * create a filter that will be used to determine
 * which keystrokes are allowed in the input field
 * and which are not. Since we're working exclusively
 * with time numbers, we'll need the following:
 * -- digits 0 to 9 from the numeric keys
 * -- digits 0 to 9 from the num pad keys
 * -- arrow keys (left/right)
 * -- backspace / delete for correcting
 * -- tab key to allow focus to shift
 *******************************************************/
var keyFilter = [];

//since we're looking for phone numbers, we need
//to allow digits 0 - 9 (they can come from either
//the numeric keys or the numpad)
const keypadZero = 48;
const numpadZero = 96;

//add key codes for digits 0 - 9 into this filter
for (var i = 0; i <= 9; i++) {
  keyFilter.push(i + keypadZero);
  keyFilter.push(i + numpadZero);
}

//add other keys that might be needed for navigation
//or for editing the keyboard input
keyFilter.push(8); //backspace
keyFilter.push(9); //tab
keyFilter.push(32); //space
keyFilter.push(46); //delete
keyFilter.push(37); //left arrow
keyFilter.push(39); //right arrow
keyFilter.push(65); //a
keyFilter.push(77); //m
keyFilter.push(80); //p
keyFilter.push(186); //colon

/*******************************************************
 * onKeyDown(e)
 * when a key is pressed down, check if it is allowed
 * or not. If not allowed, prevent the key event
 * from propagating further
 *******************************************************/
const TimePickerKeyFilterOnKeyDown = (e) => {
  if (keyFilter.indexOf(e.keyCode) < 0) {
    e.preventDefault();
    return false;
  }
};

export default TimePickerKeyFilterOnKeyDown;
