import { ComponentStyleConfig } from '@chakra-ui/react';
import { selectedMainNavigationTab, selectedSubNavigationTab } from '../constants';

const NavLinkBase = {
  display: 'flex',
  alignContent: 'center',
  flexWrap: 'wrap',
  px: '1rem',
  py: { lg: '0', base: '1rem' },
  textDecoration: 'none',
  width: { lg: 'auto', base: '100%' },
};
const Link: ComponentStyleConfig = {
  baseStyle: {
    textDecoration: 'underline',
    _hover: { color: 'orange.standard' },
  },
  variants: {
    mainNav: {
      ...NavLinkBase,
      color: 'white',
      _active: {
        ...selectedMainNavigationTab,
      },
      _hover: {
        ...selectedMainNavigationTab,
        fontWeight: 'regular',
      },
      _focus: { ...selectedMainNavigationTab },
    },
    subNav: {
      ...NavLinkBase,
      color: 'black',
      _active: {
        ...selectedSubNavigationTab,
      },
      _hover: {
        ...selectedSubNavigationTab,
      },
      _focus: { ...selectedSubNavigationTab },
    },
    card: {
      color: 'orange.standard',
      textDecoration: 'none',
      fontWeight: 'bold',
    },
  },
};

export default Link;
