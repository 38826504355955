import remCalc from '../helper/remCalc';

export const spacing = {
  xsmall: remCalc(5),
  small: remCalc(8),
  medium: remCalc(12),
  large: remCalc(24),
  xlarge: remCalc(48),
  xxlarge: remCalc(80),
  default: remCalc(16),
};
