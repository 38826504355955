import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SCForm } from '../../../components/ServiceUI/Core/FormElements';
import { findByProp } from '../../../helper/findByProp';
import { setFieldValue } from '../../../reducers/eftRequestDetailFormReducer';
const FormMulti = ({
  fieldID,
  label,
  required,
  visible,
  reference,
  formData,
  value,
  options,
  fieldPropMapping,
  readOnly,
}) => {
  const reqDetailDataFromReduxStore = useSelector((state: any) => {
    let checkboxValues: any[] = [];
    if (state) {
      options?.map((item) => {
        let x = findByProp(state.EFTRequestMemberForm.formFields, 'reference', item.reference);
        return checkboxValues.push(x);
      });
    }
    return checkboxValues;
  });
  const dispatch = useDispatch();
  const onCheckboxChange = (target) => {
    const prevState = reqDetailDataFromReduxStore.find((i) => i?.reference === target?.reference)?.value;
    dispatch(
      setFieldValue({
        reference: target?.reference,
        value: !prevState,
        formData,
        required,
        visible,
      }),
    );
  };

  return (
    <SCForm.Multi
      disabled={readOnly}
      desktopWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop}
      mobileWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile}
      label={label}
      value={value}
      visible={visible}
      required={required}
      id={fieldID}
      onCheckboxChange={(e) => onCheckboxChange(e)}
      options={options?.map((item, idx) => {
        return {
          key: item.control?.label,
          isChecked: reqDetailDataFromReduxStore[idx]?.value,
          reference: item.reference,
        };
      })}
      isInvalid={
        reqDetailDataFromReduxStore.filter((e) => e?.value === '' || e?.value === false)?.length ===
        reqDetailDataFromReduxStore?.length
      }
      errorMessage={fieldPropMapping[fieldID]?.required.errorMessage}
    />
  );
};

export default FormMulti;
