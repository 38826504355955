import * as React from 'react';
import { SVGProps } from 'react';
const Information = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.033 17.041C9.62182 17.041 11.175 16.5698 12.496 15.6871C13.817 14.8044 14.8467 13.5497 15.4546 12.0818C16.0626 10.6139 16.2216 8.99873 15.9116 7.44045C15.6015 5.88217 14.8364 4.45083 13.7128 3.32744C12.5893 2.20404 11.1579 1.43905 9.59955 1.12921C8.04123 0.819367 6.42603 0.97859 4.95821 1.58674C3.49039 2.19489 2.23588 3.22466 1.35332 4.54581C0.470762 5.86696 -0.000197724 7.42016 6.22721e-08 9.00898C0.0026468 11.1386 0.84986 13.1801 2.35579 14.6859C3.86173 16.1916 5.90342 17.0386 8.033 17.041ZM8.033 1.82098C9.45445 1.82098 10.844 2.24249 12.0259 3.03221C13.2078 3.82192 14.129 4.94438 14.6729 6.25763C15.2169 7.57089 15.3592 9.01595 15.0819 10.4101C14.8046 11.8042 14.1201 13.0848 13.115 14.09C12.1099 15.0951 10.8293 15.7796 9.43511 16.0569C8.04097 16.3342 6.59591 16.1919 5.28265 15.6479C3.9694 15.1039 2.84695 14.1828 2.05723 13.0009C1.26751 11.819 0.846 10.4294 0.846 9.00798C0.849701 7.1031 1.60812 5.27731 2.95517 3.93044C4.30222 2.58358 6.12812 1.82542 8.033 1.82198V1.82098Z"
      fill="#404040"
    />
    <path
      d="M8.03339 7.34497C7.89866 7.34497 7.76945 7.39849 7.67418 7.49376C7.57891 7.58903 7.52539 7.71824 7.52539 7.85297V12.814C7.53119 12.9447 7.5872 13.0681 7.68174 13.1585C7.77628 13.249 7.90207 13.2994 8.03289 13.2994C8.16372 13.2994 8.28951 13.249 8.38404 13.1585C8.47858 13.0681 8.53459 12.9447 8.54039 12.814V7.85297C8.54039 7.71841 8.48701 7.58935 8.39196 7.49411C8.2969 7.39887 8.16795 7.34524 8.03339 7.34497Z"
      fill="#404040"
    />
    <path
      d="M8.0331 6.52793C8.16379 6.52773 8.29149 6.4888 8.40005 6.41605C8.50862 6.3433 8.59319 6.23999 8.64307 6.11919C8.69294 5.99839 8.70589 5.86552 8.68027 5.73737C8.65465 5.60921 8.59162 5.49152 8.49914 5.39918C8.40666 5.30684 8.28888 5.24398 8.16068 5.21856C8.03249 5.19314 7.89964 5.20628 7.77891 5.25634C7.65819 5.3064 7.55501 5.39112 7.48243 5.4998C7.40984 5.60848 7.37109 5.73624 7.37109 5.86693C7.37136 6.04233 7.44122 6.21046 7.56534 6.33439C7.68946 6.45832 7.8577 6.52793 8.0331 6.52793Z"
      fill="#404040"
    />
  </svg>
);
export default Information;
