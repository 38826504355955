import React, { useEffect, useRef, useState } from 'react';
import { InfoCircle } from 'react-bootstrap-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Select from 'react-select';
import './ProfileContent.scss';

import axios from 'axios';
import { Button, Col, Container, Form, FormControl, InputGroup, Modal, Row } from 'react-bootstrap';
import { appConstants } from '../../../helper/client/constant';
import { getURL } from '../../../helper/getAPIUrl';
import '../../../sass/custom.scss';

import LinkDefenceEmail from '../../Auth-Profile/LinkDefenceEmail';
import GenericErrorModal from '../../GenericErrorModal/GenericErrorModal';
import SystemOutageModal from '../../SystemOutageModal/SystemOutageModal';
import AuthenticationModal from '../AuthenticationModal/AuthenticationModal';

import GenericErrorModalProps from '../../GenericErrorModal/__mocks__/default.json';
import AuthenticationModalProps from '../../LandingPage/AuthenticationModal/__mocks__/default.json';
import SystemOutageModalProps from '../../SystemOutageModal/__mocks__/default.json';
import { BaseInductions } from '../BaseInductions/BaseInductions';
import ProfileDetails from '../ProfileDetails/ProfileDetails';

const ProfileContent = (props) => {
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedValueAlternateBase, setselectedValueAlternateBase] = useState(props.alternativeBaseName);

  const handleClose = () => {
    setShowEmailModal(false);
    setShowLinkDefenceEmailModal(false);
  };

  const handleEdit = () => {
    setShowEmailModal(false);
    setShowLinkDefenceEmailModal(true);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      If your preferred home base is not appearing in the list below, please send this feedback to
      yourcustomer.service@defence.gov.au, and select the Defence base or location nearest to you in the meantime.
    </Tooltip>
  );
  const renderAlternativeBaseTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      If your preferred home base is not appearing in the list below, please send this feedback to
      yourcustomer.service@defence.gov.au, and select the Defence base or location nearest to you in the meantime.
    </Tooltip>
  );
  const renderWorkingLocationTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      If your preferred working location is not appearing in the list below, please send this feedback to
      yourcustomer.service@defence.gov.au, and select the Defence base or location nearest to you in the meantime.
    </Tooltip>
  );

  const renderBaseLocationTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <p>
        If your base does not appear as an option in the list provided, please select the base closest to the intended
        location.
      </p>
      <p>
        The list of bases are obtained from the Garrison and Estate Management System (GEMS) and is limited to
        functional location and property level data.
      </p>
      <p>Building level data is currently not avaliable.</p>
    </Tooltip>
  );
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [showPhoneEdit, setShowPhoneEdit] = useState(false);
  const [showMobileEdit, setShowMobileEdit] = useState(false);
  const [showHomeBaseEdit, setShowHomeBaseEdit] = useState(false);
  const [showAlternativeBaseEdit, setShowAlternativeBaseEdit] = useState(false);
  const [showWorkingLocationEdit, setShowWorkingLocationEdit] = useState(false);
  const [linkedDefenceEmail, setLinkedDefenceEmail] = useState('');
  const [dfenceEmailValid, setdfenceEmailValid] = useState(false);

  const [showLinkDefenceEmailModal, setShowLinkDefenceEmailModal] = useState(false);
  const phoneNumberInputRef = useRef();
  const mobileNumberInputRef = useRef();
  const startDateInputRef = useRef();
  const endDateInputRef = useRef();
  const [ProfileData, setProfileData] = useState({
    phoneNumber: props.phoneNumber,
    mobileNumber: '',
    homeBaseName: '',
    homeBaseState: '',
    homeBaseAddress: '',
    altBaseName: '',
    altBaseStartDate: '',
    altBaseEndDate: '',
    altBaseAddress: '',
    altBaseState: '',
    workingLocBaseName: '',
    workingLocBaseAddress: '',
    workingLocBaseState: '',
  });
  const [dropDownLocationBaseName, setDropDownLocationBaseName] = useState({
    dropDownArrayBaseName: [],
    selectedValue: '',
    selectedAddress: '',
    selectedState: '',
  });
  const [dropDownOtherBaseName, setdropDownOtherBaseName] = useState({
    dropDownArrayOtherBaseName: [],
    selectedWorkingLocation: '',
    selectedAlternateBase: '',
    selectedWorkingLocationAddress: '',
    selectedAlternateBaseAddress: '',
    selectedAlternateState: '',
    selectedWorkingState: '',
    selectedStartDate: '',
    selectedEndDate: '',
  });
  const [dropDownWorkingLocName, setdropDownWorkingLocName] = useState({
    dropDownArrayWorkingLocName: [],
    selectedWorkingLocation: '',
    selectedWorkingLocationAddress: '',
    selectedWorkingState: '',
  });

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const setField = (field, value) => {
    if (field === 'endDate') {
      setProfileData((prevState) => {
        return {
          ...prevState,
          altBaseEndDate: value,
        };
      });
    }
    if (field === 'startDate') {
      setProfileData((prevState) => {
        return {
          ...prevState,
          altBaseStartDate: value,
        };
      });
    }
    setForm({ ...form, [field]: value });
    setErrors({});
    if (!!errors[field]) setErrors({ ...errors, [field]: null });
  };

  const validateForm = () => {
    const { phoneNumber, mobileNumber, alternateBaseLocation, startDate, endDate, workingLocation } = form;

    const newErrors = {};

    if (!phoneNumber || phoneNumber === '') {
      newErrors.phoneNumber = appConstants.PROFILE_ERROR_PH;
    }
    if (phoneNumber) {
      if (!phoneNumber.match('[0-9]{10}')) {
        newErrors.phoneNumber = appConstants.PROFILE_INVALID_PH;
      }
    }
    if (!mobileNumber || mobileNumber === '') {
      newErrors.mobileNumber = appConstants.PROFILE_ERROR_PH;
    }
    if (mobileNumber) {
      if (!mobileNumber.match('[0-9]{10}')) {
        newErrors.mobileNumber = appConstants.PROFILE_INVALID_MOBILE;
      }
    }
    if (alternateBaseLocation !== appConstants.PROFILE_LOCSELECT) {
      let startDateAlt = new Date(ProfileData.altBaseStartDate);
      let endDateAlt = new Date(ProfileData.altBaseEndDate);

      //if select option is not chosen then do validation check
      if (dropDownOtherBaseName.selectedAlternateBase !== 'Select') {
        if (ProfileData.altBaseStartDate.length === 0) {
          newErrors.startdatealternatelocation = appConstants.PROFILE_ERROR_START_DATE;
        }
        if (ProfileData.altBaseEndDate.length === 0 || endDateAlt < startDateAlt) {
          newErrors.enddatealternatelocation = appConstants.PROFILE_ERROR_END_DATE;
        }
      }
    }

    /* 
    if (
      dropDownOtherBaseName.selectedAlternateBase ===
      appConstants.PROFILE_LOCSELECT
    ) {
      newErrors.alternateBaseLocation = appConstants.PROFILE_ERROR_ALT_BASE;
    } */
    if (dropDownOtherBaseName.selectedWorkingLocation === appConstants.PROFILE_LOCSELECT) {
      newErrors.workingLocation = appConstants.PROFILE_ERROR_WORK_BASE;
    }
    return newErrors;
  };

  const getBaseNameDropDown = () => {
    let URL = process.env.REACT_APP_GET_BASE_DROPDOWN_PROFILE_SETUP;
    URL = getURL(URL);
    axios
      .get(URL, {
        withCredentials: true,
      })
      .then((res) => {
        const locDetailsBase = [{ name: 'Select' }].concat(res.data.locDetails);
        //const locDetailsBase = res.data.locDetails;
        setdropDownOtherBaseName((prevState) => {
          return {
            ...prevState,
            dropDownArrayOtherBaseName: locDetailsBase,
            selectedAlternateBase: ProfileData.altBaseName,
            selectedWorkingLocation: ProfileData.workingLocBaseName,
          };
        });

        setDropDownLocationBaseName((prevState) => {
          return {
            ...prevState,
            dropDownArrayBaseName: res.data.locDetails,
            selectedValue: ProfileData.homeBaseName,
          };
        });
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };

  const getWorkingLocDropDown = () => {
    let URL = process.env.REACT_APP_GET_WORKLOC_DROPDOWN_PROFILE_SETUP;
    URL = getURL(URL);
    axios
      .get(URL, {
        withCredentials: true,
      })
      .then((res) => {
        const locDetailsBase = [{ name: 'Select' }].concat(res.data.locDetails);
        setdropDownWorkingLocName((prevState) => {
          return {
            ...prevState,
            dropDownArrayWorkingLocName: locDetailsBase,
            selectedWorkingLocation: props.workingLocBaseName,
          };
        });
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };

  const handleBaseSelect = (text, selectedBaseNameObj) => {
    setDropDownLocationBaseName((prevState) => {
      return {
        ...prevState,
        selectedValue: text,
        selectedAddress: selectedBaseNameObj.baseAddress,
        selectedState: selectedBaseNameObj.state,
      };
    });
  };

  const handleAlternateBaseSelect = (text, selectedBaseNameObj) => {
    if (text === 'Select') {
      setProfileData((prevState) => {
        return {
          ...prevState,
          altBaseStartDate: '',
          altBaseEndDate: '',
        };
      });
    }
    setselectedValueAlternateBase(text);
    setdropDownOtherBaseName((prevState) => {
      return {
        ...prevState,
        selectedAlternateBase: text,
        selectedAlternateBaseAddress: selectedBaseNameObj.baseAddress,
        selectedAlternateState: selectedBaseNameObj.state,
      };
    });
    setErrors({});
  };

  const handleWorkingLocationSelect = (text, selectedBaseNameObj) => {
    setdropDownWorkingLocName((prevState) => {
      return {
        ...prevState,
        selectedWorkingLocation: text,
        selectedWorkingLocationAddress: selectedBaseNameObj.baseAddress,
        selectedWorkingState: selectedBaseNameObj.state,
      };
    });
    setErrors({});
  };

  const updateHomeBaseHandler = (e) => {
    e.preventDefault();

    let mobileNumber = ProfileData.mobileNumber;
    let phoneNumber = ProfileData.phoneNumber;

    const objPhone = [];
    if (mobileNumber && phoneNumber === '') {
      objPhone.push({
        IsPrimary: appConstants.SR_FLAG_TRUE,
        Type: appConstants.PROFILE_PHONE_TYPE_MOBILE,
        Number: mobileNumber,
      });
    }
    if (mobileNumber && phoneNumber !== '') {
      objPhone.push({
        IsPrimary: appConstants.SR_FLAG_TRUE,
        Type: appConstants.PROFILE_PHONE_TYPE_WORK,
        Number: phoneNumber,
      });
      objPhone.push({
        IsPrimary: appConstants.SR_FLAG_FALSE,
        Type: appConstants.PROFILE_PHONE_TYPE_MOBILE,
        Number: mobileNumber,
      });
    }
    if (phoneNumber && mobileNumber === '') {
      objPhone.push({
        IsPrimary: appConstants.SR_FLAG_TRUE,
        Type: appConstants.PROFILE_PHONE_TYPE_WORK,
        Number: phoneNumber,
      });
    }
    if (phoneNumber === '' && mobileNumber === '') {
      objPhone.push({
        IsPrimary: appConstants.SR_FLAG_TRUE,
        Type: appConstants.PROFILE_PHONE_TYPE_WORK,
        Number: '00000000',
      });
    }
    const data = {
      phone: objPhone,
      baseName: dropDownLocationBaseName.selectedValue,
    };
    let URL = process.env.REACT_APP_UPDATE_USER_PROFILE_DETAILS;
    URL = getURL(URL);
    axios
      .put(URL, data, {
        withCredentials: true,
      })
      .then((res) => {
        setProfileData((prevState) => {
          return {
            ...prevState,
            homeBaseName: dropDownLocationBaseName.selectedValue,
            homeBaseAddress: dropDownLocationBaseName.selectedAddress,
            homeBaseState: dropDownLocationBaseName.selectedState,
          };
        });

        handlerClose();
        props.onComingtoProfileMainFromUpdate();
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          setAPIError(error);
          if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setErrorModal(true);
            //alert(appConstants.SR_GENERIC_ERROR);
          }
        }
      });
  };

  const updateAltBaseHandler = (e) => {
    e.preventDefault();
    const createError = validateForm();

    if (
      createError.alternateBaseLocation ||
      createError.startdatealternatelocation ||
      createError.enddatealternatelocation
    ) {
      setErrors(createError);
    } else {
      let mobileNumber = ProfileData.mobileNumber;
      let phoneNumber = ProfileData.phoneNumber;
      const objPhone = [];
      if (mobileNumber && phoneNumber === '') {
        objPhone.push({
          IsPrimary: appConstants.SR_FLAG_TRUE,
          Type: appConstants.PROFILE_PHONE_TYPE_MOBILE,
          Number: mobileNumber,
        });
      }
      if (mobileNumber && phoneNumber !== '') {
        objPhone.push({
          IsPrimary: appConstants.SR_FLAG_TRUE,
          Type: appConstants.PROFILE_PHONE_TYPE_WORK,
          Number: phoneNumber,
        });
        objPhone.push({
          IsPrimary: appConstants.SR_FLAG_FALSE,
          Type: appConstants.PROFILE_PHONE_TYPE_MOBILE,
          Number: mobileNumber,
        });
      }
      if (phoneNumber && mobileNumber === '') {
        objPhone.push({
          IsPrimary: appConstants.SR_FLAG_TRUE,
          Type: appConstants.PROFILE_PHONE_TYPE_WORK,
          Number: phoneNumber,
        });
      }
      if (phoneNumber === '' && mobileNumber === '') {
        objPhone.push({
          IsPrimary: appConstants.SR_FLAG_TRUE,
          Type: appConstants.PROFILE_PHONE_TYPE_WORK,
          Number: '00000000',
        });
      }
      const data = {
        phone: objPhone,
        alternateBaseName: dropDownOtherBaseName.selectedAlternateBase,
        startdate: ProfileData.altBaseStartDate,
        enddate: ProfileData.altBaseEndDate,
      };
      let URL = process.env.REACT_APP_UPDATE_USER_PROFILE_DETAILS;
      URL = getURL(URL);
      axios
        .put(URL, data, {
          withCredentials: true,
        })
        .then((res) => {
          setProfileData((prevState) => {
            return {
              ...prevState,
              altBaseName: dropDownOtherBaseName.selectedAlternateBase,
              altBaseAddress: dropDownOtherBaseName.selectedAlternateBaseAddress,
              altBaseState: dropDownOtherBaseName.selectedAlternateState,
            };
          });
          handlerClose();
          props.onComingtoProfileMainFromUpdate();
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            setAPIError(error);

            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
            } else if (error.response.status === 500) {
              setServiceDownModal(true);
            } else {
              setErrorModal(true);
              //alert(appConstants.SR_GENERIC_ERROR);
            }
          }
        });
    }
  };

  const updateWorkingLocHandler = (e) => {
    e.preventDefault();

    const createError = validateForm();
    if (createError.workingLocation) {
      setErrors(createError);
    } else {
      let mobileNumber = ProfileData.mobileNumber;
      let phoneNumber = ProfileData.phoneNumber;

      const objPhone = [];
      if (mobileNumber && phoneNumber === '') {
        objPhone.push({
          IsPrimary: appConstants.SR_FLAG_TRUE,
          Type: appConstants.PROFILE_PHONE_TYPE_MOBILE,
          Number: mobileNumber,
        });
      }
      if (mobileNumber && phoneNumber !== '') {
        objPhone.push({
          IsPrimary: appConstants.SR_FLAG_TRUE,
          Type: appConstants.PROFILE_PHONE_TYPE_WORK,
          Number: phoneNumber,
        });
        objPhone.push({
          IsPrimary: appConstants.SR_FLAG_FALSE,
          Type: appConstants.PROFILE_PHONE_TYPE_MOBILE,
          Number: mobileNumber,
        });
      }
      if (phoneNumber && mobileNumber === '') {
        objPhone.push({
          IsPrimary: appConstants.SR_FLAG_TRUE,
          Type: appConstants.PROFILE_PHONE_TYPE_WORK,
          Number: phoneNumber,
        });
      }
      if (phoneNumber === '' && mobileNumber === '') {
        objPhone.push({
          IsPrimary: appConstants.SR_FLAG_TRUE,
          Type: appConstants.PROFILE_PHONE_TYPE_WORK,
          Number: '00000000',
        });
      }
      const data = {
        phone: objPhone,
        workingLocation: dropDownWorkingLocName.selectedWorkingLocation,
      };
      let URL = process.env.REACT_APP_UPDATE_USER_PROFILE_DETAILS;
      URL = getURL(URL);
      axios
        .put(URL, data, {
          withCredentials: true,
        })
        .then((res) => {
          setProfileData((prevState) => {
            return {
              ...prevState,
              workingLocBaseName: dropDownWorkingLocName.selectedWorkingLocation,
              workingLocBaseAddress: dropDownWorkingLocName.selectedWorkingLocationAddress,
              workingLocBaseState: dropDownWorkingLocName.selectedWorkingState,
            };
          });
          handlerClose();
          props.onComingtoProfileMainFromUpdate();
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            setAPIError(error);
            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
            } else if (error.response.status === 500) {
              setServiceDownModal(true);
            } else {
              setErrorModal(true);
              //alert(appConstants.SR_GENERIC_ERROR);
            }
          }
        });
    }
  };

  const updatePhoneHandler = (e) => {
    e.preventDefault();
    let phoneNumber = props.phoneNumber;
    let mobileNumber = props.mobileNumber;
    const createError = validateForm();
    if (createError.phoneNumber) {
      setErrors(createError);
    } else {
      if (phoneNumberInputRef.current.value) {
        phoneNumber = phoneNumberInputRef.current.value;
      }

      const objPhone = [
        {
          IsPrimary: appConstants.SR_FLAG_TRUE,
          Type: appConstants.PROFILE_PHONE_TYPE_WORK,
          Number: phoneNumber,
        },
      ];
      if (mobileNumber) {
        objPhone.push({
          IsPrimary: appConstants.SR_FLAG_FALSE,
          Type: appConstants.PROFILE_PHONE_TYPE_MOBILE,
          Number: mobileNumber,
        });
      }
      const data = {
        phone: objPhone,
      };
      let URL = process.env.REACT_APP_UPDATE_USER_PROFILE_DETAILS;
      URL = getURL(URL);
      axios
        .put(URL, data, {
          withCredentials: true,
        })
        .then((res) => {
          setProfileData((prevState) => {
            return {
              ...prevState,
              phoneNumber: phoneNumberInputRef.current.value,
            };
          });

          handlerClose();
          props.onComingtoProfileMainFromUpdate();
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            setAPIError(error);
            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
            } else if (error.response.status === 500) {
              setServiceDownModal(true);
            } else {
              setErrorModal(true);
              //alert(appConstants.SR_GENERIC_ERROR);
            }
          }
        });
    }
  };

  const updateMobileHandler = (e) => {
    e.preventDefault();
    const createError = validateForm();
    if (createError.mobileNumber) {
      setErrors(createError);
    } else {
      const objPhone = [];
      if (ProfileData.phoneNumber === '') {
        objPhone.push({
          IsPrimary: appConstants.SR_FLAG_TRUE,
          Type: appConstants.PROFILE_PHONE_TYPE_MOBILE,
          Number: mobileNumberInputRef.current.value,
        });
      } else {
        objPhone.push(
          {
            IsPrimary: appConstants.SR_FLAG_TRUE,
            Type: appConstants.PROFILE_PHONE_TYPE_WORK,
            Number: ProfileData.phoneNumber,
          },
          {
            IsPrimary: appConstants.SR_FLAG_FALSE,
            Type: appConstants.PROFILE_PHONE_TYPE_MOBILE,
            Number: mobileNumberInputRef.current.value,
          },
        );
      }
      const data = {
        phone: objPhone,
      };
      let URL = process.env.REACT_APP_UPDATE_USER_PROFILE_DETAILS;
      URL = getURL(URL);
      axios
        .put(URL, data, {
          withCredentials: true,
        })
        .then((res) => {
          setProfileData((prevState) => {
            return {
              ...prevState,
              mobileNumber: mobileNumberInputRef.current.value,
            };
          });
          handlerClose();
          props.onComingtoProfileMainFromUpdate();
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            setAPIError(error);

            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
            } else if (error.response.status === 500) {
              setServiceDownModal(true);
            } else {
              setErrorModal(true);
              //alert(appConstants.SR_GENERIC_ERROR);
            }
          }
        });
    }
  };

  const handlerPhoneOpen = (i) => {
    setShowPhoneEdit(true);
    setShowMobileEdit(false);
    setShowHomeBaseEdit(false);
    setShowAlternativeBaseEdit(false);
    setShowWorkingLocationEdit(false);
  };

  const handlerMobileOpen = (i) => {
    setShowMobileEdit(true);
    setShowPhoneEdit(false);
    setShowHomeBaseEdit(false);
    setShowAlternativeBaseEdit(false);
    setShowWorkingLocationEdit(false);
  };

  const handlerHomeBaseOpen = (i) => {
    getBaseNameDropDown();
    setShowHomeBaseEdit(true);
    setShowMobileEdit(false);
    setShowPhoneEdit(false);
    setShowAlternativeBaseEdit(false);
    setShowWorkingLocationEdit(false);
  };

  const handlerAlternateBaseOpen = (i) => {
    getBaseNameDropDown();
    setShowAlternativeBaseEdit(true);
    setShowHomeBaseEdit(false);
    setShowMobileEdit(false);
    setShowPhoneEdit(false);
    setShowWorkingLocationEdit(false);
  };

  const handlerWorkingLocationOpen = (i) => {
    getWorkingLocDropDown();
    setShowWorkingLocationEdit(true);
    setShowAlternativeBaseEdit(false);
    setShowHomeBaseEdit(false);
    setShowMobileEdit(false);
    setShowPhoneEdit(false);
  };

  const handlerClose = () => {
    setShowPhoneEdit(false);
    setShowMobileEdit(false);
    setShowHomeBaseEdit(false);
    setShowAlternativeBaseEdit(false);
    setShowWorkingLocationEdit(false);
    setErrors({});
  };

  const submitLinkDefenceEmail = (defenceEmail) => {
    const data = {
      defenceIdentity: defenceEmail,
      externalType: 'mygovid',
    };
    let URL = process.env.REACT_APP_API_IDENTITYLINK;
    URL = getURL(URL);
    axios
      .post(URL, data, {
        withCredentials: true,
      })
      .then((res) => {
        setShowLinkDefenceEmailModal(false);

        setProfileData((prevState) => {
          return {
            ...prevState,
            myGovIDLink: {
              ...prevState.myGovIDLink,
              defenceIdentity: defenceEmail,
            },
          };
        });
      })
      .catch((error) => {
        if (error.response) {
          setAPIError(error);

          // Request made and server responded
          if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setErrorModal(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      });

    //2nd popup to confirm for email
    setShowEmailModal(true);
  };

  useEffect(() => {
    setProfileData((prevState) => {
      return {
        ...prevState,
        phoneNumber: props.phoneNumber,
        mobileNumber: props.mobileNumber,
        homeBaseName: props.homeBasebaseName,
        homeBaseAddress: props.homeBasebaseAddress,
        homeBaseState: props.homeBaseState,
        altBaseName: props.alternativeBaseName,
        altBaseStartDate: props.alternativeBaseStartDate,
        altBaseEndDate: props.alternativeBaseEndDate,
        altBaseAddress: props.alternativeBaseAddress,
        altBaseState: props.alternativeBaseState,
        workingLocBaseName: props.workingLocBaseName,
        workingLocBaseAddress: props.workingLocBaseAddress,
        workingLocBaseState: props.workingLocBaseState,
        myGovIDLink: props.myGovIDLink,
      };
    });
  }, [props]);

  return (
    <>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}

      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}

      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
        />
      )}

      {showEmailModal && (
        <Modal show onHide={handleClose} animation={false} className="modalSize">
          <Modal.Title className="px-5 modalTitle pt-5">Complete the verification process</Modal.Title>

          <Modal.Body className="px-5">
            <p>
              An email has been sent to the provided email address on the DPE with further instructions. Please follow
              the instructions to complete the verification process and access all services and features in
              ServiceConnect. For security reasons, you will be prompted to verify your Defence email address every 90
              days.
            </p>
            <p>Note: Once you have verified your Defence email address, this field will no longer be editable.</p>
          </Modal.Body>

          <Container className="px-5 pt-2 pb-6">
            <Container className="pb-2">
              <Button data-testid="emailEditButton" className="rounded-0 w-100" variant="primary" onClick={handleEdit}>
                Edit
              </Button>
            </Container>
            <Container>
              <Button
                data-testid="closeEditButton"
                className="rounded-0 w-100"
                variant="secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </Container>
          </Container>
        </Modal>
      )}

      <Container fluid className="px-lg-6 px-4 landingPageWidth">
        <Container fluid className="pt-4 pb-6 pb-lg-6 pt-lg-5">
          <Container>
            <h3>{props.personalDetailsTitle}</h3>
            <p className="pb-4">{props.personalDetailsDescription}</p>

            <Col className="d-flex desktopflex mobilecolumn">
              <Container className="pb-4">
                <h4>Given Name(s)</h4>
                <p className="mb-0">{props.givenName}</p>
              </Container>

              <Container className="pb-4">
                <h4>Surname</h4>
                <p className="mb-0">{props.surname}</p>
              </Container>

              {ProfileData.myGovIDLink?.myGovIDIdentity && (
                <Container className="pb-4">
                  <h4>MyGovID Username</h4>
                  <p className="mb-0">{ProfileData.myGovIDLink?.myGovIDIdentity}</p>
                  {props.idp === 'defence' && ProfileData.myGovIDLink?.defenceIdentity && (
                    <Button
                      variant="link"
                      className="p-0"
                      onClick={() => submitLinkDefenceEmail(ProfileData.myGovIDLink?.defenceIdentity)}
                    >
                      Re-authorise
                    </Button>
                  )}
                </Container>
              )}

              <Container className="pb-4">
                <h4>Defence email address</h4>

                {props.idp === 'mygovid' && <p className="mb-0">{ProfileData.myGovIDLink?.defenceIdentity}</p>}
                {props.idp !== 'mygovid' && <p className="mb-0">{props.email}</p>}
                {props.idp === 'mygovid' && ProfileData.myGovIDLink?.defenceIdentity && (
                  <>
                    <Button
                      variant="link"
                      className="p-0 editButton"
                      onClick={() => setShowLinkDefenceEmailModal(true)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="link"
                      className="p-0 btn btn-link poro-link ps-3"
                      onClick={() => submitLinkDefenceEmail(ProfileData.myGovIDLink?.defenceIdentity)}
                    >
                      Resend
                    </Button>
                  </>
                )}
                {props.idp === 'mygovid' && !ProfileData.myGovIDLink?.defenceIdentity && (
                  <>
                    <Button onClick={() => setShowLinkDefenceEmailModal(true)}>Verify your Defence email</Button>
                  </>
                )}
                {/* <p>
                  We have sent a email to verify your defence email address.
                </p> */}
              </Container>
            </Col>

            <Col className="d-flex desktopflex mobilecolumn">
              <Container className="pb-4">
                <h4>Phone number</h4>
                <p className="mb-0">{ProfileData.phoneNumber}</p>
                <Button variant="link" className="p-0 editButton" onClick={handlerPhoneOpen}>
                  Edit
                </Button>
              </Container>

              <Container className="pb-4">
                <h4>Mobile Number</h4>
                <p className="mb-0">{ProfileData.mobileNumber}</p>
                <Button variant="link" className="p-0 editButton" onClick={handlerMobileOpen}>
                  Edit
                </Button>
              </Container>

              <Container className="pb-4 desktop">
                <h4>ODS/PMKeys</h4>
                <p className="mb-4 pb-1">{props.employeeId === '' ? props.pmkeys : props.employeeId}</p>
              </Container>
            </Col>

            {showPhoneEdit && (
              <Container fluid className="px-0 pb-6">
                <Container fluid className="py-2 px-0 bg-defencewhisperlightgrey">
                  <h3 className="px-4 pt-4 pb-1">{props.phoneTitle}</h3>
                  <Container className="px-4 pb-4">{/* {props.phoneDescription} */}</Container>

                  <Container className="px-4 inputFormSize">
                    <Form.Label>
                      Phone Number<span className="required-field"></span>
                    </Form.Label>
                    <InputGroup>
                      <FormControl
                        className="py-2 fw-light fst-italic border-defencemediumgrey rounded-0"
                        placeholder=""
                        maxLength="10"
                        data-testid="input"
                        ref={phoneNumberInputRef}
                        //onChange={(e) => handleChange(e.target.value)}
                        //value={value}
                        value={form.phoneNumber}
                        onChange={(e) => setField('phoneNumber', e.target.value)}
                        isInvalid={!!errors.phoneNumber}
                      />
                      <Form.Control.Feedback type="invalid" className="validation-error">
                        {errors.phoneNumber}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Container>

                  <Container className="p-4 d-flex desktopflex mobilecolumn">
                    <Button variant="standard" className="mobileButton rounded-0 px-6" onClick={updatePhoneHandler}>
                      Update
                    </Button>

                    <Container className="ps-lg-3 py-lg-0 py-3">
                      <Button
                        variant="secondary"
                        className="mobileButton rounded-0 px-6 text-white"
                        onClick={handlerClose}
                      >
                        Cancel
                      </Button>
                    </Container>
                  </Container>
                </Container>
              </Container>
            )}

            {showMobileEdit && (
              <Container fluid className="px-0 pb-6">
                <Container fluid className="py-2 px-0 bg-defencewhisperlightgrey">
                  <h3 className="px-4 pt-4 pb-1">{props.mobileTitle}</h3>
                  <Container className="px-4 pb-4">{/* {props.mobileDescription} */}</Container>

                  <Container className="px-4 inputFormSize">
                    <Form.Label>Mobile Number</Form.Label>
                    <InputGroup>
                      <FormControl
                        className="py-2 fw-light fst-italic border-defencemediumgrey rounded-0"
                        placeholder="Mobile Number"
                        maxLength="10"
                        data-testid="input"
                        ref={mobileNumberInputRef}
                        //onChange={(e) => handleChange(e.target.value)}
                        //value={value}
                        value={form.mobileNumber}
                        onChange={(e) => setField('mobileNumber', e.target.value)}
                        isInvalid={!!errors.mobileNumber}
                      />
                      <Form.Control.Feedback type="invalid" className="validation-error">
                        {errors.mobileNumber}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Container>

                  <Container className="p-4 d-flex desktopflex mobilecolumn">
                    <Button variant="standard" className="mobileButton rounded-0 px-6" onClick={updateMobileHandler}>
                      Update
                    </Button>

                    <Container className="ps-lg-3 py-lg-0 py-3">
                      <Button
                        variant="secondary"
                        className="mobileButton rounded-0 px-6 text-white"
                        onClick={handlerClose}
                      >
                        Cancel
                      </Button>
                    </Container>
                  </Container>
                </Container>
              </Container>
            )}

            <Col className="d-flex desktopflex mobilecolumn">
              <Container className="pb-4 desktop">
                <h4>Service</h4>
                <p className="mb-0">{props.service}</p>
              </Container>

              <Container className="pb-4 desktop">
                <h4>Group</h4>
                <p className="mb-0">{props.group}</p>
              </Container>

              <Container className="pb-4 desktop">
                <h4>Rank or title</h4>
                <p>{props.rank}</p>
              </Container>
            </Col>

            {/* <Container className="mobile pb-4">
              <h4>Defence Email Address</h4>
              <Button variant="standard" className="bg-white mobileButton">
                Close
              </Button>
            </Container> */}
          </Container>

          <Row>
            <Col>
              <h3 className="pt-2">{props.locationDetailsTitle}</h3>
              <Container className="d-flex align-items-start gap-2">
                <p className="pb-2 desktop">{props.locationDetailsDescription} </p>
                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderBaseLocationTooltip}>
                  <Button variant="link" className="d-flex align-items-center pb-1 px-0 pt-0 mb-0 linkBase">
                    Is your location appearing?
                    <InfoCircle className="ms-1" size={15} />
                  </Button>
                </OverlayTrigger>
              </Container>

              <Container>
                <Row>
                  <Container>
                    <h4 className="pt-3">
                      {props.homeBaseText}
                      <Button variant="link" className="p-0 editButton" onClick={handlerHomeBaseOpen}>
                        Edit
                      </Button>
                    </h4>
                  </Container>

                  <Container className="d-flex desktopflex mobilecolumn">
                    <Container>
                      <h4>State</h4>
                      <p> {ProfileData.homeBaseState}</p>
                    </Container>

                    <Container>
                      <h4>Base name</h4>
                      <p>{ProfileData.homeBaseName}</p>
                    </Container>

                    <Container>
                      <h4>Base address</h4>
                      <p>{ProfileData.homeBaseAddress}</p>
                    </Container>
                  </Container>
                </Row>
                {showHomeBaseEdit && (
                  <Container fluid className="px-0 pb-6">
                    <Container fluid className="py-2 px-0 bg-defencewhisperlightgrey">
                      <h3 className="px-4 pt-4 pb-1">{props.homeBaseTitle}</h3>
                      <Container className="px-4 pb-4">
                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                          <p className="mb-0">
                            {props.homeBaseDescription}
                            <InfoCircle size={15} />
                          </p>
                        </OverlayTrigger>
                      </Container>

                      <Form.Label className="px-4">Select your home base below.</Form.Label>
                      <Container className="px-4 inputFormSize">
                        <Select
                          style={{
                            borderColor: 'gray',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                          }}
                          value={[
                            {
                              label: dropDownLocationBaseName.selectedValue,
                              value: dropDownLocationBaseName.selectedValue,
                            },
                          ]}
                          onChange={(selection, action) => {
                            handleBaseSelect(selection.label, selection.value);
                          }}
                          options={dropDownLocationBaseName.dropDownArrayBaseName.map((key, text) => {
                            return {
                              value: key,
                              label: key.name,
                            };
                          })}
                        />
                      </Container>

                      <Container className="p-4 d-flex desktopflex mobilecolumn">
                        <Button
                          variant="standard"
                          className="mobileButton rounded-0 px-6"
                          onClick={updateHomeBaseHandler}
                        >
                          Update
                        </Button>

                        <Container className="ps-lg-3 py-lg-0 py-3">
                          <Button
                            variant="secondary"
                            className="mobileButton rounded-0 px-6 text-white"
                            onClick={handlerClose}
                          >
                            Cancel
                          </Button>
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                )}
              </Container>

              <Row>
                <Container>
                  <h4 className="pt-3">
                    {props.alternativeBaseText}
                    <Button variant="link" className="p-0 editButton" onClick={handlerAlternateBaseOpen}>
                      Edit
                    </Button>
                  </h4>
                </Container>

                <Container className="d-flex desktopflex mobilecolumn">
                  <Container>
                    <h4>State</h4>
                    <p> {ProfileData.altBaseState}</p>
                  </Container>

                  <Container>
                    <h4>Alternate Base name</h4>
                    <p>{ProfileData.altBaseName}</p>
                  </Container>

                  <Container>
                    <h4>Alternate Base address</h4>
                    <p>{ProfileData.altBaseAddress}</p>
                  </Container>
                </Container>
                {showAlternativeBaseEdit && (
                  <Container fluid className="px-0 pb-6">
                    <Container fluid className="py-2 px-0 bg-defencewhisperlightgrey">
                      <h3 className="px-4 pt-4 pb-1">{props.alternativeBaseTitle}</h3>
                      <Container className="px-4 pb-4">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderAlternativeBaseTooltip}
                        >
                          <p className="mb-0">
                            {props.alternativeBaseDescription}
                            <InfoCircle size={15} />
                          </p>
                        </OverlayTrigger>
                      </Container>

                      <Form.Label className="px-4">Alternate Base</Form.Label>
                      <Container className="px-4 inputFormSize">
                        <Select
                          style={{
                            borderColor: 'gray',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                          }}
                          value={[
                            {
                              label: dropDownOtherBaseName.selectedAlternateBase,
                              value: dropDownOtherBaseName.selectedAlternateBase,
                            },
                          ]}
                          onChange={(selection, action) => {
                            handleAlternateBaseSelect(selection.label, selection.value);
                          }}
                          options={dropDownOtherBaseName.dropDownArrayOtherBaseName.map((key, text) => {
                            return {
                              value: key,
                              label: key.name,
                            };
                          })}
                        />

                        <Form.Label className="profile-other-info-text">Date From</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder=""
                          name="startDate"
                          ref={startDateInputRef}
                          value={ProfileData.altBaseStartDate}
                          onChange={(e) => setField('startDate', e.target.value)}
                        />
                        <div className="dropdown-error-msg">{errors.startdatealternatelocation}</div>

                        <Form.Group>
                          <Form.Label className="profile-other-info-text">Date To</Form.Label>
                          <Form.Control
                            type="date"
                            placeholder=""
                            name="endDate"
                            ref={endDateInputRef}
                            value={ProfileData.altBaseEndDate}
                            onChange={(e) => setField('endDate', e.target.value)}
                          />
                        </Form.Group>
                        <div className="dropdown-error-msg">{errors.enddatealternatelocation}</div>
                      </Container>

                      <Container className="p-4 d-flex desktopflex mobilecolumn">
                        <Button
                          variant="standard"
                          className="mobileButton rounded-0 px-6"
                          onClick={updateAltBaseHandler}
                        >
                          Update
                        </Button>

                        <Container className="ps-lg-3 py-lg-0 py-3">
                          <Button
                            variant="secondary"
                            className="mobileButton rounded-0 px-6 text-white"
                            onClick={handlerClose}
                          >
                            Cancel
                          </Button>
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                )}
              </Row>

              <Row>
                <Container>
                  <h4 className="pt-3">
                    Working Location
                    <Button variant="link" className="p-0 editButton" onClick={handlerWorkingLocationOpen}>
                      Edit
                    </Button>
                  </h4>
                </Container>

                <Container className="d-flex desktopflex mobilecolumn">
                  <Container>
                    <h4>State</h4>
                    <p> {ProfileData.workingLocBaseState}</p>
                  </Container>

                  <Container>
                    <h4>Location name</h4>
                    <p>{ProfileData.workingLocBaseName}</p>
                  </Container>

                  <Container>
                    <h4>Location address</h4>
                    <p>{ProfileData.workingLocBaseAddress}</p>
                  </Container>
                </Container>
                {showWorkingLocationEdit && (
                  <Container fluid className="px-0 pb-6">
                    <Container fluid className="py-2 px-0 bg-defencewhisperlightgrey">
                      <h3 className="px-4 pt-4 pb-1">Edit Working Location</h3>
                      <Container className="px-4 pb-4">
                        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                          <p className="mb-0">
                            {props.workingLocationDesc}
                            <InfoCircle size={15} />
                          </p>
                        </OverlayTrigger>
                      </Container>

                      <Form.Label className="px-4">Working Location</Form.Label>
                      <Container className="px-4 inputFormSize">
                        <Select
                          style={{
                            borderColor: 'gray',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                          }}
                          value={[
                            {
                              label: dropDownWorkingLocName.selectedWorkingLocation,
                              value: dropDownWorkingLocName.selectedWorkingLocation,
                            },
                          ]}
                          onChange={(selection, action) => {
                            handleWorkingLocationSelect(selection.label, selection.value);
                          }}
                          options={dropDownWorkingLocName.dropDownArrayWorkingLocName.map((key, text) => {
                            return {
                              value: key,
                              label: key.name,
                            };
                          })}
                        />
                        <div className="dropdown-error-msg">{errors.workingLocation}</div>
                      </Container>

                      <Container className="p-4 d-flex desktopflex mobilecolumn">
                        <Button
                          variant="standard"
                          className="mobileButton rounded-0 px-6"
                          onClick={updateWorkingLocHandler}
                        >
                          Update
                        </Button>

                        <Container className="ps-lg-3 py-lg-0 py-3">
                          <Button
                            variant="secondary"
                            className="mobileButton rounded-0 px-6 text-white"
                            onClick={handlerClose}
                          >
                            Cancel
                          </Button>
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                )}
              </Row>

              <Container className="pb-3">
                <Container className="mobile p-4 d-flex bg-defencewhisperlightgrey">
                  <Container className="mobile">
                    <h4>Working Location</h4>
                    <p>{props.workingLocationDesc}</p>
                    <Button
                      variant="standard"
                      className="px-5 bg-white mobileButton"
                      href={props.workingLocationCtaUrl}
                    >
                      {props.ctaText}
                    </Button>
                  </Container>
                </Container>
              </Container>

              <Container className="mobile p-4 d-flex bg-defencewhisperlightgrey">
                <Container className="mobile">
                  <h4>Alternate base</h4>
                  <p>{props.alternativeBaseDescription}</p>
                  <Button variant="standard" className="px-5 bg-white mobileButton" href={props.alternativeBaseCtaUrl}>
                    {props.ctaText}
                  </Button>
                </Container>
              </Container>
            </Col>
          </Row>
        </Container>
      </Container>
      <BaseInductions basesInducted={props.basesInducted} {...props.baseInductionContent} />
      <Modal show={showLinkDefenceEmailModal}>
        <Modal.Body>
          <LinkDefenceEmail
            defenceEmail={linkedDefenceEmail}
            onChangeEmail={(value) => setLinkedDefenceEmail(value)}
            onIsEmailValid={(isvalid) => setdfenceEmailValid(isvalid)}
          />
          <Button variant="secondary" onClick={() => setShowLinkDefenceEmailModal(false)}>
            Close
          </Button>
          <Button
            disabled={!dfenceEmailValid}
            onClick={() => submitLinkDefenceEmail(linkedDefenceEmail)}
            style={{ float: 'right' }}
          >
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileContent;
