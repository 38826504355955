import React from 'react';
import { EFTConstants } from '../../../../pages/EFT/EFTConstant';
import { Link } from '@chakra-ui/react';

export const getUploadInfo = (e): { maxFiles: number; caption: JSX.Element; tooltipContent?: string } => {
  switch (e) {
    case EFTConstants.PIR_CATEGORY_COMMON_IDENTITY:
      return {
        maxFiles: 2,
        caption: (
          <>
            Two{' '}
            <Link
              href="https://immi.homeaffairs.gov.au/help-support/applying-online-or-on-paper/on-paper/certified-copy"
              target="_blank"
              rel="noreferrer noopener"
            >
              certified
            </Link>{' '}
            proof of identity documents of family member (Max 1MB, pdf jpg jpeg).
            <br />
            1. Primary - passport, drivers licence, birth certificate. <br />
            2. Secondary - medicare card, senior card, or RSL membership card.
          </>
        ),
        tooltipContent:
          'Your identity has been verified through your ServiceConnect profile. You do not need to provide any further proof of identity documents.',
      };

    case EFTConstants.PIR_CATEGORY_COMMON_SIGNED_CONSENT:
      return {
        maxFiles: 1,
        caption: (
          <>
            Upload a hand-signed document with the wording "I [name] residing at [address] consent to allow my family
            member's Defence information to be released to [name] via [address]. By signing this document I authorise
            Defence to disclose the information identified in the online application made via www.defence.gov.au on
            [date]." followed by signature. (Max 1MB, pdf jpg jpeg).
          </>
        ),
      };
    case EFTConstants.PIR_CATEGORY_COMMON_SIGN_THIS_FORM:
      return {
        maxFiles: 1,
        caption: (
          <>
            Upload a hand-signed document with the wording "This signature supports my online application for Defence
            information on [insert date]." followed by a signature (Max 1MB, pdf jpg jpeg).
          </>
        ),
      };
    case EFTConstants.PIR_CATEGORY_COMMON_PROOF_OF_FAMILY_MEMBER:
      return {
        maxFiles: 1,
        caption: (
          <>
            Example: marriage certificate, birth certificate, proof of defacto relationship, etc (Max 1MB, pdf jpg
            jpeg).
          </>
        ),
      };
    case EFTConstants.PIR_CATEGORY_COMMON_FAMILY_MEMBER_IDENTIFICATION:
      return {
        maxFiles: 2,
        caption: (
          <>
            Two certified proof of identity documents of the ex-member (Max 1MB, pdf jpg jpeg). Primary - passport,
            drivers licence, birth certificate. Secondary - medicare card, senior card, or RSL membership card.
          </>
        ),
      };
    case EFTConstants.PIR_CATEGORY_COMMON_FAMILY_MEMEBER_SIGNED_CONSENT:
      return {
        maxFiles: 1,
        caption: (
          <>
            Upload a hand-signed document with the wording "I [name] residing at [address] consent to allow my Defence
            information to be released to [name] via [address]. By signing this document I authorise Defence to disclose
            the information identified in the online application made via www.defence.gov.au on [date]." followed by
            signature. (Max 1MB, pdf jpg jpeg).
          </>
        ),
      };
    case EFTConstants.PIR_CATEGORY_COMMON_PROOF_OF_DEATH:
      return { maxFiles: 1, caption: <>Example: death certificate or funeral notice (Max 1MB, pdf jpg jpeg).</> };

    default:
      return { maxFiles: 0, caption: <></> };
  }
};
