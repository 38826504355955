import React from 'react';
import './ProfileDetails.scss';
import { Container, Row, Col } from 'react-bootstrap';
import '../../../sass/custom.scss';
import { Link } from 'react-router-dom';

const ProfileDetails = (props) => {
  return (
    <Container fluid className="bg-defencecharcoalgrey">
      <Container fluid className="px-4 px-lg-6 landingPageWidth">
        <Row>
          <Col className="py-lg-5 py-4 profileinfoText">
            <h2 className="text-white">{props.title}</h2>
            <p className="text-white">{props.description}</p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
export default ProfileDetails;
