import * as React from 'react';
import { SVGProps } from 'react';
const PIRHistorical = (props: SVGProps<SVGSVGElement>) => (
  <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M36.857 42.9997C36.0085 42.9997 35.3213 42.3125 35.3213 41.464V19.964H26.107C25.2585 19.964 24.5713 19.2768 24.5713 18.4283C24.5713 17.5798 25.2585 16.8926 26.107 16.8926H36.857C37.7055 16.8926 38.3927 17.5798 38.3927 18.4283V41.464C38.3927 42.3125 37.7055 42.9997 36.857 42.9997Z"
        fill="#CF4928"
      />
      <path
        d="M12.2857 43.0002C11.4372 43.0002 10.75 42.313 10.75 41.4645V27.6431C10.75 26.7946 11.4372 26.1074 12.2857 26.1074C13.1342 26.1074 13.8214 26.7946 13.8214 27.6431V41.4645C13.8214 42.313 13.1342 43.0002 12.2857 43.0002Z"
        fill="#CF4928"
      />
      <path
        d="M41.4645 39.9289C40.616 39.9289 39.9288 39.2417 39.9288 38.3932V15.3576H27.6431C26.7946 15.3576 26.1074 14.6703 26.1074 13.8218C26.1074 12.9733 26.7946 12.2861 27.6431 12.2861H41.4645C42.313 12.2861 43.0002 12.9733 43.0002 13.8218V38.3932C43.0002 39.2417 42.313 39.9289 41.4645 39.9289Z"
        fill="#CF4928"
      />
      <path
        d="M30.7148 26.1076H21.5006C20.6521 26.1076 19.9648 25.4203 19.9648 24.5718C19.9648 23.7233 20.6521 23.0361 21.5006 23.0361H30.7148C31.5633 23.0361 32.2505 23.7233 32.2505 24.5718C32.2505 25.4203 31.5633 26.1076 30.7148 26.1076Z"
        fill="#CF4928"
      />
      <path
        d="M30.714 32.2501H18.4283C17.5798 32.2501 16.8926 31.5629 16.8926 30.7144C16.8926 29.8659 17.5798 29.1787 18.4283 29.1787H30.714C31.5625 29.1787 32.2497 29.8659 32.2497 30.7144C32.2497 31.5629 31.5625 32.2501 30.714 32.2501Z"
        fill="#CF4928"
      />
      <path
        d="M30.714 38.3927H18.4283C17.5798 38.3927 16.8926 37.7055 16.8926 36.857C16.8926 36.0085 17.5798 35.3213 18.4283 35.3213H30.714C31.5625 35.3213 32.2497 36.0085 32.2497 36.857C32.2497 37.7055 31.5625 38.3927 30.714 38.3927Z"
        fill="#CF4928"
      />
      <path
        d="M12.2857 24.5715C5.51136 24.5715 0 19.0601 0 12.2858C0 11.4373 0.687212 10.7501 1.53571 10.7501C2.38421 10.7501 3.07142 11.4373 3.07142 12.2858C3.07142 17.3671 7.20443 21.5 12.2857 21.5C17.367 21.5 21.5 17.367 21.5 12.2858C21.5 7.20451 17.367 3.0715 12.2857 3.0715C9.2757 3.0715 6.44802 4.5477 4.72213 7.02022C4.23838 7.71707 3.27856 7.8898 2.58363 7.40223C1.8887 6.91848 1.71788 5.96058 2.20354 5.26565C4.50136 1.96773 8.27169 0 12.2857 0C19.06 0 24.5714 5.51136 24.5714 12.2857C24.5714 19.06 19.06 24.5715 12.2857 24.5715Z"
        fill="#CF4928"
      />
      <path
        d="M12.2854 13.8211H7.67829C6.82979 13.8211 6.14258 13.1339 6.14258 12.2854C6.14258 11.4369 6.82979 10.7497 7.67829 10.7497H10.7497V7.67829C10.7497 6.82979 11.4369 6.14258 12.2854 6.14258C13.1339 6.14258 13.8211 6.82979 13.8211 7.67829V12.2854C13.8211 13.1339 13.1339 13.8211 12.2854 13.8211Z"
        fill="#CF4928"
      />
      <path
        d="M6.14327 7.67898H3.07184C2.22335 7.67898 1.53613 6.99177 1.53613 6.14327V3.07184C1.53613 2.22335 2.22335 1.53613 3.07184 1.53613C3.92034 1.53613 4.60756 2.22335 4.60756 3.07184V4.60756H6.14327C6.99177 4.60756 7.67898 5.29477 7.67898 6.14327C7.67898 6.99177 6.99177 7.67898 6.14327 7.67898Z"
        fill="#CF4928"
      />
    </g>
  </svg>
);
export default PIRHistorical;
