import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import convertSpecialChars from '../../../helper/convertSpecialChars';
import { findByProp } from '../../../helper/findByProp';
import { setFieldValue } from '../../../reducers/eftRequestDetailFormReducer';
import { SCForm } from '../../../components/ServiceUI/Core/FormElements';

const FormTextAreaInput = ({
  fieldID,
  label,
  fieldPropMapping,
  required,
  visible,
  reference,
  formData,
  error,
  readOnly,
}) => {
  const eftreqDetailDataFromReduxStore = useSelector((state: any) => {
    if (state) {
      return findByProp(state.EFTRequestMemberForm.formFields, 'reference', reference);
    }
  });
  const [displayValue, setDisplayValue] = useState(eftreqDetailDataFromReduxStore?.value);
  const dispatch = useDispatch();
  const onTextAreaBlur = (e) => {
    setDisplayValue(e);
  };
  useEffect(() => {
    dispatch(
      setFieldValue({
        reference,
        value: displayValue,
        formData,
        required,
        visible,
      }),
    );
  }, [reference, displayValue, required]);

  useEffect(() => {
    const updatedValueText = eftreqDetailDataFromReduxStore?.value;
    setDisplayValue(updatedValueText);
  }, [eftreqDetailDataFromReduxStore]);

  return (
    <SCForm.TextArea
      disabled={readOnly}
      onBlur={(e) => onTextAreaBlur(e)}
      visible={visible}
      desktopWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop}
      mobileWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile}
      required={required}
      label={label}
      value={displayValue}
      isInvalid={error}
      errorMessage={fieldPropMapping[fieldID]?.required?.errorMessage}
    />
  );
};

export default FormTextAreaInput;
