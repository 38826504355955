import React, { useState, useEffect } from 'react';

import { Col, Form, FormControl } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import convertSpecialChars from '../../../../helper/convertSpecialChars';
import { findByProp } from '../../../../helper/findByProp';
import { setFieldValue } from '../../../../reducers/requestDetailFormReducer';

const FormTextAreaInput = ({ fieldID, label, fieldPropMapping, required, visible, reference, formData }) => {
  const reqDetailDataFromReduxStore = useSelector((state) => {
    if (!!state) {
      return findByProp(state.requestForm.formFields, 'reference', reference);
    }
  });
  const [displayValue, setDisplayValue] = useState(convertSpecialChars(reqDetailDataFromReduxStore?.value));
  const dispatch = useDispatch();
  const onTextAreaBlur = (e) => {
    setDisplayValue(e.target.value);
  };
  useEffect(() => {
    dispatch(
      setFieldValue({
        reference,
        value: displayValue,
        formData,
        required,
        visible,
      }),
    );
  }, [reference, displayValue, required]);

  useEffect(() => {
    const updatedValueText = convertSpecialChars(reqDetailDataFromReduxStore?.value);

    setDisplayValue(updatedValueText);
  }, [reqDetailDataFromReduxStore]);

  return (
    <>
      {visible && (
        <Col
          lg={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop ?? 12}
          sm={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile ?? 12}
          className="mb-4 pe-4"
        >
          <Form.Group controlId={fieldID}>
            <Form.Label className={required ? 'required-field' : ''} dangerouslySetInnerHTML={{ __html: label }} />
            <FormControl
              onBlur={(e) => onTextAreaBlur(e)}
              onChange={(e) => onTextAreaBlur(e)}
              as="textarea"
              type="text"
              required={required}
              value={displayValue}
            />
            <Form.Control.Feedback type="invalid">
              {fieldPropMapping[fieldID]?.required?.errorMessage}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      )}
    </>
  );
};

export default FormTextAreaInput;
