import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import content from './Content/Help.json';
import allcontent from './Content/General.json';
import axios from 'axios';
import { getURL } from '../../helper/getAPIUrl';

import Header from '../../components/ServiceUI/Core/Header/Header';
import Feedback from '../../components/ServiceUI/Core/Feedback/Feedback';
import Accordion from '../../components/ServiceUI/Core/Accordion/Accordion';

export const HelpPage: React.FC = () => {
  useEffect(() => {
    document.title = "Help and FAQ's";
  });
  const FEEDBACK_API = process.env.REACT_APP_FEEDBACK;

  return (
    <>
      <Box>
        <Header {...content.header} />

        {content.accordion.map((value, index) => (
          <Accordion {...value} key={index} index={index} />
        ))}
        <Feedback
          {...allcontent.feedback}
          modalProps={{
            ...allcontent.feedback.modalProps,
            submitAction: (data) =>
              axios.post(getURL(FEEDBACK_API), data, {
                withCredentials: true,
              }),
          }}
        />
      </Box>
    </>
  );
};
