import React from 'react';
import './SupportOverview.scss';
import { Container } from 'react-bootstrap';
import '../../../sass/custom.scss';

const SupportOverview = (props) => {
  return (
    <Container className="p-4 p-lg-6">
      <Container fluid className="bg-white landingPageWidth">
        <Container className="p-3 p-lg-6 overviewBox">
          <h2 className="pb-lg-3">{props.title}</h2>
          <p>{props.description}</p>
        </Container>
      </Container>
    </Container>
  );
};
export default SupportOverview;
