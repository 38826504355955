import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { setShowError } from '../../reducers/errorHandling';

import content from './Content/ProvideDocuments.json';
import requestContent from './Content/RequestInformation';

import { useNavigate, useParams } from 'react-router-dom';

import AuthenticationModal from '../../components/LandingPage/AuthenticationModal/AuthenticationModal';
import AuthenticationModalProps from '../../components/LandingPage/AuthenticationModal/__mocks__/default.json';
import KeyRequestInfo from '../../components/ServiceUI/EFT/KeyRequestInfo/KeyRequestInfo';

import { Accordion, Button } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { GenericAccordionItem } from '../../components/ServiceUI/Core/Accordion/GenericAccordionItem';
import { SCForm } from '../../components/ServiceUI/Core/FormElements';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import UtilityModals from '../../components/ServiceUI/Core/Modal/UtilityModals';
import SendDocUpload from '../../components/ServiceUI/Core/Send-Doc-Upload/Send-Doc-Upload';
import { Spinner } from '../../components/ServiceUI/Core/Spinner/Spinner';
import { ViewPIR } from '../../components/ServiceUI/EFT/ViewPIR/ViewPIR';
import { appConstants } from '../../helper/client/constant';
import { getURL } from '../../helper/getAPIUrl';
import { savePIR } from '../../helper/savePIR';
import { request } from '../../helper/useAxios';
import { PIRCaseAudit } from './API/PIRCaseAudit';
export const ProvideDocumentsPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    document.title = 'Send Document Request';
  }, []);
  const [uploadedFilesCount, setUploadedFilesCount] = useState(0);
  const [pirData, setPirData] = useState<any>();
  const [showAuthenticationFailed, setShowAuthenticationFailed] = useState(false);

  const [noteToSender, setNoteToSender] = useState('');
  const { alert, confirm } = UtilityModals();

  useEffect(() => {
    setLoading(true);
    if (id) {
      request('REACT_APP_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL', 'get', { id: id })
        .then((res) => {
          setPirData(res.data);

          setNoteToSender(res.data.content.PersonalInfoRequest.CommentsForApplicant ?? '');
          setLoading(false);
          PIRCaseAudit(id, '', 'PIR03')
            .then((_) => {})
            .catch((error) => {
              dispatch(setShowError({ error: true, code: error.response.status }));
            });
          if (res.data && res.data?.status !== 'Pending-Fulfillment') {
            setShowAuthenticationFailed(true);
          }
          content.header.mainHeading = res.data?.content?.PersonalInfoRequest?.ServiceTypeValue;
          content.header.subHeading =
            requestContent.businessBannerCaption[res.data?.content?.PersonalInfoRequest?.ServiceType];
        })
        .catch((error) => {
          dispatch(setShowError({ error: true, code: error.response.status }));
        });
    }
  }, []);

  const handleSaveAndClose = async () => {
    if (await confirm('Would you like to save or cancel this request?', { cancelText: 'Cancel', okText: 'Save' })) {
      setLoading(true);
      const pageInstructionFieldForSendDoc = [
        {
          instruction: 'UPDATE',
          target: '.PersonalInfoRequest',
          content: {
            CommentsForApplicant: noteToSender,
          },
        },
      ];
      const data = {
        content: {},
        pageInstructions: pageInstructionFieldForSendDoc,
      };
      const finalID = id ?? '';
      await savePIR(finalID, data)
        .then((_) => {
          setLoading(false);
          console.log('after success');
          navigate('/');
        })
        .catch((error) => {
          setLoading(false);
          dispatch(setShowError({ error: true, code: error.response.status }));
        });
    }
  };
  const handleSendDocument = () => {
    const pageInstructionFieldForSendDoc = [
      {
        instruction: 'UPDATE',
        target: '.PersonalInfoRequest',
        content: {
          CommentsForApplicant: noteToSender,
        },
      },
    ];
    const data = {
      content: {},
      pageInstructions: pageInstructionFieldForSendDoc,
    };

    let URL =
      `${process.env.REACT_APP_UPDATE_SERVICE_REQUEST_DETAILS}ASSIGN-WORKBASKET ${id}!UPLOAD_FLOW` +
      (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV
        ? '?actionID=UploadDocumentation'
        : '/UploadDocumentation');
    URL = getURL(URL);
    axios
      .post(URL, data, {
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);
        PIRCaseAudit(id, '', 'PIR04')
          .then((_) => {})
          .catch((error) => {
            dispatch(setShowError({ error: true, code: error.response.status }));
          });
        navigate(`/personal-information-request/provide-documents/complete/${id}`);
      })
      .catch((error) => {
        dispatch(setShowError({ error: true, code: error.response.status }));
      });
  };
  const preCheckFilesUploaded = async () => {
    if (uploadedFilesCount === 0) {
      if (
        !(await confirm(
          'You have added one or more attachments but have not uploaded them. Are you sure you want to continue?',
          { cancelText: 'No', okText: 'Yes' },
        ))
      ) {
        return;
      }
    }
    handleSendDocument();
  };
  return (
    <>
      <Header {...content.header} />
      {showAuthenticationFailed && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
        />
      )}
      <SubContainer>
        <>
          <KeyRequestInfo variant="full" pirInfo={pirData} />
          <Accordion allowToggle>
            <GenericAccordionItem title="View all request information">
              <ViewPIR PIR={pirData?.content?.PersonalInfoRequest} />
            </GenericAccordionItem>
          </Accordion>
          <SendDocUpload caseId={id} setUploadedFilesCount={setUploadedFilesCount} />
          <SCForm.TextArea
            visible
            value={noteToSender}
            onBlur={(e) => setNoteToSender(e)}
            label="Details for the applicant"
            desktopWidth={12}
            required
            isInvalid={!noteToSender}
            errorMessage="This field is required"
          />
          {loading && <Spinner />}
          <div className="col-12">
            {/* TODO: change back after discussions with business
               <Flex justifyContent="space-between" flexWrap={{ lg: 'nowrap', base: 'wrap' }} gap={3} pb="1rem"> */}
            <Button variant="secondary" onClick={() => handleSaveAndClose()} w={{ lg: 'auto', base: '100%' }}>
              Save & Close
            </Button>
            <Button
              variant="primary"
              isDisabled={!noteToSender}
              className=" float-md-end mb-2"
              onClick={() => preCheckFilesUploaded()}
              w={{ lg: 'auto', base: '100%' }}
            >
              Finalise Request
            </Button>
          </div>
        </>
      </SubContainer>
    </>
  );
};
