import { ComponentStyleConfig } from '@chakra-ui/react';
import { spacing } from '../spacing';
import { disabledField } from '../constants';
const Input: ComponentStyleConfig = {
  parts: ['field'],
  baseStyle: {},
  variants: {
    primary: {
      field: {
        fontSize: 'regular',
        fontWeight: '200',
        height: '44px',
        borderRadius: '2px',
        backgroundColor: 'white',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'mediumgrey',
        padding: `0.5rem ${spacing.default}`,
        _focus: {
          borderWidth: '2px',
          borderColor: 'charcoal',
        },
        _disabled: disabledField,
        _readOnly: disabledField,
        _invalid: { borderColor: 'redalert' },
      },
    },
    mobileSearch: {
      field: { height: '44px', borderRadius: 0 },
    },
  },
};

export default Input;
