import { Modal, Button, Row, Col } from 'react-bootstrap';

const DeleteNotificationModal = (props) => {
  return (
    <Modal dialogClassName="modal-lg modalPadding px-lg-0 py-5" contentClassName="p-lg-4" show={props.show}>
      <Modal.Header className=" pb-0 bg-defencewhite border-0 flex-column align-items-start">
        <h1>Are you sure you want to delete all notifications?</h1>
      </Modal.Header>
      <Modal.Footer className="border-0 d-flex justify-content-between">
        <Row>
          <Col className="pe-2">
            <Button className="uploadbuttonWidth" variant="standard" onClick={props.deleteAction}>
              Yes
            </Button>
          </Col>
          <Col>
            <Button className="uploadbuttonWidth" variant="standard" onClick={props.closeAction}>
              No
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteNotificationModal;
