import { createSlice } from '@reduxjs/toolkit';
import { appConstants, cateringCTAs } from '../helper/client/constant';
import findAndReplace from '../helper/findAndReplace';
import { findByProp } from '../helper/findByProp';
import findPath from '../helper/findPath';

const trasportFieldMap = {
  SelfDrive: [
    'IsReturnLegRequired',
    'PickUpDate',
    'PickUpDay',
    'PickupLocation',
    'VehicleType',
    'AutoOrManual',
    'PickUpTime',
    'AdditionalInfo',
    'VehicleOptions',
    'PassengerNumber',
  ],
  DriverVehicle: [
    'IsReturnLegRequired',
    'PickUpDate',
    'PickUpDay',
    'DropoffLocation',
    'PickupLocation',
    'PickUpTime',
    'AdditionalInfo',
    'PassengerNumber',
  ],
  Other: ['pyDescription'],
  SelfDriveIsReturnLegRequired: ['ReturnDate', 'ReturnDay', 'ReturnTime', 'ReturnDropOffLocation'],
  DriverVehicleIsReturnLegRequired: [
    'ReturnDate',
    'ReturnDay',
    'ReturnTime',
    'ReturnPickUpTime',
    'ReturnPickupLocation',
    'ReturnDropOffLocation',
  ],
  VehicleType: ['OtherVehicleType'],
};

const wasteFields = ['EODetails', 'EOAcknowledge'];

const cateringFieldMap = {
  MealInMess: [
    'MealType',
    'DateReqFrom',
    'DateReqTo',
    'TimeReq',
    'FurtherRequirements',
    'NumberAttending',
    'PickUpTime',
  ],
  MealAwayMess: [
    'MealType',
    'DateReqFrom',
    'DateReqTo',
    'TimeReq',
    'FurtherRequirements',
    'Quantity',
    'PickupDelivery',
  ],
  Other: ['DateReqFrom', 'DateReqTo', 'TimeReq', 'FurtherRequirements'],
  KnownAllergiesYN: ['AllergyDetails'],
  PickupDelivery: ['DeliveryLocation'],
};

// added pickupdate and returndate to validate manual date input
// user is unable to input a date in the past or the next 7 days
const initialState = {
  formFields: {
    fieldGroup: [],
  },
  transportType: 'SelfDrive',
  mealCount: 1,
  uploaded: false,
  currentType: '',
  pageInstructions: [],
  nextAssignmentID: '',
  PickUpDate: [
    {
      fieldID: 'PickUpDateValidation',
      label: 'PickupDate',
      maxLength: 0,
      readOnly: false,
      reference: 'ServiceRequest.PickUpDateValidation',
      error: false,
    },
  ],
  ReturnDate: [
    {
      fieldID: 'ReturnDateValidation',
      label: 'ReturnDate',
      visible: false,
      maxLength: 0,
      readOnly: false,
      reference: 'ServiceRequest.ReturnDateValidation',
      error: false,
    },
  ],
  notificationsCount: null,
};

const generateFieldList = (fieldMap) => {
  return Object.values(fieldMap).reduce(function (acc, x) {
    acc = [...acc, ...x];
    return acc;
  }, []);
};

const cateringFields = generateFieldList(cateringFieldMap);
const transportFields = generateFieldList(trasportFieldMap);

const processFields = (item, fieldsArr, currentType, state) => {
  if (
    !!item.field &&
    item?.field?.fieldID !== 'WorkType' &&
    item?.field?.fieldID !== 'WorkSubType' &&
    item?.field?.fieldID !== 'ServiceType'
  ) {
    if (item.field.reference) {
      const currentFormData = _processField(item.field, currentType);
      fieldsArr[item.field.fieldID] = currentFormData;
    }
  } else if (!!item.view) {
    item.view?.groups.forEach((group) => {
      if (group.layout.fieldListID === '.Items') {
        group.layout?.rows.forEach((row, index) => {
          let processedFields = [];

          const fields = findPath(row, ['field', 'caption']);
          fields.forEach((field) => {
            const currentFormData = _processField(field, currentType);
            processedFields.push(currentFormData);
          });
          if (index !== 0) {
            processedFields = [
              ...processedFields,
              {
                ...cateringCTAs.removeMeal,
                reference: index + 1,
              },
            ];
          }
          processedFields.length > 0 && fieldsArr['fieldGroup'].push(processedFields);
        });
        state.mealCount = group.layout?.rows.length;
      } else if (currentType === appConstants.SR_TYPE_CATERING && group.layout.title !== 'Hospitality and Catering') {
        const fields = findPath(group.layout, ['field', 'caption']);

        fields.forEach((field) => {
          processFields({ field: field }, fieldsArr, currentType);
        });
      } else {
        const processedFields = [];
        const fields = findPath(group.layout, ['field', 'caption']);
        fields.forEach((field) => {
          const currentFormData = _processField(field, currentType);
          processedFields.push(currentFormData);
        });
        processedFields.length > 0 && fieldsArr['fieldGroup'].push(processedFields);
      }
    });
  }
};

const _processField = (field, currentType) => {
  let visible = true;
  switch (currentType) {
    case appConstants.SR_TYPE_CATERING:
      visible = !cateringFields.includes(field?.fieldID);
      break;
    case appConstants.SR_TYPE_TRANSPORT:
      visible = !transportFields.includes(field?.fieldID);
      break;
    case appConstants.SR_TYPE_WASTE:
      visible = !wasteFields.includes(field?.fieldID);
      break;
    default:
      break;
  }

  if (currentType === appConstants.SR_TYPE_TRANSPORT && field.label === 'Please provide some detail on this request') {
    visible = true;
  }
  const required = field?.customAttributes?.required ? field?.customAttributes?.required : field?.required;
  return {
    ...field,
    fieldType: field?.type,
    type: field.captionFor ? 'caption' : field?.control?.type,
    visible,
    required,
    error: required && visible && field.value === '',
    value: field.type === 'True-False' ? (field.value === 'true' ? true : false) : field.value,
  };
};

export const requestDetailForm = createSlice({
  name: 'requestDetail',
  initialState,
  reducers: {
    setFormFields: (state, { payload }) => {
      let fieldsArr = {
        fieldGroup: [],
      };
      payload.fieldList.forEach((value) => {
        processFields(value, fieldsArr, state.currentType, state);
      });
      if (state.currentType === appConstants.SR_TYPE_CATERING) {
        //fieldsArr = { addNewMeal: cateringCTAs.addNewMeal, ...fieldsArr };

        // NOTE: added this code to change position of the Add new item button
        // BUG raised: https://dev.azure.com/UCAzuredefence/ServiceConnect/_git/React.js/pullrequest/1170?_a=files

        //make temp fields array to get the addNewMeal object
        const tempfieldsArr = {
          addNewMeal: cateringCTAs.addNewMeal,
          ...fieldsArr,
        };

        //convert into array
        const newArray = Object.entries(fieldsArr);

        //get the array value
        const arrayValue = Object.entries(tempfieldsArr)[0];

        //add the value at index 1
        newArray.splice(1, 0, arrayValue);

        //convert to object
        const fieldsObj = Object.fromEntries(newArray);

        fieldsArr = fieldsObj;
      }
      // setFormData(fieldsArr);
      state.formFields = fieldsArr;
    },

    setNotificationsRead: (state, { payload }) => {
      state.notificationsCount = payload;
    },

    setTransportType: (state, { payload }) => {
      let { value: payloadValue, radioSelection, formData } = payload;
      state.transportType = payloadValue;
      const fieldGroup = [...formData.fieldGroup[0]];
      const fieldListUpdated = fieldGroup.map((field) => {
        if (transportFields.includes(field.fieldID)) {
          // on transport type radio selection, set the field values to empty
          // in case of edit leave it as is
          field = {
            ...field,
            visible: false,
            value: radioSelection ? '' : field.value,
            error: false,
          };
        }
        if (trasportFieldMap[payloadValue].includes(field.fieldID)) {
          field = {
            ...field,
            visible: true,
            error: field.required && field.value === '',
          };
        }
        return { ...field };
      });
      state.formFields = {
        ...state.formFields,
        fieldGroup: [[...fieldListUpdated]],
      };
    },
    setReturnLeg: (state, { payload }) => {
      let { value: payloadValue, fieldID } = payload;
      const returnLegKey = state.transportType + fieldID;
      const fieldGroup = [...state.formFields.fieldGroup[0]];
      const fieldListUpdated = fieldGroup?.map((field) => {
        if (trasportFieldMap[returnLegKey]?.includes(field.fieldID)) {
          const fieldValue = payloadValue === false ? '' : field.value;
          field = {
            ...field,
            error: field.required && payloadValue && !fieldValue,
            visible: payloadValue,
            value: fieldValue,
          };
        }
        return field;
      });

      state.formFields = {
        ...state.formFields,
        fieldGroup: [[...fieldListUpdated]],
      };
    },
    setWasteExplosive: (state, action) => {
      state.formFields.fieldGroup[0].forEach((field, key) => {
        if (wasteFields.includes(field.fieldID)) {
          if (action.payload === 'No') {
            state.formFields.fieldGroup[0][key].fieldType === 'Text'
              ? (state.formFields.fieldGroup[0][key].value = '')
              : (state.formFields.fieldGroup[0][key].value = 'false');
          }
          state.formFields.fieldGroup[0][key].visible = action.payload === 'Yes';
          state.formFields.fieldGroup[0][key].error =
            state.formFields.fieldGroup[0][key].required &&
            state.formFields.fieldGroup[0][key].visible &&
            (state.formFields.fieldGroup[0][key].value === '' ||
              state.formFields.fieldGroup[0][key].value === false ||
              state.formFields.fieldGroup[0][key].value === 'false');
        }
      });
    },

    setAttachmentmessage: (state, action) => {
      // if (action.payload === "Transport" || action.payload === "Waste")
      if (action.payload === 'Waste') {
        state.formFields['pyTemplateInputBox'].error = state.formFields['pyTemplateInputBox'].value.length === 0;
      }
    },

    setUploadAttachments: (state, action) => {
      //getting boolean from dispatch
      state.uploaded = action.payload;
    },

    setFieldValue: (state, { payload }) => {
      let { value: payloadValue, reference } = payload;
      const field = findByProp(state.formFields, 'reference', reference);

      let fieldList = findAndReplace(state.formFields, 'reference', reference, 'value', payloadValue);
      fieldList = findAndReplace(
        fieldList,
        'reference',
        reference,
        'error',
        field.required && field.visible && !payloadValue,
      );
      state.formFields = { ...fieldList };
    },
    setCateringMealLocation: (state, { payload }) => {
      let currIndex = parseInt(payload.reference.match(/\(([^)]+)\)/)[1]);
      currIndex = currIndex - 1;
      let formData = { ...state.formFields };
      let fieldGroup = [...formData['fieldGroup']];
      const fieldList = [...formData['fieldGroup'][currIndex]];
      const fieldListUpdated = fieldList.map((field) => {
        return {
          ...field,
          visible:
            field.fieldID !== 'MealLocation' ? cateringFieldMap[payload.selectedItem].includes(field.fieldID) : true,
          error: cateringFieldMap[payload.selectedItem].includes(field.fieldID)
            ? field.required && field.value === ''
            : false,
        };
      });
      fieldGroup[currIndex] = [...fieldListUpdated];
      state.formFields = {
        ...state.formFields,
        fieldGroup: [...fieldGroup],
      };
    },
    addNewMeal: (state, { payload }) => {
      const currMealCount = state.mealCount + 1;
      state.mealCount = currMealCount;
      const newFields = state.formFields.fieldGroup[0].reduce((acc, field) => {
        return cateringFields.includes(field.fieldID) || field.fieldID === 'MealLocation'
          ? [
              ...acc,
              {
                ...field,
                // reference: field.reference.replaceAll(
                //   /\s*\(.*?\)\s*/g,
                //   `(${currMealCount})`
                // ),
                reference: field.reference.replace(/\s*\(.*?\)\s*/g, `(${currMealCount})`),
                visible: field.fieldID === 'MealLocation' ? true : false,
                value: '',
                error: field.fieldID === 'MealLocation' ? true : false,
              },
            ]
          : acc;
      }, []);
      state.formFields = {
        ...state.formFields,
        fieldGroup: [
          ...state.formFields.fieldGroup,
          [
            ...newFields,
            {
              ...cateringCTAs.removeMeal,
              reference: currMealCount - 1,
            },
          ],
        ],
      };
    },
    removeMeal: (state, { payload }) => {
      state.mealCount = state.mealCount - 1;
      let fieldGroupArr = [...payload.formData.fieldGroup];
      fieldGroupArr.splice(payload.reference, 1);
      fieldGroupArr.forEach((items, index) => {
        fieldGroupArr[index] = items.reduce((acc, field) => {
          return [
            ...acc,
            {
              ...field,
              reference:
                typeof field.reference === 'string' && field.reference?.replace(/\s*\(.*?\)\s*/g, `(${index + 1})`),
            },
          ];
        }, []);
      });

      state.formFields = {
        ...state.formFields,
        fieldGroup: [...fieldGroupArr],
      };
    },
    setKnownAllergies: (state, { payload }) => {
      const fieldIds = Object.keys(state.formFields);
      fieldIds.forEach((fieldId) => {
        if (cateringFieldMap[payload.fieldID].includes(fieldId)) {
          state.formFields[fieldId].visible = payload.value === 'Yes';
          state.formFields[fieldId].error =
            state.formFields[fieldId].required &&
            state.formFields[fieldId].visible &&
            state.formFields[fieldId].value === '';
        }

        // findAndReplace(field, payload.fieldID, field.fieldID, )
      });
    },
    setVehicleType: (state, { payload }) => {
      const fieldList = state.formFields['fieldGroup'];
      fieldList.forEach((field, key) => {
        field.forEach((item, itemKey) => {
          if (trasportFieldMap[payload.fieldID].includes(item.fieldID)) {
            state.formFields.fieldGroup[key][itemKey].visible = payload.value === 'Other';
            state.formFields.fieldGroup[key][itemKey].error =
              state.formFields.fieldGroup[key][itemKey].required &&
              state.formFields.fieldGroup[key][itemKey].visible &&
              state.formFields.fieldGroup[key][itemKey].value === '';
          }
        });

        // findAndReplace(field, payload.fieldID, field.fieldID, )
      });
    },
    setRequestRelatesTo: (state, { payload }) => {
      let { value } = payload;
      state.formFields = {
        ...state.formFields,
        RequestRelationDetail: {
          ...state.formFields['RequestRelationDetail'],
          required: value !== 'None' ? true : false,
          visible: value !== 'None' ? true : false,
          value: value === 'None' ? '' : state.formFields['RequestRelationDetail']?.value,
          error: value !== 'None' && state.formFields['RequestRelationDetail']?.value.length === 0 ? true : false,
        },
      };
    },
    setDayName: (state, { payload }) => {
      let { date, fieldToUpdate } = payload;
      const d = new Date(date);
      let day = d.toLocaleDateString('en-AU', { weekday: 'long' });
      let fieldList = findAndReplace(state.formFields, 'reference', fieldToUpdate, 'value', day);
      state.formFields = { ...fieldList };
    },

    setDateValidation: (state, { payload }) => {
      let { date, fieldToUpdate, isVisible } = payload;
      const selectedDate = new Date(date);
      const currentDate = new Date();
      const sevenDaysFromNow = new Date();
      sevenDaysFromNow.setDate(currentDate.getDate() + 6);
      let value = selectedDate < currentDate || selectedDate < sevenDaysFromNow;

      //PickUp Validation
      if (fieldToUpdate === 'ServiceRequest.PickUpDateValidation') {
        const objList = {
          fieldID: 'PickUpDateValidation',
          label: 'PickupDate',
          maxLength: 0,
          readOnly: false,
          reference: 'ServiceRequest.PickUpDateValidation',
          error: value ? true : false,
        };
        state.PickUpDate = [objList];
      } else {
        //ReturnDate Validation
        const objList = {
          fieldID: 'ReturnDateValidation',
          label: 'ReturnDate',
          visible: isVisible,
          maxLength: 0,
          readOnly: false,
          reference: 'ServiceRequest.ReturnDateValidation',
          error: value && isVisible ? true : false,
        };
        state.ReturnDate = [objList];
      }
    },

    setCurrentCaseDetails: (state, { payload }) => {
      state.currentType = payload.currentType;
      state.nextAssignmentID = payload.nextAssignmentID;
    },
    setPageInstructions: (state, { payload }) => {
      state.pageInstructions = [...payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFormFields,
  setNotificationsRead,
  setTransportType,
  setReturnLeg,
  setWasteExplosive,
  setFieldValue,
  setCateringMealLocation,
  setAttachmentmessage,
  setUploadAttachments,
  addNewMeal,
  removeMeal,
  setKnownAllergies,
  setVehicleType,
  setRequestRelatesTo,
  setDayName,
  setDateValidation,
  setCurrentCaseDetails,
  setPageInstructions,
} = requestDetailForm.actions;

export default requestDetailForm.reducer;
