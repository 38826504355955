import { appConstants } from '../helper/client/constant';

const cookie = document.cookie;
const cookieSplit = cookie.split(/[;.\s]/); //array of cookies

export const getIDPCookie = () => {
  //check if local (local does not have IDP cookie)
  if (
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_LOCAL_ENV
    // || process.env.REACT_APP_CURRENT === appConstants.REACT_APP_DEV_ENV ||
    // process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST_ENV ||
    // process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST2_ENV
  ) {
    //console.log("IN LOCAL IS TRUE");
    return true;
  } else {
    //check IDP cookie
    if (cookieSplit.includes('IDP=mygovid')) {
      //console.log("EXTERNAL IS FALSE");
      return false; //external
    } else if (cookieSplit.includes('IDP=defence')) {
      //console.log("INTERNAL IS TRUE");
      return true; //internal
    } else {
      if (
        !cookieSplit.includes('IDP=mygovid') &&
        !cookieSplit.includes('IDP=defence') &&
        window.location.href.includes(appConstants.SR_PUBLIC_PORTAL)
      ) {
        return false; //external
      } else {
        return true; //internal
      }
    }
  }
};
