import React from 'react';
import { Container, Button, Modal, Form, Row } from 'react-bootstrap';
import { useState } from 'react';

const FirstPopUp = (props) => {
  const [isServiceRequired, setIsServiceRequired] = useState(false);
  const [isServiceRelated, setIsServiceRelated] = useState(false);

  return (
    <>
      <Modal show={props.show} animation={false} dialogClassName="modal-xl modalPadding px-lg-0 py-6">
        <Modal.Body className="px-lg-5 pt-lg-5 px-4 pt-5">
          <Container className="p-4 bg-defencemediumlightblue mobilecolumn">
            <p className="fw-bold m-0 d-inline">Is this service required within 7 days?</p>
            <Form className="d-lg-inline pb-2 px-lg-3 fw-light">
              <Form.Group className="d-inline">
                <Form.Check
                  defaultChecked
                  inline
                  label="Yes"
                  name="notice"
                  type="radio"
                  onChange={() => setIsServiceRequired(false)}
                />
                <Form.Check inline label="No" name="notice" type="radio" onChange={() => setIsServiceRequired(true)} />
              </Form.Group>
            </Form>
            <p className="fw-light">
              If this service request is required within 7 days, please contact the Base Service Support Centre (BSSC)
              on 1300 658 975
            </p>
            <a className="phone fw-light" href="tel:1300 658 975">
              1300 658 975
            </a>
          </Container>

          <Container className="px-4 pt-4 mobilecolumn">
            <p className="fw-bold m-0 d-inline">
              Is this service request related to an authorised exercise requiring multiple services? (bulk form)
            </p>
            <Form className="d-lg-inline pb-2 ps-lg-3 fw-light">
              <Form.Group className="d-inline">
                <Form.Check
                  defaultChecked
                  inline
                  label="Yes"
                  name="notice"
                  type="radio"
                  onChange={() => setIsServiceRelated(false)}
                />
                <Form.Check inline label="No" name="notice" type="radio" onChange={() => setIsServiceRelated(true)} />
              </Form.Group>
            </Form>
            <p className="fw-light">
              If this service request requires multiple services for an authorised exercise, please contact the Base
              Service Support Centre (BSSC) on 1300 658 975
            </p>
          </Container>
        </Modal.Body>

        <Modal.Footer className="border-0 px-5 pb-5 d-flex justify-content-between footerMobile">
          <Row className="pt-2 p-lg-0 mobileButton reLoginButton">
            <Button data-testid="closeButton" className="rounded-0" variant="standard" onClick={props.close}>
              Cancel
            </Button>
          </Row>

          {isServiceRequired === true && isServiceRelated === true && (
            <Button
              data-testid="ContinueButton"
              className="rounded-0 reLoginButton mobileButton"
              variant="primary"
              onClick={() => {
                props.continue();
                setIsServiceRelated(false);
                setIsServiceRequired(false);
              }}
            >
              Continue
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FirstPopUp;
