import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Header from '../LandingPage/Header/Header';
import content from './Content/HelpandFAQs.json';
import SubNavigation from './MSRcomponents/SubNavigation/SubNavigation';
import SubNavProps from './MSRcomponents/SubNavigation/__mocks__/default.json';

import Feedback from '../LandingPage/Feedback/Feedback';

import FAQs from '../../components/LandingPage/FAQs/FAQs';
//import FAQProps from "./MSRcomponents/FAQs/__mocks__/default.json";

const Content = () => {
  useEffect(() => {
    document.title = "Help and FAQ's";
  }, []);

  return (
    <>
      <SubNavigation {...SubNavProps} />
      <Container className="msrbackground">
        <Header
          servicerequest
          breadcrumbs={content.header.breadcrumbs}
          mainHeading={content.header.mainHeading}
          subHeading={content.header.subHeading}
        />
        {content.FAQs.map((item, idx) => {
          return (
            <FAQs
              key={idx}
              expandButtonText={item.expandButtonText}
              title={item.title}
              FAQs={item.FAQs}
              odd={idx % 2 ? true : false}
            />
          );
        })}
      </Container>{' '}
      <Container className="mb-3 px-4 px-lg-6">
        <p className="text-defencered landingPageWidth">
          *Link accessible through the Defence network (Defence Protected Environment) only.
        </p>
      </Container>{' '}
      <Feedback variant="servicerequest" {...content.feedback} />
    </>
  );
};

export default Content;
