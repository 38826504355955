import * as React from 'react';
import { SVGProps } from 'react';
const Chevron_Orange = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 9" {...props}>
    <g clipPath="url(#clip0_116_4)">
      <path
        fill="#CF4928"
        d="M7.43074 8.71124C7.5047 8.78717 7.59312 8.84751 7.69078 8.88872C7.78844 8.92992 7.89336 8.95115 7.99935 8.95115C8.10535 8.95115 8.21027 8.92992 8.30793 8.88872C8.40559 8.84751 8.49401 8.78717 8.56797 8.71124L15.7329 1.54634C15.8195 1.46289 15.8887 1.36301 15.9363 1.25252C15.9839 1.14203 16.009 1.02316 16.0102 0.90286C16.0113 0.782557 15.9885 0.663233 15.943 0.551865C15.8974 0.440497 15.8302 0.33932 15.7451 0.254249C15.66 0.169178 15.5589 0.101918 15.4475 0.056405C15.3361 0.0108915 15.2168 -0.011963 15.0965 -0.0108237C14.9762 -0.00968436 14.8573 0.0154266 14.7468 0.0630411C14.6364 0.110656 14.5365 0.179817 14.453 0.266484L8.56859 6.15092C8.49463 6.22684 8.40621 6.28719 8.30855 6.32839C8.2109 6.36959 8.10597 6.39082 7.99998 6.39082C7.89398 6.39082 7.78906 6.36959 7.6914 6.32839C7.59374 6.28719 7.50533 6.22684 7.43136 6.15092L1.54631 0.283999C1.46324 0.195904 1.36334 0.125372 1.25252 0.076577C1.1417 0.0277821 1.02222 0.00171703 0.901153 -7.48625e-05C0.780083 -0.00186676 0.659883 0.0206504 0.547671 0.0661444C0.435459 0.111638 0.333515 0.179185 0.247875 0.264783C0.162235 0.350381 0.0946391 0.452291 0.0490902 0.564481C0.00354136 0.676671 -0.019035 0.79686 -0.0173023 0.917932C-0.0155696 1.039 0.010437 1.1585 0.0591777 1.26934C0.107918 1.38018 0.178403 1.48011 0.266457 1.56322L7.43074 8.71124Z"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={16} height={8.95144} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Chevron_Orange;
