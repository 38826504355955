import axios from 'axios';
import React, { useState } from 'react';
import GenericErrorModal from '../../components/GenericErrorModal/GenericErrorModal';
import GenericErrorModalProps from '../../components/GenericErrorModal/__mocks__/default.json';
import AuthenticationModal from '../../components/LandingPage/AuthenticationModal/AuthenticationModal';
import AuthenticationModalProps from '../../components/LandingPage/AuthenticationModal/__mocks__/default.json';
import ErrorPopUp from '../../components/MyServiceRequest/CreateRequest/ErrorPopup/ErrorPopup';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import KeyRequestInfo from '../../components/ServiceUI/EFT/KeyRequestInfo/KeyRequestInfo';
import { ViewPIR } from '../../components/ServiceUI/EFT/ViewPIR/ViewPIR';
import SystemOutageModal from '../../components/SystemOutageModal/SystemOutageModal';
import SystemOutageModalProps from '../../components/SystemOutageModal/__mocks__/default.json';
import { appConstants } from '../../helper/client/constant';
import { getURL } from '../../helper/getAPIUrl';
import content from './Content/NewRequestView.json';
import { BackLink } from '../../components/ServiceUI/Core/Links/Links';
export const NewRequestViewPage: React.FC = () => {
  let CASEID = new URLSearchParams(document.location.search).get('CASEID');
  const [pirInfo, setPirInfo] = React.useState<any>();
  const [pirRequestInfo, setPirRequestInfo] = React.useState<any>();
  const [error, setError] = React.useState(false);
  const [attachmentInfo, setAttachmentInfo] = useState([]);
  const [showSpinnerApiIsInProgress, setShowSpinnerApiIsInProgress] = useState(false);
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalHttpCode, setShowModalHttpCode] = useState('');
  const [showError, setShowError] = useState(false);

  //error handling

  const setPirData = (pirData: any) => {
    setPirInfo(pirData);
    setPirRequestInfo(pirData.content.PersonalInfoRequest);
  };
  React.useEffect(() => {
    if (!!CASEID) {
      const URL_GET_DATA_FOR_SR = process.env.REACT_APP_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL + CASEID;
      const final_URL_GET_DATA_FOR_SR = getURL(URL_GET_DATA_FOR_SR);

      axios
        .get(final_URL_GET_DATA_FOR_SR, {
          withCredentials: true,
        })
        .then((res) => {
          setPirData(res.data);
        })
        .catch((err) => {
          let error = { ...err };
          error.response = error.response || { response: { data: [] } };
          setAPIError(error);
          if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
            setShowModalHttpCode(error.response.status);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setErrorModal(true);
          }
        });
      let newURL = process.env.REACT_APP_GET_ATTACHMENT_ALL + CASEID + '/attachments';
      newURL = getURL(newURL);
      axios
        .get(newURL, {
          withCredentials: true,
        })
        .then((res) => {
          const finalArr = res.data.attachments.filter(
            (file) =>
              file.category !== appConstants.ATTACHMENT_TYPE_CORRESPONDENCE &&
              file.category !== appConstants.ATTACHMENTS_FILE_CATEGORY,
          );
          setAttachmentInfo(finalArr);
          setShowSpinnerApiIsInProgress(false);
        })
        .catch((err) => {
          setShowSpinnerApiIsInProgress(false);
          let error = { ...err };
          error.response = error.response || { response: { data: [] } };
          if (error.response.status === 400) {
            setShowError(true);
            setError(error.response.data);
          } else if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
            setShowModalHttpCode(error.response.status);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setAPIError(error);
            setErrorModal(true);
          }
        });
    }
  }, []);

  return (
    <>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}

      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}

      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
          descriptionNoPrivilage={AuthenticationModalProps.descriptionWithNoPrivilage}
          code={showModalHttpCode}
        />
      )}
      <ErrorPopUp show={showError} error={error} closeAction={() => setShowError(false)} />
      <Header {...content.header} />
      <SubContainer verticalMargin="1rem">
        <BackLink text="Back to homepage" href=".." />
        {error === false ? (
          <>
            <KeyRequestInfo variant="submitted" pirInfo={pirInfo} />
            <ViewPIR PIR={pirRequestInfo} Attachments={attachmentInfo} />
          </>
        ) : (
          <>No data found for this case</>
        )}
      </SubContainer>
    </>
  );
};
