import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SCForm } from '../../../components/ServiceUI/Core/FormElements';
import convertSpecialChars from '../../../helper/convertSpecialChars';
import { findByProp } from '../../../helper/findByProp';
import { setFieldValue } from '../../../reducers/eftRequestDetailFormReducer';
const FormTextInput = ({
  fieldID,
  label,
  fieldPropMapping,
  required,
  visible,
  reference,
  error,
  formData,
  type,
  value,
  readOnly,
  errorMessage,
}) => {
  const reqDetailDataFromReduxStore = useSelector((state: any) => {
    if (state) {
      return findByProp(state.EFTRequestMemberForm.formFields, 'reference', reference);
    }
  });
  const [displayValue, setDisplayValue] = useState(value);
  const dispatch = useDispatch();
  const [showTooltip, setshowTooltip] = useState(false);

  useEffect(() => {
    /*  if (fieldID === "AmountOfAssets") {
      setVisibleField(false);
    }
    if (fieldID === "RequestRelationDetail") {
      setVisibleField(false);
    } */
    if (fieldID === 'RequestTitle' || fieldID === 'CostCodeCentre') {
      setshowTooltip(true);
    }
  }, [fieldID]);

  useEffect(() => {
    dispatch(
      setFieldValue({
        reference,
        value: displayValue,
        formData,
        required,
        visible,
      }),
    );
  }, [displayValue]);

  useEffect(() => {
    const convertedValue = convertSpecialChars(reqDetailDataFromReduxStore?.value ?? '');

    setDisplayValue(convertedValue);
  }, [reqDetailDataFromReduxStore]);

  return (
    <>
      <SCForm.Text
        disabled={readOnly}
        id={fieldID}
        desktopWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop}
        mobileWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile}
        label={label}
        value={displayValue}
        visible={visible}
        required={required}
        isInvalid={error}
        readOnly={readOnly}
        errorMessage={fieldPropMapping[fieldID]?.required?.errorMessage}
        onBlur={(e) => {
          setDisplayValue(e);
        }}
      />
    </>
  );
};

export default FormTextInput;
