import { Container, Modal, Button, Image } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getURL } from '../../../../helper/getAPIUrl';

import './HeaderModal.scss';
import { useNavigate } from 'react-router-dom';
import icon_hammer from '../Images/icon_hammer.svg';
import icon_list from '../Images/icon_list.svg';
import { appConstants } from '../../../../helper/client/constant';

import SystemOutageModal from '../../../SystemOutageModal/SystemOutageModal';
import GenericErrorModal from '../../../GenericErrorModal/GenericErrorModal';
import AuthenticationModal from '../../../LandingPage/AuthenticationModal/AuthenticationModal';

import AuthenticationModalProps from '../../../LandingPage/AuthenticationModal/__mocks__/default.json';
import GenericErrorModalProps from '../../../GenericErrorModal/__mocks__/default.json';
import SystemOutageModalProps from '../../../SystemOutageModal/__mocks__/default.json';

const HeaderModal = (props) => {
  const data = props.modalProps;

  return (
    <>
      <Modal
        centered
        size="md"
        data-testid="modal"
        dialogClassName="pt-4 pb-4 mh-20 ps-1 modal-lg modal-dialog pe-2"
        contentClassName="p-3 p-lg-4 modal-75h srtModalContent"
        show={props.show}
      >
        <HeaderModalContent close={props.close} data={data} />
      </Modal>
    </>
  );
};

const HeaderModalContent = (props) => {
  const [MMdescription, setMMDescription] = useState({ refValues: [] });
  const [SRdescription, setSRDescription] = useState({ refValues: [] });
  const [reqisInProgress, setReqIsInProgress] = useState(false);
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  //get minor maintenance List - WorkType is the parameter
  const GetMinorMaintenanceList = () => {
    let URL = process.env.REACT_APP_GET_REF_DATA + 'WorkType';
    URL = getURL(URL);
    axios
      .get(URL, {
        withCredentials: true,
      })
      .then((res) => {
        //setMMDescription(res.data.refValues);
        setMMDescription((prevState) => {
          return {
            ...prevState,
            refValues: res.data.refValues,
          };
        });

        //setMMDescription(res.data.refValues);
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);

        if (error.response.status === 400) {
          console.log(error.response.data);
        } else if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };

  //get minor maintenance List - WorkType is the parameter
  const GetServiceRequestList = () => {
    let URL = process.env.REACT_APP_GET_REF_DATA + 'ServiceType';
    URL = getURL(URL);
    axios
      .get(URL, {
        withCredentials: true,
      })
      .then((res) => {
        //setSRDescription(res);

        setSRDescription((prevState) => {
          return {
            ...prevState,
            refValues: res.data.refValues,
          };
        });
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);

        if (error.response.status === 400) {
          console.log(error.response.data);
        } else if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };

  const createNewReq = (type) => {
    setReqIsInProgress(true);
    const data = {
      caseTypeID: 'DoD-SConn-Work-ServiceRequest',
      content: {},
    };
    /* setLoading((prevState) => {
          return {
            ...prevState,
            isLoading: true,
          };
        }); */

    // INFO: Create case id call
    const URL_CREATE_NEW_REQUEST_API = getURL(process.env.REACT_APP_CREATE_NEW_REQUEST_API);
    axios
      .post(URL_CREATE_NEW_REQUEST_API, data, {
        withCredentials: true,
      })
      .then((res) => {
        // NOTE: call to get the next action which will help us to know hwat is the next action
        const URL_NEXT_ACTION_API = process.env.REACT_APP_NEXT_ACTION_API + res.data.nextAssignmentID;
        const final_URL_NEXT_ACTION_API = getURL(URL_NEXT_ACTION_API);
        axios
          .get(final_URL_NEXT_ACTION_API, {
            withCredentials: true,
          })
          .then((res) => {
            // updating the app states this we will use in update call
            sessionStorage.setItem('nextAssignmentId', JSON.stringify(res.data.ID));
            sessionStorage.setItem('actionId', JSON.stringify(res.data.actions[0].ID));
            // This call is to prepopulate the req data
            const URL_CREATE_ACTION_API =
              process.env.REACT_APP_CREATE_ACTION_API +
              res.data.ID +
              '/actions/' +
              res.data.actions[0].ID +
              (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV ? '/?flatListOfFields=Basic' : '');
            const final_URL_CREATE_ACTION_API = getURL(URL_CREATE_ACTION_API);
            axios
              .get(final_URL_CREATE_ACTION_API, {
                withCredentials: true,
              })
              .then((res) => {
                sessionStorage.setItem('caseId', res.data.caseID);
                sessionStorage.setItem('createReqResData', JSON.stringify(res.data));
                let path = `/my-service-request/new-service-request?type=${type}`;
                setReqIsInProgress(false);
                navigate(path);
              })
              .catch((err) => {
                setReqIsInProgress(false);
                let error = { ...err };
                error.response = error.response || { response: { data: [] } };
                setAPIError(error);
                if (error.response.status === 403 || error.response.status === 401) {
                  setShowModal(true);
                } else if (error.response.status === 500) {
                  setServiceDownModal(true);
                } else {
                  setErrorModal(true);
                }
              });
          })
          .catch((err) => {
            setReqIsInProgress(false);
            let error = { ...err };
            error.response = error.response || { response: { data: [] } };
            setAPIError(error);
            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
            } else if (error.response.status === 500) {
              setServiceDownModal(true);
            } else {
              setErrorModal(true);
            }
          });
      })
      .catch((err) => {
        setReqIsInProgress(false);
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };

  useEffect(() => {
    GetMinorMaintenanceList();
    GetServiceRequestList();
  }, []);

  const [MMRSelected, setMMRSelected] = useState();
  let navigate = useNavigate();
  const routeChange = (type) => {
    //check if savedID matches the present caseID, then only u can create a request?
    //check implemented so if user goes clicks back
    //createNewReq(type);
    /* let saveId = window.sessionStorage.getItem("savedCaseId");
        let cId = window.sessionStorage.getItem("caseId");
        if (saveId !== cId) {
          
          //goes here so happens onClick of start new SR
        } */
    if (!reqisInProgress) {
      createNewReq(type);
    }
  };

  //Minor Maintenace Panel List
  //const MMRefValueArray = MMdescription;
  /*   MMRefValueArray.map((value) => {
    }); */

  //Service Request Panel List
  //const SRRefValueArray = SRdescription.data.refValues;

  const dataDescription = props.data;
  return (
    <>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}

      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}

      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
        />
      )}

      {/*  {loading.isLoading && (
        <Container>
          <h2 style={{ textAlign: "center", margin: "20px" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </h2>
        </Container>
      )} */}

      {/* {!loading.isLoading && ( */}
      <>
        <Modal.Title className="ps-4 pt-3 headerModalTitle">{dataDescription.modalHeader}</Modal.Title>
        <Modal.Body className="pt-0">
          <Container fluid className="d-flex gap-5 mt-4 mobilecolumn">
            <Container
              tabIndex={0}
              className={`p-4 modalCard ${MMRSelected === true ? 'modalCardClicked' : 'modalCardUnclicked'}`}
              aria-selected={MMRSelected === true ? 'true' : 'false'}
              role="button"
              onKeyDown={(e) => e.key === 'Enter' && setMMRSelected(true)}
              onClick={() => setMMRSelected(true)}
            >
              <Image className="mb-3" src={icon_hammer} alt="" />
              <h2>{dataDescription.modalContainerNotice1.heading}</h2>

              {/* {MMRefValueArray} */}
              <p className="text-defenceorange">
                {/* Add API description here  from WorkType*/}
                {dataDescription.modalContainerNotice1.description}
              </p>
              <ul>
                {MMdescription.refValues.map((value, index) => {
                  return (
                    <Container>
                      {value.description !== 'Please select a service type' && (
                        <li key={index} className="fw-light">
                          {value.description}
                        </li>
                      )}
                    </Container>
                  );
                })}
                {/* {MMRefValueArray.map((value, index) => {
                return (
                  <li key={index} className="fw-light">
                    {value.description}
                  </li>
                );
              })} */}
                {/*       {data.modalContainerNotice1.listings.map((item, index) => {
                return (
                  <li key={index} className="fw-light">
                    {item}
                  </li>
                );
              })} */}
              </ul>
            </Container>
            <Container
              tabIndex={0}
              className={`p-4 modalCard ${MMRSelected === false ? 'modalCardClicked' : 'modalCardUnclicked'}`}
              role="button"
              aria-selected={MMRSelected === false ? 'true' : 'false'}
              onKeyDown={(e) => e.key === 'Enter' && setMMRSelected(false)}
              onClick={() => setMMRSelected(false)}
            >
              <Image className="mb-3" src={icon_list} alt="" />
              <h2>{dataDescription.modalContainerNotice2.heading}</h2>
              <p className="text-defenceorange">
                {/* Add API description here from ServiceType*/}

                {dataDescription.modalContainerNotice2.description}
              </p>
              <ul>
                {SRdescription.refValues.map((value, index) => {
                  return (
                    <Container>
                      {value.description !== 'Please select a service type' && (
                        <li key={index} className="fw-light">
                          {value.description}
                        </li>
                      )}
                    </Container>
                  );
                })}
                {/*  {SRRefValueArray.map((value, index) => {
                return (
                  <li key={index} className="fw-light">
                    {value.description}
                  </li>
                );
              })} */}
                {/*  {data.modalContainerNotice2.listings.map((item, index) => {
                return (
                  <li key={index} className="fw-light">
                    {item}
                  </li>
                );
              })} */}
              </ul>
            </Container>
          </Container>
        </Modal.Body>

        <Modal.Footer className="border-0 px-3 d-flex justify-content-between">
          <Button variant="standard" onClick={props.close}>
            Cancel
          </Button>
          {MMRSelected !== undefined && (
            <Button
              variant="primary"
              disabled={reqisInProgress}
              onClick={() =>
                routeChange(
                  MMRSelected ? appConstants.SERVICE_REQUEST_TYPE_MINOR : appConstants.SERVICE_REQUEST_TYPE_FULL,
                )
              }
            >
              Start a request
            </Button>
          )}
        </Modal.Footer>
      </>
      {/* )} */}
    </>
  );
};

export default HeaderModal;
