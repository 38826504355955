const content = {
  ServiceType: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please select the type of service you are requesting.',
    },
  },
  AdditionalAOADetails: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    required: {
      errorMessage:
        'Provide specific details of the records, the desired amendment or annotation and the reason for the request.',
    },
  },
  AdditionalDetails: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    required: {
      errorMessage:
        "Please provide additonal details  for 'Request a copy of member records' selection against 'Type of Records'",
    },
  },
  DateOfFuneral: {
    fieldColumnWidth: { desktop: 6, mobile: 12 },
    required: {
      errorMessage: 'Please provide funeral date.',
    },
  },
  GivenNames: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    required: {
      errorMessage: 'Applicant first name is required.',
    },
  },
  FullName: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    required: {
      errorMessage: 'Applicant first name is required.',
    },
  },
  Surname: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    required: {
      errorMessage: 'Applicant last name is required.',
    },
  },
  Address: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    required: {
      errorMessage: 'Applicant email is required.',
    },
  },
  Number: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    required: {
      errorMessage: 'Applicant contact number is required.',
    },
  },
  ContactMethod: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
  },
  ServiceArea: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    required: {
      errorMessage: 'Applicant service area(s) is required.',
    },
  },
  IsActiveInForce: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    required: {
      errorMessage: 'Applicant status is required.',
    },
  },
  DateOfBirth: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    required: {
      errorMessage: 'Applicant Date of Birth cannot be current or future dated.',
    },
  },
  PMKeySNumber: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please select an option',
    },
  },
  ServiceUnit: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please select an option',
    },
  },
  PlannedDischargeDate: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    required: {
      errorMessage: 'Applicants planned date of discharge cannot be past dated.',
    },
  },
  IsDeceased: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
  },
  YearOfDischarge: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
  },
  Relationship: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    required: {
      errorMessage: 'Relationship description is required.',
    },
  },
  OrganisationName: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    required: {
      errorMessage: 'Advocate Organisation name is required.',
    },
  },
  OrganisationType: {
    fieldColumnWidth: {
      desktop: 6,
      mobile: 12,
    },
    required: {
      errorMessage: 'Advocate Organisation type is required.',
    },
  },
  Acknowledged: {
    fieldColumnWidth: {
      desktop: 12,
      mobile: 12,
    },
    required: {
      errorMessage: 'Please accept the terms of the Personal information request privacy notice.',
    },
  },
};

export const dynamicFields = {
  IsActiveInForce: {
    target: '.PersonalInfoRequest.SelfApplicantDetails',
    multiAction: true,
    sendListIndex: false,
  },
  IsPersonnelInformation: {
    target: '.PersonalInfoRequest',
    multiAction: true,
    sendListIndex: false,
  },
  ApplicantType: {
    target: '.PersonalInfoRequest',
    multiAction: true,
    sendListIndex: false,
  },
  ServiceType: {
    target: '.PersonalInfoRequest',
    multiAction: true,
    sendListIndex: false,
  },
};

export default content;
