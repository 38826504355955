import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import SubNavigation from './MSRcomponents/SubNavigation/SubNavigation';
import subNavContent from './MSRcomponents/SubNavigation/__mocks__/default.json';
import Header from './MSRcomponents/Header/Header';

import HeaderProps from './MSRcomponents/Header/__mocks__/default.json';
import Cta_Panels from './MSRcomponents/CtaPanels/CtaPanels';
import Cta_PanelsProps from './MSRcomponents/CtaPanels/__mocks__/default.json';
import Cta_Popular from './MSRcomponents/CtaPopular/CtaPopular';
import Cta_PopularProps from './MSRcomponents/CtaPopular/__mocks__/default.json';
import ServReqList from './MSRcomponents/ServiceRequestList/ServReqList';
import ServReqListProps from './MSRcomponents/ServiceRequestList/__mocks__/default.json';
import Feedback from '../LandingPage/Feedback/Feedback';
import { getBaseURL, getFormsURL } from '../../helper/getOtherAppURL';
import { appConstants } from '../../helper/client/constant';
import content from './Content/Home.json';
const Content = () => {
  useEffect(() => {
    sessionStorage.clear();
    document.title = 'My Service Request';
  }, []);
  const myFormsURLValue =
    Cta_PanelsProps.ctaText_left === appConstants.VISIT_MY_FORMS ? getFormsURL() : Cta_PanelsProps.ctaURL_left;
  const myBaseURLValue =
    Cta_PanelsProps.ctaText_right === appConstants.VISIT_MY_BASE ? getBaseURL() : Cta_PanelsProps.ctaURL_right;
  return (
    <>
      <SubNavigation {...subNavContent} />
      <div className="msrbackground">
        <Header
          mainHeading={HeaderProps.mainHeading}
          subHeading={HeaderProps.subHeading}
          modalProps={HeaderProps.modalData}
          requestBox
        />
        <Cta_Popular title={Cta_PopularProps.title} description={Cta_PopularProps.description} />
        <Container>
          <ServReqList data={ServReqListProps} />
          <Cta_Panels data={Cta_PanelsProps} myBaseUrl={myBaseURLValue} myFormsUrl={myFormsURLValue} />
        </Container>
        <Feedback variant="servicerequest" {...content.feedback} />
      </div>
    </>
  );
};

export default Content;
