import axios from 'axios';
import { getURL } from './getAPIUrl';

export function request(url, type, opts?: { data?: Object; id?: string; config?: Object }) {
  const envURL = getURL(process.env[url]);
  let { id, data, config } = opts ?? {};
  let returnData;

  const get = async () => {
    if (id) {
      if (id !== null) {
        return axios.get(`${envURL}${id && id}`, { withCredentials: true });
      } else {
        return;
      }
    } else {
      return id !== null && axios.get(envURL, { withCredentials: true });
    }
  };

  const post = async () => {
    if (id) {
      if (id !== null) {
        return axios.post(`${envURL}${id && id}`, data, { withCredentials: true });
      } else {
        return;
      }
    } else {
      return id !== null && axios.post(envURL, data, { withCredentials: true });
    }
  };

  const put = async () => {
    if (id) {
      if (id !== null) {
        return axios.put(`${envURL}${id && id}`, data, config);
      } else {
        return;
      }
    } else {
      return id !== null && axios.post(envURL, data);
    }
  };

  const _delete = async () => {
    if (id) {
      if (id !== null) {
        return axios.delete(`${envURL}${id && id}`, { withCredentials: true });
      } else {
        return;
      }
    } else {
      return id !== null && axios.delete(envURL, data);
    }
  };

  switch (type) {
    case 'get':
      returnData = get().then((e) => {
        return e;
      });
      break;
    case 'post':
      returnData = post().then((e) => {
        return e;
      });
      break;
    case 'put':
      returnData = put();
      break;
    case 'delete':
      returnData = _delete();
      break;
    default:
      break;
  }
  return returnData;
}
