import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setFormFields, setPageInstructions } from '../../../../reducers/requestDetailFormReducer';
import preparePageInstructions from '../../../../helper/PreparePageInstructions';
import { refreshSRForm } from '../../API/refreshSRForm';

const CATERING_LABELS = {
  ADD_NEW_ITEM: 'Add new item',
  REMOVE_ITEM: 'Remove this item',
};

const FormCTA = ({ control, fieldPropMapping, fieldID, formData, reference }) => {
  const nextAssignmentID = useSelector((state) => state.requestForm.nextAssignmentID);
  const pageInstructionsFromStore = useSelector((state) => state.requestForm.pageInstructions);
  const dispatch = useDispatch();

  const onButtonClick = () => {
    let pageInstructions = [];
    switch (control.label) {
      case CATERING_LABELS.ADD_NEW_ITEM:
        pageInstructions = preparePageInstructions(formData, pageInstructionsFromStore, [
          {
            instruction: 'APPEND',
          },
        ]);
        dispatch(setPageInstructions(pageInstructions));

        refreshSRForm(pageInstructions, nextAssignmentID).then((fieldList) => {
          dispatch(
            setFormFields({
              fieldList,
            }),
          );
        });
        break;
      case CATERING_LABELS.REMOVE_ITEM:
        pageInstructions = preparePageInstructions(formData, pageInstructionsFromStore, [
          {
            instruction: 'DELETE',
            listIndex: reference,
          },
        ]);
        dispatch(setPageInstructions(pageInstructions));
        refreshSRForm(pageInstructions, nextAssignmentID).then((fieldList) => {
          dispatch(
            setFormFields({
              fieldList,
            }),
          );
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Col
        lg={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop ?? 12}
        sm={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile ?? 12}
        className="mb-4 pe-4"
      >
        <Button onClick={onButtonClick} id={control.label.replace(/ /g, '_')}>
          {control.label}
        </Button>
      </Col>
    </>
  );
};

export default FormCTA;
