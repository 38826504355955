import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { SubmissionSuccess } from '../../components/ServiceUI/EFT/SubmissionSuccess/SubmissionSuccess';
import content from './Content/ProvideDocumentsComplete.json';
import { request } from '../../helper/useAxios';
import { setShowError } from '../../reducers/errorHandling';
import { useDispatch } from 'react-redux';
import requestContent from './Content/RequestInformation';

export const ProvideDocumentsCompletePage: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      request('REACT_APP_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL', 'get', { id: id })
        .then((res) => {
          setLoading(false);
          content.header.mainHeading = res.data?.content?.PersonalInfoRequest?.ServiceTypeValue;
          content.header.subHeading =
            requestContent.businessBannerCaption[res.data?.content?.PersonalInfoRequest?.ServiceType];
        })
        .catch((error) => {
          dispatch(setShowError({ error: true, code: error.response.status }));
        });
    }
  }, []);

  return (
    <>
      <Header {...content.header} />
      <SubContainer verticalMargin="1rem">
        <SubmissionSuccess
          {...content.submissionSuccess}
          dateHeading="Completed"
          caseID={`${id}`}
          date={new Date()}
          status="PENDING-DOWNLOAD"
        />
      </SubContainer>
    </>
  );
};
