import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Image, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SuccessCheck from '../MyServiceRequest/MSRcomponents/Images/check.svg';
import { getURL } from '../../helper/getAPIUrl';
import { getAuthURL } from '../../helper/getOtherAppURL';
import './VerifyIdentityLink.scss';
const VerifyIdentityLink = () => {
  const { code } = useParams();
  const [tokenValidated, setTokenValidated] = useState(null);

  useEffect(() => {
    validateToken();
  }, []);
  const gotoLogin = () => {
    const loginURL = getAuthURL();
    window.location.href = loginURL;
  };
  const validateToken = () => {
    if (sessionStorage.getItem('tokenValidated') !== code) {
      let URL = process.env.REACT_APP_API_VERIFY_IDENTITYLINK + code;
      URL = getURL(URL);
      axios
        .post(URL, {})
        .then((res) => {
          setTokenValidated(res.status === 200 ? true : false);
        })
        .catch((error) => {
          setTokenValidated(false);
        });
      sessionStorage.setItem('tokenValidated', code);
    }
  };

  return (
    <>
      {tokenValidated && (
        <Container className="m-5 p-5">
          <Image data-testid="icon" src={SuccessCheck} alt="Success" height={100} className="mb-3" />
          <h3>Your Defence email address has now been verified.</h3>
          <p>
            You can now access all features available in ServiceConnect outside the Defence Protected Environment (DPE).
            Please check your Defence email inbox regularly. For security reasons, you will be prompted to verify your
            Defence email address every 90 days.
          </p>
          <p className="extra-space">
            If this is your first time accessing ServiceConnect, you will now be prompted to provide additional details
            in order to pre-fill your forms and service requests as well as personalise your base information.
          </p>
          <p>
            <Button
              data-testid="OutageButton"
              className="rounded-0 reLoginButton"
              variant="primary"
              onClick={gotoLogin}
            >
              Close
            </Button>
          </p>
        </Container>
      )}
      {tokenValidated === false && (
        <Container className="m-5">
          <h3>Unable to link your defence email to you MyGov ID.</h3>
          <p>Your link has expired. Please try relinking you defence email to your MyGov ID.</p>
        </Container>
      )}
    </>
  );
};

export default VerifyIdentityLink;
