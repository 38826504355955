import * as React from 'react';
import { SVGProps } from 'react';
const Checkbox = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <g>
      <g>
        <path
          fill="#04795C"
          d="M10.5136 5.60319L6.75361 9.31359L5.73281 8.14239C5.62195 8.02198 5.46842 7.94968 5.30499 7.94091C5.14156 7.93215 4.98117 7.98761 4.85807 8.09547C4.73497 8.20333 4.65891 8.35503 4.64612 8.5182C4.63332 8.68136 4.68482 8.84306 4.78961 8.96879L6.25201 10.64C6.30828 10.7043 6.37713 10.7565 6.45432 10.7932C6.5315 10.8299 6.61538 10.8505 6.70081 10.8536H6.72401C6.88873 10.8534 7.04677 10.7885 7.16401 10.6728L11.3944 6.49519C11.5127 6.37838 11.5797 6.21938 11.5808 6.05315C11.5818 5.88691 11.5168 5.72707 11.4 5.60879C11.2832 5.4905 11.1242 5.42346 10.958 5.42241C10.7917 5.42136 10.6319 5.48638 10.5136 5.60319Z"
        />
      </g>
    </g>
  </svg>
);
export default Checkbox;
