import React from 'react';
import { Box, Button, Text, useDisclosure } from '@chakra-ui/react';
import { MainContainer } from '../Layout/MainContainer/MainContainer';
import FeedbackModal from './FeedbackModal';

export interface Service {
  key: string;
  value: string;
}

export interface ModalProps {
  modalHeading: string;
  modalDescription: string;
  serviceHeading: string;
  services: Service[];
  ratingHeading: string;
  reviewHeading: string;
  closeCTAText: string;
  submitCTAText: string;
  thankYouHeading: string;
  thankYouDescription: string;
  usageHeading: string;
  usageContent: string;
  errorHeading: string;
  errorDescription: string;
  pegaFeedbackApiEndPoint: string;
  submitAction?(data): Promise<any>;
}

export interface FeedbackProps {
  title: string;
  description: string;
  ctaText: string;
  backgroundImgURL: string;
  modalProps: ModalProps;
}

const Feedback: React.FC<FeedbackProps> = (props: FeedbackProps): JSX.Element => {
  const { isOpen: modalOpen, onToggle: modalToggle } = useDisclosure();
  return (
    <>
      <Box
        data-testid="feedback-modal"
        bg="navy"
        color="white"
        padding="1rem 0"
        backgroundRepeat="none"
        backgroundSize="cover"
        backgroundImage={`url(${props.backgroundImgURL})`}
      >
        <MainContainer flex alignItems={{ lg: 'center', base: 'flex-start' }} direction={{ lg: 'row', base: 'column' }}>
          <Text fontSize="sm" w={{ lg: '33%' }} fontWeight="bold">
            {props.title}
          </Text>
          <Text color="white" w={{ lg: '33%' }}>
            {props.description}
          </Text>
          <Button
            w={{ lg: '20%', base: '100%' }}
            marginTop={{ lg: 0, base: '1rem' }}
            variant="secondary_fill"
            onClick={modalToggle}
          >
            {props.ctaText}
          </Button>
        </MainContainer>
      </Box>
      {props.modalProps && <FeedbackModal {...props.modalProps} isOpen={modalOpen} onClose={modalToggle} />}
    </>
  );
};

export default Feedback;
