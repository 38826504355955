import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Image } from 'react-bootstrap';
import './FrontpageHeader.scss';
import home from '../../../images/home.svg';
import bg_image from '../../../images/mobileHeaderbg.png';

const FrontpageHeader = (props) => {
  return (
    <Container fluid className="pb-2 text-white bg-defenceorange backgroundStyles">
      <Image className="mobile headerbgmobile" alt="A woman in uniform using a computer" src={bg_image} />
      <Container className="px-4 p-lg-6 landingPageWidth">
        <Container className="frontPageHeaderContent">
          <Image src={home} alt="home" />
          <p className="name">Hello {props.name}</p>
          <p className="p-0 m-0 fw-light">Your home base is {props.homeBase}</p>
          <Container className="pb-3">
            <Link to={props.profileUrl} className="text-white whiteArrow viewProfile">
              View my profile
            </Link>
          </Container>
          <h1 className="desktop mainHeadingdesktopSize">{props.mainHeading}</h1>
          <h1 className="mobile mainHeadingmobileSize">{props.mainHeading}</h1>
          <p className="desktop w-50 fw-light">{props.subHeading}</p>
          <p className="mobile fw-light">{props.subHeading}</p>
        </Container>
      </Container>
    </Container>
  );
};

export default FrontpageHeader;
