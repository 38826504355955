export const colors = {
  //primary
  navy: '#142A33',
  white: '#FFFFFF',
  charcoal: '#1F2827',
  orange: { standard: '#CF4520', dark: '#B73D1D', darker: '#7A1E06' },
  lightblue: '#D6E3EC',

  //secondary
  verylightblue: '#EEF9FF',

  //neutral
  whispergrey: '#EDEDED',
  grey: '#DCDCDC',
  mediumgrey: '#C5C5C5',
  charcoalgrey: '#1f2727',
  darkgrey: '#7b7b7b',

  //warning
  redalert: '#D52F48',
  progress: '#106BC7',
  success: '#00795C',
  informationrequired: '#FFAF00',

  smile_red: '#d52f48',
  smile_orange: '#c14f30',
  smile_blue: '#1b558e',
  smile_cyan: '#0e7c90',
  smile_green: '#04795c',

  badge_green: '#04795C',
  badge_blue: '#1B558E',
};
