import { createSlice } from '@reduxjs/toolkit';
import { cateringCTAs } from '../helper/client/constant';
import { findAndReplaceAll as findAndReplace } from '../helper/findAndReplace';
import { findByProp } from '../helper/findByProp';
import findPath from '../helper/findPath';
import { EFTConstants } from '../pages/EFT/EFTConstant';

// added pickupdate and returndate to validate manual date input
// user is unable to input a date in the past or the next 7 days
const initialState = {
  formFields: {
    ServiceType: {},
    TypeofRecords: [],
    TypeOfRecordsAdditionalFields: [],
    ApplicantType: {},
    ApplicantTypeSelfMemData: [],
    ApplicantTypeFamilyMemData: [],
    ApplicantTypeAdvocateMemData: [],
    DeliveryDetailsData: [],
    DeclarationData: [],
    fieldGroup: [],
    uploaded: false,
  },
};

const processFields = (item, fieldsArr, state) => {
  // Process Applicant Types
  let processedFields = (items) => {
    // Loop over times and find an array
    let returnedFields = [];
    items.forEach((item) => {
      let objectKey = Object.keys(item);

      let allFields = [];
      item[objectKey].forEach((item) => {
        // Check if the item has a checkbox before adding the caption
        if (item.layout && findByProp(item.layout, 'type', 'pxCheckbox')) {
          // Compose checkbox data from layout object then add caption
          let checkbox = [];
          const caption = {
            type: 'caption',
            captionFor: 'Service Area',
            required: true,
          };
          checkbox.push(caption);
          const fields = findPath(item, ['field']);
          fields.forEach((field) => {
            checkbox.push(_processField(field));
          });
          allFields.push(checkbox);
        } else {
          const fields = findPath(item, ['field']);
          fields.forEach((field) => {
            const currentFormData = _processField(field);
            allFields.push(currentFormData);
          });
        }
      });
      returnedFields.push({ [objectKey]: allFields });
    });
    return returnedFields;
  };

  if (item.ApplicantTypeSelfMemData) {
    let returnedFields = processedFields(item.ApplicantTypeSelfMemData);
    fieldsArr.ApplicantTypeSelfMemData = returnedFields;
    state.formFields.ApplicantTypeSelfMemData = fieldsArr.ApplicantTypeSelfMemData;
  }
  if (item.ApplicantTypeFamilyMemData) {
    let returnedFields = processedFields(item.ApplicantTypeFamilyMemData);
    fieldsArr.ApplicantTypeFamilyMemData = returnedFields;
    state.formFields.ApplicantTypeFamilyMemData = fieldsArr.ApplicantTypeFamilyMemData;
  }
  if (item.ApplicantTypeAdvocateMemData) {
    let returnedFields = processedFields(item.ApplicantTypeAdvocateMemData);
    fieldsArr.ApplicantTypeAdvocateMemData = returnedFields;
    state.formFields.ApplicantTypeAdvocateMemData = fieldsArr.ApplicantTypeAdvocateMemData;
  }

  if (!!item.field) {
    if (item.field.reference) {
      const currentFormData = _processField(item.field);
      if (item.field.reference === 'PersonalInfoRequest.ServiceType') {
        fieldsArr = { ServiceType: { ...currentFormData }, ...fieldsArr };
        state.formFields = fieldsArr;
      } else if (item.field.viewID === 'PIRDeliveryDetails') {
        const fields = {
          // Section Tile
          title: currentFormData.groups[0].layout.title,
          // DeliveryType field
          field: currentFormData.groups[0].layout.groups[1].field,
        };
        fieldsArr = { DeliveryDetailsData: [{ ...fields }] };
        state.formFields.DeliveryDetailsData = [fields];
      } else if (item.field.viewID === 'PIRDeclaration') {
        const fields = {
          // Section Tile
          title: currentFormData.groups[0].layout.title,
          // Upload attachment
          uploadDeclaration: currentFormData.groups[0].layout.groups[0].layout.groups,
          acknowledgement: [
            // Acknowledgement field
            currentFormData.groups[0].layout.groups[1].layout.groups[0].field,
            // Acknowledgement label
            currentFormData.groups[0].layout.groups[1].layout.groups[1].paragraph,
          ],
        };
        // Set acknowledgement checkbox to required as not returned from Pega
        fields.acknowledgement[0].required = true;
        fieldsArr = Object.assign({}, fieldsArr, { DeclarationData: [{ ...fields }] });
        state.formFields.DeclarationData = [fields];
      } else {
        state.formFields = {
          ...state.formFields,
          [item.field.fieldID]: currentFormData,
        };
      }
    }
  } else if (!!item.view?.groups) {
    item.view?.groups.forEach((group) => {
      if (group.layout.fieldListID === '.Items') {
        group.layout?.rows.forEach((row, index) => {
          let processedFields = [];

          const fields = findPath(row, ['field', 'caption']);
          fields.forEach((field) => {
            const currentFormData = _processField(field);
            processedFields.push(currentFormData);
          });
          if (index !== 0) {
            processedFields = [
              ...processedFields,
              {
                ...cateringCTAs.removeMeal,
                reference: index + 1,
              },
            ];
          }
          processedFields.length > 0 && fieldsArr['fieldGroup'].push(processedFields);
        });
        state.mealCount = group.layout?.rows.length;
      } else {
        const processedFields = [];
        // Get all the fields - Find Path
        const fields = findPath(group.layout, ['field', 'caption']);
        // Loop through all fields
        fields.forEach((field) => {
          const fields = findPath(field, ['field', 'caption']);
          // Format fields witjh extra
          fields.forEach((field) => {
            const currentFormData = _processField(field);
            processedFields.push(currentFormData);
          });
        });
        processedFields.length > 0 && fieldsArr['fieldGroup'].push(processedFields);
      }
    });
  }
  //testing something
  else if (!!item.layout && item.layout?.groups?.length > 0) {
    let processedFields = [];
    item.layout?.groups.forEach((item) => {
      if (!!item.field && item.field.visible) {
        const currentFormData = _processField(item.field);
        processedFields.push(currentFormData);
      } else {
        processFields(item, fieldsArr, state);
      }
    });

    const isTypeOfRecord = processedFields.some((item) => item.fieldID === 'pyStyle');

    if (isTypeOfRecord) {
      const updatedArr = processedFields.filter(
        (item) =>
          item.fieldID !== 'pyStyle' &&
          item.fieldID !== 'AdditionalDetails' &&
          item.fieldID !== 'AdditionalAOADetails' &&
          item.fieldID !== 'DateOfFuneral' &&
          item.visible,
      );
      const TypeOfrecordsAddionalFields = processedFields.filter(
        (item) =>
          item.fieldID === 'AdditionalDetails' ||
          item.fieldID === 'AdditionalAOADetails' ||
          item.fieldID === 'DateOfFuneral',
      );
      processedFields.length > 0 && fieldsArr['TypeofRecords'].push(updatedArr);
      TypeOfrecordsAddionalFields.length > 0 &&
        fieldsArr['TypeOfRecordsAdditionalFields'].push(TypeOfrecordsAddionalFields);
    }
  }
  // testing something
};

const _processField = (field) => {
  let visible = true;

  const required = field?.customAttributes?.required ? field?.customAttributes?.required : field?.required;
  return {
    ...field,
    fieldType: field?.type,
    type: field.captionFor ? 'caption' : field?.control?.type,
    visible,
    required,
    error: required && visible && (field.value === '' || field.value === 'Select'),
    value: field.type === 'True-False' ? (field.value === 'true' ? true : false) : field.value,
  };
};

export const eftRequestDetailForm = createSlice({
  name: 'eftrequestDetail',
  initialState,
  reducers: {
    setFormFields: (state, { payload }) => {
      let fieldsArr = {
        TypeofRecords: [],
        TypeOfRecordsAdditionalFields: [],
        ApplicantType: {},
        ApplicantTypeSelfMemData: [],
        ApplicantTypeFamilyMemData: [],
        ApplicantTypeAdvocateMemData: [],
        DeliveryDetailsData: [],
        DeclarationData: [],
        fieldGroup: [],
      };
      payload.fieldList.forEach((value) => {
        processFields(value, fieldsArr, state);
      });

      //state.formFields = fieldsArr;
    },

    setFieldValue: (state, { payload }) => {
      let { value: payloadValue, reference } = payload;
      const field = findByProp(state.formFields, 'reference', reference);

      let fieldList = findAndReplace(state.formFields, 'reference', reference, 'value', payloadValue);
      fieldList = findAndReplace(
        fieldList,
        'reference',
        reference,
        'error',
        field?.required && field.visible && !payloadValue,
      );
      state.formFields = { ...fieldList };
    },
    resetEFTFormState: (state) => {
      state.formFields = { ...initialState };
    },

    setAcknowledgementCheckbox: (state, { payload }) => {
      state.formFields.DeclarationData[0].acknowledgement[0].value = payload.value;
    },

    setCurrentCaseDetails: (state, { payload }) => {
      state.currentType = payload.currentType;
      state.nextAssignmentID = payload.nextAssignmentID;
    },
    setTypeOFRecordsFromPega: (state, { payload }) => {
      let fieldsArr = {
        TypeOfRecordsAdditionalFields: [],
        TypeofRecords: [],
        ApplicantType: {},
        ApplicantTypeSelfMemData: [],
        ApplicantTypeFamilyMemData: [],
        ApplicantTypeAdvocateMemData: [],
        DeliveryDetailsData: [],
        DeclarationData: [],
        fieldGroup: [],
      };
      payload.fieldList.forEach((value) => {
        processFields(value, fieldsArr, state);
      });
      if (state.formFields.ServiceType.value === EFTConstants.PIR_ROYAL_SERVICE_TYPE) {
        state.formFields.TypeofRecords[0].forEach((item) => {
          if (item.reference === EFTConstants.PIR_TYPE_OF_RECORD_PERSONNEL_REFERENCE) {
            item.value = EFTConstants.YES_SELECTED;
          }
        });
      }
    },
    setApplicantTypeFromPega: (state, { payload }) => {
      let fieldsArr = {
        TypeofRecords: [],
        TypeOfRecordsAdditionalFields: [],
      };

      payload.fieldList.forEach((value) => {
        processFields(value, fieldsArr, state);
      });
    },
    updateSelectedTypeofRecord: (state, { payload }) => {
      let { value: payloadValue, reference } = payload;
      state.formFields.TypeofRecords[0].forEach((item) => {
        if (item.reference === reference) {
          item.value = payloadValue;
        }
      });
    },
    setUploadAttachments: (state, action) => {
      //getting boolean from dispatch
      state.uploaded = action.payload;
    },
    setAttachmentmessage: (state, action) => {
      // if (action.payload === "Transport" || action.payload === "Waste")
      if (action.payload === 'Waste') {
        state.formFields['pyTemplateInputBox'].error = state.formFields['pyTemplateInputBox'].value.length === 0;
      }
    },
    resetmemDataforSelf: (state) => {
      state.formFields.ApplicantTypeSelfMemData = [];
    },
    resetmemDataforFamily: (state) => {
      state.formFields.ApplicantTypeFamilyMemData = [];
    },
    resetmemDataforAdvocate: (state) => {
      state.formFields.ApplicantTypeAdvocateMemData = [];
    },

    updateSelectedApplicantType: (state, { payload }) => {
      let { value: payloadValue, reference } = payload;
      state.formFields.ApplicantType.value = payloadValue;
      state.formFields.ApplicantType.error = payloadValue.length > 0 ? false : true;
    },
    setmemberDetailForSelf: (state, { payload }) => {
      let fieldsArr = {
        ApplicantTypeSelfMemData: [],
        ApplicantTypeFamilyMemData: [],
        ApplicantTypeAdvocateMemData: [],
        DeliveryDetailsData: [],
        DeclarationData: [],
        TypeofRecords: [],
        TypeOfRecordsAdditionalFields: [],
      };
      state.formFields.ApplicantTypeFamilyMemData = [];
      state.formFields.ApplicantTypeAdvocateMemData = [];
      payload.fieldList.forEach((value) => {
        processFields(value, fieldsArr, state);
      });
    },
    setmemberDetailForFamilyData: (state, { payload }) => {
      let fieldsArr = {
        ApplicantTypeSelfMemData: [],
        ApplicantTypeFamilyMemData: [],
        ApplicantTypeAdvocateMemData: [],
        DeliveryDetailsData: [],
        DeclarationData: [],
        TypeofRecords: [],
        TypeOfRecordsAdditionalFields: [],
      };
      state.formFields.ApplicantTypeSelfMemData = [];
      state.formFields.ApplicantTypeAdvocateMemData = [];

      payload.fieldList.forEach((value) => {
        processFields(value, fieldsArr, state);
      });
    },
    setmemberDetailForAdvocate: (state, { payload }) => {
      let fieldsArr = {
        ApplicantTypeSelfMemData: [],
        ApplicantTypeFamilyMemData: [],
        ApplicantTypeAdvocateMemData: [],
        DeliveryDetailsData: [],
        DeclarationData: [],
        TypeofRecords: [],
        TypeOfRecordsAdditionalFields: [],
      };
      state.formFields.ApplicantTypeFamilyMemData = [];
      state.formFields.ApplicantTypeSelfMemData = [];
      payload.fieldList.forEach((value) => {
        processFields(value, fieldsArr, state);
      });
    },
    setDeliveryDetails: (state, { payload }) => {
      let fieldsArr = {
        DeliveryDetailsData: [],
      };

      payload.fieldList.forEach((value) => {
        processFields(value, fieldsArr, state);
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFormFields,
  setCurrentCaseDetails,
  setAcknowledgementCheckbox,
  setFieldValue,
  setTypeOFRecordsFromPega,
  setApplicantTypeFromPega,
  updateSelectedTypeofRecord,
  updateSelectedApplicantType,
  setmemberDetailForSelf,
  setmemberDetailForFamilyData,
  setmemberDetailForAdvocate,
  resetmemDataforSelf,
  setDeliveryDetails,
  resetmemDataforFamily,
  resetmemDataforAdvocate,
  setUploadAttachments,
  setAttachmentMessage,
  resetEFTFormState,
} = eftRequestDetailForm.actions;

export default eftRequestDetailForm.reducer;
