import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Container, Image } from 'react-bootstrap';
import alertImage from '../../../images/OrangeAlert.svg';

const ServiceTypeDropdown = ({ isReview, isminorReqFlag, types, currentType, handleChangeType, isError }) => {
  const [error, setError] = useState(true);

  const handleChangeTypeFunc = (type) => {
    handleChangeType(type);
  };

  useEffect(() => {
    if (currentType.description === 'Please select a service type') {
      setError(true);
      isError(true);
    } else {
      setError(false);
      isError(false);
    }
  }, [currentType]);

  return (
    <Form.Group className="mb-4 mx-lg-0 mx-4" controlId="request">
      <Form.Label className="required-field">
        {isminorReqFlag ? 'What does this request relate to?' : 'What service is being requested?'}
      </Form.Label>
      {error && (
        <Container className="error-msg">
          <span className="align-center-error">
            <Image alt="error" className="icon-size-small" src={alertImage} />
          </span>
          <span className="align-center-error-message">Service type is required</span>
        </Container>
      )}
      {!isReview && (
        <Dropdown>
          <Dropdown.Toggle value={currentType.code} variant="dropdown">
            {currentType.description}
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-100">
            {types?.map((item, idx) => (
              <Dropdown.Item
                className="text-wrap"
                onClick={(e) => handleChangeTypeFunc(types.find((item) => item.description === e.target.textContent))}
                key={idx}
              >
                {item.description}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Form.Group>
  );
};

export default ServiceTypeDropdown;
