import React from 'react';
import { Button } from '@chakra-ui/react';
import { FormElement, ButtonProps } from '../FormElementType';

const FormCTA: React.FC<FormElement & ButtonProps> = (props: FormElement & ButtonProps) => {
  return (
    <Button
      disabled={props.disabled}
      variant={props.variant}
      onClick={props.onButtonClick}
      id={props.label?.replace(/ /g, '_')}
      w={{ lg: props.desktopWidth, sm: props.mobileWidth }}
    >
      {props.label}
    </Button>
  );
};

export default FormCTA;
