import React, { useState } from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import plusicon from '../Images/plus.svg';
import './Header.scss';

import FirstPopUp from './FirstPopUp';
import HeaderModal from './HeaderModal';

const Header = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalHeader, setShowModalHeader] = useState(false);

  return (
    <Container fluid className="headerImg text-white bg-defencenavy">
      <Container className="px-4 py-6 p-lg-6 landingPageWidth d-flex justify-content-between flex-lg-row flex-column">
        <Container>
          <h1 className="mainHeading pb-2 pb-lg-0">{props.mainHeading}</h1>
          <p className="w-100 fw-light pb-5 pb-lg-0">{props.subHeading}</p>
        </Container>

        {/* Create request box*/}
        <Container className="bg-defencewhite newRequestBoxMySR p-4 mt-lg-6">
          <Image src={plusicon} alt="Plus" className="mb-4" />
          <h2 className="text-black mb-4">Create and submit a base service request</h2>
          <Button
            className="w-100"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Start a Request
          </Button>
        </Container>
      </Container>
      <FirstPopUp
        show={showModal}
        close={() => {
          setShowModal(false);
        }}
        continue={() => {
          setShowModal(false);
          setShowModalHeader(true);
        }}
      />
      <HeaderModal modalProps={props.modalProps} close={() => setShowModalHeader(false)} show={showModalHeader} />
    </Container>
  );
};

export default Header;
