import React from 'react';
import { useEffect, useState } from 'react';
import HeaderNotifications from '../LandingPage/HeaderNotifications/HeaderNotifications';
import content from '../content/content/Notifications.json';
import AllNotificationsSearch from '../LandingPage/AllNotificationsSearch/AllNotificationsSearch';

const NotificationPage = () => {
  const [notificationList, setNotificationList] = useState(); //notification list for header

  const getList = (list) => {
    setNotificationList(list);
  };
  useEffect(() => {
    document.title = 'Notifications';
  });

  return (
    <>
      <HeaderNotifications
        mainHeading={content.header.mainHeading}
        subHeading={<div dangerouslySetInnerHTML={{ __html: content.header.subHeading }} />}
        breadcrumbs={content.header.breadcrumbs}
        breadcrumbItems={content.header.breadcrumbItems}
        notificationTally={content.header.notificationTally}
        notifications={notificationList}
      />
      <AllNotificationsSearch notificationList={getList} />
    </>
  );
};

export default NotificationPage;
