import * as React from 'react';
import { SVGProps } from 'react';
const Download = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_790_1876)">
      <path
        d="M10.4996 15.649C10.3315 15.649 10.1633 15.585 10.0353 15.457L7.41035 12.832C7.15358 12.5753 7.15358 12.161 7.41035 11.9042C7.66712 11.6475 8.08136 11.6475 8.33813 11.9042L10.4997 14.0649L12.6603 11.9042C12.9171 11.6475 13.3314 11.6475 13.5881 11.9042C13.8449 12.161 13.8449 12.5753 13.5881 12.832L10.9631 15.457C10.836 15.585 10.6678 15.649 10.4996 15.649Z"
        fill="#CF4928"
      />
      <path
        d="M10.5 15.6489C10.1374 15.6489 9.84375 15.3553 9.84375 14.9927V8.43018C9.84375 8.06759 10.1374 7.77393 10.5 7.77393C10.8626 7.77393 11.1563 8.06759 11.1563 8.43018V14.9927C11.1563 15.3553 10.8626 15.6489 10.5 15.6489Z"
        fill="#CF4928"
      />
      <path
        d="M14.4375 18.2739H6.5625C6.19991 18.2739 5.90625 17.9803 5.90625 17.6177C5.90625 17.2551 6.19991 16.9614 6.5625 16.9614H14.4375C14.8001 16.9614 15.0938 17.2551 15.0938 17.6177C15.0938 17.9803 14.8001 18.2739 14.4375 18.2739Z"
        fill="#CF4928"
      />
      <path
        d="M17.348 21.5552H3.65366C2.72507 21.5552 1.96875 20.8186 1.96875 19.913V6.43116C1.96875 5.86105 2.20009 5.30241 2.60366 4.89881L6.31226 1.19021C6.7216 0.780061 7.26629 0.555298 7.84461 0.555298H17.3471C18.2757 0.555298 19.0312 1.29194 19.0312 2.19674V19.9138C19.0312 20.8186 18.2766 21.5552 17.348 21.5552ZM7.84463 1.86771C7.6166 1.86771 7.40166 1.95631 7.24007 2.11791L3.53146 5.82651C3.37232 5.98565 3.28127 6.20631 3.28127 6.43108V19.912C3.28127 20.0941 3.44862 20.2426 3.65369 20.2426H17.3472C17.5522 20.2426 17.7188 20.095 17.7188 19.9137V2.1966C17.7188 2.01531 17.5522 1.86765 17.3472 1.86765L7.84463 1.86771Z"
        fill="#CF4928"
      />
      <path
        d="M6.89073 7.11766H4.59375C4.23116 7.11766 3.9375 6.824 3.9375 6.46141C3.9375 6.09882 4.23116 5.80516 4.59375 5.80516H6.89073C7.07202 5.80516 7.21886 5.65832 7.21886 5.47703V3.18005C7.21886 2.81747 7.51252 2.5238 7.87511 2.5238C8.23769 2.5238 8.53136 2.81747 8.53136 3.18005V5.47703C8.53136 6.38102 7.79472 7.11766 6.89073 7.11766Z"
        fill="#CF4928"
      />
    </g>
    <defs>
      <clipPath id="clip0_790_1876">
        <rect width="21" height="21" fill="white" transform="translate(0 0.555176)" />
      </clipPath>
    </defs>
  </svg>
);
export default Download;
