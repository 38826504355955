import * as React from 'react';
import { SVGProps } from 'react';
const PIRComp = (props: SVGProps<SVGSVGElement>) => (
  <svg width="39" height="43" viewBox="0 0 39 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5347 6.66355C21.6508 6.10106 22.4221 4.89735 22.4221 3.50054C22.4221 1.56749 20.9434 0 19.1197 0C17.296 0 15.8173 1.56744 15.8173 3.50054C15.8173 4.8974 16.5885 6.10111 17.7046 6.66355V10.0009H4.02638C3.24455 10.0009 2.61133 10.6722 2.61133 11.5009C2.61133 12.3278 3.24455 13.0009 4.02638 13.0009H17.7046V40.0001H9.6866C8.90476 40.0001 8.27154 40.6713 8.27154 41.5C8.27154 42.3269 8.90476 43 9.6866 43H28.5528C29.3346 43 29.9678 42.3269 29.9678 41.5C29.9678 40.6713 29.3346 40.0001 28.5528 40.0001H20.5347V13.0009H34.213C34.9948 13.0009 35.628 12.3278 35.628 11.5009C35.628 10.6722 34.9948 10.0009 34.213 10.0009H20.5347V6.66355ZM18.6474 3.50054C18.6474 3.22492 18.8597 2.99991 19.1197 2.99991C19.3797 2.99991 19.592 3.22491 19.592 3.50054C19.592 3.77617 19.3797 4.00116 19.1197 4.00116C18.8597 4.00116 18.6474 3.77617 18.6474 3.50054Z"
        fill="#CF4928"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.09176 14.8906C8.86535 14.3488 8.3577 14 7.79874 14C7.23978 14 6.73213 14.3487 6.50572 14.8906L0.438878 29.3785C-0.6578 31.9959 0.304446 35.4777 3.28492 36.3402C6.3202 37.2196 9.32007 37.2177 12.3322 36.344C15.3074 35.4796 16.2572 32.0016 15.1659 29.3919L9.09176 14.8906ZM3.02492 30.5953L3.4742 29.5228C6.30607 30.3384 9.29199 30.3384 12.1239 29.5228L12.5785 30.6103C13.1322 31.9321 12.5219 33.1771 11.5844 33.4489C9.06386 34.182 6.57853 34.1839 4.02962 33.4471C3.08509 33.1733 2.4713 31.919 3.02492 30.5953ZM10.9583 26.7423C8.8817 27.2654 6.71664 27.2654 4.64012 26.7423L7.79924 19.1974L10.9583 26.7423Z"
        fill="#CF4928"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.7324 14.8906C31.506 14.3488 30.9983 14 30.4394 14C29.8804 14 29.3728 14.3487 29.1463 14.8906L23.0795 29.3785C21.9828 31.9959 22.9451 35.4777 25.9255 36.3402C28.9608 37.2196 31.9607 37.2177 34.9728 36.344C37.948 35.4796 38.8979 32.0016 37.8065 29.3919L31.7324 14.8906ZM27.2803 26.742L30.4394 19.1971L33.5985 26.742C31.522 27.265 29.3568 27.265 27.2803 26.742ZM26.1146 29.5225L25.6654 30.595C25.1117 31.9187 25.7255 33.1731 26.6701 33.4468C29.2189 34.1837 31.704 34.1817 34.2249 33.4505C35.1623 33.1768 35.7726 31.9318 35.2189 30.61L34.7644 29.5225C31.9325 30.3381 28.9466 30.3381 26.1146 29.5225Z"
        fill="#CF4928"
      />
    </g>
  </svg>
);
export default PIRComp;
