import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import GenericErrorModal from '../../../components/GenericErrorModal/GenericErrorModal';
import SystemOutageModal from '../../../components/SystemOutageModal/SystemOutageModal';
import { appConstants } from '../../../helper/client/constant';
import { getURL } from '../../../helper/getAPIUrl';
import AuthenticationModal from '../../LandingPage/AuthenticationModal/AuthenticationModal';
import AuthenticationModalProps from '../../LandingPage/AuthenticationModal/__mocks__/default.json';
import Header from '../../LandingPage/Header/Header';
import ErrorPopUp from '../../MyServiceRequest/CreateRequest/ErrorPopup/ErrorPopup';
import SubNavigation from '../MSRcomponents/SubNavigation/SubNavigation';
import SubNavProps from '../MSRcomponents/SubNavigation/__mocks__/default.json';
import ContactReadOnly from './ContactReadOnly/ContactReadOnly';
import SecondaryContactReadOnly from './ContactReadOnly/SecondaryContactReadOnly';
import LocationReadOnly from './LocationReadOnly/LocationReadOnly';
import RequestDetailReadOnly from './RequestDetailReadOnly/RequestDetailReadOnly';
import ViewSubheader from './ViewSubHeader/ViewSubheader';
import ViewSubheaderProps from './ViewSubHeader/__mocks__/default.json';

import ViewContent from './content/ViewServiceRequest.json';

import GenericErrorModalProps from '../../GenericErrorModal/__mocks__/default.json';
import SystemOutageModalProps from '../../SystemOutageModal/__mocks__/default.json';
import KeyRequestInfo from './KeyRequestInfo/KeyRequestInfo';

const ViewServiceRequest = () => {
  const { id } = useParams();

  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [error, setError] = useState();
  const [showError, setShowError] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showModalHttpCode, setShowModalHttpCode] = useState('');
  useEffect(() => {
    document.title = 'View Service Request';
  }, []);
  const [loading, setLoading] = useState({
    isLoading: false,
  });
  const [srView, setsrView] = useState({
    data: [],
  });
  const [srgemrelData, setsrgemrelData] = useState({
    status: '',
    gemsNotificationID: '',
  });
  const [workType, setworkType] = useState({
    dropdown: [],
    description: '',
  });
  const [workSubType, setSubworkType] = useState({
    dropdown: [],
    description: '',
  });
  const [vessel, setvessel] = useState({
    dropdown: [],
    description: '',
  });
  const [groupPrimary, setgroupPrimary] = useState({
    dropdown: [],
    description: '',
  });
  const [groupSecondary, setgroupSecondary] = useState({
    dropdown: [],
    description: '',
  });
  const getRefDropDownWorkSubType = (currentworkType, selectedCode) => {
    let URL = process.env.REACT_APP_GET_SUB_WORK_TYPE + `?worktype=${currentworkType}`;
    URL = getURL(URL);
    axios
      .get(URL, {
        withCredentials: true,
      })
      .then((response) => {
        let selectedDescription = 'Select';
        selectedDescription = getSelectedDescription(response.data.refValues, selectedCode);
        setSubworkType((prevState) => {
          return {
            ...prevState,
            dropdown: response.data.refValues,
            description: selectedDescription,
          };
        });
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };

        if (error.response.status === 400) {
          setShowError(true);

          setError(error.response.data);
        } else if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
          setShowModalHttpCode(error.response.status);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setAPIError(error);
          setErrorModal(true);
        }
      });
  };
  const getSelectedDescription = (refDataDropdown, selectedCode) => {
    const selectedRefDataDescription = refDataDropdown.filter((item) => item.code === selectedCode)[0]?.description;
    return selectedRefDataDescription;
  };
  const getRefDropDown = (value, selectedCode, type) => {
    let URL = process.env.REACT_APP_GET_REF_DATA + value;
    URL = getURL(URL);
    axios
      .get(URL, {
        withCredentials: true,
      })
      .then((res) => {
        let selectedDescription = 'Select';
        if (!!res.data) {
          if (value === 'Vessel') {
            setvessel((prevState) => {
              selectedDescription = getSelectedDescription(res.data.refValues, selectedCode);
              return {
                ...prevState,
                dropdown: res.data.refValues,
                description: selectedDescription,
              };
            });
          } else if (value === 'WorkType' || value === 'servicetype') {
            setworkType((prevState) => {
              selectedDescription = getSelectedDescription(res.data.refValues, selectedCode);
              return {
                ...prevState,
                dropdown: res.data.refValues,
                description: selectedDescription,
              };
            });
          } else if (value === 'groupdiv') {
            if (type === '') {
              selectedDescription = getSelectedDescription(res.data.groupDetails, selectedCode);

              setgroupPrimary((prevState) => {
                return {
                  ...prevState,
                  dropdown: res.data.groupDetails,
                  description: !!selectedDescription ? selectedDescription : '',
                };
              });
            } else {
              selectedDescription = getSelectedDescription(res.data.groupDetails, selectedCode);
              setgroupSecondary((prevState) => {
                return {
                  ...prevState,
                  dropdown: res.data.groupDetails,
                  description: !!selectedDescription ? selectedDescription : '',
                };
              });
            }
          }
        }
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        if (error.response.status === 400) {
          setShowError(true);

          setError(error.response.data);
        } else if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
          setShowModalHttpCode(error.response.status);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setAPIError(error);
          setErrorModal(true);
        }
      });
  };
  useEffect(() => {
    if (!!id) {
      setLoading((prevState) => {
        return {
          ...prevState,
          isLoading: true,
        };
      });
      let URL = process.env.REACT_APP_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL + id;
      URL = getURL(URL);
      axios
        .get(URL, {
          withCredentials: true,
        })
        .then((res) => {
          if (res.data.content.ServiceRequest.ServiceType === appConstants.SERVICE_REQUEST_TYPE) {
            const workTypeCode = res.data.content.ServiceRequest.WorkType;
            getRefDropDown('WorkType', workTypeCode, '');
            const workSubTypeCode = res.data.content.ServiceRequest.WorkSubType;
            getRefDropDownWorkSubType(workTypeCode, workSubTypeCode);
          }
          if (res.data.content.ServiceRequest.ServiceType !== appConstants.SERVICE_REQUEST_TYPE) {
            const workTypeCode = res.data.content.ServiceRequest.WorkType;
            getRefDropDown('servicetype', workTypeCode, '');
          }
          if (!!res.data.content.ServiceRequest.Location.Vessel.SiteOrWharf) {
            const vesselCode = res.data.content.ServiceRequest.Location.Vessel.SiteOrWharf;
            getRefDropDown('Vessel', vesselCode, '');
          }
          if (!!res.data.content.ServiceRequest.PrimaryContact) {
            const groupEntityPrimaryCode = res.data.content.ServiceRequest.PrimaryContact.Service;
            getRefDropDown('groupdiv', groupEntityPrimaryCode, '');
          }
          if (!!res.data.content.ServiceRequest?.Contacts[0]?.Service) {
            const groupEntitySecondaryCode = res.data.content.ServiceRequest?.Contacts[0].Service;
            getRefDropDown('groupdiv', groupEntitySecondaryCode, 'sec');
          }

          setsrView((prevState) => {
            return {
              ...prevState,
              data: [res.data.content.ServiceRequest, ...prevState.data],
            };
          });
          setsrgemrelData((prevState) => {
            return {
              ...prevState,
              status: res.data.content.pyStatusWork,
              caseID: res.data.ID,
              gemsNotificationID: !!res.data.content.GemsNotificationID ? res.data.content.GemsNotificationID : '',
            };
          });
          setLoading((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        })
        .catch((err) => {
          let error = { ...err };
          error.response = error.response || { response: { data: [] } };
          if (error.response.status === 400) {
            setShowError(true);

            setError(error.response.data);
          } else if (error.response.status === 403 || error.response.status === 401) {
            setShowModal(true);
            setShowModalHttpCode(error.response.status);
          } else if (error.response.status === 500) {
            setServiceDownModal(true);
          } else {
            setAPIError(error);
            setErrorModal(true);
          }
        });
    }
  }, []);
  return (
    <div>
      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          descriptionNoPrivilage={AuthenticationModalProps.descriptionWithNoPrivilage}
          code={showModalHttpCode}
          ctaText={AuthenticationModalProps.ctaText}
        />
      )}
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}
      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}
      <ErrorPopUp show={showError} error={error} closeAction={() => setShowError(false)} />
      <SubNavigation {...SubNavProps} />
      <Header
        servicerequest
        mainHeading={srView.data.length > 0 ? srView.data[0].RequestTitle : 'Loading...'}
        subHeading={ViewContent.header.subHeading}
        breadcrumbs={ViewContent.header.breadcrumbs}
        breadcrumbItems={ViewContent.header.breadcrumbItems}
      />
      {srView.data.length > 0 && (
        <KeyRequestInfo
          details={srView}
          status={srgemrelData.status}
          caseID={srgemrelData.caseID}
          notificationId={srgemrelData.gemsNotificationID}
          OnsetAPIError={(data) => setAPIError(data)}
          OnsetShowModal={() => setShowModal(true)}
          OnsetServiceDownModal={() => setServiceDownModal(true)}
          OnsetErrorModal={() => setErrorModal(true)}
        />
      )}
      <ViewSubheader
        heading={ViewSubheaderProps.mainHeading}
        subheading={ViewSubheaderProps.subHeading}
      ></ViewSubheader>
      {loading.isLoading && (
        <Container>
          <h2 style={{ textAlign: 'center', margin: '20px' }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </h2>
        </Container>
      )}
      {!loading.isLoading && srView.data.length > 0 && (
        <div className="landingPageWidth">
          <RequestDetailReadOnly
            workTypeDesc={workType.description}
            workSubTypeDesc={workSubType.description}
            reqData={srView.data}
            caseID={id}
          ></RequestDetailReadOnly>
          <Container className=" pt-4 pt-lg-6 ps-4 ps-lg-6 pe-4 pe-lg-6 pb-3">
            <div className="">
              <LocationReadOnly vesselNameDesc={vessel.description} reqData={srView.data[0]}></LocationReadOnly>
            </div>
          </Container>
          <Container className=" pt-4 pt-lg-6 ps-4 ps-lg-6 pe-4 pe-lg-6 pb-1">
            <div className="">
              <ContactReadOnly groupPrimaryDesc={groupPrimary.description} reqData={srView.data[0]}></ContactReadOnly>
            </div>
          </Container>
          {srView.data[0].HasSecondaryContact === 'true' && (
            <Container className=" pt-4 pt-lg-6 ps-4 ps-lg-6 pe-4 pe-lg-6 pb-3 pb-lg-1">
              <SecondaryContactReadOnly
                groupSecondaryDesc={groupSecondary.description}
                reqData={srView.data[0]}
              ></SecondaryContactReadOnly>
            </Container>
          )}
          <Row className="buttonContainer ps-4 ps-lg-6 pe-4 pe-lg-6 pt-4 pt-lg-6">
            <Col md className="d-flex justify-content-start pt-xs-2 pt-2 pb-6 col-md-12 col-lg-8 order-2 order-lg-1">
              <Button variant="outline-dark" className="mobileButton">
                <a className="noURlStyle" href="/my-service-request">
                  Cancel
                </a>
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};
export default ViewServiceRequest;
