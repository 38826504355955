import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Container, Dropdown, FormControl, InputGroup } from 'react-bootstrap';
import { getURL } from '../../../helper/getAEMUrl';
import FormsPagination from '../Pagination/Pagination';
import './Search.scss';

//Tile item
const Item = (props) => {
  return (
    <Card data-testid="item" className="justify-content-between g-col-12 g-col-lg-4 item">
      <Card.Header className="bg-white border-0">
        {props.item.tags.slice(0, 5).map((item) => (
          <Badge key={item} pill bg="white" className="text-black px-2 me-2 pillStyles">
            {item}
          </Badge>
        ))}
        <h4 className="mt-2">{props.item.title}</h4>
      </Card.Header>
      <Card.Body className="pt-0 limit fw-light">
        <Card.Text dangerouslySetInnerHTML={{ __html: props.item.description }} />
      </Card.Body>
      <Card.Footer className="flex-end bg-white border-0">
        <a href={props.item.buttonURL} className="text-decoration-none arrow text-defenceorange">
          View
        </a>
      </Card.Footer>
    </Card>
  );
};

const ServiceDropdown = (props) => {
  const services = ['MyBase', 'MyServiceRequest', 'MyForms', 'ServiceConnect'];
  return (
    <Dropdown>
      <Dropdown.Toggle variant="standard">All Services</Dropdown.Toggle>
      <Dropdown.Menu>
        {services.map((item) => (
          <Dropdown.Item onClick={() => props.handleFilter(item)}>{item}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const FilterDropdown = (props) => {
  const services = ['Title', 'Description'];
  return (
    <Dropdown>
      <Dropdown.Toggle variant="sort">
        <span className="chevronsmall">
          Sorted by<b className="ms-1">{props.filter}</b>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {services.map((item) => (
          <Dropdown.Item onClick={() => props.handleChangeSort(item.toLowerCase())}>{item}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const SearchBar = (props) => {
  return (
    <Container fluid className="orangeStrip px-lg-4 bg-defencelightgrey">
      <Container className="landingPageWidth p-4 py-lg-3 px-lg-5">
        <Container className="rounded bg-white p-3 p-lg-6">
          <h3>Start typing a keyword for the item you are looking for </h3>
          <InputGroup>
            {/* <ServiceDropdown handleFilter={handleFilter} /> */}
            <FormControl
              data-testid="input"
              id="searchbar"
              className="fw-light searchbar fst-italic rounded-1 border border-light"
              onChange={(e) => props.handleChange(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && props.handleSearch(e.target.value)}
              value={props.value}
              placeholder="Search"
            />
            <Button
              data-testid="searchButton"
              aria-label="Search"
              className="p-0 h-100 bg-defenceorange searchButton"
              onClick={(e) => props.handleSearch(e.target.value)}
              variant="search"
            />
          </InputGroup>
        </Container>
      </Container>
    </Container>
  );
};

const SearchResults = (props) => {
  const itemsPerPage = 12;
  const [trimStart, setTrimStart] = useState(0);
  const [trimEnd, setTrimEnd] = useState(itemsPerPage);
  const update = (trims) => {
    setTrimStart(trims[0]);
    setTrimEnd(trims[1]);
  };
  return (
    <Container className="bg-defencelightgrey px-lg-4 pb-lg-6 pb-3">
      {props.searchResults.length > 0 ? (
        <>
          <Container className="landingPageWidth d-flex justify-content-between p-4 py-lg-3 px-lg-5 pt-3 pb-3">
            <Container>
              {props.searchResults.length} result
              {props.searchResults.length > 1 && 's'}{' '}
              <Button variant="secondary" onClick={props.reset}>
                Clear
              </Button>
            </Container>
            <FilterDropdown filter={props.filter} handleChangeSort={props.handleChangeSort} />
          </Container>
          <Container className="landingPageWidth p-4 py-lg-3 px-lg-5 grid pt-3 pb-3 gap-2 gap-lg-5 justify-content-start m-0 w-auto">
            {props.searchResults.slice(trimStart, trimEnd).map((item) => (
              <Item item={item} />
            ))}
          </Container>
          {props.searchResults.length > itemsPerPage && (
            <FormsPagination update={update} itemsPerPage={itemsPerPage} items={props.searchResults} />
          )}
        </>
      ) : (
        <Container className="landingPageWidth d-flex justify-content-between p-4 py-lg-3 px-lg-5 pt-3 pb-3">
          No results found
        </Container>
      )}
    </Container>
  );
};

const Search = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const query = urlParams.get('query');
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [value, setValue] = useState(urlParams ? query : '');
  const [filter, setFilter] = useState('description');
  const handleSearch = () => {
    if (value === '') {
      setShowResults(false);
    } else {
      setShowResults(true);
      let URL = `${process.env.REACT_APP_SEARCH}?search=${value}`;
      URL = getURL(URL);
      axios(URL, {
        withCredentials: true,
      })
        .then((response) => setSearchResults(response.data.searchResults))
        .catch((error) => <></>);
    }
  };
  const handleChangeSort = (filter) => {
    setFilter(filter);
  };

  const reset = () => {
    setSearchResults([]);
    setShowResults(false);
    setValue('');
  };

  useEffect(() => {
    query && handleSearch();
  }, [query]);

  const sortedResults = searchResults.sort(function (a, b) {
    let keyA;
    let keyB;
    switch (filter) {
      case 'description':
        keyA = a.description;
        keyB = b.description;
        break;
      case 'title':
        keyA = a.title;
        keyB = b.title;
        break;
      default:
        return null;
    }
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;

    return 0;
  });
  return (
    <>
      <SearchBar handleChange={setValue} handleSearch={handleSearch} value={value} />
      {showResults && (
        <SearchResults
          filter={filter}
          handleChangeSort={handleChangeSort}
          searchResults={sortedResults}
          reset={reset}
        />
      )}
    </>
  );
};

export default Search;
