import * as React from 'react';
import { SVGProps } from 'react';
const Arrow_Orange = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_756_3827" maskUnits="userSpaceOnUse" x="4" y="5" width="16" height="14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0951 12.3932C19.2228 12.2673 19.2954 12.0956 19.2963 11.9164C19.2972 11.7371 19.2264 11.5645 19.0996 11.4377L13.4413 5.77945C13.31 5.64813 13.1379 5.5827 12.9658 5.5827C12.7937 5.5827 12.6216 5.64813 12.4903 5.77945C12.2281 6.04208 12.2281 6.46785 12.4903 6.73004L16.9873 11.2271H4.85196C4.48042 11.2271 4.17969 11.5282 4.17969 11.8993C4.17969 12.2709 4.48042 12.5716 4.85196 12.5716H16.9931L12.5042 16.9772C12.2388 17.2376 12.2353 17.6629 12.4952 17.9282C12.7556 18.1931 13.1814 18.1967 13.4458 17.9372L19.0951 12.3932Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_756_3827)">
      <rect
        x="-1.82373"
        y="26.1177"
        width="28.2353"
        height="28.2353"
        transform="rotate(-90 -1.82373 26.1177)"
        fill="inherit"
      />
    </g>
  </svg>
);
export default Arrow_Orange;
