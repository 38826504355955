import React, { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import Arrow from './ArrowButton';
const FormsPagination = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageLimit = 5;
  const itemsPerPage = props.itemsPerPage;
  const numberOfPages = Math.ceil(props.items.length / itemsPerPage);
  const trimStart = (currentPage - 1) * itemsPerPage;
  const trimEnd = trimStart + itemsPerPage;
  useEffect(() => {
    currentPage !== 1 && setCurrentPage(1);
  }, [props.value]);
  useEffect(() => {
    props.update([trimStart, trimEnd]);
  }, [currentPage]);

  const PageNumbers = () => {
    let numbers = [];
    let currentNumbers = [];
    for (let index = 1; index < numberOfPages - 1; index++) {
      numbers.push(
        <Pagination.Item key={index + 1} onClick={() => setCurrentPage(index + 1)} active={index + 1 === currentPage}>
          {index + 1}
        </Pagination.Item>,
      );
    }
    if (currentPage <= pageLimit) {
      currentNumbers = numbers.slice(0, pageLimit);
    } else if (currentPage > numberOfPages - 3) {
      currentNumbers = numbers.slice(numberOfPages - pageLimit, numberOfPages - 1);
    } else {
      currentNumbers = numbers.slice(currentPage - 4, currentPage + 1);
    }
    return currentNumbers;
  };
  //Main component
  const FormsPagination = () => {
    const pageUp = () => {
      if (currentPage < numberOfPages) {
        setCurrentPage(currentPage + 1);
      } else {
        return;
      }
    };
    const pageDown = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      } else {
        return;
      }
    };
    return (
      <Pagination className="m-auto justify-content-center align-items-center">
        <Pagination.Prev onClick={pageDown}>
          <Arrow className="flip" alt="Left arrow" />
        </Pagination.Prev>
        <Pagination.Item onClick={() => setCurrentPage(1)} active={1 === currentPage}>
          1
        </Pagination.Item>
        {!(currentPage <= pageLimit) && <Pagination.Item className="ellipseItem">...</Pagination.Item>}
        <PageNumbers />
        {!(currentPage > numberOfPages - 4) && (
          <Pagination.Item tabIndex={-1} className="ellipseItem">
            ...
          </Pagination.Item>
        )}
        <Pagination.Item onClick={() => setCurrentPage(numberOfPages)} active={numberOfPages === currentPage}>
          {numberOfPages}
        </Pagination.Item>
        <Pagination.Next onClick={pageUp}>
          <Arrow alt="Right arrow" />
        </Pagination.Next>
      </Pagination>
    );
  };
  return <FormsPagination />;
};

export default FormsPagination;
