import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Dropdown } from 'react-bootstrap';
import './PersonalDetailsSecondary.scss';

import { appConstants } from '../../../../helper/client/constant';

const PersonalDetailsSecondary = (props) => {
  const [initialPrefferedContactTimes, setinitialPrefferedContactTimes] = useState('');

  const [secondaryContactDetailsForms, setSecondaryContactDetailsForms] = useState({
    FullName: props.allDetailsOnSecondary.length > 0 ? props.allDetailsOnSecondary[0].value : '',
    EmployeeID: props.allDetailsOnSecondary.length > 0 ? props.allDetailsOnSecondary[1].value : '',
    Service: props.allDetailsOnSecondary.length > 0 ? props.entityValuesSecondary.selectedDescriptionSecondary : '',
    Address: props.allDetailsOnSecondary.length > 0 ? props.allDetailsOnSecondary[3].value : '',

    Number: props.allDetailsOnSecondary.length > 0 ? props.allDetailsOnSecondary[4].value : '',

    PreferredContactTimes: props.allDetailsOnSecondary.length > 0 ? props.allDetailsOnSecondary[5].value : '',
  });
  const [secondaryContactDetailsErrors, setSecondaryContactDetailsErrors] = useState({});
  const getReference = (fieldName) => {
    if (fieldName === 'Number') {
      return 'ServiceRequest.Contacts(1).Phone(1).' + fieldName;
    } else if (fieldName === 'Address') {
      return 'ServiceRequest.Contacts(1).Email(1).' + fieldName;
    } else {
      return 'ServiceRequest.Contacts(1).' + fieldName;
    }
  };
  const afterSetStateFinished = () => {
    const test = Object.entries(secondaryContactDetailsForms).map((item) => {
      return {
        fieldID: item[0],
        fieldName: item[0],
        isReadOnly: false,
        isRequired: false,
        error: false,
        errorMessage: '',
        value: item[1],
        reference: getReference(item[0]),
      };
    });

    const secondaryContactFormErrors = validateSecondaryContactForm();

    if (Object.keys(secondaryContactFormErrors).length > 0) {
      setSecondaryContactDetailsErrors(secondaryContactFormErrors);
    } else {
      setSecondaryContactDetailsErrors({});
    }
    props.onPersonalSeconadryInfoChange(secondaryContactDetailsForms, secondaryContactDetailsErrors, test);
    props.onIsInvalidsecondaryForm(Object.keys(secondaryContactFormErrors).length > 0);
  };
  const validateSecondaryContactForm = () => {
    const {
      FullName,
      EmployeeID,
      Service,
      Address,

      Number,
      PreferredContactTimes,
    } = secondaryContactDetailsForms;
    const emailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    let newErrors = {};
    if (!FullName || FullName === '') {
      newErrors.FullName = 'Please enter your full name.';
    }
    if (!Address || Address === '') {
      newErrors.Address = 'Please enter your email.';
    }
    if (!!Address && !emailRegEx.test(Address)) {
      newErrors.Address = 'Entered email is invalid';
    }
    // if (!EmployeeID || EmployeeID === "") {
    //   newErrors.EmployeeID = "Please enter your employee id.";
    // }
    if (!!EmployeeID && EmployeeID.length > 9) {
      newErrors.EmployeeID = 'Employee id should not exceeds more than 9 characters';
    }
    if (!!EmployeeID && EmployeeID.length < 6) {
      newErrors.EmployeeID = 'Employee id should be more than 6 characters.';
    }
    if (!!EmployeeID && EmployeeID.length > 0) {
      const specialChars = appConstants.ATTACHMENTS_SPECIAL_CHARACTERS_STRING;

      specialChars.split('').forEach((specialChar) => {
        if (EmployeeID.includes(specialChar)) {
          newErrors.EmployeeID = 'Please enter a valid employee id.';
        }
      });
    }

    if (!Number || Number === '') {
      newErrors.Number = 'Please enter your preferred contact number.';
    }
    if (!Number.match('[0-9]{10}')) {
      newErrors.Number = appConstants.PROFILE_INVALID_PH;
    }
    if (!!Number && Number.length > appConstants.PHONE_NUM_MAX_LIMIT) {
      newErrors.Number = appConstants.PHONE_NUM_MAX_LIMIT_MSG;
    }
    return newErrors;
  };
  const setPersonalDetailsSecondaryFields = (field, value) => {
    setSecondaryContactDetailsForms((prevState) => {
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  const changeEntityDropDownValueSecondary = (text, refCode, id) => {
    setPersonalDetailsSecondaryFields(id, text);
    const secondaryContactFormErrors = validateSecondaryContactForm();
    if (Object.keys(secondaryContactFormErrors).length > 0) {
      setSecondaryContactDetailsErrors(secondaryContactFormErrors);
    } else {
      setSecondaryContactDetailsErrors({});
    }

    props.onEntityChangeSecondary(text, refCode);
  };
  // test

  useEffect(() => {
    const val = props.allDetailsOnSecondary.length > 0 ? props.allDetailsOnSecondary[5].value : '';

    setinitialPrefferedContactTimes(val);
  }, []);

  useEffect(() => {
    afterSetStateFinished();
  }, [secondaryContactDetailsForms]);
  // test

  // custom toggle for dropdowns
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      className={
        props.isReview ? 'customDropdownReview dropDownSepArrow fw-light' : 'customDropdown dropDownSepArrow fw-light'
      }
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="dropdownButtons">{children}</div>
    </a>
  ));

  const renderElement = () => {
    return (
      <div className="personal-details-secondary-container">
        <Form>
          <Row xs={1} sm={1} md={1} lg={3} className="gx-5">
            <Col>
              <Form.Group>
                <Form.Label
                  className={`${
                    props.isReview ? 'required-field-hidden-review' : 'required-field'
                  }"personal-details-secondary-sub-details"`}
                >
                  Full name
                </Form.Label>
                <Form.Control
                  className={props.isReview ? 'textBoxField-hidden fw-light' : 'textBoxField fw-light'}
                  type="text"
                  placeholder=""
                  name="FullName"
                  value={secondaryContactDetailsForms.FullName}
                  onChange={(e) => setPersonalDetailsSecondaryFields('FullName', e.target.value)}
                  disabled={props.isReadonlyPersonalSecondaryDetails}
                  isInvalid={!!secondaryContactDetailsErrors.FullName}
                />
                <Form.Control.Feedback type="invalid" className="validation-error">
                  {secondaryContactDetailsErrors.FullName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label
                  className={`${
                    props.isReview ? 'required-field-hidden-review' : 'required-field'
                  }"personal-details-secondary-sub-details"`}
                >
                  Employee ID
                </Form.Label>
                <Form.Control
                  type="text"
                  className={props.isReview ? 'textBoxField-hidden fw-light' : 'textBoxField fw-light'}
                  placeholder=""
                  name="EmployeeID"
                  value={secondaryContactDetailsForms.EmployeeID}
                  onChange={(e) => setPersonalDetailsSecondaryFields('EmployeeID', e.target.value)}
                  disabled={props.isReadonlyPersonalSecondaryDetails}
                  isInvalid={!!secondaryContactDetailsErrors.EmployeeID}
                />
                <Form.Control.Feedback type="invalid" className="validation-error">
                  {secondaryContactDetailsErrors.EmployeeID}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className={`${props.isReview && 'required-field'}"personal-details-secondary-sub-details"`}>
                  Group or entity
                </Form.Label>

                <div className="d-inline w-100">
                  {props.isReview ? (
                    <p className="fw-light">{secondaryContactDetailsForms.Service}</p>
                  ) : (
                    <Dropdown
                      className={secondaryContactDetailsErrors.Service?.length > 0 && 'error-border-class-secondary'}
                    >
                      <Dropdown.Toggle
                        title="Group or entity"
                        variant="dropdown"
                        id="grouporentity-2"
                        disabled={props.isNoDisableFlag ? true : props.isReadonlyPersonalSecondaryDetails}
                      >
                        {secondaryContactDetailsForms.Service}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropDownMenuSecondary">
                        {props.entityValuesSecondary.dropDownArrayEntity.map((item) => (
                          <Dropdown.Item
                            key={item.code}
                            className="fw-light dropdownSecondaryItem"
                            onClick={(e) =>
                              changeEntityDropDownValueSecondary(e.target.textContent, item.code, 'Service')
                            }
                          >
                            {item.description}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
                {/* <div>
                  <Form.Select
                    as={CustomToggle}
                    style={
                      secondaryContactDetailsErrors.service?.length > 0
                        ? {
                            borderColor: "#dc3545",
                            borderWidth: "1px",
                            borderStyle: "solid",
                          }
                        : {
                            borderColor: "gray",
                            borderWidth: "1px",
                            borderStyle: "solid",
                          }
                    }
                    aria-label="Default select example"
                    value={secondaryContactDetailsForms.service}
                    onChange={(e) =>
                      changeEntityDropDownValueSecondary(
                        e.target.textContent,
                        "test",
                        "Service"
                      )
                    }
                  >
                    {props.entityValuesSecondary.dropDownArrayEntity.map(
                      (item) => (
                        <option>{item.description}</option>
                      )
                    )}
                  </Form.Select>
                </div> */}
                <div className="dropdown-error-msg">{secondaryContactDetailsErrors.Service}</div>
                {/* <div className="dropdown-error-msg">
             
                  {secondaryContactDetailsErrors.Service}
                </div>  */}
              </Form.Group>
            </Col>
          </Row>
          <Row xs={1} sm={1} md={1} lg={2} className="gx-5">
            <Col>
              <Form.Group>
                <Form.Label
                  className={`${
                    props.isReview ? 'required-field-hidden-review' : 'required-field'
                  }"personal-details-secondary-sub-details"`}
                >
                  Email
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  className={props.isReview ? 'textBoxField-hidden fw-light' : 'textBoxField fw-light'}
                  name="Address"
                  value={secondaryContactDetailsForms.Address}
                  onChange={(e) => setPersonalDetailsSecondaryFields('Address', e.target.value)}
                  disabled={props.isReadonlyPersonalSecondaryDetails}
                  isInvalid={!!secondaryContactDetailsErrors.Address}
                />
                <Form.Control.Feedback type="invalid" className="validation-error">
                  {secondaryContactDetailsErrors.Address}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label
                  className={`${
                    props.isReview ? 'required-field-hidden-review' : 'required-field'
                  }"personal-details-secondary-sub-details"`}
                >
                  Preferred contact number
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  className={props.isReview ? 'textBoxField-hidden fw-light' : 'textBoxField fw-light'}
                  name="Number"
                  value={secondaryContactDetailsForms.Number}
                  onChange={(e) => setPersonalDetailsSecondaryFields('Number', e.target.value)}
                  disabled={props.isReadonlyPersonalSecondaryDetails}
                  isInvalid={!!secondaryContactDetailsErrors.Number}
                />
                <Form.Control.Feedback type="invalid" className="validation-error">
                  {secondaryContactDetailsErrors.Number}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-5">
            <Col>
              <Form.Group>
                <Form.Label className="personal-details-secondary-sub-details">
                  How and when can this person be contacted?
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  className={props.isReview ? 'textBoxField-hidden fw-light' : 'textBoxField fw-light'}
                  name="PreferredContactTimes"
                  value={secondaryContactDetailsForms.PreferredContactTimes}
                  onChange={(e) => setPersonalDetailsSecondaryFields('PreferredContactTimes', e.target.value)}
                  disabled={props.isReadonlyPersonalSecondaryDetails && initialPrefferedContactTimes !== ''}
                  isInvalid={!!secondaryContactDetailsErrors.PreferredContactTimes}
                />
                <Form.Control.Feedback type="invalid" className="validation-error">
                  {secondaryContactDetailsErrors.PreferredContactTimes}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          {/* <Row className="pt-3">
            <label>Is this person the primary contact?</label>
            <Form>
              <Form.Check
                inline
                label="Yes"
                //defaultChecked={item.description === "Defence Location"}
                type="radio"
                //id={item.fieldId}
                value="true"
                name="primaryContact"
              />
              <Form.Check
                inline
                label="No"
                //defaultChecked={item.description === "Defence Location"}
                type="radio"
                //id={item.fieldId}
                value="true"
                name="primaryContact"
              />
            </Form>
          </Row> */}
        </Form>
      </div>
    );
  };
  return <Col className="formField">{renderElement()}</Col>;
};

export default PersonalDetailsSecondary;
