import { Grid, GridItem, Heading, Text } from '@chakra-ui/react';

import { Spinner } from '../../Core/Spinner/Spinner';
import { KeyToLabelMap } from './KeyToLabelMap';

import React from 'react';
import { FileTiles } from '../FileTiles/FileTiles';
import { PegaFile } from '../types';

interface ViewPIRProps {
  PIR?: any;
  Attachments?: PegaFile[];
}

interface SectionItems {
  Label: string;
  type: string;
}
interface SectionProps {
  section: string;
  map: SectionItems;
  Label?: string;
  PIR: any;
  attachments?: PegaFile[] | undefined;
}

interface TitleValue {
  title: string;
  value: string | [{ Address?: string; Number?: string }];
}

const TypeToPropertySetMap = {
  Advocate: 'AdvocateApplicantDetails',
  Family: 'FamilyMemberApplicantDetails',
  Self: 'SelfApplicantDetails',
};

const PropValuePair: React.FC<TitleValue> = (props: TitleValue) => {
  const value = typeof props.value === 'object' ? Object.values(props.value[0])[0] : props.value;
  return value && value !== 'Invalid Date' ? (
    <GridItem colSpan={{ lg: 1, base: 2 }}>
      <Text m={0} color="charcoal">
        {props.title}
      </Text>
      <Text fontWeight="light">{value}</Text>
    </GridItem>
  ) : (
    <></>
  );
};

const Section: React.FC<SectionProps> = (props: SectionProps): JSX.Element => {
  const items = { ...props.map };
  return (
    <>
      <GridItem colSpan={2}>
        <Heading as="h3" fontSize="sm">
          {items.Label}
        </Heading>
      </GridItem>
      {Object.keys(items).map((i, idx) => {
        const item = items[i];
        return props.section === 'RequestDetails' ? (
          i !== 'Label' && (
            <PropValuePair
              key={idx}
              title={item}
              value={i !== 'SubmissionDateTime' ? props.PIR[i] : new Date(props.PIR[i]).toDateString()}
            />
          )
        ) : typeof item === 'string' ? (
          i !== 'Label' && (
            <PropValuePair key={idx} title={item} value={props.PIR[TypeToPropertySetMap[props.PIR.ApplicantType]][i]} />
          )
        ) : item.type !== 'files' ? (
          <React.Fragment key={idx}>
            <GridItem colSpan={2}>
              <Heading as="h3" fontSize="sm">
                {item.Label}
              </Heading>
            </GridItem>
            {Object.keys(item).map(
              (j, index) =>
                j !== 'Label' && (
                  <PropValuePair key={index} title={items[i][j]} value={props.PIR[props.section][i][j]} />
                ),
            )}
          </React.Fragment>
        ) : (
          props.attachments && (
            <GridItem colSpan={2}>
              <FileTiles header={item.Label} files={props.attachments} filterCategory={i} />
            </GridItem>
          )
        );
      })}
    </>
  );
};

export const ViewPIR: React.FC<ViewPIRProps> = (props: ViewPIRProps) => {
  return props.PIR ? (
    <Grid gridTemplateColumns="repeat(2,1fr)" my="1rem">
      {Object.keys(KeyToLabelMap).map(
        (i, index) =>
          (i === 'RequestDetails' ||
            i === 'DeliveryInformation' ||
            TypeToPropertySetMap[props.PIR.ApplicantType] === i) && (
            <Section key={index} section={i} map={KeyToLabelMap[i]} PIR={props.PIR} attachments={props.Attachments} />
          ),
      )}
    </Grid>
  ) : (
    <Spinner />
  );
};
