import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, ThemeProvider } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';

import { ErrorHandler } from './components/ServiceUI/Core/ErrorHandler/ErrorHandler';

import Home from './components/content/Home';
import Logout from './components/content/Logout';
import NotificationPage from './components/content/NotificationPage';
import OurServices from './components/content/OurServices';
import Profile from './components/content/Profile';
import Search from './components/content/Search';
import Support from './components/content/Support';
import LearnMore from './components/LandingPage/LearnMore/LearnMore';

import CreateRequest from './components/MyServiceRequest/CreateRequest/CreateRequest';
import ReviewRequest from './components/MyServiceRequest/CreateRequest/ReviewRequest';
import ThankyouPage from './components/MyServiceRequest/CreateRequest/ThankyouPage';
import HelpAndFAQs from './components/MyServiceRequest/HelpAndFAQs';
import MyServiceRequest from './components/MyServiceRequest/MyServiceRequest';

import ProfileModal from './components/Auth-Profile/AuthProfileModal';

import LocationPreviewModalProps from './components/LocationPreviewModal/__mocks__/default.json';
import LocationPreviewModal from './components/LocationPreviewModal/LocationPreviewModal';

import SystemOutageModalProps from './components/SystemOutageModal/__mocks__/default.json';
import SystemOutageModal from './components/SystemOutageModal/SystemOutageModal';

import GenericErrorModalProps from './components/GenericErrorModal/__mocks__/default.json';
import GenericErrorModal from './components/GenericErrorModal/GenericErrorModal';

import FooterProps from './components/LandingPage/Footer/__mocks__/default.json';
import Footer from './components/LandingPage/Footer/Footer';
import navprops from './components/LandingPage/MainNavigation/__mocks__/default.json';
import MainNav from './components/LandingPage/MainNavigation/MainNav';

import { useLocation } from 'react-router-dom';
import VerifyIdentityLink from './components/Auth-Profile/VerifyIdentityLink';
import Privacy from './components/content/Privacy';
import AuthenticationModalProps from './components/LandingPage/AuthenticationModal/__mocks__/default.json';
import AuthenticationModal from './components/LandingPage/AuthenticationModal/AuthenticationModal';
import SessionTimeout from './components/LandingPage/SessionTimeout/SessionTimeout';
import EditServiceRequestCreate from './components/MyServiceRequest/EditServiceRequest/EditServiceRequestCreate';
import EditServiceRequestReview from './components/MyServiceRequest/EditServiceRequest/EditServiceRequestReview';
import ViewServiceRequest from './components/MyServiceRequest/ViewServiceRequest/ViewServiceRequest';
import { appConstants } from './helper/client/constant';

import {
  ApplicantDocsReadyToDownload,
  CollectDocumentsPage,
  HelpPage,
  LandingPage,
  Layout,
  NewRequestCompletePage,
  NewRequestPage,
  NewRequestReviewPage,
  NewRequestViewPage,
  ProvideDocumentsCompletePage,
  ProvideDocumentsPage,
} from './pages/EFT';

import { redirectSSOCurrentURL } from './helper/redirectSSOCurrentURL';

import PrivacyModal from './components/PrivacyModal/PrivacyModal';
import { getURL } from './helper/getAPIUrl';

const App = () => {
  let location = useLocation();

  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [isAuthorised, setisAuthorised] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [APIError, setAPIError] = useState({});
  const [CookieFlag, setCookieFlag] = useState({
    isCookieFlag: false,
  });
  const [cameFromProfile, setcameFromProfile] = useState(false);
  const [onloadData, setOnLoadData] = useState({
    isProfileLoaded: false,
    profileData: {},
    baseDetails: { baseName: '', baseAddress: '' },
    workBaseDetails: { baseName: '', baseAddress: '' },
    alternateBaseDetails: { baseName: '', baseAddress: '', start: '', end: '' },
    isShowLocationPopup: false,
    isprofileUpdated: null,
    isShowPrivacyModel: false,
  });
  const getPrivacyFlag = (profileDataObj) => {
    if (profileDataObj.privacyFlag === false && profileDataObj.isLocationConfirmed === false) {
      //|| res.data.privacyFlag === undefined
      return true;
    } else if (profileDataObj.privacyFlag === false && profileDataObj.isLocationConfirmed === true) {
      return true;
    } else {
      return false;
    }
  };
  const getLocationFlag = (profileDataObj) => {
    if (profileDataObj.privacyFlag === true && profileDataObj.isLocationConfirmed === false) {
      return true;
    } else {
      return false;
    }
  };
  const updateOnLoadStateDetails = (res) => {
    setOnLoadData((prevState) => {
      return {
        ...prevState,
        isProfileLoaded: true,
        profileData: res.data,
        baseDetails: {
          baseName: res.data.location.name,
          baseAddress: res.data.location.fullAddress,
        },
        workBaseDetails:
          !!res.data.workingLocation?.name.length > 0
            ? {
                baseName: res.data.workingLocation.name,
                baseAddress: res.data.workingLocation.fullAddress,
              }
            : { baseName: '', baseAddress: '' },
        alternateBaseDetails:
          !!res.data.alternateBaseLocation?.name.length > 0
            ? {
                baseName: res.data.alternateBaseLocation.name,
                baseAddress: res.data.alternateBaseLocation.fullAddress,
                start: !!res.data.alternateLocStartDate ? res.data.alternateLocStartDate : '',
                end: !!res.data.alternateLocEndDate ? res.data.alternateLocEndDate : '',
              }
            : {
                baseName: '',
                baseAddress: '',
                start: '',
                end: '',
              },
        isShowLocationPopup: getLocationFlag(res.data),
        isShowPrivacyModel: getPrivacyFlag(res.data),
      };
    });
  };
  const renderAuthToken = (v) => {
    // Need to remove after test deployment
    if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_LOCAL_ENV) {
      const URL_base = getURL(`${process.env.REACT_APP_API_BASE_URL}/setdummyCookie`);
      axios
        .get(URL_base, {
          withCredentials: true,
        })
        .then((res) => {
          const URL_heart_beat = getURL(process.env.REACT_APP_HEART_BEAT_API);
          axios
            .get(URL_heart_beat, {
              withCredentials: true,
            })
            .then((res) => {
              setisAuthorised(true);
              const URL_GET_USER_PROFILE_DETAILS = getURL(process.env.REACT_APP_GET_USER_PROFILE_DETAILS);
              axios
                .get(URL_GET_USER_PROFILE_DETAILS, {
                  withCredentials: true,
                })
                .then((res) => {
                  updateOnLoadStateDetails(res);
                });
            })
            .catch((err) => {
              let error = { ...err };
              error.response = error.response || { response: { data: [] } };
              setAPIError(error);
              if (error.response.status === 403 || error.response.status === 401) {
                setisAuthorised(false);
                redirectSSOCurrentURL();
                setShowModal(false);
              } else if (error.response.status === 500) {
                //PEGA is down - show Modal Here for service down
                setServiceDownModal(true);
              } else {
                setErrorModal(true);
              }
            });
        });
    } else if (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV) {
      const URL_HEART_BEAT_API = getURL(process.env.REACT_APP_HEART_BEAT_API);
      axios
        .get(URL_HEART_BEAT_API, {
          withCredentials: true,
        })
        .then((res) => {
          setisAuthorised(true);
          const URL_GET_USER_PROFILE_DETAILS = getURL(process.env.REACT_APP_GET_USER_PROFILE_DETAILS);
          axios
            .get(URL_GET_USER_PROFILE_DETAILS, {
              withCredentials: true,
            })
            .then((res) => {
              updateOnLoadStateDetails(res);
            });
        })
        .catch((err) => {
          let error = { ...err };
          setAPIError(error);
          error.response = error.response || { response: { data: [] } };
          if (error.response.status === 403 || error.response.status === 401) {
            setisAuthorised(false);
            redirectSSOCurrentURL();
            setShowModal(false);
          } else if (error.response.status === 500) {
            //PEGA is down - show Modal Here for service down
            setServiceDownModal(true);
          } else {
            setErrorModal(true);
          }
        });
    }
  };

  const renderAuthTokenProfileSetup = () => {
    // Need to remove after test deployment
    if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_LOCAL_ENV) {
      const URL_API_BASE_URL_PROFILE_SETUP = getURL(
        `${process.env.REACT_APP_API_BASE_URL_PROFILE_SETUP}/setdummyCookieProfileSetup`,
      );
      axios
        .get(URL_API_BASE_URL_PROFILE_SETUP, {
          withCredentials: true,
        })
        .then((res) => {
          const URL_HEART_BEAT_API_PROFILE_SETUP = getURL(process.env.REACT_APP_HEART_BEAT_API_PROFILE_SETUP);
          axios
            .get(URL_HEART_BEAT_API_PROFILE_SETUP, {
              withCredentials: true,
            })
            .then((res) => {
              setisAuthorised(true);
              setCookieFlag((prevState) => {
                return {
                  isCookieFlag: true,
                };
              });
            })
            .catch((err) => {
              let error = { ...err };
              setAPIError(error);
              error.response = error.response || { response: { data: [] } };
              if (error.response.status === 403 || error.response.status === 401) {
                setisAuthorised(false);
                redirectSSOCurrentURL();
                setShowModal(false);
              } else {
                setErrorModal(true);
              }
            });
        });
    } else if (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV) {
      const URL_HEART_BEAT_API_PROFILE_SETUP = getURL(process.env.REACT_APP_HEART_BEAT_API_PROFILE_SETUP);

      axios
        .get(URL_HEART_BEAT_API_PROFILE_SETUP, {
          withCredentials: true,
        })
        .then((res) => {
          setisAuthorised(true);
          setCookieFlag((prevState) => {
            return {
              isCookieFlag: true,
            };
          });
        })
        .catch((err) => {
          let error = { ...err };
          error.response = error.response || { response: { data: [] } };
          if (error.response.status === 403 || error.response.status === 401) {
            setisAuthorised(false);
            redirectSSOCurrentURL();
            setShowModal(false);
          }
        });
    }
  };
  const onPrivacyFlagUpdatedHandler = (flag) => {
    setOnLoadData((prevState) => {
      return {
        ...prevState,
        isShowLocationPopup: flag,
      };
    });
  };
  const GetUpdatedUserProfile = () => {
    const URL_GET_USER_PROFILE_DETAILS = getURL(process.env.REACT_APP_GET_USER_PROFILE_DETAILS);
    axios
      .get(URL_GET_USER_PROFILE_DETAILS, {
        withCredentials: true,
      })
      .then((res) => {
        setOnLoadData((prevState) => {
          return {
            ...prevState,
            isprofileUpdated: false,
            profileData: res.data,
          };
        });
      })
      .catch((err) => {
        let error = { ...err };
        setAPIError(error);
        error.response = error.response || { response: { data: [] } };
        if (error.response.status === 403 || error.response.status === 401) {
          redirectSSOCurrentURL();
          setShowModal(false);
        } else if (error.response.status === 500) {
          //PEGA is down - show Modal Here for service down
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
          //alert("Something went wrong! Please try again later.");
        }
      });
  };
  const updatePersonalData = () => {
    setOnLoadData((prevState) => {
      return {
        ...prevState,
        isprofileUpdated: true,
      };
    });
  };

  useEffect(() => {
    if (window.location.href.includes(appConstants.SR_PROFILE)) {
      renderAuthTokenProfileSetup();
    } else {
      if (!window.location.href.includes(appConstants.SR_VERIFY_LINK)) {
        renderAuthToken();
      }
    }
  }, [cameFromProfile]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (onloadData.isprofileUpdated !== null && onloadData.isprofileUpdated) {
      GetUpdatedUserProfile();
    }
  }, [onloadData.isprofileUpdated]);

  // IMPORTANT!!!!! This we use only for verifyEmail functionality
  const SCRoutesWithoutAuth = () => {
    return (
      <Routes>
        <Route path="/verifyidentitylink/:code" element={<VerifyIdentityLink />} />
      </Routes>
    );
  };
  //
  const SCRoutes = () => {
    return (
      <Routes>
        <Route path="/my-service-request" element={<MyServiceRequest />} />
        <Route path="/help-and-faqs" element={<HelpAndFAQs />} />
        <Route path="/my-service-request/new-service-request" element={<CreateRequest />} />
        <Route path="/my-service-request/review-request/:id" element={<ReviewRequest />} />
        <Route path="/my-service-request/view-service-request/:id" element={<ViewServiceRequest />} />
        <Route path="/my-service-request/edit-service-request-create/:id" element={<EditServiceRequestCreate />} />
        <Route path="/my-service-request/edit-service-request-review/:id" element={<EditServiceRequestReview />} />
        <Route path="/req-completed/" element={<ThankyouPage />} />

        <Route
          path="/profile/"
          element={
            <ProfileModal isCookieSet={CookieFlag.isCookieFlag} onComingtoHomePage={() => setcameFromProfile(true)} />
          }
        />
        <Route path="/" element={onloadData.isProfileLoaded ? <Home {...onloadData.profileData} /> : <></>} />
        <Route path="/reactjs" element={onloadData.isProfileLoaded ? <Home {...onloadData.profileData} /> : <></>} />
        <Route
          path="/serviceconnect"
          element={onloadData.isProfileLoaded ? <Home {...onloadData.profileData} /> : <></>}
        />
        <Route path="/serviceconnect/all-services" element={<OurServices />} />
        <Route path="/serviceconnect/notifications" element={<NotificationPage />} />
        <Route
          path="/locationpreview"
          element={
            <LocationPreviewModal {...LocationPreviewModalProps} onComingtoHomePageFromUpdate={updatePersonalData} />
          }
        />
        <Route
          path="/serviceconnect/Profile"
          element={
            onloadData.isProfileLoaded ? (
              <Profile {...onloadData.profileData} onComingtoHomePageFromUpdate={updatePersonalData} />
            ) : (
              <></>
            )
          }
        />
        <Route path="/serviceconnect/support" element={<Support />} />
        <Route path="/serviceconnect/search" element={<Search />} />
        <Route path="/serviceconnect/learnMore" element={<LearnMore />} />
        <Route path="/serviceconnect/privacy" element={<Privacy />} />
        <Route path="logout" element={<Logout />} />
        <Route path="/verifyidentitylink/:code" element={<VerifyIdentityLink />} />
        <Route path="/personal-information-request" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path="new" element={<NewRequestPage />} />
          <Route path="new/:id" element={<NewRequestPage />} />
          <Route path="review/:id" element={<NewRequestReviewPage />} />
          <Route path="complete/:id" element={<NewRequestCompletePage />} />
          <Route path="view/" element={<NewRequestViewPage />} />
          <Route path="doc-ready/:id" element={<ApplicantDocsReadyToDownload />} />
          <Route path="provide-documents">
            <Route path=":id" element={<ProvideDocumentsPage />} />
            <Route path="complete/:id" element={<ProvideDocumentsCompletePage />} />
          </Route>
          <Route path="collect-documents" element={<CollectDocumentsPage />} />
          <Route path="help-and-faqs" element={<HelpPage />} />
        </Route>
      </Routes>
    );
  };

  return (
    <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
      <ErrorHandler />
      {onloadData.isShowPrivacyModel && (
        <PrivacyModal profileData={onloadData.profileData} onPrivacyFlagUpdated={onPrivacyFlagUpdatedHandler} />
      )}
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}

      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}

      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
        />
      )}
      {onloadData.isShowLocationPopup && (
        <LocationPreviewModal
          {...LocationPreviewModalProps}
          onComingtoHomePageFromUpdate={updatePersonalData}
          baseName={onloadData.baseDetails.baseName}
          baseAddress={onloadData.baseDetails.baseAddress}
          alternateBaseName={onloadData.alternateBaseDetails.baseName}
          alternateBaseAddress={onloadData.alternateBaseDetails.baseAddress}
          alternateBaseStart={onloadData.alternateBaseDetails.start}
          alternateBaseEnd={onloadData.alternateBaseDetails.end}
          workBaseName={onloadData.workBaseDetails.baseName}
          workBaseAddress={onloadData.workBaseDetails.baseAddress}
        />
      )}

      <SessionTimeout idleTime={720} timeUntilRedirect={180} redirectURL={process.env.REACT_APP_REDIRECT_URL} />
      {!window.location.href.includes(appConstants.SR_VERIFY_LINK) && isAuthorised && <MainNav {...navprops} />}

      {!window.location.href.includes(appConstants.SR_VERIFY_LINK) && (
        <Container className="content">{isAuthorised && <SCRoutes />}</Container>
      )}
      {window.location.href.includes(appConstants.SR_VERIFY_LINK) && (
        <Container className="content">
          <SCRoutesWithoutAuth />
        </Container>
      )}

      {/*  <PlannedSystemOutageModal
        title={PlannedSystemOutageModalProps.title}
        description={PlannedSystemOutageModalProps.description}
        ctaText={PlannedSystemOutageModalProps.ctaText}
      /> */}
      {!window.location.href.includes(appConstants.SR_VERIFY_LINK) && isAuthorised && (
        <Footer
          logoAltText={FooterProps.logoAltText}
          acknowledgmentText={FooterProps.acknowledgmentText}
          quickAccessTitle={FooterProps.quickAccessTitle}
          quickAccess={FooterProps.quickAccess}
          moreInfoTitle={FooterProps.moreInfoTitle}
          moreInformation={FooterProps.moreInformation}
        />
      )}
    </ThemeProvider>
  );
};

export default App;
