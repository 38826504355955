import * as React from 'react';
import { SVGProps } from 'react';
const Alert = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_413_9)">
      <g>
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="#D52F48"
                  fillRule="evenodd"
                  d="M8 8.93793C7.64138 8.93793 7.36552 8.66207 7.36552 8.30345V5.02069C7.36552 4.68966 7.64138 4.38621 8 4.38621C8.35862 4.38621 8.63448 4.66207 8.63448 5.02069L8.63448 8.33103C8.63448 8.66207 8.35862 8.93793 8 8.93793ZM8 11.1724C7.64138 11.1724 7.36552 10.8966 7.36552 10.5655C7.36552 10.2345 7.64138 9.93103 8 9.93103C8.35862 9.93103 8.63448 10.2069 8.63448 10.5655C8.63448 10.8966 8.35862 11.1724 8 11.1724ZM8 1.24138C11.7241 1.24138 14.7586 4.27586 14.7586 8C14.7586 11.7241 11.7241 14.7586 8 14.7586C4.27586 14.7586 1.24138 11.7241 1.24138 8C1.24138 4.27586 4.27586 1.24138 8 1.24138ZM8 16C12.4138 16 16 12.4138 16 8C16 3.58621 12.4138 0 8 0C3.58621 0 0 3.58621 0 8C0 12.4138 3.58621 16 8 16Z"
                  clipRule="evenodd"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath>
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Alert;
