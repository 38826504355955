/* eslint-disable eqeqeq */
/*******************************************************
 * validateTime
 * return true if the string 'p' is a valid time
 *******************************************************/
const validateTime = (p) => {
  var timeRegex = /(((0[1-9])|(1[0-2])):([0-5])([0-9])\s(A|P)M)/;
  //var digits = p.replace(/\D/g, "");
  return timeRegex.test(p);
};

const getMinutesFromHHMM = (value) => {
  const [str1, str2] = value.split(':');

  const val1 = Number(str1);
  const val2 = Number(str2);

  if (!isNaN(val1) && isNaN(val2)) {
    // minutes
    return val1;
  }

  if (!isNaN(val1) && !isNaN(val2)) {
    // hours * 60 + minutes
    return val1 * 60 + val2;
  }

  return 0;
};

const toHHMM = (secs) => {
  const secNum = parseInt(secs.toString(), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return [hours, minutes, seconds]
    .map((val) => (val < 10 ? `0${val}` : val))
    .filter((val, index) => val !== '00' || index > 0)
    .join(':');
  // remove initial 0 if required
  //.replace(/^0/, "");
};

const TimePickerBlurEvent = (e) => {
  if (e && e.target && e.target.value) {
    var timeValue = JSON.parse(JSON.stringify(e.target.value));
    var timeStr = timeValue.toString().substring(0, 5);
    // pre-process input to insert a missing colon
    if (timeStr.length > 0 && timeStr.indexOf(':') < 0) {
      let timeMissingColon = timeStr.split('');
      if (timeStr.length < 4) {
        timeMissingColon.splice(1, 0, ':');
      } else if (timeStr.length === 4) {
        timeMissingColon.splice(2, 0, ':');
      }
      timeStr = timeMissingColon.join('');
    }
    // pre-process input to check for '00:' and '0:'
    if (timeStr.length > 1) {
      timeStr = timeStr.replace('00:', '12:');
    }
    if (timeStr.substring(0, 2) === '0:') {
      timeStr = timeStr.replace('0:', '12:');
    }
    var secondsValue = Math.max(0, getMinutesFromHHMM(timeStr));

    var ampm = '';
    var timeSuffix = timeValue.match(/\s(.*)$/);
    if (timeSuffix) {
      ampm = timeSuffix[1];
    }

    // reduce to single day
    if (secondsValue > 1440) {
      secondsValue = Math.floor(secondsValue / 1440);
    }

    // < 12:00 is AM
    if (secondsValue < 720 && !ampm) {
      ampm = 'AM';
    }

    // > 12:00 is PM
    if (secondsValue >= 720 && !ampm) {
      ampm = 'PM';
    }

    // 24:00 is AM
    if (secondsValue === 1440) {
      ampm = 'AM';
    }

    if (secondsValue >= 780) {
      secondsValue = secondsValue - 720;
    }

    const timeFromSeconds = toHHMM(secondsValue);
    var targetTimeValue = JSON.parse(JSON.stringify(timeFromSeconds));
    var target12HourTime = `${targetTimeValue} ${ampm}`;

    var isValid = validateTime(target12HourTime);
    if (!isValid) {
      target12HourTime = '12:00 AM';
    }

    e.target.value = target12HourTime;
  }
  return;
};

export { TimePickerBlurEvent, validateTime, getMinutesFromHHMM, toHHMM };
