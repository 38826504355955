import axios from 'axios';
import { appConstants } from '../../../helper/client/constant';
import { getURL } from '../../../helper/getAPIUrl';

export const PIRCaseAudit = (caseID, attachmentID = '', auditcode) => {
  const URL_AUDIT = process.env.REACT_APP_AUDIT_UPDATE + caseID;
  if (attachmentID !== '') {
    const final_URL_AUDIT = getURL(URL_AUDIT);
    let URL_ACCORDING_TO_ENV =
      process.env.REACT_APP_CURRENT === appConstants.REACT_APP_LOCAL_ENV
        ? final_URL_AUDIT + '/' + auditcode + '?AttachmentID=' + attachmentID
        : final_URL_AUDIT + '?AuditCode=' + auditcode + '&AttachmentID=' + attachmentID;
    return axios
      .put(
        URL_ACCORDING_TO_ENV,
        {},
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        return 'success';
      });
  } else {
    const URL_AUDIT = process.env.REACT_APP_AUDIT_UPDATE + caseID;

    const final_URL_AUDIT = getURL(URL_AUDIT);
    let URL_ACCORDING_TO_ENV =
      process.env.REACT_APP_CURRENT === appConstants.REACT_APP_LOCAL_ENV
        ? final_URL_AUDIT + '/' + auditcode
        : final_URL_AUDIT + '?AuditCode=' + auditcode;
    return axios
      .put(
        URL_ACCORDING_TO_ENV,
        {},
        {
          withCredentials: true,
        },
      )
      .then((_) => {
        return 'success';
      });
  }
};
