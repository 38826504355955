import React, { useEffect, useState } from 'react';
import { Form, FormSelect } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setFieldValue, setRequestRelatesTo, setVehicleType } from '../../../../reducers/requestDetailFormReducer';
import { FormFieldCol } from './styles';
import { findByProp } from '../../../../helper/findByProp';

const FormSelectInput = ({
  fieldID,
  label,
  required,
  value,
  control,
  fieldPropMapping,
  visible,
  reference,
  formData,
}) => {
  const reqDetailDataFromReduxStore = useSelector((state) => {
    if (!!state) {
      return findByProp(state.requestForm.formFields, 'reference', reference);
    }
  });
  const [fieldValue, setValue] = useState(value);
  const [options, setOptions] = useState(control?.modes[0]?.options);
  const dispatch = useDispatch();

  useEffect(() => {
    setValue(reqDetailDataFromReduxStore?.value);
  }, [reqDetailDataFromReduxStore]);

  const onSelect = ({ target }) => {
    setValue(target.value);
  };
  // useEffect(() => {
  //   if (!!control?.modes[0]?.options && control?.modes[0]?.options.length > 0) {
  //     const selectedValue = control?.modes[0]?.options?.filter(
  //       (item) => item.value === value
  //     );
  //     setValue(value);
  //     dispatch(
  //       setFieldValue({
  //         fieldID,
  //         formData,
  //         // value: fieldValue,
  //         value:
  //           !!selectedValue && selectedValue.length > 0
  //             ? selectedValue[0].key
  //             : fieldValue,
  //       })
  //     );
  //   }
  // }, [control?.modes[0]?.options]);
  useEffect(() => {
    const selectedValue = options?.find((item) => item.key === value);

    const selectListValue = !!selectedValue && selectedValue.length > 0 ? selectedValue.key : fieldValue;

    dispatch(
      setFieldValue({
        reference,
        value: selectListValue,
        formData,
        required,
        visible,
      }),
    );

    switch (fieldID) {
      case 'VehicleType':
        dispatch(setVehicleType({ fieldID, value: selectListValue }));
        break;
      case 'RequestRelatesTo':
        dispatch(setRequestRelatesTo({ fieldID, value: selectListValue, formData }));
        break;
    }
  }, [reference, fieldValue, formData.fieldGroup.length]);

  return (
    <>
      {visible && (
        <FormFieldCol
          lg={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop ?? 12}
          sm={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile ?? 12}
          className="mb-4 pe-lg-4 mobileButton"
        >
          <Form.Group className={`${fieldPropMapping[fieldID]?.layoutClassName ?? ''}`} controlId={reference}>
            <Form.Label className={required ? 'required-field' : ''}>{label}</Form.Label>
            <FormSelect required={required} id={reference} name={reference} onChange={(e) => onSelect(e)}>
              <option selected={fieldValue === ''} value="">
                Please select an option
              </option>
              {options?.map((item) => (
                <option selected={fieldValue === item.key} key={item.key} value={item.key}>
                  {item.value}
                </option>
              ))}
            </FormSelect>
          </Form.Group>
        </FormFieldCol>
      )}
    </>
  );
};

export default FormSelectInput;
