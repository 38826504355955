import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const FormCTA = ({ control, fieldPropMapping, fieldID, formData, reference }) => {
  const dispatch = useDispatch();

  const onButtonClick = () => {
    let pageInstructions = [];
  };

  return (
    <>
      <Col
        lg={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop ?? 12}
        sm={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile ?? 12}
        className="mb-4 pe-4"
      >
        <Button onClick={onButtonClick} id={control.label.replace(/ /g, '_')}>
          {control.label}
        </Button>
      </Col>
    </>
  );
};

export default FormCTA;
