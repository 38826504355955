import React, { useEffect, useRef, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import './Location.scss';
import SearchDropdown from '../SearchDropdown';
import SearchVesselDropdown from '../SearchVesselDropdown';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { InfoCircle } from 'react-bootstrap-icons';

const Location = (props) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [isOpen, setIsOpen] = useState({
    flag: false,
  });

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      If your request relates to a vessel, select the Defence location where you would receive the service.
    </Tooltip>
  );

  const renderTooltipImpactedArea = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Include the interior or exterior area, building, level, room, desk etc.
    </Tooltip>
  );

  const nameInputRef = useRef();
  const [name, setName] = useState({
    fieldName: '',
    fieldValue: '',
    fieldId: '',
  });
  const [dropDownState, setStateDropDown] = useState({
    dropDownArray: [],
    selectedValue: props.stateValues.selectedValue,
    description: props.stateValues.selectedDescription,
  });
  const [vesselSelected, setVesselSelected] = useState({
    isSelected: props.VesselValues.selectedValue.length > 0 ? true : false,
    selectedVessel: props.VesselValues.selectedValue,
    showVesselDropDown: props.VesselValues.selectedValue === '' ? false : true,
  });
  const [dropDownLocationType, setDropDownLocationType] = useState({
    dropDownArray: [],
    selectedValue: props.locationTypeValues.selectedValue,
    description: props.locationTypeValues.selectedDescription,
  });
  const [dropDownLocationBaseName, setDropDownLocationBaseName] = useState({
    dropDownArray: [],
    selectedValue: props.baseNameValues.selectedValue,
  });

  const [dropDownvesselSelected, setDropDownVesselSelected] = useState({
    dropDownArray: [],
    selectedValue: props.VesselValues.selectedValue,
  });

  const inputChangedHandler = (event) => {
    event.persist();

    setName((prevState) => {
      return {
        ...prevState,
        fieldValue: event.target.value,
      };
    });

    props.onLocationInfoChange(
      props.locationDetail.fieldID,
      props.locationDetail.fieldName,
      nameInputRef.current.value,
      props.locationDetail.reference,
    );
  };

  const changeNameHandler = () => {
    if (props.locationDetail.fieldName === 'Type') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Location type',
        };
      });
    } else if (props.locationDetail.fieldName === 'Name') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Base Name',
        };
      });
    } else if (props.locationDetail.fieldName === 'FullAddress') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Address',
        };
      });
    } else if (props.locationDetail.fieldName === 'Area') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Impacted area',
        };
      });
    } else if (props.locationDetail.fieldName === 'Description') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Location Description',
        };
      });
    } else if (props.locationDetail.fieldID === 'Line1') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Address',
        };
      });
    } else if (props.locationDetail.fieldID === 'SiteOrWharf') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Is this a vessel?',
        };
      });
    } else {
      setName((prevState) => {
        return {
          fieldName: !!props.locationDetail.fieldName ? props.locationDetail.fieldName : props.locationDetail.fieldID,
          fieldValue: props.locationDetail.value,
          fieldId: props.locationDetail.fieldID,
        };
      });
    }
  };
  const changeStateLocationTypeDropDownValue = (dropdownName, text, refCode, id) => {
    if (dropdownName === 'State') {
      setStateDropDown((prevState) => {
        return {
          ...prevState,
          selectedValue: refCode,
          description: text,
        };
      });
      props.onStateLocationTypeDroppDownChange('State', text, refCode, id);
    } else if (dropdownName === 'Location') {
      setDropDownLocationType((prevState) => {
        return {
          ...prevState,
          selectedValue: refCode,
          description: text,
        };
      });

      setName((prevState) => {
        return {
          ...prevState,
          fieldName: '',
          fieldValue: '',
          fieldId: '',
        };
      });

      // setVesselSelected((prevState) => {

      //   return {
      //     ...prevState,
      //     isSelected: prevState.isSelected === true ? false : false,
      //     showVesselDropDown:
      //       prevState.showVesselDropDown === true ? false : false,
      //   };
      // });
      props.onStateLocationTypeDroppDownChange('Location', text, refCode, id);
    }
  };
  const getLocationDropdownClass = () => {
    if (props.isReview) {
      return 'customDropdownReview dropDownSepArrow fw-light';
    } else {
      if (props.locationDetail.error) {
        return ' customDropdown dropDownSepArrow fw-light show-error-border';
      } else {
        return 'customDropdown dropDownSepArrow fw-light';
      }
    }
  };
  const changeVesselDropDownValue = (text, refCode, id) => {
    setVesselSelected((prevState) => {
      return {
        ...prevState,
        selectedVessel: refCode,
      };
    });
    props.onVesselDropDownChange(text, refCode, id);

    props.onLocationInfoChange(id, props.locationDetail.fieldName, refCode, props.locationDetail.reference);
  };
  const testbaseValueOpen = () => {
    setIsOpen((prevState) => {
      return {
        ...prevState,
        flag: true,
      };
    });
  };
  const testbaseValueClose = () => {
    setIsOpen((prevState) => {
      return {
        ...prevState,
        flag: false,
      };
    });
  };
  const updateStateForVesselSelected = () => {
    setVesselSelected((prevState) => {
      return {
        ...prevState,
        isSelected: !prevState.isSelected,
        showVesselDropDown: !prevState.showVesselDropDown,
      };
    });
  };
  const addVesselClickHandler = (flag) => {
    if (flag === true) {
      props.onAddVesselSelected(true);
      updateStateForVesselSelected();
    } else {
      props.onAddVesselSelected(false);
      updateStateForVesselSelected();
    }
  };
  const handleBaseSelect = (text, selectedBaseNameObj, id) => {
    setDropDownLocationBaseName((prevState) => {
      return {
        ...prevState,
        selectedValue: text,
      };
    });

    props.onBaseNameDropDownChange(text, selectedBaseNameObj, id);
  };
  const getRequiredClassNameForImpactedArea = () => {
    if (props.isReview) {
      return 'mb-0';
    } else {
      const selectedWorkType = props.reqInfo.commonFieldsArr.find((item) => {
        if (item.fieldID === 'WorkType') {
          return item.value;
        }
      });
      if (!!selectedWorkType && (selectedWorkType.value === 'Catering' || selectedWorkType.value === 'Transport')) {
        return 'mb-0';
      } else {
        return 'mb-0 required-field';
      }
    }
  };
  const handleVesselSelect = (value, selectedVesselobj, id) => {
    setDropDownVesselSelected((prevState) => {
      return {
        ...prevState,
        selectedValue: value,
        //selectedDescription: selectedVesselCode,
      };
    });

    props.onVesselDropDownChange(value, selectedVesselobj.code, id);
    props.onLocationInfoChange(
      id,
      props.locationDetail.fieldName,
      selectedVesselobj.code,
      props.locationDetail.reference,
    );
  };

  const renderElement = () => {
    if (props.locationDetail.fieldName === 'Type') {
      // return location type dropdown
      return (
        <>
          <Col className="formField">
            <label htmlFor={props.locationDetail.fieldID}>{name.fieldName}</label>
            <div className="">
              {props.isReview ? (
                <p className="fw-light">
                  {props.locationTypeValues.selectedValue === 'Other' ? 'Other Location' : 'Defence Location'}
                </p>
              ) : (
                <>
                  <Form>
                    {props.locationTypeValues.dropDownArrayLocType.map((item, idx) => (
                      <Form.Check
                        inline
                        label={item.description}
                        checked={props.locationTypeValues.selectedDescription === item.description}
                        type="radio"
                        id={item.fieldId}
                        name={`primaryContact-${idx}`}
                        onClick={(e) =>
                          changeStateLocationTypeDropDownValue(
                            'Location',
                            e.target.textContent,
                            item.code,
                            props.locationDetail.fieldID,
                          )
                        }
                      />
                    ))}
                  </Form>
                </>
              )}
            </div>
          </Col>
        </>
      );
    } else if (props.locationDetail.fieldName === 'State') {
      // return state dropdown
      return (
        <>
          <p></p>
          <div className="fieldContainer p-2">
            <label className={!props.isReview && 'required-field'} htmlFor={props.locationDetail.fieldID}>
              {name.fieldName}
            </label>
            {props.isReview ? (
              <p className="fw-light">{props.stateValues.selectedDescription}</p>
            ) : (
              <Dropdown>
                <Dropdown.Toggle title="State" variant="dropdown" id="State" disabled={props.isReadonlyLocation}>
                  {props.stateValues.selectedDescription}
                </Dropdown.Toggle>
                <Dropdown.Menu className=" dropDownMenu">
                  {props.stateValues.dropDownArrayLocState.map((item) => (
                    <Dropdown.Item
                      className="fw-light"
                      onClick={(e) =>
                        changeStateLocationTypeDropDownValue(
                          'State',
                          e.target.textContent,
                          item.code,
                          props.locationDetail.fieldID,
                        )
                      }
                      as="button"
                      key={item.fieldId}
                    >
                      {item.description}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </>
      );
    } else if (props.locationDetail.fieldName === 'Name') {
      // return Base name dropdown
      return (
        <>
          <p></p>
          <div className="fieldContainer p-2">
            <label className={!props.isReview && 'required-field'}>Defence Location</label>
            <SearchDropdown
              //label="Defence Location"
              id={props.locationDetail.fieldID}
              //className={!props.isReview && "required-field"}
              autoComplete="nope"
              options={props.baseNameValues.dropDownArrayBaseName}
              selectedValue={props.baseNameValues.selectedValue}
              isReview={props.isReview}
              error={props.locationDetail.error}
              handleChange={(selection) => {
                handleBaseSelect(selection.name, selection, props.locationDetail.fieldID);
              }}
            />
          </div>
        </>
      );
    } else if (props.locationDetail.fieldName === 'SiteOrWharf') {
      // instantiate vessel dropdown
      const vesselDropDownSection = vesselSelected.isSelected ? (
        <Col className="">
          {/* <label className={!props.isReview && "required-field"}>
            Select Vessel
          </label> */}
          {/* <label>Vessel Name</label> */}
          <div className="fieldContainer">
            {props.isReview ? (
              <>
                {' '}
                <label>Vessel Name</label>
                <p className="fw-light">{props.VesselValues.selectedDescription}</p>
              </>
            ) : (
              <SearchVesselDropdown
                label="Vessel Name"
                id={props.locationDetail.fieldID}
                options={props.VesselValues.dropDownArrayVessel}
                selectedValue={props.VesselValues.selectedDescription}
                isReview={props.isReview}
                error={props.locationDetail.error}
                handleChange={(selection) => {
                  handleVesselSelect(selection.description, selection, props.locationDetail.fieldID);
                }}
              />
            )}
          </div>
        </Col>
      ) : (
        <></>
      );
      return (
        <>
          {!props.isReview && (
            <Col className="formField">
              <h3>
                {name.fieldName}{' '}
                <button title="Tooltip" className="border-0 bg-transparent" ref={target} onClick={() => setShow(!show)}>
                  {!props.isReview && (
                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                      <InfoCircle size={15} />
                    </OverlayTrigger>
                  )}
                </button>
              </h3>

              <Form>
                <Form.Check
                  inline
                  label="Yes"
                  type="radio"
                  // onClick={addVesselClickHandler}
                  onClick={() => addVesselClickHandler(true)}
                  //id={props.otherDetail.fieldID}
                  value="true"
                  //defaultChecked={nameOther.fieldValue === "true"}
                  checked={vesselSelected.isSelected === true}
                  disabled={props.isReadonlyOther}
                />
                <Form.Check
                  inline
                  label="No"
                  type="radio"
                  onClick={() => addVesselClickHandler(false)}
                  id="inline-radio-2"
                  value="false"
                  checked={vesselSelected.isSelected === false}
                  disabled={props.isReadonlyOther}
                />
              </Form>
            </Col>
          )}

          {vesselDropDownSection}
        </>
      );
    } else if (props.locationDetail.fieldName === 'Area') {
      return (
        <>
          <label className={!props.isReview && 'p-2'}>
            <p className={getRequiredClassNameForImpactedArea()}>
              {name.fieldName}{' '}
              <button title="Tooltip" className="border-0 bg-transparent" ref={target} onClick={() => setShow(!show)}>
                {!props.isReview && (
                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltipImpactedArea}>
                    <InfoCircle size={15} />
                  </OverlayTrigger>
                )}
              </button>
            </p>

            <input
              ref={nameInputRef}
              id={props.locationDetail.fieldID}
              type="text"
              value={props.locationDetail.value}
              readOnly={props.locationDetail.isReadOnly}
              onChange={inputChangedHandler}
              className={
                props.isReview
                  ? 'textBoxFieldReview fw-light w-100'
                  : props.locationDetail.error
                  ? ' textBoxField fw-light w-100 show-error-border'
                  : 'textBoxField fw-light w-100'
              }
            />
          </label>
        </>
      );
    } else {
      // return textbox fields
      return (
        <Col className="formField">
          <div>
            <label className={!props.isReview && 'required-field pb-2'} htmlFor={props.locationDetail.fieldID}>
              {name.fieldName}
            </label>
            {props.isReview ? (
              <p className="fw-light">{props.locationDetail.value}</p>
            ) : (
              <input
                ref={nameInputRef}
                id={props.locationDetail.fieldID}
                type="text"
                value={props.locationDetail.value}
                readOnly={props.locationDetail.isReadOnly}
                onChange={inputChangedHandler}
                className={
                  props.isReview
                    ? 'textBoxFieldReview fw-light w-100'
                    : props.locationDetail.error
                    ? ' textBoxField fw-light w-100 show-error-border'
                    : 'textBoxField fw-light w-100'
                }
              />
            )}
          </div>
        </Col>
      );
    }
  };
  // test
  useEffect(() => {
    //createNewReq();
    setName((prevState) => {
      return {
        fieldName: '',
        fieldValue: '',
        fieldId: '',
      };
    });

    changeNameHandler();
  }, [props]);

  return (
    <>
      {/* {renderLocationType()} */}
      {renderElement()}
    </>
  );
};

export default Location;
