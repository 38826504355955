import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const SecondaryContactReadOnly = (props) => {
  return (
    <>
      <Container className="bg-defencewhite mt-4">
        <Container className="px-lg-6 px-0">
          <h1 className="mx-lg-0 mx-4">Alternate point of contact</h1>
          <Row>
            <Col>
              <Container className="ps-4 ps-lg-0">
                <Form.Group controlId="workType">
                  <Form.Label className="p-lg-0">Full name</Form.Label>
                  <br />
                  <Container className="d-flex p-1 gap-5 mb-4 subTypes">
                    <p className="fw-light">{props.reqData.Contacts[0].FullName}</p>
                  </Container>
                </Form.Group>
              </Container>
            </Col>
            <Col>
              <Container className="ps-4 ps-lg-0">
                <Form.Group controlId="workType">
                  <Form.Label className="p-lg-0">Employee ID</Form.Label>
                  <br />
                  <Container className="d-flex p-1 gap-5 mb-4 subTypes">
                    <p className="fw-light">{props.reqData.Contacts[0].EmployeeID}</p>
                  </Container>
                </Form.Group>
              </Container>
            </Col>
          </Row>

          <Row>
            {props.groupSecondaryDesc.length > 0 && (
              <Col>
                <Container className="ps-4 ps-lg-0">
                  <Form.Group controlId="workType">
                    <Form.Label className="p-lg-0">Group or entity</Form.Label>
                    <br />
                    <Container className="d-flex p-1 gap-5 mb-4 subTypes">
                      <p className="fw-light">{props.groupSecondaryDesc}</p>
                    </Container>
                  </Form.Group>
                </Container>
              </Col>
            )}

            <Col>
              <Container className="ps-4 ps-lg-0">
                <Form.Group controlId="workType">
                  <Form.Label className="p-lg-0">Defence email address</Form.Label>
                  <br />
                  <Container className="d-flex p-1 gap-5 mb-4 subTypes">
                    <p className="fw-light">{props.reqData.Contacts[0].Email[0].Address}</p>
                  </Container>
                </Form.Group>
              </Container>
            </Col>
          </Row>

          <Row>
            <Col>
              <Container className="ps-4 ps-lg-0">
                <Form.Group controlId="workType">
                  <Form.Label className="p-lg-0">Preferred contact number</Form.Label>
                  <br />
                  <Container className="d-flex p-1 gap-5 mb-4 subTypes">
                    <p className="fw-light">{props.reqData.Contacts[0].Phone[0].Number}</p>
                  </Container>
                </Form.Group>
              </Container>
            </Col>
            <Col>
              <Container className="ps-4 ps-lg-0">
                <Form.Group controlId="workType">
                  <Form.Label className="p-lg-0">How would you like to be contacted?</Form.Label>
                  <br />
                  <Container className="d-flex p-1 gap-5 mb-4 subTypes">
                    <p className="fw-light">
                      {!!props.reqData.Contacts[0].PreferredContactTimes
                        ? props.reqData.Contacts[0].PreferredContactTimes
                        : ''}
                    </p>
                  </Container>
                </Form.Group>
              </Container>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default SecondaryContactReadOnly;
