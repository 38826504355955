const findAllItems = (obj, keyToFind) => {
  return (
    Object.entries(obj).reduce(
      (acc, [key, value]) =>
        key === keyToFind
          ? acc.concat(obj)
          : typeof value === 'object' && value
          ? acc.concat(findAllItems(value, keyToFind))
          : acc,
      [],
    ) || []
  );
};

export default findAllItems;
