//Use this file to determine the order, label and visibility of the fields on the view page

export const KeyToLabelMap = {
  RequestDetails: {
    Label: 'Request information',
    ServiceTypeValue: 'What type of service do you require?',
    //Start type of records
    IsPersonnelInformation: 'Requires personnel information',
    IsMedicalRecords: 'Requires medical records',
    IsPsychologicalRecords: 'Requires psychological records',
    IsRequestCopyOfRecords: 'Requires copy of records',
    IsEulogy: 'Requires eulogy',
    IsStatementOfService: 'Requires statement of service',
    IsCertificateOfService: 'Requires certificate of service',
    IsDisciplinaryRecords: 'Requires disciplinary records',
    IsServiceReport: 'Requires service report',
    //End type of records
    ApplicantTypeValue: 'Applicant type',
    SubmissionDateTime: 'Date of submission',
    DeliveryType: 'Delivery type',
    AdditionalDetails: 'Additional details',
  },
  AdvocateApplicantDetails: {
    Label: 'Applicant details',
    DateOfBirth: 'Date of birth',
    GivenNames: 'First name',
    Surname: 'Surname',
    IsActiveInForce: 'Are they a current member of the Australian Defence Force?',
    IsDeceased: 'Is this member deceased?',
    ProofOfDeath: { Label: 'Proof of death', type: 'files' },
    ProofOfRelationship: { Label: 'Proof of family member relationship to ex-member', type: 'files' },
    ProofOfIdentification: { Label: 'Family member proof of identification', type: 'files' },
    SignedConsent: { Label: 'Family member signed consent', type: 'files' },
    AdvocateDetails: {
      Label: 'My information (the advocate)',
      GivenNames: 'First name',
      Surname: 'Surname',
      ContactMethod: 'Contact',
      OrganisationName: 'Organisation name',
      OrganisationType: 'Organisation type',
      Relationship: 'Relationship',
      Email: 'Email',
      Phone: 'Phone',
    },
  },
  FamilyMemberApplicantDetails: {
    Label: 'Family member details',
    DateOfBirth: 'Date of birth',
    GivenNames: 'First name',
    Surname: 'Surname',
    IsActiveInForce: 'Are they a current member of the Australian Defence Force?',
    IsDeceased: 'Is this member deceased?',
    ProofOfDeath: { Label: 'Proof of death', type: 'files' },
    PMKeySNumber: 'PMKeyS Number',
    ServiceAreaType: 'Service area(s)',
    YearOfDischarge: 'Year of Discharge',
    ProofOfIdentification: { Label: 'Proof of identification', type: 'files' },
    FamilyDetails: {
      Label: 'My information (the family member)',
      ContactMethod: 'Contact method',
      GivenNames: 'First name',
      Surname: 'Surname',
      Relationship: 'Relationship',
      Email: 'Email',
      Phone: 'Phone',
    },
    ProofOfFamilyMember: { Label: 'Proof of family member relationship to current member', type: 'files' },
    FamilyMemberIdentification: { Label: 'Proof of identification (applicant)', type: 'files' },
  },
  SelfApplicantDetails: {
    Label: 'Member information',
    GivenNames: 'First name',
    Surname: 'Last name',
    Email: 'Email',
    Phone: 'Phone',
    ContactMethod: 'Contact method',
    DateOfBirth: 'Date of birth',
    IsActiveInForce: 'Are they a current member of the Australian Defence Force?',
    PlannedDischargeDate: 'Planned discharge date',
    PMKeySNumber: 'PMKeyS number',
    ServiceAreaType: 'Service area(s)',
    ServiceUnit: 'Service unit',
  },
  DeliveryInformation: { Declaration: { Label: 'Declaration', type: 'files' } },
};
