import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { getURL } from '../../../../helper/getAPIUrl';
import { setShowError } from '../../../../reducers/errorHandling';
import GenericErrorModal from '../../../GenericErrorModal/GenericErrorModal';
import SystemOutageModal from '../../../SystemOutageModal/SystemOutageModal';

import AuthenticationModal from '../../../LandingPage/AuthenticationModal/AuthenticationModal';

import { useDispatch } from 'react-redux';
import { appConstants } from '../../../../helper/client/constant';
import { PIRCaseAudit } from '../../../../pages/EFT/API/PIRCaseAudit';
import { EFTConstants } from '../../../../pages/EFT/EFTConstant';
import GenericErrorModalProps from '../../../GenericErrorModal/__mocks__/default.json';
import AuthenticationModalProps from '../../../LandingPage/AuthenticationModal/__mocks__/default.json';
import SystemOutageModalProps from '../../../SystemOutageModal/__mocks__/default.json';
import DocumentUploadSecurityNotice from '../../EFT/Content/DocumentUploadSecurityNotice';
import { SCForm } from '../FormElements';
import UtilityModals from '../Modal/UtilityModals';
import SendDocFilesUploaded from './Send-Doc-Files-Uploaded';

const SendDocUpload = (props: { caseId: string | undefined; setUploadedFilesCount: any }) => {
  const [allFiles, setAllFiles] = useState<any>([]);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [APIError, setAPIError] = useState({});
  const [showSpinnerApiIsInProgress, setShowSpinnerApiIsInProgress] = useState(false);
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [error, setError] = useState();
  const [attachmentCatogryInForm, setattachmentCatogryInForm] = useState({
    selectedCategory: 'Official Documents',
    selectedCategoryID: appConstants.ATTACHMENT_TYPE_OFFICIAL_DOC,

    allowedFileExt: appConstants.SR_ALLOWED_FILE_EXT_IMAGE_STRING,
  });
  const [showModal, setShowModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<any>({ selectedFile: [] });
  const [uploadedFileInPega, setUploadedFileInPega] = useState({
    selectedFile: [],
  });
  const dispatch = useDispatch();
  const { confirm } = UtilityModals();
  useEffect(() => {
    let total = 0;
    for (let index = 0; index < allFiles.length; index++) {
      total = total + allFiles[index][0].size;
    }
    setTotalFileSize(total);
  }, [allFiles]);
  useEffect(() => {
    getALLSavedFiles();
  }, []);
  const initialStatefileUploadValiditywithInfo = {
    isInValid: false,
    fileInfo: [],
  };
  const [fileUploadValiditywithInfo, setfileUploadValiditywithInfo] = useState({
    ...initialStatefileUploadValiditywithInfo,
  });
  const getUpdatedFilesFromPega = (responseFromPega) => {
    const filteredArrayOnlyOfficialDoc = responseFromPega.attachments.filter(
      (file) => file.category === appConstants.ATTACHMENT_TYPE_OFFICIAL_DOC,
    );

    filteredArrayOnlyOfficialDoc.forEach((element) => {
      caseAuditForEachFile(element);
    });

    const fileInfoArray = filteredArrayOnlyOfficialDoc.map((file) => {
      return {
        name: file.name, //changed this from fileName to name
        attachmentLinkID: file.ID,
      };
    });

    setUploadedFileInPega((prevState: any) => {
      return {
        ...prevState,
        selectedFile: [...fileInfoArray],
      };
    });

    props.setUploadedFilesCount(fileInfoArray.length);
  };
  const getALLSavedFiles = () => {
    setShowSpinnerApiIsInProgress(true);

    if (!props.caseId) {
      return;
    }

    let URL = process.env.REACT_APP_GET_ATTACHMENT_ALL + props.caseId + '/attachments';
    URL = getURL(URL);
    axios
      .get(URL, {
        withCredentials: true,
      })
      .then((res) => {
        setShowSpinnerApiIsInProgress(false);
        if (!!res.data.attachments) {
          getUpdatedFilesFromPega(res.data);
        } else {
          setUploadedFileInPega((prevState) => {
            return {
              ...prevState,
              selectedFile: [],
            };
          });
        }

        setUploadedFile((prevState) => {
          return {
            ...prevState,
            selectedFile: [],
          };
        });
      })
      .catch((err) => {
        setShowSpinnerApiIsInProgress(false);
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);

        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };
  const containsSpecialChars = (fileName) => {
    const specialChars = appConstants.ATTACHMENTS_SPECIAL_CHARACTERS_STRING;

    const result = specialChars.split('').some((specialChar) => {
      if (fileName.includes(specialChar)) {
        return true;
      }

      return false;
    });

    return result;
  };
  const resetErrorArray = () => {
    setfileUploadValiditywithInfo((prevState) => {
      return {
        isInValid: false,
        fileInfo: [],
      };
    });
  };
  const checkFileValidity = async (files) => {
    const validExtsAllowed = EFTConstants.PIR_ALLOWED_FILE_EXT_BUSINESS_USER;
    resetErrorArray();
    let count = 0;
    files.map((file) => {
      const FileNameWithOutExtension = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
      const fileExtFromFile = file.name.substring(file.name.lastIndexOf('.') + 1) || file.name;

      count = count + 1;

      if (
        count === files.length &&
        (totalFileSize + file.size).toFixed(2) > EFTConstants.PIR_FILE_SIZE_MAX_LIMIT * (1024 * 1024)
      ) {
        const fileDataExtremeSize = {
          fileName: file.name,
          errorMessage: EFTConstants.PIR_FILE_SIZE_EXCEED_MSG,
          reason: EFTConstants.PIR_FILE_ERROR_TYPE_SIZE,
        };
        setfileUploadValiditywithInfo((prevState: any) => {
          return {
            ...prevState,
            isInValid: true,
            fileInfo: [{ ...fileDataExtremeSize }],
          };
        });
      } else if (
        count !== files.length &&
        Number(totalFileSize.toFixed(2)) > EFTConstants.PIR_TOTAL_FILE_SIZE_MAX_LIMIT * (1024 * 1024)
      ) {
        const fileDataExtremeSize = {
          fileName: file.name,
          errorMessage: EFTConstants.PIR_FILE_SIZE_EXCEED_MSG,
          reason: EFTConstants.PIR_FILE_ERROR_TYPE_SIZE,
        };
        setfileUploadValiditywithInfo((prevState: any) => {
          return {
            ...prevState,
            isInValid: true,
            fileInfo: [{ ...fileDataExtremeSize }],
          };
        });
      } else if (validExtsAllowed.indexOf(fileExtFromFile) < 0) {
        const fileDataInvalidFormat = {
          fileName: file.name,
          errorMessage: file.name + appConstants.SR_FILE_SIZE_INVALID_MSG,
          reason: appConstants.SR_FILE_ERROR_TYPE_FORMAT,
        };

        setfileUploadValiditywithInfo((prevState: any) => {
          return {
            ...prevState,
            isInValid: true,
            fileInfo: [...fileUploadValiditywithInfo.fileInfo, fileDataInvalidFormat],
          };
        });
      } else if (FileNameWithOutExtension.length > 48) {
        const fileDataInvalidFormat = {
          fileName: file.name,
          errorMessage: appConstants.SR_THE_ATTACHMENTS + file.name + appConstants.SR_FILE_NAME_INVALID_MSG,
          reason: appConstants.SR_FILE_ERROR_NAME_FORMAT,
        };

        setfileUploadValiditywithInfo((prevState: any) => {
          return {
            ...prevState,
            isInValid: true,
            fileInfo: [...fileUploadValiditywithInfo.fileInfo, fileDataInvalidFormat],
          };
        });
      } else if (containsSpecialChars(FileNameWithOutExtension)) {
        const fileDataInvalidFormat = {
          fileName: file.name,
          errorMessage:
            appConstants.SR_THE_ATTACHMENTS + file.name + appConstants.SR_FILE_NAME_SPECIAL_CHARS_INVALID_NAME_MSG,
          reason: appConstants.SR_FILE_ERROR_NAME_SPECIAL_CHARS_FORMAT,
        };
        setfileUploadValiditywithInfo((prevState: any) => {
          return {
            ...prevState,
            isInValid: true,
            fileInfo: [...fileUploadValiditywithInfo.fileInfo, fileDataInvalidFormat],
          };
        });
      } else {
        const validFileData = {
          fileName: file.name,
          errorMessage: '',
          reason: '',
        };

        setfileUploadValiditywithInfo((prevState: any) => {
          return {
            ...prevState,
            isInValid: false,
            fileInfo: [...fileUploadValiditywithInfo.fileInfo, validFileData],
          };
        });

        setUploadedFile((prevState: any) => {
          const newArray = [...prevState.selectedFile, file];
          return {
            ...prevState,
            selectedFile: newArray,
          };
        });

        return true;
      }
    });
    
    return false;
  };
  const deleteAttachment = async (file: any) => {
    if (await confirm('Would you like to delete this file?', { okText: 'Delete' })) {
      setAllFiles(allFiles.filter((item: any) => item.name === file.name));
      const fileFoundinSavedArray = uploadedFileInPega.selectedFile.filter((obj: any) => obj.name === file.name);
      if (fileFoundinSavedArray.length > 0) {
        // api call
        let URL = process.env.REACT_APP_DELETE_FILE + file.attachmentLinkID;
        URL = getURL(URL);
        axios
          .delete(URL, {
            withCredentials: true,
          })
          .then((res) => {
            getALLSavedFiles();
            let el = document.getElementById('file-upload') as HTMLInputElement;
            el.value = '';
          })
          .catch((err) => {
            let error = { ...err };
            error.response = error.response || { response: { data: [] } };
            setAPIError(error);

            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
            } else if (error.response.status === 500) {
              setServiceDownModal(true);
            } else {
              setErrorModal(true);
            }
          });
      } else {
        setUploadedFile((prevState) => {
          return {
            ...prevState,
            selectedFile: uploadedFile.selectedFile.filter((stateFile: any) => stateFile.name !== file.name),
          };
        });
        let el = document.getElementById('file-upload') as HTMLInputElement;
        el.value = '';
      }
    }
  };
  const caseAuditForEachFile = (file) => {
    PIRCaseAudit(props.caseId, file.ID, 'PIR05')
      .then((_) => {})
      .catch((error) => {
        dispatch(setShowError({ error: true, code: error.response.status }));
      });
  };
  const apiCallsForUpload = (data, caseId) => {
    setShowSpinnerApiIsInProgress(true);
    const URL_API_FILE_UPLOAD = process.env.REACT_APP_API_FILE_UPLOAD;
    const final_URL_API_FILE_UPLOAD = getURL(URL_API_FILE_UPLOAD);
    axios
      .post(final_URL_API_FILE_UPLOAD, data, {
        withCredentials: true,
      })
      .then((res) => {
        const attachmentData = uploadedFile.selectedFile.map((item: any) => {
          const obj = {
            type: appConstants.SR_ATTACHMENT_TYPE,

            category: attachmentCatogryInForm.selectedCategoryID,

            name: item.name,

            ID: res.data.ID,
          };

          return obj;
        });
        const data = {
          attachments: attachmentData,
        };
        const URL_FILE_UPLOAD_DOC =
          process.env.REACT_APP_API_FILE_UPLOAD_DOC_PART1 + caseId + process.env.REACT_APP_API_FILE_UPLOAD_DOC_PART2;
        const final_URL_FILE_UPLOAD_DOC = getURL(URL_FILE_UPLOAD_DOC);
        axios
          .post(
            final_URL_FILE_UPLOAD_DOC,
            data,

            {
              withCredentials: true,
            },
          )
          .then((res) => {
            // Add the get call to get the updated list of uploaded file with new Name start
            getALLSavedFiles();
            // Ends here
          })
          .catch((err) => {
            setShowSpinnerApiIsInProgress(false);
            let error = { ...err };

            error.response = error.response || { response: { data: [] } };
            setAPIError(error);

            if (error.response.status === 400 || error.response.status === 500) {
              setError(error.response.data);
              setShowError(true);
            }
            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
            } else if (error.response.status === 500) {
              setServiceDownModal(true);
            } else {
              setErrorModal(true);
            }
          });
      })
      .catch((err) => {
        setShowSpinnerApiIsInProgress(false);
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);

        if (error.response.status === 400 || error.response.status === 500) {
          setError(error.response.data);
          setShowError(true);
        }
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };
  const checkFileSizeValidityBeforeUpload = (bodyFormData) => {
    let count = 0;
    uploadedFile.selectedFile.forEach((file: any) => {
      count = count + 1;

      if (count === uploadedFile.selectedFile.length) {
        if (Number(totalFileSize.toFixed(2)) > EFTConstants.PIR_TOTAL_FILE_SIZE_MAX_LIMIT * (1024 * 1024)) {
          const fileDataExtremeSize = {
            fileName: file.name,
            errorMessage: EFTConstants.PIR_FILE_SIZE_EXCEED_MSG,
            reason: EFTConstants.PIR_FILE_ERROR_TYPE_SIZE,
          };
          setfileUploadValiditywithInfo((prevState: any) => {
            return {
              ...prevState,
              isInValid: true,
              fileInfo: [{ ...fileDataExtremeSize }],
            };
          });
        } else {
          apiCallsForUpload(bodyFormData, props.caseId);
        }
      }
    });
  };
  const onFileUpload = async (event) => {
    // Create an object of formData
    if (await confirm(<DocumentUploadSecurityNotice />, { okText: 'Add', modalProps: { size: 'large' } })) {
      const bodyFormData = new FormData();

      for (let item = 0; item < uploadedFile.selectedFile.length; item++) {
        bodyFormData.append(
          appConstants.SR_ATTACHMENT_FORM_DATA_KEY,
          uploadedFile.selectedFile[item],

          uploadedFile.selectedFile[item].name,
        );
      }
      checkFileSizeValidityBeforeUpload(bodyFormData);
    }
  };
  const onFileChange = async (event) => {
    const files: any = Array.from(event.target.files);

    if (await checkFileValidity(files)) {
      if (Number(totalFileSize + files[0].size) < EFTConstants.PIR_TOTAL_FILE_SIZE_MAX_LIMIT * (1024 * 1024)) {
        setAllFiles([...allFiles, files]);
      }  
    }
  };
  return (
    <>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}
      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}
      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
        />
      )}
      <form onSubmit={(event) => event.preventDefault()} encType="multipart/form-data">
        {/* HEADER */}
        <Heading fontWeight="h3" fontSize="sm">
          Upload Documents to the application
        </Heading>
        {/* Intro text */}
        <Text fontWeight="light">
          Once the requested documents are ready for the customer, you can upload them by selecting the ‘Upload’ button
          below.
        </Text>
        <Flex flexDirection="column">
          {/* Add attachments button, opens the file upload input using a React ref */}
          <SCForm.FilePicker onClick={onFileChange} ctaText="Add attachment" multiple />
          {/* Upload button. Only appears after files have been selected for upload */}
          {uploadedFile.selectedFile.length > 0 && (
            <Button
              variant="primary"
              className="float-md-end mb-2"
              onClick={onFileUpload}
              disabled={uploadedFile.selectedFile.length === 0}
              w={{ lg: '20%', base: '100%' }}
            >
              Upload
            </Button>
          )}
        </Flex>
        {uploadedFile.selectedFile.length > 0 && (
          <SendDocFilesUploaded
            header="List of files to be uploaded:"
            files={uploadedFile.selectedFile}
            ctaCallback={(item) => deleteAttachment(item)}
            ctaText="Delete"
          />
        )}
        {uploadedFileInPega.selectedFile.length > 0 && (
          <SendDocFilesUploaded
            files={uploadedFileInPega.selectedFile}
            header="Uploaded files:"
            ctaCallback={(item) => deleteAttachment(item)}
            ctaText="Delete"
          />
        )}{' '}
        {fileUploadValiditywithInfo.isInValid &&
          fileUploadValiditywithInfo.fileInfo.map((item: any, index) => {
            return (
              <Text key={index} color="redalert" fontSize="xxs">
                {item.errorMessage ?? ''}
              </Text>
            );
          })}
      </form>
    </>
  );
};

export default SendDocUpload;
