// created this file to have all EFT constant at one place
export const EFTConstants = {
  PIR_ROYAL_SERVICE_TYPE: 'RoyalCommission',
  PIR_TYPE_OF_RECORD_PERSONNEL_REFERENCE: 'PersonalInfoRequest.IsPersonnelInformation',
  YES_SELECTED: 'Yes',
  NO_SELECTED: 'No',
  PIR_MEDICAL_RECORDS_FIELDID: 'IsMedicalRecords',
  PIR_IS_EOLOGY_FIELDID: 'IsEulogy',
  PIR_SERVICE_TYPE_FIELDID: 'ServiceType',
  PIR_ALLOWED_FILE_EXT_END_USER: '.pdf, .jpg, .jpeg',
  PIR_ALLOWED_FILE_EXT_BUSINESS_USER: ' pdf, jpg, jpeg, png, doc, docx, txt',
  PIR_FILE_SIZE_MAX_LIMIT_IN_BYTES: 1048576, // 1mb= 1048576 bytes
  PIR_FILE_SIZE_MAX_LIMIT_IN_BYTES_PROOFOFIDENTITY: 1048576 * 2, // 2mb= 1048576 *2 bytes
  PIR_IDENTITY_TWO_FILES_MSG: 'You can only upload two type of document for proof of identity.',
  PIR_FILE_ERROR_TYPE_COUNT: 'Count',
  MEM_IS_DECEASED: 'IsDeceased',
  PIR_CATOGORY_FAMILY_MEMBER_IDENDITY: 'FamilyMemberIdentification',
  PIR_CATEGORY_COMMON_IDENTITY: 'ProofOfIdentification',
  PIR_CATEGORY_COMMON_SIGNED_CONSENT: 'SignedConsent',
  PIR_CATEGORY_COMMON_PROOF_OF_DEATH: 'ProofOfDeath',
  PIR_CATEGORY_COMMON_SIGN_THIS_FORM: 'Declaration',
  PIR_CATEGORY_COMMON_PROOF_OF_FAMILY_MEMBER: 'ProofOfFamilyExMember',
  PIR_CATEGORY_COMMON_FAMILY_MEMBER_IDENTIFICATION: 'FamilyMemberIdentification',
  PIR_CATEGORY_COMMON_FAMILY_MEMEBER_SIGNED_CONSENT: 'FamilyMemberSignedConsent',
  PIR_FILE_UPLOAD_ID: 'file-upload',
  PIR_FILE_SIZE_EXCEED_MSG: 'File size can not be more than 25MB.',
  PIR_FILE_ERROR_TYPE_SIZE: 'Size',
  PIR_FILE_SIZE_MAX_LIMIT: 25,
  PIR_TOTAL_FILE_SIZE_MAX_LIMIT: 750,
  PIR_END_USER_FILE_SIZE_ERROR: 'Each file may not be bigger than 1mb',
};

export const root = '/personal-information-request';
export const newRequest = `${root}/new`;

//request details constants
export const REQUEST_DETAILS_PEGA_VIEWID = 'RequestInformation';

export const APPLICANT_TYPE_PEGA_VIEWID = 'PersonalInformation';
export const PIR_ALLOWED_FILE_EXT_SUPPORTING_DOC_STRING = ' xlsx, xls, png, jpeg or pdf';
