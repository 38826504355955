import { Heading, UnorderedList, ListItem, Text } from '@chakra-ui/react';
import React from 'react';

const DocumentUploadSecurityNotice = () => {
  return (
    <>
      <Heading as="h2" fontSize="lg" fontWeight="medium">
        Document Upload Notice
      </Heading>
      <Heading as="h3" fontSize="sm" fontWeight="medium">
        The uploaded documents, as requested through the Personal Information request process, will leave the Defence
        network.
      </Heading>
      <Text fontWeight="light">Before you finalise the document upload, please check that:</Text>
      <UnorderedList fontWeight="light">
        <ListItem>You have reviewed the request details.</ListItem>
        <ListItem>The ADF member for who the request is for matches the documents you are uploading.</ListItem>
        <ListItem>The documents have undergone redaction, where applicable, by the relevant business area.</ListItem>
        <ListItem>The documents do not contain any content with a classification above Official: Sensitive.</ListItem>
      </UnorderedList>
      <Text fontWeight="light">
        If you are unsure of the releasability of certain information, please review with the information owner before
        proceeding with the document upload.
      </Text>
    </>
  );
};

export default DocumentUploadSecurityNotice;
