import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import data from './__mocks__/default.json';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

//import { useLocation } from "react-router-dom";

const LearnMore = () => {
  return (
    <>
      <Container fluid className="py-lg-2 text-white bg-defenceorange servicebackgroundImg">
        <Container className="p-4 p-lg-6 landingPageWidth">
          {data.breadcrumbs === true && <Breadcrumbs />}
          <h2 className="desktop desktopServiceHeading">{data.mainHeading}</h2>
          <h2 className="mobile mobileServiceHeading">{data.mainHeading}</h2>
          <p>{data.subHeading}</p>
        </Container>
      </Container>

      <Container fluid className="p-6 landingPageWidth">
        <h2 className="pb-2">Digital Service Delivery Program</h2>
        <h3>Digital transformation</h3>
        <p>
          As part of the enduring Defence Transformation System, the vision for ServiceConnect is to transform and
          modernise access to services across the department.
        </p>
        <p>
          ServiceConnect and its key services, including My Service Request, My Forms and My Base, are being delivered
          under the <b>Digital Service Delivery Program.</b>
        </p>

        <p>
          The <b>Digital Service Delivery Program</b> conducts extensive research across Defence to understand where
          existing administrative and transactional services can be modernised and transformed into new, digital
          experiences.
        </p>
        <p>
          By digitising Defence services, the program aims to:
          <ul>
            <li>Modernise traditional service delivery across Defence;</li>
            <li>Improve the customer experience;</li>
            <li>Reduce the need for administrative processing; and</li>
            <li>Improve data quality across Defence.</li>
          </ul>
          <br />
          This program operates under the Directorate of Digital Service Delivery (DDSD) within Security and Estate
          Group (SEG), and it is supported by representatives across the ADF, APS and Defence industry partners.
          <br />
          <br />
          The <b>Digital Service Delivery Program</b> is an iterative program, meaning that more services and features
          will be researched and delivered over time. To learn more, visit the Digital Service Delivery Program page on
          DRNet.
        </p>
      </Container>
    </>
  );
};

export default LearnMore;
