import React from 'react';
import '../../../sass/custom.scss';
import { Card, Container, Row, Col, Button, Image } from 'react-bootstrap';

import panelPic_1 from '../../../images/serviceRequest.png';
import panelPic_2 from '../../../images/forms.png';
import panelPic_3 from '../../../images/mybase.png';

const Panel = (props) => {
  return (
    <Card data-testid="item" className="g-col-12 g-col-lg-4">
      <Image data-testid="sr_image" src={props.image} alt={props.alt} />
      <Card.Body className="p-4 px-lg-4 py-lg-3">
        <Card.Title className="pt-lg-4">
          <h3>{props.title}</h3>
        </Card.Title>
        <Card.Text className="fw-lighter text-black">{props.description}</Card.Text>
      </Card.Body>
      <Card.Footer className="p-4 px-lg-4 py-lg-3 border-0 bg-white">
        <Button
          data-testid="top_left_card"
          className="mobileButton w-100 text-wrap d-flex h-100"
          variant="standard"
          href={props.ctaUrl}
        >
          {props.ctaText}
        </Button>
      </Card.Footer>
    </Card>
  );
};

const ThreeCtaPanels = (props) => {
  return (
    <Container className="desktop bg-defencelightgrey px-lg-4">
      <Container className="landingPageWidth p-1 px-3 py-lg-4 px-lg-5 me-lg-5">
        <h3 className="ps-3 ps-lg-0">{props.heading}</h3>
        <Row className="grid gap-lg-5 gap-3 my-3">
          <Panel
            title={props.title_TopLeft}
            description={props.description_TopLeft}
            image={panelPic_1}
            alt={props.alt_TopLeft}
            ctaText={props.ctaText_TopLeft}
            ctaUrl={props.ctaUrl_TopLeft}
            isExternal={props.isExternal_TopLeft}
          />
          <Panel
            title={props.title_TopRight}
            description={props.description_TopRight}
            image={panelPic_2}
            alt={props.alt_TopRight}
            ctaText={props.ctaText_TopRight}
            ctaUrl={props.ctaUrl_TopRight}
            isExternal={props.isExternal_TopRight}
          />
          <Panel
            title={props.title_BottomLeft}
            description={props.description_BottomLeft}
            image={panelPic_3}
            alt={props.alt_BottomLeft}
            ctaText={props.ctaText_BottomLeft}
            ctaUrl={props.ctaUrl_BottomLeft}
            isExternal={props.isExternal_BottomLeft}
          />
        </Row>
      </Container>
    </Container>
  );
};

export default ThreeCtaPanels;
