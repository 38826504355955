import { findByProp } from '../../../helper/findByProp';

export const formDataTransform = (res: any) => {
  let fieldList: any[] = [];
  let getUpdatedFields;

  // Service Type
  // Type of records
  // Type of records addtional fields
  // Applicant Type

  // Parse the returned fields data from PEGA and create field list for each viewID

  if (findByProp(res.data, 'viewID', 'RequestInformation')) {
    getUpdatedFields = findByProp(res.data, 'viewID', 'RequestInformation');
    fieldList = [...fieldList, ...getUpdatedFields.groups[0].layout.groups];
  }

  if (findByProp(res.data, 'viewID', 'PIRDeliveryDetails')) {
    getUpdatedFields = findByProp(res.data, 'viewID', 'PIRDeliveryDetails');
    fieldList.push({
      field: getUpdatedFields,
    });
  }

  if (findByProp(res.data, 'viewID', 'PIRDeclaration')) {
    getUpdatedFields = findByProp(res.data, 'viewID', 'PIRDeclaration');
    fieldList.push({
      field: getUpdatedFields,
    });
  }

  if (findByProp(res.data, 'viewID', 'PersonalInformation')) {
    getUpdatedFields = findByProp(res.data, 'viewID', 'PersonalInformation');
    fieldList = [...fieldList, ...getUpdatedFields.groups[0].layout.groups];
  }

  if (findByProp(res.data, 'viewID', 'PIRContactDetails_Family')) {
    getUpdatedFields = findByProp(res.data, 'viewID', 'PIRContactDetails_Family');
    fieldList = [
      ...fieldList,
      {
        ApplicantTypeFamilyMemData: [
          { member: getUpdatedFields.groups[0].layout.groups[0].layout.groups },
          { family: getUpdatedFields.groups[0].layout.groups[1].layout.groups },
        ],
      },
    ];
  }

  if (findByProp(res.data, 'viewID', 'PIRContactDetails_Advocate')) {
    getUpdatedFields = findByProp(res.data, 'viewID', 'PIRContactDetails_Advocate');
    fieldList = [
      ...fieldList,
      {
        ApplicantTypeAdvocateMemData: [
          { applicant: getUpdatedFields.groups[0].layout.groups[0].layout.groups },
          { deceased: getUpdatedFields.groups[0].layout.groups[1].layout.groups },
          { advocateDetails: getUpdatedFields.groups[0].layout.groups[2].layout.groups },
        ],
      },
    ];
  }

  if (findByProp(res.data, 'viewID', 'PIRContactDetails_Self')) {
    getUpdatedFields = findByProp(res.data, 'viewID', 'PIRContactDetails_Self');
    fieldList = [
      ...fieldList,
      {
        ApplicantTypeSelfMemData: [{ self: getUpdatedFields.groups[0].layout.groups }],
      },
    ];
  }

  return fieldList;
};
