import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { Spinner } from '../../components/ServiceUI/Core/Spinner/Spinner';
import { appConstants } from '../../helper/client/constant';
import findAllItems from '../../helper/findAllItems';
import { getURL } from '../../helper/getAPIUrl';
import { resetEFTFormState, setFormFields } from '../../reducers/eftRequestDetailFormReducer';
import { setShowError } from '../../reducers/errorHandling';
import { ActionButtons } from './ActionButtons';
import content from './Content/CreateRequest.json';
import { root } from './EFTConstant';
import { formDataTransform } from './Helper/formDataTransform';
import { RequestDetailEft } from './RequestDetailEft';

//Error Handling Modals - to remove and add new method
import GenericErrorModal from '../../components/GenericErrorModal/GenericErrorModal';
import GenericErrorModalProps from '../../components/GenericErrorModal/__mocks__/default.json';
import AuthenticationModal from '../../components/LandingPage/AuthenticationModal/AuthenticationModal';
import AuthenticationModalProps from '../../components/LandingPage/AuthenticationModal/__mocks__/default.json';
import DeleteModal from '../../components/MyServiceRequest/CreateRequest/DeleteModal';
import UploadConfirmationSRModal from '../../components/MyServiceRequest/CreateRequest/UploadConfirmationSRModal';
import SystemOutageModal from '../../components/SystemOutageModal/SystemOutageModal';
import SystemOutageModalProps from '../../components/SystemOutageModal/__mocks__/default.json';
import { deletePIR } from '../../helper/deletePIR';
import { savePIR } from '../../helper/savePIR';
import { flattenData, getPageInstructionObj, prepareFormFields } from './Helper/eftHelperFunc';

export const NewRequestPage: React.FC = () => {
  //Define variables
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reqDetailReduxStore = useSelector((state: any) => state.EFTRequestMemberForm);

  //States
  const requestCameFromCaseID = window.location.search;
  const urlParams = new URLSearchParams(requestCameFromCaseID);
  const cameFrom = urlParams.get('cameFrom');
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { id } = useParams();
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessage, setsuccessMessage] = useState('');
  const [showSpinnerApiIsInProgress, setShowSpinnerApiIsInProgress] = useState(false);
  const [loading, setLoading] = useState({
    isLoading: true,
  });
  const [nextAssignmentIdState, setNextAssignmentId] = useState({
    nextAssignmentId: '',
  });
  const [allCaseData, setallCaseData] = useState({
    caseData: {},
  });
  const [actionIdState, setActionId] = useState({
    actionId: '',
  });
  const [caseId, setCaseId] = useState({
    caseId: cameFrom === 'editReq' && !!id ? id : '',
  });

  //API CALLS

  //DELETE PEGA API
  const deletePIRHandler = async () => {
    setShowSpinnerApiIsInProgress(true);
    await deletePIR(caseId.caseId, actionIdState.actionId)
      .then((_) => {
        setShowSpinnerApiIsInProgress(false);
        dispatch(resetEFTFormState());
        // After api success go to PIR home page
        navigate('/personal-information-request');
      })
      .catch((error) => {
        setShowSpinnerApiIsInProgress(false);
        dispatch(setShowError({ error: true, code: error.response.status }));
      });
  };

  //SAVE PEGA API
  const savePIRHandlerFunc = async () => {
    const allUpdatedValue = prepareFormFields(reqDetailReduxStore.formFields);
    const finalInput = getPageInstructionObj(allUpdatedValue);

    finalInput.then(async (finalResult) => {
      //Define data for put request
      const data = {
        content: {},
        pageInstructions: finalResult,
      };
      await savePIR(caseId.caseId, data)
        .then((_) => {
          setShowSpinnerApiIsInProgress(false);
          setshowSuccess(true);
          setsuccessMessage(appConstants.PIR_SAVE_SUCCESS_MESSAGE);
          dispatch(resetEFTFormState());
          navigate('/personal-information-request');
        })
        .catch((error) => {
          setShowSpinnerApiIsInProgress(false);
          dispatch(setShowError({ error: true, code: error.response.status }));
        });
    });
  };
  const handleUploadPopUp = () => {
    if (reqDetailReduxStore.uploadedFile) {
      setShowUploadModal(true);
    } else {
      reviewRequestandlerFunc();
    }
  };
  const reviewRequestandlerFunc = () => {
    const allUpdatedValue = prepareFormFields(reqDetailReduxStore.formFields);
    const finalInput = getPageInstructionObj(allUpdatedValue);

    finalInput.then((pageInstructionData) => {
      const data = {
        content: {},
        pageInstructions: pageInstructionData,
      };

      let URL =
        process.env.REACT_APP_UPDATE_SERVICE_REQUEST_DETAILS +
        nextAssignmentIdState.nextAssignmentId +
        (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV
          ? '?actionID=' + actionIdState.actionId
          : '/' + actionIdState.actionId);
      URL = getURL(URL);
      axios
        .post(URL, data, {
          withCredentials: true,
        })
        .then((res) => {
          setShowSpinnerApiIsInProgress(false);
          navigate(
            '/personal-information-request/review/' + nextAssignmentIdState.nextAssignmentId,

            {
              state: [
                {
                  nextAssignmentIdState,

                  caseId,
                },
              ],
            },
          );
        })
        .catch((error) => {
          setShowSpinnerApiIsInProgress(false);
          dispatch(setShowError({ error: true, code: error.response.status }));
        });
      window.scrollTo(0, 0); //scroll to top of review page once everything is done
    });
  };

  const createNewPIRReq = () => {
    const data = {
      caseTypeID: 'DoD-SConn-Work-PersonalInfoRequest',
      content: {},
    };
    setLoading((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });
    // INFO: Create case id call
    const URL_CREATE_NEW_PIR_REQUEST_API = getURL(process.env.REACT_APP_CREATE_NEW_REQUEST_API);
    axios
      .post(URL_CREATE_NEW_PIR_REQUEST_API, data, {
        withCredentials: true,
      })
      .then((res) => {
        // NOTE: call to get the next action which will help us to know hwat is the next action
        const URL_NEXT_ACTION_API = process.env.REACT_APP_NEXT_ACTION_API + res.data.nextAssignmentID;
        const FINAL_URL_NEXT_ACTION_API = getURL(URL_NEXT_ACTION_API);
        axios
          .get(FINAL_URL_NEXT_ACTION_API, {
            withCredentials: true,
          })
          .then((res) => {
            setCaseId((prevState) => {
              return {
                ...prevState,
                caseId: res.data.caseID,
              };
            });
            // updating the app states this we will use in update call
            setNextAssignmentId((prevState) => {
              return {
                ...prevState,
                nextAssignmentId: res.data.ID,
              };
            });
            setActionId((prevState) => {
              return {
                ...prevState,
                actionId: res.data.actions[0].ID,
              };
            });
            // This call is to prepopulate the req data
            const URL_CREATE_ACTION_API =
              process.env.REACT_APP_CREATE_ACTION_API_ALL + res.data.ID + '/actions/' + res.data.actions[0].ID;
            const FINAL_URL_CREATE_ACTION_API = getURL(URL_CREATE_ACTION_API);
            axios
              .get(FINAL_URL_CREATE_ACTION_API, {
                withCredentials: true,
              })
              .then((res) => {
                setallCaseData((prevState) => {
                  return {
                    ...prevState,
                    caseData: res.data,
                  };
                });
                let fieldList: any[] = [];

                fieldList = formDataTransform(res);

                dispatch(
                  setFormFields({
                    fieldList,
                  }),
                );

                setLoading((prevState) => {
                  return {
                    ...prevState,
                    isLoading: false,
                  };
                });
              })
              .catch((error) => {
                dispatch(setShowError({ error: true, code: error.response.status }));
              });
          })
          .catch((error) => {
            dispatch(setShowError({ error: true, code: error.response.status }));
          });
      })
      .catch((error) => {
        dispatch(setShowError({ error: true, code: error.response.status }));
      });
  };
  const ViewReqInNewState = () => {
    setLoading((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });
    const URL_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL =
      process.env.REACT_APP_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL + caseId.caseId;
    const final_URL_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL = getURL(URL_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL);
    axios
      .get(final_URL_VIEW_REQUEST_GET_NEXT_ASSIGNMENT_ALL, {
        withCredentials: true,
      })
      .then((res) => {
        // updating the app states this we will use in update call

        setNextAssignmentId((prevState) => {
          return {
            ...prevState,
            nextAssignmentId: res.data.assignments[0].ID,
          };
        });

        setActionId((prevState) => {
          return {
            ...prevState,
            actionId: res.data.assignments[0].actions[0].ID,
          };
        });
        // This call is to prepopulate the req data

        const URL_CREATE_ACTION_API =
          process.env.REACT_APP_CREATE_ACTION_API_ALL +
          res.data.assignments[0].ID +
          '/actions/' +
          res.data.assignments[0].actions[0].ID;
        const FINAL_URL_CREATE_ACTION_API = getURL(URL_CREATE_ACTION_API);
        axios
          .get(FINAL_URL_CREATE_ACTION_API, {
            withCredentials: true,
          })
          .then((res) => {
            setallCaseData((prevState) => {
              return {
                ...prevState,
                caseData: res.data,
              };
            });

            let fieldList: any[] = [];

            fieldList = formDataTransform(res);

            dispatch(
              setFormFields({
                fieldList,
              }),
            );

            setLoading((prevState) => {
              return {
                ...prevState,
                isLoading: false,
              };
            });
          })
          .catch((error) => {
            dispatch(setShowError({ error: true, code: error.response.status }));
          });
      })
      .catch((error) => {
        dispatch(setShowError({ error: true, code: error.response.status }));
      });
  };
  const isFormInvalid = () => {
    const reqDetailsFields = findAllItems(reqDetailReduxStore.formFields, 'fieldID');

    const isInvalidReqDetails = reqDetailsFields?.some(
      (item) => item.required === true && (item.value === '' || item.value === false),
    );
    // TODO: This is testing bit. I will remove it later
    // const inValidFieldsData = reqDetailsFields?.filter(
    //   (item) => item.required === true && (item.value === '' || item.value === false),
    // );
    // console.log('ths is for testing-->', inValidFieldsData);
    const isInvalidTypeOdRecords = reqDetailReduxStore.formFields.TypeofRecords[0]?.every(
      (item) => item.value === 'No',
    );
    let serviceArea: any[] = [];
    if (reqDetailReduxStore.formFields?.ApplicantType.value === 'Self') {
      if (!!reqDetailReduxStore.formFields?.ApplicantTypeSelfMemData) {
        flattenData(reqDetailReduxStore.formFields?.ApplicantTypeSelfMemData).forEach((item: any) => {
          if (Array.isArray(item)) {
            item.forEach((item) => {
              if (!!item.value) {
                serviceArea.push(item);
              }
            });
          }
        });
      }
    } else if (reqDetailReduxStore.formFields?.ApplicantType.value === 'Family') {
      if (!!reqDetailReduxStore.formFields?.ApplicantTypeFamilyMemData) {
        flattenData(reqDetailReduxStore.formFields?.ApplicantTypeFamilyMemData).forEach((item: any) => {
          if (Array.isArray(item)) {
            item.forEach((item) => {
              if (!!item.value) {
                serviceArea.push(item);
              }
            });
          }
        });
      }
    } else {
      if (!!reqDetailReduxStore.formFields?.ApplicantTypeAdvocateMemData) {
        flattenData(reqDetailReduxStore.formFields?.ApplicantTypeAdvocateMemData).forEach((item: any) => {
          if (Array.isArray(item)) {
            item.forEach((item) => {
              if (!!item.value) {
                serviceArea.push(item);
              }
            });
          }
        });
      }
    }

    const isInvalidServiceArea = serviceArea.every((e) => e?.value === '' || e?.value === false);

    return isInvalidReqDetails || isInvalidTypeOdRecords || isInvalidServiceArea;
  };
  useEffect(() => {
    document.title = 'New personal information request';
    if (cameFrom === 'editReq') {
      ViewReqInNewState();
    } else {
      createNewPIRReq();
    }
  }, []);

  return (
    <>
      <Header
        {...content.header}
        breadcrumbItems={[
          {
            name: 'Personal information request',
            url: root,
          },
        ]}
      />
      <SubContainer>
        <>
          <DeleteModal
            show={showDeleteModal}
            closeAction={() => setShowDeleteModal(false)}
            deleteAction={() => deletePIRHandler()}
          />
          {errorModal && (
            <GenericErrorModal
              title={GenericErrorModalProps.title}
              ctaText={GenericErrorModalProps.ctaText}
              error={APIError}
            />
          )}
          {showUploadModal && (
            <UploadConfirmationSRModal
              handleUpdateForms={reviewRequestandlerFunc}
              show={showUploadModal}
              closeAction={() => setShowUploadModal(false)}
            />
          )}
          {serviceDownModal && (
            <SystemOutageModal
              title={SystemOutageModalProps.title}
              description={SystemOutageModalProps.description}
              ctaText={SystemOutageModalProps.ctaText}
            />
          )}

          {showModal && (
            <AuthenticationModal
              title={AuthenticationModalProps.title}
              description={AuthenticationModalProps.description}
              ctaText={AuthenticationModalProps.ctaText}
            />
          )}

          {loading.isLoading && <Spinner />}
          {!!nextAssignmentIdState.nextAssignmentId && !!actionIdState.actionId && !!caseId.caseId && (
            <RequestDetailEft
              caseData={allCaseData.caseData}
              nextAssignmentID={nextAssignmentIdState.nextAssignmentId}
              actionId={actionIdState.actionId}
              caseIdOrig={caseId.caseId}
            />
          )}

          <ActionButtons
            savePIRHandler={() => savePIRHandlerFunc()}
            setShowDeleteModal={(flag) => setShowDeleteModal(flag)}
            handleReviewRequest={() => handleUploadPopUp()}
            isInvalid={isFormInvalid()}
          />
        </>
      </SubContainer>
    </>
  );
};
