import { Box, Grid, GridItem } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconCard } from '../../components/ServiceUI/Core/Card/Card';
import Feedback from '../../components/ServiceUI/Core/Feedback/Feedback';
import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { StartForm } from '../../components/ServiceUI/Core/StartForm/StartForm';
import SubHeader from '../../components/ServiceUI/Core/SubHeader/SubHeader';
import { PIRDashBoard } from '../../components/ServiceUI/EFT/PIRDashboard/PIRDashboard';
import { PIR } from '../../components/ServiceUI/EFT/types';
import { getURL } from '../../helper/getAPIUrl';
import { getFormsURL } from '../../helper/getOtherAppURL';
import { request } from '../../helper/useAxios';
import allcontent from './Content/General.json';
import content from './Content/LandingPage.json';
import { newRequest } from './EFTConstant';

export const LandingPage: React.FC = () => {
  const [pirItems, setPIRItems] = useState<PIR[]>([]);
  const getPIRs = request('REACT_APP_GET_RECENT_PIRS', 'get');
  useEffect(() => {
    const getPIRItems = () => {
      getPIRs.then((data) => data && setPIRItems(data.data.pxResults));
    };
    getPIRItems();
  }, []);

  let navigate = useNavigate();
  const createPIRCaseFunc = () => {
    navigate(newRequest);
  };
  const FEEDBACK_API = process.env.REACT_APP_FEEDBACK;
  return (
    <Box bg="whispergrey">
      <Header {...content.header} />
      <StartForm {...content.startform} createPIRCase={() => createPIRCaseFunc()} />
      <SubHeader {...content.top_subheader} />
      <SubContainer>
        <PIRDashBoard {...content.pir_dashboard} pirs={pirItems} />
      </SubContainer>
      <SubHeader {...content.bottom_subheader} />
      <SubContainer>
        <Grid mb="3.5rem" gridTemplateColumns="repeat(2,1fr)" gap={{ lg: '4rem', base: '1rem' }}>
          {content.cards.map((i, idx) => (
            <GridItem colSpan={{ lg: 1, base: 2 }} key={idx}>
              <IconCard {...i} linkURL={i.isAEM ? getFormsURL() : i.linkURL} />
            </GridItem>
          ))}
        </Grid>
      </SubContainer>
      <Feedback
        {...allcontent.feedback}
        modalProps={{
          ...allcontent.feedback.modalProps,
          submitAction: (data) =>
            axios.post(getURL(FEEDBACK_API), data, {
              withCredentials: true,
            }),
        }}
      />
    </Box>
  );
};
