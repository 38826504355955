import React from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetmemDataforAdvocate,
  resetmemDataforFamily,
  resetmemDataforSelf,
  setmemberDetailForAdvocate,
  setmemberDetailForFamilyData,
  setmemberDetailForSelf,
} from '../../../reducers/eftRequestDetailFormReducer';
import { refreshEFTForm } from '../API/refreshEFTForm';
import FormCTA from './FormCTA';
import FormCheckbox from './FormCheckbox';
import FormDateInput from './FormDateInput';
// import FormDateInput from "./FormDateInput";
import FormMenu from './FormMenu';
import FormRadio from './FormRadio';
import FormTextAreaInput from './FormTextAreaInput';
import FormTextInput from './FormTextInput';

import FormInputFileUpload from '../../../components/ServiceUI/Core/FilesUpload/FormInputFileUpload';
import fieldPropMapping from './fieldPropsMapping';

// @ts-ignore
import { getPageInstructionObj, prepareFormFields } from '../Helper/eftHelperFunc';

import { FormLabel } from '@chakra-ui/react';
import { EFTConstants } from '../EFTConstant';
import FormMulti from './FormMulti';

const PIRDynamicFormRender = ({
  caseID,
  caseIDOriginal,
  isReview,
  actionID,
  attachmentCatogry,
  nextAssignmentId,
  formDataTorender,
  currentStateData,
  selectedTypeOfRecords,
  deleteAndUpdateDoc,
}) => {
  const dispatch = useDispatch();
  const reqDetailReduxStore = useSelector((state: any) => state.EFTRequestMemberForm);
  const componentMap = (field: any) => {
    switch (field.type) {
      case 'pxDropdown':
        return (
          // <FormSelect
          //   {...field}
          //   fieldPropMapping={fieldPropMapping}
          //   formData={formDataTorender}
          //   dynamicFieldAction={dynamicFieldAction}
          // />
          <FormMenu
            {...field}
            fieldPropMapping={fieldPropMapping}
            formData={formDataTorender}
            dynamicFieldAction={dynamicFieldAction}
          />
        );
      case 'pxTextInput':
        return (
          <FormTextInput
            {...field}
            fieldPropMapping={fieldPropMapping}
            formData={formDataTorender}
            key={field.fieldID}
          />
        );
      // case "pxInteger":
      //   return (
      //     <FormTextInput
      //       {...field}
      //       fieldPropMapping={fieldPropMapping}
      //       formData={formDataTorender}
      //     />
      //   );
      case 'pxTextArea':
        return <FormTextAreaInput {...field} fieldPropMapping={fieldPropMapping} formData={formDataTorender} />;
      case 'pxCheckbox':
        return (
          <FormCheckbox
            {...field}
            fieldPropMapping={fieldPropMapping}
            formData={formDataTorender}
            key={field.fieldID}
          />
        );
      case 'pxRadioButtons':
        return (
          <FormRadio
            formData={formDataTorender}
            caseID={caseID}
            nextAssignmentId={nextAssignmentId}
            {...field}
            fieldPropMapping={fieldPropMapping}
            dynamicFieldAction={dynamicFieldAction}
            key={field.fieldID}
          />
        );
      case 'pxAttachContent':
        return (
          <FormInputFileUpload
            {...field}
            isReview={isReview}
            attachmentCatogry={attachmentCatogry}
            caseIDOriginal={caseIDOriginal}
            fieldPropMapping={fieldPropMapping}
            formData={formDataTorender}
          />
        );
      case 'pxDateTime':
        return (
          <FormDateInput
            {...field}
            fieldPropMapping={fieldPropMapping}
            formData={formDataTorender}
            key={field.fieldID}
          />
        );
      case 'pxButton':
        return (
          <FormCTA {...field} fieldPropMapping={fieldPropMapping} formData={formDataTorender} key={field.fieldID} />
        );
      case 'caption':
        return <FormLabel>{field.captionFor}</FormLabel>;
      case 'pxDisplayText':
        return <Form.Label>{field.label}</Form.Label>;
      case 'multi':
        return (
          <FormMulti {...field} fieldPropMapping={fieldPropMapping} formData={formDataTorender} key={field.fieldID} />
        );
      case 'default':
        return (
          <FormTextInput
            {...field}
            fieldPropMapping={fieldPropMapping}
            formData={formDataTorender}
            key={field.fieldID}
          />
        );
    }

    return null;
  };
  const dynamicFieldAction = (currValue, fieldID, reference, required, visible) => {
    // below if condition is only applicable for is decased fieldID as we need to remove existing documents
    // for this selection
    if (fieldID === EFTConstants.MEM_IS_DECEASED) {
      deleteAndUpdateDoc();
    }

    const allUpdatedValue = prepareFormFields(reqDetailReduxStore.formFields);

    getPageInstructionObj(allUpdatedValue, currValue, fieldID, reference).then((finalInstruction) => {
      const finalViewId =
        currentStateData.ApplicantType.value === 'Self'
          ? 'PIRContactDetails_Self'
          : currentStateData.ApplicantType.value === 'Family'
          ? 'PIRContactDetails_Family'
          : 'PIRContactDetails_Advocate';

      const finalAction =
        currentStateData.ApplicantType.value === 'Self'
          ? setmemberDetailForSelf
          : currentStateData.ApplicantType.value === 'Family'
          ? setmemberDetailForFamilyData
          : setmemberDetailForAdvocate;
      if (currentStateData.ApplicantType.value === 'Self') {
        dispatch(resetmemDataforSelf());
      }
      if (currentStateData.ApplicantType.value === 'Family') {
        dispatch(resetmemDataforFamily());
      }
      if (currentStateData.ApplicantType.value === 'Advocate') {
        dispatch(resetmemDataforAdvocate());
      }

      refreshEFTForm(finalInstruction, nextAssignmentId, actionID, finalViewId).then((fieldList) => {
        dispatch(
          finalAction({
            fieldList,
          }),
        );
      });
    });
  };

  return (
    <>
      {Object.values(formDataTorender).map((field, idx) => {
        return Array.isArray(field) ? (
          field.map((fieldGroup) =>
            fieldGroup.length > 0 ? fieldGroup.map((fieldItem) => componentMap(fieldItem)) : componentMap(fieldGroup),
          )
        ) : (
          <React.Fragment key={idx}>{componentMap(field)}</React.Fragment>
        );
      })}
    </>
  );
};

export default PIRDynamicFormRender;
