import React, { useEffect } from 'react';
import CtaPopular from '../LandingPage/CtaPopular/CtaPopular';
import CtaPanels from '../LandingPage/CtaPanels/CtaPanels';
import RecentItems from '../LandingPage/RecentItems/RecentItems';
import RecentItemProps from '../LandingPage/RecentItems/__mocks__/default.json';
import Feedback from '../LandingPage/Feedback/Feedback';
import { Container } from 'react-bootstrap';
import FrontpageHeader from '../LandingPage/FrontpageHeader/FrontpageHeader';
import content from '../content/content/Home.json';
import ServiceConnectSpinner from '../commonComponents/Spinner/ServiceConnectSpinner';
import { useState } from 'react';
import { Grid, GridItem, Center } from '@chakra-ui/react';

const Content = (ProfileData) => {
  useEffect(() => {
    document.title = 'ServiceConnect';
  });
  const [showApiIsInProgress, setShowApiIsInProgress] = useState(false);
  const ShowSpinerHandler = (flag) => {
    setShowApiIsInProgress(flag);
  };
  return (
    <>
      <FrontpageHeader
        name={ProfileData.givenNames}
        homeBase={ProfileData.location.name}
        profileUrl={content.header.profileUrl}
        mainHeading={content.header.mainHeading}
        subHeading={content.header.subHeading}
        ctaText={content.header.ctaText}
        ctaUrl={content.header.ctaUrl}
      />
      <CtaPopular
        title={content.learnMore.title}
        description={content.learnMore.description}
        ctaText={content.learnMore.ctaText}
        ctaUrl={content.learnMore.ctaUrl}
      />
      <Center bg="whispergrey">
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
          gap={{ lg: '55px', base: '25px' }}
          maxW="1480px"
          w="100%"
          my="48px"
          px={{ lg: '48px', base: '25px' }}
        >
          <GridItem colSpan={{ md: '2', base: '1' }}>
            <CtaPanels heading={content.panels_heading} panels={content.panels} />
          </GridItem>
          <RecentItems
            onrecentListcameFromAPI={ShowSpinerHandler}
            mainHeading={RecentItemProps.mainHeading}
            recentItems={RecentItemProps.recentItems}
          />
        </Grid>
      </Center>
      {showApiIsInProgress && (
        <Container>
          <ServiceConnectSpinner></ServiceConnectSpinner>
        </Container>
      )}
      <Feedback {...content.feedback} />
    </>
  );
};

export default Content;
