const preparePageInstructions = (formData, pageInstructionsFromStore, actions) => {
  // const formFields = getDataFromReduxStore(formData);
  const formFields = Object.values(formData);
  // let fieldGroupArr = [];
  // const pageData = formFields.reduce((acc, item, key) => {
  //   if (!Array.isArray(item)) {
  //     acc[item.fieldID] = item.value;
  //   } else {
  //     fieldGroupArr = [...fieldGroupArr, ...item];
  //   }
  //   return acc;
  // }, {});

  let fieldGroupArr = formFields.filter((field) => Array.isArray(field));
  const pageGroupData = fieldGroupArr[0].reduce((acc, fieldArr, key) => {
    const contentItem = {};
    fieldArr.forEach((item) => {
      contentItem[item.fieldID] = item.value;
    });
    acc.push({
      instruction: 'UPDATE',
      target: '.ServiceRequest.RequestDetail.Items',
      listIndex: key + 1,
      content: {
        ...contentItem,
      },
    });
    return acc;
  }, []);

  let pageDataActions = [];
  if (actions.length > 0) {
    pageDataActions = actions.map((action) => {
      let data = {};
      switch (action.instruction) {
        case 'DELETE':
          data = {
            instruction: action.instruction,
            target: '.ServiceRequest.RequestDetail.Items',
            listIndex: action.listIndex,
          };

          break;
        case 'APPEND':
          data = {
            instruction: action.instruction,
            target: '.ServiceRequest.RequestDetail.Items',
            content: {},
          };
          break;
        default:
          break;
      }
      return data;
    });
  }

  return [...pageInstructionsFromStore, ...pageGroupData, ...pageDataActions];
};

export default preparePageInstructions;
