import axios from 'axios';
import { getURL } from '../../../helper/getAPIUrl';
import { formDataTransform } from '../Helper/formDataTransform';

export const refreshEFTForm = (fields, nextAssignmentID, actionId, viewID) => {
  const REACT_APP_SR_REFRESH_PUT = getURL(process.env.REACT_APP_SR_REFRESH_PUT);
  const data = {
    content: {},
    pageInstructions: fields,
  };
  return axios
    .put(REACT_APP_SR_REFRESH_PUT + nextAssignmentID + '/actions/' + actionId + '/refresh', data, {
      withCredentials: true,
    })
    .then((res) => {
      let fieldList = [];
      fieldList = formDataTransform(res);

      return fieldList;
    });
};
