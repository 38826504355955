import { appConstants } from './client/constant';

//const idp = "mygovidm"; //temp cookie variable
const cookie = document.cookie;
const cookieSplit = cookie.split(/[;.\s]/); //array of cookies

export function getCorrectURLForInternalExternal(URL, externalLink, internalLink, commonURL) {
  if (cookieSplit.includes('IDP=mygovid')) {
    URL = externalLink + commonURL;
    return URL;
  } else if (cookieSplit.includes('IDP=defence')) {
    URL = internalLink + commonURL;
    return URL;
  } else {
    if (
      !cookieSplit.includes('IDP=mygovid') &&
      !cookieSplit.includes('IDP=defence') &&
      window.location.href.includes(appConstants.SR_PUBLIC_PORTAL)
    ) {
      URL = externalLink + commonURL;
      return URL;
    } else {
      URL = internalLink + commonURL;
      return URL;
    }
  }
}

export function getBaseURL() {
  let URL = appConstants.DEV_DOMAIN_URL + appConstants.MYBASE_COMMON_URL;
  if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PRE_PROD_ENV) {
    URL = getCorrectURLForInternalExternal(
      URL,
      appConstants.PRE_PROD_DOMAIN_URL_EXTERNAL,
      appConstants.PRE_PROD_DOMAIN_URL_INTERNAL,
      appConstants.MYBASE_COMMON_URL,
    );
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PROD_ENV) {
    URL = getCorrectURLForInternalExternal(
      URL,
      appConstants.PROD_DOMAIN_URL_EXTERNAL,
      appConstants.PROD_DOMAIN_URL_INTERNAL,
      appConstants.MYBASE_COMMON_URL,
    );
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_QA_ENV) {
    URL = getCorrectURLForInternalExternal(
      URL,
      appConstants.QA_DOMAIN_URL_EXTERNAL,
      appConstants.QA_DOMAIN_URL_INTERNAL,
      appConstants.MYBASE_COMMON_URL,
    );
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST_ENV) {
    URL = appConstants.TST_DOMAIN_URL + appConstants.MYBASE_COMMON_URL;
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST2_ENV) {
    URL = appConstants.TST2_DOMAIN_URL + appConstants.MYBASE_COMMON_URL;
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_BST_ENV) {
    URL = appConstants.BST_DOMAIN_URL + appConstants.MYBASE_COMMON_URL;
    return URL;
  } else {
    return URL;
  }
}

export function getFormsURL() {
  let URL = appConstants.DEV_DOMAIN_URL + appConstants.FORMS_COMMON_URL;
  if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PRE_PROD_ENV) {
    URL = getCorrectURLForInternalExternal(
      URL,
      appConstants.PRE_PROD_DOMAIN_URL_EXTERNAL,
      appConstants.PRE_PROD_DOMAIN_URL_INTERNAL,
      appConstants.FORMS_COMMON_URL,
    );
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PROD_ENV) {
    URL = getCorrectURLForInternalExternal(
      URL,
      appConstants.PROD_DOMAIN_URL_EXTERNAL,
      appConstants.PROD_DOMAIN_URL_INTERNAL,
      appConstants.FORMS_COMMON_URL,
    );
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_QA_ENV) {
    URL = getCorrectURLForInternalExternal(
      URL,
      appConstants.QA_DOMAIN_URL_EXTERNAL,
      appConstants.QA_DOMAIN_URL_INTERNAL,
      appConstants.FORMS_COMMON_URL,
    );
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST_ENV) {
    URL = appConstants.TST_DOMAIN_URL + appConstants.FORMS_COMMON_URL;
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST2_ENV) {
    URL = appConstants.TST2_DOMAIN_URL + appConstants.FORMS_COMMON_URL;
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_BST_ENV) {
    URL = appConstants.BST_DOMAIN_URL + appConstants.FORMS_COMMON_URL;
    return URL;
  } else {
    return URL;
  }
}

export function getPostingConnectURL() {
  let URL = appConstants.DEV_DOMAIN_URL_POSTINGCONNECT;
  if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PRE_PROD_ENV) {
    URL = appConstants.PRE_PROD_DOMAIN_URL_POSTINGCONNECT;
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PROD_ENV) {
    URL = appConstants.PROD_DOMAIN_URL_POSTINGCONNECT;
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_QA_ENV) {
    URL = appConstants.QA_DOMAIN_URL_POSTINGCONNECT;
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST_ENV) {
    URL = appConstants.TST_DOMAIN_URL_POSTINGCONNECT;
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST2_ENV) {
    URL = appConstants.TST2_DOMAIN_URL_POSTINGCONNECT;
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_BST_ENV) {
    URL = appConstants.BST_DOMAIN_URL_POSTINGCONNECT;
    return URL;
  } else {
    return URL;
  }
}
export function getCorrectAuthURLForInternalExternal(
  URL,
  externalDomain,
  internalDomain,
  loginExternalURL,
  loginInternalURL,
) {
  if (cookieSplit.includes('IDP=mygovid')) {
    URL = externalDomain + loginExternalURL;
    return URL;
  } else if (cookieSplit.includes('IDP=defence')) {
    URL = internalDomain + loginInternalURL;
    return URL;
  } else {
    if (
      !cookieSplit.includes('IDP=mygovid') &&
      !cookieSplit.includes('IDP=defence') &&
      window.location.href.includes(appConstants.SR_PUBLIC_PORTAL)
    ) {
      URL = externalDomain + loginExternalURL;
      return URL;
    } else {
      URL = internalDomain + loginInternalURL;
      return URL;
    }
  }
}
export function getAuthURL() {
  let URL = appConstants.SC_DEV_DOMAIN_URL + appConstants.SC_LOGIN_INTERNAL_URL;
  if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PRE_PROD_ENV) {
    URL = getCorrectAuthURLForInternalExternal(
      URL,
      appConstants.SC_PRE_PROD_DOMAIN_URL_EXTERNAL,
      appConstants.SC_PRE_PROD_DOMAIN_URL_INTERNAL,
      appConstants.SC_LOGIN_EXTERNAL_URL,
      appConstants.SC_LOGIN_INTERNAL_URL,
    );
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PROD_ENV) {
    URL = getCorrectAuthURLForInternalExternal(
      URL,
      appConstants.SC_PROD_DOMAIN_URL_EXTERNAL,
      appConstants.SC_PROD_DOMAIN_URL_INTERNAL,
      appConstants.SC_LOGIN_EXTERNAL_URL,
      appConstants.SC_LOGIN_INTERNAL_URL,
    );
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_QA_ENV) {
    URL = getCorrectAuthURLForInternalExternal(
      URL,
      appConstants.SC_QA_DOMAIN_URL_EXTERNAL,
      appConstants.SC_QA_DOMAIN_URL_INTERNAL,
      appConstants.SC_LOGIN_EXTERNAL_URL,
      appConstants.SC_LOGIN_INTERNAL_URL,
    );
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST_ENV) {
    URL = appConstants.SC_TEST_DOMAIN_URL + appConstants.SC_LOGIN_INTERNAL_URL;
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST2_ENV) {
    URL = appConstants.SC_TEST2_DOMAIN_URL + appConstants.SC_LOGIN_COMMON_URL_TST2;
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_BST_ENV) {
    URL = appConstants.SC_BST_DOMAIN_URL + appConstants.SC_LOGIN_INTERNAL_URL;
    return URL;
  } else {
    return URL;
  }
}

// test
export function getCorrectURLAfterSSOForInternalExternal(externalSSOLink, internalSSOLink) {
  if (cookieSplit.includes('IDP=mygovid')) {
    return externalSSOLink; //external
  } else if (cookieSplit.includes('IDP=defence')) {
    return internalSSOLink; //internal
  } else {
    if (
      !cookieSplit.includes('IDP=mygovid') &&
      !cookieSplit.includes('IDP=defence') &&
      window.location.href.includes(appConstants.SR_PUBLIC_PORTAL)
    ) {
      return externalSSOLink; //external
    } else {
      return internalSSOLink; //internal
    }
  }
}

// test

export function getAuthDomainURLAfterSSO() {
  let URL = appConstants.SC_DEV_DOMAIN_URL_AFTER_SSO;
  if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PRE_PROD_ENV) {
    URL = getCorrectURLAfterSSOForInternalExternal(
      appConstants.SC_PRE_PROD_DOMAIN_URL_AFTER_SSO_EXTERNAL,
      appConstants.SC_PRE_PROD_DOMAIN_URL_AFTER_SSO,
    );
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_PROD_ENV) {
    URL = getCorrectURLAfterSSOForInternalExternal(
      appConstants.SC_PROD_DOMAIN_URL_AFTER_SSO_EXTERNAL,
      appConstants.SC_PROD_DOMAIN_URL_AFTER_SSO,
    );
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_QA_ENV) {
    URL = getCorrectURLAfterSSOForInternalExternal(
      appConstants.SC_QA_DOMAIN_URL_AFTER_SSO_EXTERNAL,
      appConstants.SC_QA_DOMAIN_URL_AFTER_SSO,
    );
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST_ENV) {
    URL = appConstants.SC_TEST_DOMAIN_URL_AFTER_SSO;
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST2_ENV) {
    URL = appConstants.SC_TEST2_DOMAIN_URL_AFTER_SSO;
    return URL;
  } else if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_BST_ENV) {
    URL = appConstants.SC_BST_DOMAIN_URL_AFTER_SSO;
    return URL;
  } else {
    return URL;
  }
}
