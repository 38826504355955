import { ComponentStyleConfig } from '@chakra-ui/react';
const Card: ComponentStyleConfig = {
  parts: ['container', 'body'],
  baseStyle: {
    container: { backgroundColor: 'white', padding: '1rem' },
    header: { padding: '0.5rem', borderRadius: 'inherit' },
    body: { padding: '0.5rem', maxHeight: '110px', overflow: 'hidden' },
    footer: { padding: '0.5rem' },
  },
};

export default Card;
