import { ComponentStyleConfig } from '@chakra-ui/react';
const Menu: ComponentStyleConfig = {
  parts: ['item', 'list', 'button'],
  baseStyle: {
    button: { _isInvalid: { borderColor: 'red' } },
    list: {
      padding: 0,
      borderRadius: 0,
      outlineColor: 'black',
      outlineStyle: 'solid',
      outlineWidth: '1px',
      outlineOffset: 0,
      border: 'none',
      w: '100%',
    },
    item: {
      bg: 'white',
      borderRadius: '0px',
      border: 0,
      _focus: { bg: 'mediumgrey' },
      _hover: { bg: 'mediumgrey' },
    },
  },
  variants: {
    main: {
      item: {
        borderRadius: '0px',
        borderBottom: '1px white solid',
        color: 'white',
        bg: 'charcoalgrey',
        _hover: { bg: 'orange.standard' },
      },
      list: {
        padding: 0,
        borderRadius: '0px',
        border: 0,
        overflowY: 'overflow',
      },
    },
  },
};

export default Menu;
