import React from 'react';
import { getBaseURL, getFormsURL, getPostingConnectURL } from '../../../helper/getOtherAppURL';
import { appConstants } from '../../../helper/client/constant';
import { Box, Heading, Grid } from '@chakra-ui/react';
import { Card } from '../../../components/ServiceUI/Core/Card/Card';

export interface CardProps {
  title?: string;
  description?: string;
  dpeDescription?: string;
  ctaText?: string;
  ctaUrl?: string;
  image?: string;
  altText?: string;
  isButton?: boolean;
  isExternal?: boolean;
}

export interface CardPanelsProps {
  heading: string;
  panels: CardProps[];
  variation?: string;
}

const CtaPanels = ({ heading, panels, variation }: CardPanelsProps) => {
  let isDefenceProtectedEnvironment = false;
  const cookie = document.cookie;
  const cookieSplit = cookie.split(/[;.\s]/); //array of cookies
  if (cookieSplit.includes('IDP=mygovid')) {
    isDefenceProtectedEnvironment = true;
  }

  return (
    <Box data-testid="CardPanel">
      <Heading as="h2" size="xs" color="black" fontWeight="medium" mb={{ md: '48px', base: '12px' }}>
        {heading}
      </Heading>
      <Grid templateColumns="repeat(auto-fit, minmax(350px, 1fr))" gap={{ lg: '55px', base: '25px' }}>
        {panels.map((item) => (
          <Card
            header={item.title}
            content={isDefenceProtectedEnvironment ? item.dpeDescription || item.description : item.description}
            image={item.image ? require(`../../../images/${item.image}`) : undefined}
            alt={item.altText}
            linkText={item.ctaText}
            isButton
            buttonVariant={`${variation === 'primary' ? 'primary' : 'secondary'}`}
            linkURL={
              item.ctaText === appConstants.VISIT_MY_FORMS
                ? getFormsURL()
                : item.ctaText === appConstants.VISIT_MY_BASE
                ? getBaseURL()
                : item.ctaText === appConstants.VISIT_POSTING_CONNECT
                ? getPostingConnectURL()
                : item.ctaUrl
            }
            isExternal={item.isExternal}
            key={item.title}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default CtaPanels;
