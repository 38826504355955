import { appConstants } from '../helper/client/constant';
//const IDP = "mygovidm"; //temp cookie variable
// document.cookie.split(";").some((item) => item.includes("IDP=mygovid"))

const cookie = document.cookie;
const cookieSplit = cookie.split(/[;.\s]/); //array of cookies

export const getURL = (url) => {
  let domain = process.env.REACT_APP_INTERNAL_DOMAIN_AEM;

  //check if local, dev, test
  if (
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_LOCAL_ENV ||
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_DEV_ENV ||
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST_ENV ||
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST2_ENV
  ) {
    return url;
  } else {
    if (cookieSplit.includes('IDP=mygovid')) {
      domain = process.env.REACT_APP_EXTERNAL_DOMAIN_AEM;
      return domain + url; //external
    } else if (cookieSplit.includes('IDP=defence')) {
      return domain + url; //internal
    } else {
      if (
        !cookieSplit.includes('IDP=mygovid') &&
        !cookieSplit.includes('IDP=defence') &&
        window.location.href.includes(appConstants.SR_PUBLIC_PORTAL)
      ) {
        domain = process.env.REACT_APP_EXTERNAL_DOMAIN_AEM;
        return domain + url; //external
      } else {
        return domain + url; //internal
      }
    }
  }
};

//Get the domain for AEM - for help guides
export const getDomain = (url) => {
  let domain = process.env.REACT_APP_INTERNAL_DOMAIN_AEM;

  //check if local or dev
  if (
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_LOCAL_ENV ||
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_DEV_ENV
  ) {
    return appConstants.DEV_DOMAIN_URL;
  }

  //if TEST env
  if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST_ENV) {
    return appConstants.TST_DOMAIN_URL;
  }
  //if TEST2 env
  if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST2_ENV) {
    return appConstants.TST2_DOMAIN_URL;
  } else {
    //check if external (govid)

    if (cookieSplit.includes('IDP=mygovid')) {
      domain = process.env.REACT_APP_EXTERNAL_DOMAIN_AEM;
      return domain + url; //external
    } else if (cookieSplit.includes('IDP=defence')) {
      return domain + url; //internal
    } else {
      if (
        !cookieSplit.includes('IDP=mygovid') &&
        !cookieSplit.includes('IDP=defence') &&
        window.location.href.includes(appConstants.SR_PUBLIC_PORTAL)
      ) {
        domain = process.env.REACT_APP_EXTERNAL_DOMAIN_AEM;
        return domain + url; //external
      } else {
        return domain + url; //internal
      }
    }
  }
};
