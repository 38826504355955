import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { spacing } from '../spacing';
import { disabledField } from '../constants';

const primary = defineStyle({
  fontSize: 'xs',
  fontWeight: '200',
  borderRadius: '2px',
  backgroundColor: 'white',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'mediumgrey',
  padding: spacing.default,
  _focus: {
    borderWidth: '2px',
    borderColor: 'charcoal',
  },
  _disabled: disabledField,
  _readOnly: disabledField,
  _invalid: { borderColor: 'redalert' },
});

export const textareaTheme = defineStyleConfig({
  variants: { primary },
});
