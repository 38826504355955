import React from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
export interface BreadcrumbItemProps {
  url: string;
  name: string;
}
export interface BreadcrumbProps {
  breadcrumbItems: BreadcrumbItemProps[];
}
export const Breadcrumbs: React.FC<BreadcrumbProps> = (props: BreadcrumbProps): JSX.Element => {
  return (
    <Breadcrumb separator=">" marginBottom="2rem" listProps={{ p: 0 }}>
      {props.breadcrumbItems.map((item: BreadcrumbItemProps, index: number) => (
        <BreadcrumbItem key={index} fontSize="xs">
          <BreadcrumbLink textDecoration="underline" href={item.url}>
            {item.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
      <BreadcrumbItem>
        <BreadcrumbLink isCurrentPage fontWeight="bold" fontSize="xs">
          Current Page
        </BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};
