import { Modal, Button } from 'react-bootstrap';

const DeleteModal = (props) => {
  return (
    <Modal dialogClassName="modal-lg modalPadding px-lg-0 py-5" contentClassName="p-lg-4 modal-75w" show={props.show}>
      <Modal.Header className=" pb-0 bg-defencewhite border-0 flex-column align-items-start">
        <h1>Are you sure you want to delete this request?</h1>
      </Modal.Header>
      <Modal.Body>
        Please confirm whether you would like to delete this drafted request. This action cannot be undone.
      </Modal.Body>
      <Modal.Footer className="border-0 d-flex justify-content-between ">
        <Button variant="standard" onClick={props.closeAction}>
          Back
        </Button>
        <Button onClick={props.deleteAction}>Delete request</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
