import { request } from './useAxios';



export const savePIR = (id: string,  data: any) => {
  let config;
 return request('REACT_APP_SR_SAVE1', 'get', { id })
    .then((res) => {
      const finalETagValue = res.data.content.pxSaveDateTime.replace(/[-:Z]/g, '') + ' GMT';
    
   config = {
        headers: {
          'if-match': finalETagValue,
        },
        withCredentials: true,
      };})
      .then(() => request('REACT_APP_SR_SAVE2', 'put', { id: id , data, config }))
      .catch((err) => {
        throw err;
      });
    

};
