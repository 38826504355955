import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findByProp } from '../../../helper/findByProp.js';

import { SCForm } from '../../../components/ServiceUI/Core/FormElements';
//@ts-ignore
//@ts-ignore
import { setFieldValue } from '../../../reducers/eftRequestDetailFormReducer';

const FormRadio = ({
  fieldID,
  label,
  control,
  fieldPropMapping,
  formData,
  reference,
  visible,
  required,
  dynamicFieldAction,
  value,
  readOnly,
}) => {
  const dispatch = useDispatch();
  const reqDetailDataFromReduxStore = useSelector((state: any) => {
    if (state) {
      return findByProp(state.EFTRequestMemberForm.formFields, 'reference', reference);
    }
  });
  const [radioSelection, setRadioSelection] = useState('No');
  const [displayValue, setDisplayValue] = useState(value);
  const onSelectRadio = (e) => {
    setRadioSelection((prevState) => (prevState = 'Yes'));
    setDisplayValue(e.value);

    const selectedValue = control?.modes[0]?.options?.filter((item) => item.value === e.value);
    const finalValue = !!selectedValue && selectedValue.length > 0 ? selectedValue[0].key : e.target.value;
    dispatch(
      setFieldValue({
        value,
        finalValue,
        reference,
        formData,
        required,
        visible,
      }),
    );
    dynamicFieldAction(finalValue, fieldID, reference, required, visible);
  };

  useEffect(() => {
    setDisplayValue(reqDetailDataFromReduxStore?.value);
  }, [formData]);

  return (
    <SCForm.Radio
      disabled={readOnly}
      value={displayValue}
      label={label}
      visible
      options={control?.modes[0]?.options}
      onSelectRadio={(e) => onSelectRadio(e)}
      variant="primary"
      required={required}
      desktopWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop}
      mobileWidth={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile}
    />
  );
};

export default FormRadio;
