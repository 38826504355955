import React from 'react';
import { FormLabel, FormErrorMessage, Radio, RadioGroup, Text, Flex } from '@chakra-ui/react';
import { PrimitiveControl } from '../PrimitiveControl';
import { FormElement, RadioProps } from '../FormElementType';
import { Tooltip } from '../../Tooltip/Tooltip';

const FormRadio: React.FC<FormElement & RadioProps> = (props: FormElement & RadioProps): JSX.Element => {
  return (
    <>
      {props.visible && (
        <PrimitiveControl
          disabled={props.disabled}
          id={props.id}
          required={props.required}
          desktopWidth={props.desktopWidth}
          mobileWidth={props.mobileWidth}
          isInvalid={props.isInvalid}
        >
          {props.label ? (
            <Flex alignItems="center" gap={1} mb={2}>
              <FormLabel m={0}>{props.label}</FormLabel>
              {props.showTooltip && props.tooltipContent && (
                <Tooltip content={props.tooltipContent} placement="bottom" />
              )}
            </Flex>
          ) : (
            <></>
          )}
          <RadioGroup data-testid="radiogroup" value={String(props.value)}>
            <Flex flexDir={{ lg: 'row', base: 'column' }} gap={3}>
              {props.options?.map((item) => (
                <Radio
                  onChange={() => props.onSelectRadio(item)}
                  data-testid="radio"
                  variant={props.variant}
                  name={props.reference}
                  value={item.key}
                  key={item.key}
                >
                  {item.value}
                  {item.tooltip && <Text fontWeight="light">{item.tooltip}</Text>}
                </Radio>
              ))}
            </Flex>
          </RadioGroup>
          <FormErrorMessage>{props.errorMessage}</FormErrorMessage>
        </PrimitiveControl>
      )}
    </>
  );
};

export default FormRadio;
