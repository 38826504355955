import React from 'react';
import { NavItem, NavItemProps } from '../NavItem/NavItem';

interface MenuNavLinks {
  variant?: 'mainNav' | 'subNav';
  router: boolean;
  navigationItems: NavItemProps[];
}

const MenuTabs = (props: MenuNavLinks): JSX.Element => {
  return (
    <>
      {props.navigationItems?.map((item: NavItemProps, key: number) => (
        <NavItem variant={props.variant} router={props.router} url={item.url} title={item.title} key={key} />
      ))}
    </>
  );
};

export default MenuTabs;
