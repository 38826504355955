import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import './ReviewSubheader.scss';
import AuthenticationModal from '../../../LandingPage/AuthenticationModal/AuthenticationModal';
import SystemOutageModal from '../../../../components/SystemOutageModal/SystemOutageModal';
import GenericErrorModal from '../../../../components/GenericErrorModal/GenericErrorModal';

import AuthenticationModalProps from '../../../LandingPage/AuthenticationModal/__mocks__/default.json';
import GenericErrorModalProps from '../../../GenericErrorModal/__mocks__/default.json';
import SystemOutageModalProps from '../../../SystemOutageModal/__mocks__/default.json';

import { getURL } from '../../../../helper/getAPIUrl';

import { appConstants } from '../../../../helper/client/constant';
import { useDispatch } from 'react-redux';

const ReviewSubheader = (props) => {
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const routeChangetoEdit = (caseId, type) => {
    const path = `/my-service-request/edit-service-request-create/${caseId}?type=${type}`;
    navigate(path);
  };

  const editRequestHandler = () => {
    const URL_EDIT_SELECT = process.env.REACT_APP_EDIT_SELECT + props.caseIdForFirstEditAPI;
    const final_URL_EDIT_SELECT = getURL(URL_EDIT_SELECT);
    axios
      .put(
        final_URL_EDIT_SELECT,
        {},
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        const URL_EDIT_FORWARDS =
          process.env.REACT_APP_EDIT_FORWARDS +
          props.caseIdForSecondEditAPI +
          (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV
            ? '?actionID=ReviewRequest'
            : '/' + 'ReviewRequest');
        const final_URL_EDIT_FORWARDS = getURL(URL_EDIT_FORWARDS);
        axios
          .post(
            final_URL_EDIT_FORWARDS,
            {},
            {
              withCredentials: true,
            },
          )
          .then((res) => {
            routeChangetoEdit(props.caseIdForFirstEditAPI, props.requestType);
          })
          .catch((err) => {
            let error = { ...err };
            error.response = error.response || { response: { data: [] } };
            setAPIError(error);

            if (error.response.status === 403 || error.response.status === 401) {
              setShowModal(true);
            } else if (error.response.status === 500) {
              setServiceDownModal(true);
            } else {
              setErrorModal(true);
            }
          });
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);

        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };
  return (
    <>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}

      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}

      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
        />
      )}

      <div className="landingPageWidth pt-4 pt-lg-6 ps-4 ps-lg-6 pe-4 pe-lg-6">
        <h1 className="pb-3" style={{ textAlign: 'start' }}>
          {props.heading}
        </h1>
        <h4 className="fw-light pb-3">{props.subheading}</h4>
        <Button variant="primary" className="mobileButton" onClick={() => editRequestHandler()}>
          Edit request
        </Button>
      </div>
    </>
  );
};

export default ReviewSubheader;
