import React from 'react';
import { Container, Badge, Col, Row } from 'react-bootstrap';
import './HeaderNotifications.scss';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

const HeaderNotifications = (props) => {
  let count = 0;

  return (
    <Container fluid className="py-lg-2 text-white bg-defenceorange servicebackgroundImg">
      <Container className="p-4 p-lg-6 landingPageWidth">
        {props.breadcrumbs === true && <Breadcrumbs breadcrumbItems={props.breadcrumbItems} />}
        <h2 className="desktop desktopServiceHeading">{props.mainHeading}</h2>
        <h2 className="mobile mobileServiceHeading">{props.mainHeading}</h2>
        <p>{props.subHeading}</p>
        <Row className="w-100">
          <Col className="d-flex align-items-center">
            <Badge pill className="px-3 me-2" bg="light" text="defenceorange">
              {props.notifications !== undefined &&
                props.notifications.map((item, index) => {
                  if (item.isRead === false) {
                    count += 1;
                  }
                })}
              {count}
            </Badge>
            {props.notificationTally}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default HeaderNotifications;
