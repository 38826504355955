import * as React from 'react';
import { SVGProps } from 'react';
const PIRRoyal = (props: SVGProps<SVGSVGElement>) => (
  <svg width="35" height="43" viewBox="0 0 35 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8343 0H12.9011C9.73496 0 7.16726 2.5677 7.16726 5.73382V8.60073H7.14934H5.73382C4.21257 8.60073 2.75581 9.20457 1.68074 10.2797C0.603841 11.3548 0 12.8133 0 14.3346V37.2662C0 40.4323 2.5677 43 5.73382 43H21.5018C24.668 43 27.2357 40.4323 27.2357 37.2662V34.3993H28.6691C31.8352 34.3993 34.4029 31.8316 34.4029 28.6654V8.59706C34.4029 8.21899 34.2524 7.85524 33.9854 7.5847L26.8503 0.421092C26.5815 0.152318 26.2159 0 25.8343 0ZM7.16726 11.4676H5.73381L5.59225 11.4712C4.88447 11.5071 4.21077 11.8045 3.70725 12.308C3.16969 12.8456 2.8669 13.573 2.8669 14.3346V37.2662C2.8669 38.8501 4.14985 40.1331 5.73381 40.1331H21.5018C23.0858 40.1331 24.3687 38.8501 24.3687 37.2662V34.3993H12.9011C9.73496 34.3993 7.16727 31.8316 7.16727 28.6654L7.16726 11.4676ZM22.9353 2.86691H12.9011C11.3171 2.86691 10.0342 4.14987 10.0342 5.73382V28.6654C10.0342 30.2494 11.3171 31.5324 12.9011 31.5324H28.6691C30.2531 31.5324 31.536 30.2494 31.536 28.6654V11.464H24.3687C23.5767 11.464 22.9353 10.8225 22.9353 10.0305V2.86691ZM14.3345 27.2338H27.2356C28.0276 27.2338 28.6691 26.5924 28.6691 25.8004C28.6691 25.0084 28.0276 24.3669 27.2356 24.3669H14.3345C13.5425 24.3669 12.9011 25.0084 12.9011 25.8004C12.9011 26.5924 13.5425 27.2338 14.3345 27.2338ZM14.3345 21.4963H27.2356C28.0276 21.4963 28.6691 20.8549 28.6691 20.0629C28.6691 19.2709 28.0276 18.6294 27.2356 18.6294H14.3345C13.5425 18.6294 12.9011 19.2709 12.9011 20.0629C12.9011 20.8549 13.5425 21.4963 14.3345 21.4963ZM14.3345 15.7643H21.5018C22.2938 15.7643 22.9353 15.1229 22.9353 14.3309C22.9353 13.5389 22.2938 12.8974 21.5018 12.8974H14.3345C13.5425 12.8974 12.9011 13.5389 12.9011 14.3309C12.9011 15.1229 13.5425 15.7643 14.3345 15.7643ZM14.3345 10.0305H18.6349C19.4269 10.0305 20.0684 9.38907 20.0684 8.59706C20.0684 7.80506 19.4269 7.16361 18.6349 7.16361H14.3345C13.5425 7.16361 12.9011 7.80506 12.9011 8.59706C12.9011 9.38907 13.5425 10.0305 14.3345 10.0305ZM25.8022 8.59706H30.9466L25.8022 3.43112V8.59706Z"
        fill="#CF4928"
      />
    </g>
  </svg>
);
export default PIRRoyal;
