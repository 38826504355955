import { Box, Flex, Grid, GridItem, Heading, Show, Spacer, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../../../components/ServiceUI/Core/Spinner/Spinner';
import { getURL } from '../../../../helper/getAPIUrl';
import { Pagination } from '../../Core/Pagination/Pagination';
import { Tooltip } from '../../Core/Tooltip/Tooltip';
import { PIR } from '../types';
import { PIRItem } from './PIRItem';
//test
import GenericErrorModal from '../../../../components/GenericErrorModal/GenericErrorModal';
import GenericErrorModalProps from '../../../../components/GenericErrorModal/__mocks__/default.json';
import AuthenticationModal from '../../../../components/LandingPage/AuthenticationModal/AuthenticationModal';
import AuthenticationModalProps from '../../../../components/LandingPage/AuthenticationModal/__mocks__/default.json';
import ErrorPopUp from '../../../../components/MyServiceRequest/CreateRequest/ErrorPopup/ErrorPopup';
import SystemOutageModal from '../../../../components/SystemOutageModal/SystemOutageModal';
import SystemOutageModalProps from '../../../../components/SystemOutageModal/__mocks__/default.json';
//test
export interface PIRDashboardProps {
  heading: string;
  tooltipContent?: string;
  noActiveRequestsMessage: string;
  pirs: PIR[];
}

export const PIRDashBoard: React.FC<PIRDashboardProps> = (props: PIRDashboardProps) => {
  const navigate = useNavigate();
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalHttpCode, setShowModalHttpCode] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const HandlerToUpdateStatus = (id) => {
    setLoading(true);
    const URL_SR_DELETE_GET = getURL(process.env.REACT_APP_SR_DELETE_GET);
    axios
      .get(URL_SR_DELETE_GET + id, {
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);
        const finalETagValue = res.data.content.pxSaveDateTime.replace(/[-:Z]/g, '') + ' GMT';
        const URL_UPDATE_STATUS =
          process.env.REACT_APP_PIR_STATUS_CHANGE_FOR_PIR_REVIEW1 +
          id +
          process.env.REACT_APP_PIR_STATUS_CHANGE_FOR_PIR_REVIEW2;
        const FINAL_URL_UPDATE_STATUS = getURL(URL_UPDATE_STATUS);
        const data = { content: { pyGotoStage: 'Resolve' } };

        // update Delete data
        setTimeout(() => {
          const config = {
            headers: {
              'if-match': finalETagValue,
            },
            withCredentials: true,
          };
          axios
            .put(FINAL_URL_UPDATE_STATUS, data, config)
            .then((res) => {
              setLoading(false);
              navigate(`/personal-information-request/doc-ready/${id}`);
            })
            .catch((err) => {
              setLoading(false);
              let error = { ...err };
              error.response = error.response || { response: { data: [] } };
              setAPIError(error);

              if (error.response.status === 403 || error.response.status === 401) {
                setShowModal(true);
              } else if (error.response.status === 500) {
                setServiceDownModal(true);
              } else {
                setErrorModal(true);
              }
            });
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);

        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });

    // update Delete data
  };
  return (
    <>
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}

      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}
      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
          descriptionNoPrivilage={AuthenticationModalProps.descriptionWithNoPrivilage}
          code={showModalHttpCode}
        />
      )}
      <ErrorPopUp show={showError} error={error} closeAction={() => setShowError(false)} />
      {loading && <Spinner />}
      <Box data-testid="pir-dashboard" py="3.5rem">
        <Flex alignItems="center" gap={1}>
          <Heading as="h2" size="sm" fontWeight="medium" m={0}>
            {props.heading}
          </Heading>
          {props.tooltipContent && <Tooltip content={props.tooltipContent} placement="bottom" />}
        </Flex>
        {props.pirs?.length > 0 ? (
          <>
            <Show above="lg">
              <Grid px="1.25rem" gridTemplateColumns="repeat(5,1fr)" gap={3}>
                <Spacer as={GridItem} colSpan={3} />
                <Text m={0} color="darkgrey" fontWeight="light" fontSize="xxs">
                  Date submitted
                </Text>
              </Grid>
            </Show>
            <Pagination itemsPerPage={6}>
              {props.pirs?.map((pirItem: PIR, key: number) => (
                <PIRItem
                  {...pirItem}
                  key={key}
                  handlestatusChangeforPendingReview={(id) => HandlerToUpdateStatus(id)}
                />
              ))}
            </Pagination>
          </>
        ) : (
          <Text fontWeight="light" mt="1rem">
            {props.noActiveRequestsMessage}
          </Text>
        )}
      </Box>
    </>
  );
};
