import styled from 'styled-components';
import { Col, Form, FormControl } from 'react-bootstrap';

export const RDForm = styled(Form)``;

export const RDFormCheckboxLabel = styled(Form.Check.Label)`
  margin-left: 5px;
`;

export const RDFormRadioElement = styled(Form.Check)`
  margin-right: 20px;
  display: inline-block;
`;

export const FormGroupBg = styled(Form.Group)`
  background: #d6e3ec;
  padding: 20px;
  margin-bottom: 20px;
`;

export const FormFieldCol = styled(Col)`
  display: inline-block;
`;

export const FormControlTextInput = styled(FormControl)`
  :read-only {
    background: #ededed;
  }
`;
