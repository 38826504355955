import React from 'react';
import { Box, Flex, GridItem, Link, Text } from '@chakra-ui/react';
import { PegaFile } from '../types';
import { convertBytesToMB } from '../../../../helper/convertBytesToMB';
import Download from '../../Core/icons/Outline/Download';

interface DownloadableFileItemProps {
  file: PegaFile;
  onClick(file: PegaFile): void;
}

export const DownloadableFileItem: React.FC<DownloadableFileItemProps> = ({
  file,
  onClick,
}: DownloadableFileItemProps): JSX.Element => {
  return (
    file && (
      <GridItem colSpan={{ lg: 1, base: 3 }} as={Flex} gap={2} mt="1rem">
        <Download />
        <Flex flexDir="column">
          <Link fontWeight="light" lineHeight={1} fontSize="xxs" onClick={() => onClick(file)}>
            {file.fileName ?? ''}
          </Link>
          <Link hidden id="download-attachment" />
          <Text fontSize="xxs" m={0} fontWeight="medium" textTransform="uppercase">
            {file.extension} | {convertBytesToMB(file.fileSizeinbytes).toFixed(2)}mb
          </Text>
        </Flex>
      </GridItem>
    )
  );
};
