import React, { useState } from 'react';
import { Container, Button, Image } from 'react-bootstrap';
import './FAQs.scss';
import { appConstants } from '../../../helper/client/constant';
import { getDomain } from '../../../helper/getAEMUrl';

const AccordionItem = (props) => {
  const determineType = (type) => {
    if (type === 'phone') {
      return 'number';
    } else if (type === 'file') {
      return 'file';
    } else if (type === 'email') {
      return 'email';
    } else {
      return 'link';
    }
  };
  const getLinkType = (type) => {
    if (type === 'phone') {
      return 'tel:';
    } else if (type === 'email') {
      return 'mailto:';
    } else {
      return '';
    }
  };
  let domainAEM = '';
  domainAEM = getDomain(domainAEM);
  return (
    <Container
      tabIndex={0}
      onClick={props.makeActive}
      onKeyDown={(e) => e.key === 'Enter' && props.test()}
      className={`${
        props.active === true ? 'selecteditem' : null
      } bg-defencewhite accordionitem p-3 mb-3 border-1 border w-100 justify-content-between rounded-0"`}
      eventkey={props.eventkey}
    >
      <h2>{props.title}</h2>
      <p
        className="accordionContent fw-light w-100"
        dangerouslySetInnerHTML={{
          __html: props.description,
        }}
      />

      <Container className="links">
        {props.links &&
          props.links.map((props, idx) => {
            return (
              <p key={idx} className="mb-0">
                <p className="mb-0 fw-bold">{props.linkTitle}</p>

                {props.isAEM ? (
                  <a
                    href={`${getLinkType(props.linkType)}${domainAEM}${props.linkURL}`}
                    aria-label="Link Item"
                    className={`${determineType(props.linkType)} d-block mb-3 d-flex gap-2 align-items-center`}
                  >
                    {props.linkURL && <Image alt="link" src={process.env.PUBLIC_URL + props.linkImage} />}
                    {props.linkName}
                  </a>
                ) : (
                  <a
                    href={`${getLinkType(props.linkType)}${props.linkURL}`}
                    className={`${determineType(props.linkType)} d-flex gap-2 align-items-center`}
                    aria-label="Link Item"
                  >
                    {props.linkURL && <Image alt="links" src={process.env.PUBLIC_URL + props.linkImage} />}
                    {props.linkName}
                  </a>
                )}
              </p>
            );
          })}
      </Container>
    </Container>
  );
};

const FAQs = (props) => {
  const [activeStates, setActiveStates] = useState(new Array(props.FAQs.length).fill(false));
  const expandAll = (direction) => {
    setActiveStates(new Array(props.FAQs.length).fill(direction));
  };
  const makeActive = (number) => {
    let x = { ...activeStates };
    x[number] = !x[number];
    setActiveStates(x);
  };
  return (
    <>
      <Container fluid className={props.odd === true ? 'bg-defencewhite' : 'bg-defencewhisperlightgrey'}>
        <Container className="p-4 p-lg-6 landingPageWidth">
          <Container className="d-flex justify-content-around">
            <h2 className="align-self-end w-50">{props.title}</h2>
            <Container className="w-50 justify-content-end d-flex flex-lg-row align-items-end">
              <Button
                variant="sort"
                className="expandAll text-nowrap d-flex buttonText flex-column flex-lg-row"
                onClick={() => expandAll(true)}
              >
                {props.expandButtonText}
              </Button>
              <Button
                variant="sort"
                className="collapseAll text-nowrap d-flex buttonText flex-column flex-lg-row"
                onClick={() => expandAll(false)}
              >
                Collapse All
              </Button>
            </Container>
          </Container>
          {props.FAQs.map((item, idx) => (
            <AccordionItem {...item} makeActive={() => makeActive(idx)} active={activeStates[idx]} />
          ))}
        </Container>
      </Container>
    </>
  );
};

export default FAQs;
