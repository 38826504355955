import { Container } from 'react-bootstrap';
import { useEffect } from 'react';
import Header from '../LandingPage/Header/Header';
import content from './content/Privacy.json';

const Privacy = () => {
  useEffect(() => {
    document.title = 'Privacy';
  });
  return (
    <>
      <Header
        breadcrumbs={true}
        mainHeading={content.mainHeading}
        subHeading={content.subHeading}
        breadcrumbItems={content.header.breadcrumbItems}
      ></Header>
      <Container fluid className="py-lg-2 mt-2">
        <Container
          className="landingPageWidth px-4 px-lg-6"
          dangerouslySetInnerHTML={{
            __html: content.content,
          }}
        />
      </Container>
    </>
  );
};

export default Privacy;
