import { appConstants } from '../helper/client/constant';
//const IDP = "mygovidm"; //temp cookie variable
// document.cookie.split(";").some((item) => item.includes("IDP=mygovid"))

const cookie = document.cookie;
const cookieSplit = cookie.split(/[;.\s]/); //array of cookies

export const logoutURL = () => {
  let url = appConstants.INTERNAL_LOGOUT_URL;

  //check if local, dev, test
  if (
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_LOCAL_ENV ||
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_DEV_ENV ||
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST_ENV ||
    process.env.REACT_APP_CURRENT === appConstants.REACT_APP_TST2_ENV
  ) {
    return url;
  } else {
    if (cookieSplit.includes('IDP=mygovid')) {
      return appConstants.EXTERNAL_LOGOUT_URL; //external
    } else if (cookieSplit.includes('IDP=defence')) {
      return url; //internal
    } else {
      if (
        !cookieSplit.includes('IDP=mygovid') &&
        !cookieSplit.includes('IDP=defence') &&
        window.location.href.includes(appConstants.SR_PUBLIC_PORTAL)
      ) {
        return appConstants.EXTERNAL_LOGOUT_URL; //external
      } else {
        return url; //internal
      }
    }
  }
};
