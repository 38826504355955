import React from 'react';
import { Input, FormLabel, FormErrorMessage, InputGroup } from '@chakra-ui/react';
import { DateProps, FormElement } from '../FormElementType';
import { PrimitiveControl } from '../PrimitiveControl';

const FormDateInput: React.FC<FormElement & DateProps> = (props: FormElement & DateProps) => {
  return (
    <PrimitiveControl
      disabled={props.disabled}
      isInvalid={props.isInvalid}
      required={props.required}
      desktopWidth={props.desktopWidth}
      mobileWidth={props.mobileWidth}
    >
      <InputGroup flexDirection="column">
        {props.label ? <FormLabel>{props.label}</FormLabel> : <></>}
        <Input
          variant="primary"
          data-testid="input"
          onChange={(e) => props.onDateBlur(e)}
          value={String(props.value)}
          placeholder={props.label}
          min={props.minDate}
          max={props.maxDate}
          name={props.reference}
          required={props.required}
          type={props.type}
        />
        <FormErrorMessage>{props.errorMessage}</FormErrorMessage>
      </InputGroup>
    </PrimitiveControl>
  );
};

export default FormDateInput;
