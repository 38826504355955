import React from 'react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Button } from '@chakra-ui/react';
import sanitizeHTML from 'sanitize-html';

interface BaseInductionModalProps {
  isOpen: boolean;
  onClose(): void;
  content: string;
}
export const BaseInductionModal: React.FC<BaseInductionModalProps> = (props: BaseInductionModalProps) => {
  //Return a large modal with the email content as the body
  return (
    <Modal size="large" isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent p="2rem">
        {props.content ? (
          <ModalBody
            style={{ border: 'none' }}
            dangerouslySetInnerHTML={{
              __html: sanitizeHTML(
                props.content.slice(props.content.lastIndexOf('<table'), props.content.lastIndexOf('</table>')),
              ),
            }}
          />
        ) : (
          <ModalBody>Error! Content not found</ModalBody>
        )}
        <ModalFooter>
          <Button variant="secondary" onClick={props.onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
