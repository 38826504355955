import { Modal, Image, Button } from 'react-bootstrap';
import { appConstants } from '../../../../helper/client/constant';
import Error from '../../../../images/redalert.svg';

const ErrorPopUp = (props) => {
  const renderErrorBody = () => {
    if (props.cameFrom === appConstants.CAME_FROM_ATTACHMENT) {
      return <Modal.Body>{props.error && <>{props.error}</>}</Modal.Body>;
    } else {
      return (
        <Modal.Body>{props.error && <>{props.error.errors[0].ValidationMessages[0].ValidationMessage}</>}</Modal.Body>
      );
    }
  };
  return (
    <Modal contentClassName="p-4" show={props.show}>
      <Modal.Header className=" pb-0 bg-defencewhite border-0 flex-column align-items-start">
        <Image src={Error} height={50} alt="Error" className="mb-3" />
        <h1>Error</h1>
      </Modal.Header>

      {renderErrorBody()}
      <Modal.Footer className="border-0">
        <Button onClick={props.closeAction}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorPopUp;
