import React from 'react';
import { Checkbox, FormErrorMessage, Box } from '@chakra-ui/react';
import CheckboxIcon from '../../icons/Outline/Checkbox';
import { FormElement, CheckboxProps } from '../FormElementType';
import { PrimitiveControl } from '../PrimitiveControl';
import sanitizeHtml from 'sanitize-html';

const Icon = (props) => {
  const { isChecked } = props;
  return isChecked ? <CheckboxIcon /> : <></>;
};
const FormCheckbox: React.FC<FormElement & CheckboxProps> = (props: FormElement & CheckboxProps) => {
  return props.visible ? (
    <PrimitiveControl
      disabled={props.disabled}
      isInvalid={props.isInvalid}
      required={props.required}
      desktopWidth={props.desktopWidth}
      mobileWidth={props.mobileWidth}
    >
      <Checkbox
        variant="main"
        icon={<Icon />}
        data-testid="checkbox"
        name={props.reference}
        isInvalid={props.isInvalid}
        onChange={(e) => props.onCheckboxChange(e)}
        isChecked={Boolean(props.value)}
      >
        <Box
          fontSize="18px"
          sx={{ a: { textDecoration: 'underline' } }}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(props.label),
          }}
        />
      </Checkbox>
      <FormErrorMessage>{props.errorMessage}</FormErrorMessage>
    </PrimitiveControl>
  ) : null;
};

export default FormCheckbox;
