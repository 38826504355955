import React, { useEffect, useState } from 'react';
import CtaPanels from '../LandingPage/CtaPanels/CtaPanels';
import Header from '../LandingPage/Header/Header';
import { getIDPCookie } from '../../helper/getIDPCookie';
import content from './content/OurServices.json';
import { Box, Center } from '@chakra-ui/react';

const OurServices = () => {
  const [displayDNRServices, setDisplayDNRServices] = useState(false);
  const [cookieChecked, setCookieChecked] = useState(false);

  const cookieCheck = () => {
    const ans = getIDPCookie();
    return ans;
  };

  useEffect(() => {
    if (!cookieChecked) {
      const cookie = cookieCheck();
      setDisplayDNRServices(cookie);
      setCookieChecked(true);
    }
  }, [cookieChecked, displayDNRServices]);

  useEffect(() => {
    document.title = 'All Services';
  });

  return (
    <>
      <Header
        mainHeading={content.header.mainHeading}
        subHeading={content.header.subHeading}
        breadcrumbs={content.header.breadcrumbs}
        breadcrumbItems={content.header.breadcrumbItems}
      />
      <Center bg="whispergrey">
        <Box maxW="1480px" w="100%" my="48px" px={{ lg: '48px', base: '25px' }}>
          <CtaPanels heading={content.services.panels_heading} variation="primary" panels={content.services.panels} />
          {displayDNRServices && (
            <>
              <Box borderTop={{ md: '1px solid darkgrey' }} my={{ md: '48px', base: '25px' }} />
              <CtaPanels
                heading={content.dnrServices.panels_heading}
                variation="secondary"
                panels={content.dnrServices.panels}
              />
            </>
          )}
        </Box>
      </Center>
    </>
  );
};

export default OurServices;
