import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const LocationReadOnly = (props) => {
  useEffect(() => {}, []);

  return (
    <>
      <Container className="bg-defencewhite mt-4">
        <Container className="px-0">
          <h1 className="mx-lg-0 mx-4">Location information</h1>
          <Row>
            <Col>
              <Container className="ps-4 ps-lg-0">
                <Form.Group controlId="workType">
                  <Form.Label className="mb-0 p-lg-0">State</Form.Label>
                  <br />
                  <Container className="d-flex p-1 gap-5 mb-4 subTypes">
                    <p className="fw-light">
                      {!!props.reqData.Location.Address?.State ? props.reqData.Location.Address?.State : ''}
                    </p>
                  </Container>
                </Form.Group>
              </Container>
            </Col>
            <Col>
              <Container className="ps-4 ps-lg-0">
                <Form.Group controlId="workType">
                  <Form.Label className="mb-0 p-lg-0">Location type</Form.Label>
                  <br />
                  <Container className="d-flex gap-5 mb-4 subTypes">
                    <p className="fw-light">
                      {props.reqData.Location.Type === 'Base' ? 'Defence Location' : 'Other Location'}
                    </p>
                  </Container>
                </Form.Group>
              </Container>
            </Col>
          </Row>
          {props.reqData.Location.Type !== 'Other' && (
            <Row>
              <Col>
                <Container className="ps-4 ps-lg-0">
                  <Form.Group controlId="workType">
                    <Form.Label className="mb-0 p-lg-0">Name</Form.Label>
                    <br />
                    <Container className="d-flex gap-5 mb-4 subTypes">
                      <p className="fw-light pb-0">{props.reqData.Location.Name}</p>
                    </Container>
                  </Form.Group>
                </Container>
              </Col>
              <Col>
                <Container className="ps-4 ps-lg-0">
                  <Form.Group controlId="workType">
                    <Form.Label className="mb-0 p-lg-0">Base Address</Form.Label>
                    <br />
                    <Container className="d-flex gap-5 mb-4 subTypes">
                      <p className="fw-light">{props.reqData.Location.Address.FullAddress}</p>
                    </Container>
                  </Form.Group>
                </Container>
              </Col>
            </Row>
          )}
          {props.reqData.Location.Type === 'Other' && (
            <>
              <Row>
                <Col>
                  <Container className="ps-4 ps-lg-0">
                    <Form.Group controlId="workType">
                      <Form.Label className="mb-0 p-lg-0">Address</Form.Label>
                      <br />
                      <Container className="d-flex gap-5 mb-4 subTypes">
                        <p className="fw-light">{props.reqData.Location.Address.Line1}</p>
                      </Container>
                    </Form.Group>
                  </Container>
                </Col>
                <Col>
                  <Container className="ps-4 ps-lg-0">
                    <Form.Group controlId="workType">
                      <Form.Label className="mb-0 p-lg-0">City</Form.Label>
                      <br />
                      <Container className="d-flex gap-5 mb-4 subTypes">
                        <p className="fw-light">{props.reqData.Location.Address.City}</p>
                      </Container>
                    </Form.Group>
                  </Container>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Container className="ps-4 ps-lg-0">
                    <Form.Group controlId="workType">
                      <Form.Label className="mb-0 p-lg-0">Postcode</Form.Label>
                      <br />
                      <Container className="d-flex gap-5 mb-4 subTypes">
                        <p className="fw-light">{props.reqData.Location.Address.Postcode}</p>
                      </Container>
                    </Form.Group>
                  </Container>
                </Col>
              </Row>
            </>
          )}
          <Row>
            {!!props.reqData.Location.Vessel.SiteOrWharf && (
              <Col>
                <Container className="ps-4 ps-lg-0">
                  <Form.Group controlId="workType">
                    <Form.Label className="mb-0 p-lg-0">Vessel Name</Form.Label>
                    <br />
                    <Container className="d-flex gap-5 mb-4 subTypes">
                      <p className="fw-light">{props.vesselNameDesc}</p>
                    </Container>
                  </Form.Group>
                </Container>
              </Col>
            )}
            {!!props.reqData.Location.Address.Area && (
              <Col>
                <Container className="ps-4 ps-lg-0">
                  <Form.Group controlId="workType">
                    <Form.Label className="mb-0 p-lg-0">Impacted area</Form.Label>
                    <br />
                    <Container className="d-flex gap-5 mb-4 subTypes">
                      <p className="fw-light">{props.reqData.Location.Address.Area}</p>
                    </Container>
                  </Form.Group>
                </Container>
              </Col>
            )}
          </Row>

          <Row>
            <Col>
              <Container className="ps-4 ps-lg-0">
                <Form.Group controlId="HasSecondaryContact">
                  <Form.Label className="mb-0 p-lg-0">Are you the primary contact for this request?</Form.Label>
                  <br />
                  <Container className="d-flex gap-5 mb-4 subTypes">
                    <p className="fw-light">{props.reqData.OperatorIsPrimary === 'true' ? 'Yes' : 'No'}</p>
                  </Container>
                </Form.Group>
              </Container>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default LocationReadOnly;
