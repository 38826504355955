import React from 'react';
import { Link as ChakraLink, Box } from '@chakra-ui/react';
import { Phone, Arrow_NoFill as ArrowNoFill, Arrow_Black as ArrowBlack } from '../icons/Outline';
import { getLinkType } from '../../../../helper/getLinkType';
import { NavLink } from 'react-router-dom';

export interface LinkProps {
  text: string;
  href: string;
}
export interface LinkWithType extends LinkProps {
  type?: 'phone' | 'file' | 'email';
}
/**
 * These are the automatic links
 */
export const Link: React.FC<LinkWithType> = (props: LinkWithType) => {
  return (
    <>
      <ChakraLink href={`${getLinkType(props.type)}${props.href}`} display="flex" type="phone">
        {props.type && props.type === 'phone' && <Phone height="1rem" />}
        <Box marginStart="0.5rem">{props.text}</Box>
      </ChakraLink>
    </>
  );
};

export const ArrowLink: React.FC<LinkProps> = (props: LinkProps) => {
  return (
    <ChakraLink
      href={props.href}
      display="flex"
      textDecoration="none"
      color="white"
      _hover={{ textDecoration: 'underline' }}
      alignItems="center"
      gap={1}
    >
      {props.text}
      <ArrowNoFill height="1rem" />
    </ChakraLink>
  );
};

export const BackLink: React.FC<LinkProps> = (props: LinkProps) => {
  return (
    <ChakraLink
      w="fit-content"
      _hover={{ fill: 'orange.standard' }}
      gap={1}
      as={NavLink}
      to={props.href}
      display="flex"
      alignItems="center"
    >
      <ArrowBlack style={{ rotate: '180deg' }} fill="inherit" height="1rem" />
      {props.text}
    </ChakraLink>
  );
};
