import * as React from 'react';
import { SVGProps } from 'react';
const PIRAmend = (props: SVGProps<SVGSVGElement>) => (
  <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M21.4921 10.7549C20.5089 10.7572 19.7093 11.5545 19.7071 12.54V23.2928C19.7048 23.7676 19.8929 24.2245 20.2267 24.5627C20.5626 24.8987 21.0173 25.0913 21.4921 25.0913C21.9691 25.0935 22.4283 24.9054 22.7665 24.5672C23.1047 24.229 23.2928 23.7699 23.2906 23.2928V12.5399C23.2906 12.0651 23.1002 11.6082 22.762 11.2745C22.4238 10.9386 21.9669 10.7527 21.4921 10.7549Z"
        fill="#CF4928"
      />
      <path
        d="M23.2857 28.6726C23.2857 29.6626 22.4839 30.4643 21.4939 30.4643C21.0191 30.4643 20.5644 30.2762 20.2285 29.938C19.8925 29.6021 19.7021 29.1474 19.7021 28.6726C19.7021 28.1978 19.8925 27.7409 20.2285 27.4049C20.5644 27.069 21.0191 26.8809 21.4939 26.8809C21.9687 26.8809 22.4256 27.069 22.7615 27.4049C23.0975 27.7409 23.2857 28.1978 23.2857 28.6726Z"
        fill="#CF4928"
      />
      <path
        d="M36.0679 0.000143262C32.2156 0.000143262 29.1251 3.27457 29.1251 7.16948V35.8445C29.1251 37.2533 28.7241 38.0507 28.1732 38.5814C27.6222 39.1122 26.8226 39.4146 25.7789 39.4146C23.9513 39.4146 22.4328 37.8848 22.4328 35.8445H22.4306C22.4328 35.3674 22.2447 34.9083 21.9065 34.5723C21.5683 34.2341 21.1114 34.0437 20.6321 34.046H1.78504C1.31023 34.0482 0.855564 34.2386 0.519631 34.5768C0.183674 34.9127 -0.00222181 35.3696 2.00435e-05 35.8444C2.00435e-05 39.7393 3.07961 43 6.92969 43H25.7767C27.573 43 29.3468 42.4222 30.657 41.1612C31.9673 39.9003 32.7063 38.0189 32.7063 35.8442V8.96778H41.199C41.6783 8.97002 42.1352 8.78189 42.4734 8.44369C42.8116 8.1055 42.9997 7.64861 42.9997 7.16928C42.9997 4.99451 42.245 3.11319 40.9369 1.85225C39.6267 0.59131 37.8663 0 36.0701 0L36.0679 0.000143262ZM36.0679 3.58367C37.1116 3.58367 37.9112 3.89946 38.4622 4.43029C38.6548 4.61619 38.6481 5.11787 38.7936 5.38219H34.3881C34.9973 4.73939 35.1519 3.58367 36.0679 3.58367ZM5.24963 37.6294H19.5614C19.7405 38.2655 19.9399 38.8612 20.2758 39.4145H6.92964C6.01358 39.4145 5.85883 38.2722 5.24963 37.6294Z"
        fill="#CF4928"
      />
      <path
        d="M17.2073 0C13.355 0 10.2776 3.27442 10.2776 7.16934V35.8444H10.2754C10.2776 36.3214 10.468 36.7761 10.8062 37.1098C11.1422 37.4458 11.599 37.6317 12.0738 37.6317C13.0593 37.6272 13.8567 36.8299 13.8611 35.8444V7.16931C13.8611 5.12671 15.3796 3.58579 17.2072 3.58579H36.068C37.0535 3.58131 37.8508 2.784 37.8531 1.80074C37.8553 1.32368 37.6694 0.869024 37.3335 0.530855C36.9997 0.192662 36.5429 0.00228205 36.068 4.59314e-05L17.2073 0Z"
        fill="#CF4928"
      />
      <path d="M30.9209 7.17118L36.0633 1.7959L41.2057 7.17118H30.9209Z" fill="#CF4928" />
    </g>
  </svg>
);
export default PIRAmend;
