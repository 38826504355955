import { Box, Button, Heading, Image } from '@chakra-ui/react';
import React from 'react';

export interface StartServiceRequestProps {
  iconURL?: string;
  heading?: string;
  ctaText?: string;
  createPIRCase(): any;
}
export const StartForm: React.FC<StartServiceRequestProps> = (props: StartServiceRequestProps) => {
  return (
    <>
      <Box
        bg="white"
        borderRadius="0.5rem"
        padding="2rem"
        w={{ xl: '380px', lg: '300px', base: '100%' }}
        position={{ lg: 'absolute', base: 'relative' }}
        marginTop={{ lg: '-12rem', base: '0' }}
        right={{ xl: '12.5%', lg: '8.33333%', sm: 'auto' }}
      >
        <Image alt="" src={require(`../../../../images/${props.iconURL}`)} height="45px" />
        <Heading as="h2" marginY="1rem" fontSize="md" fontWeight="medium">
          {props.heading}
        </Heading>
        <Button w="100%" variant="primary" onClick={() => props.createPIRCase()} whiteSpace="break-spaces">
          {props.ctaText}
        </Button>
      </Box>
    </>
  );
};
