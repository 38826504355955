import React from 'react';
import { PegaFile } from '../types';
import { FileTile } from './FileTile';
import { Box, Flex, Heading } from '@chakra-ui/react';

interface FileTilesProps {
  filterCategory?: string;
  files: PegaFile[];
  header: string;
}

export const FileTiles: React.FC<FileTilesProps> = (props: FileTilesProps) => {
  const { files, filterCategory } = props;
  const filesToShow = filterCategory ? files.filter((i) => i.category === filterCategory) : files;
  return filesToShow.length > 0 ? (
    <Box mb="1rem">
      <Heading as="h3" fontSize="sm">
        {props.header}
      </Heading>
      <Flex gap={3}>
        {filesToShow.map((item) => (
          <FileTile {...item} />
        ))}
      </Flex>
    </Box>
  ) : (
    <></>
  );
};
