import React, { useState } from 'react';
import { FormGroupBg } from '../MyServiceRequest/CreateRequest/RequestDetailsForm/styles';
import linkImage from '../../images/link.svg';
import { Container, Form, Image } from 'react-bootstrap';
import { appConstants } from '../../helper/client/constant';

const LinkDefenceEmail = ({ defenceEmail, onChangeEmail, onIsEmailValid }) => {
  const [defenceEmailValidity, setDefenceEmailValidity] = useState(true);

  const checkDefenceEmailValidity = (e) => {
    if (e.target.value === '') {
      setDefenceEmailValidity(true);
      onIsEmailValid(false);
      return;
    }
    const validEmailDomains = [
      'defence.gov.au',
      'dpe.protected.mil.au',
      'drn.mil.au',
      'navy.gov.au',
      'deployed.mil.au',
      'ddep.mil.au',
    ];
    // this is just for testing poro in dev
    const validEmailDomainsForDEV = [
      'accenture.com',
      'defence.gov.au',
      'dpe.protected.mil.au',
      'drn.mil.au',
      'navy.gov.au',
      'deployed.mil.au',
      'ddep.mil.au',
    ];
    const inputEmailDomain = e.target.value?.split('@')[1];
    const finalDomain =
      process.env.REACT_APP_CURRENT === appConstants.REACT_APP_DEV_ENV ? validEmailDomainsForDEV : validEmailDomains;
    const emailIsValid = finalDomain.includes(inputEmailDomain);
    onIsEmailValid(emailIsValid);
    return setDefenceEmailValidity(validEmailDomains.includes(inputEmailDomain));
  };

  return (
    <FormGroupBg>
      <Container className="p-2">
        <Image className="icon-size" src={linkImage} alt="" />
      </Container>
      <div className="profile-main-heading">Verify your Defence email address</div>
      <div className="profile-sub-info mt-2">
        Some services and features in ServiceConnect are only available to personnel with a valid Defence email address.
        If you are using ServiceConnect outside the Defence Protected Environment (DPE) and have not yet verified your
        Defence email address, provide your email address below to start the verification process.
      </div>
      <Form.Label className="profile-other-info-text">
        Defence email address (e.g., john.smith@defence.gov.au)
      </Form.Label>
      <Form.Control
        type="text"
        placeholder=""
        name="defenceEmailAddress"
        value={defenceEmail}
        onChange={(e) => {
          onChangeEmail?.(e.target.value);
          checkDefenceEmailValidity(e);
        }}
        isInvalid={!defenceEmailValidity}
      />
      <Form.Control.Feedback type="invalid" className="validation-error">
        Please enter a valid defence email account.
      </Form.Control.Feedback>
    </FormGroupBg>
  );
};

export default LinkDefenceEmail;
