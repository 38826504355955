import { Badge, Button, ButtonGroup, Card, Col, Container, Dropdown, Image, Modal, Row } from 'react-bootstrap';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getURL } from '../../../helper/getAPIUrl';
import '../../../sass/custom.scss';
import './AllNotificationsSearch.scss';
import { setNotificationsRead } from '../../../reducers/requestDetailFormReducer.js';

import GenericErrorModal from '../../GenericErrorModal/GenericErrorModal';
import SystemOutageModal from '../../SystemOutageModal/SystemOutageModal';
import AuthenticationModal from '../AuthenticationModal/AuthenticationModal';

import GenericErrorModalProps from '../../GenericErrorModal/__mocks__/default.json';
import AuthenticationModalProps from '../../LandingPage/AuthenticationModal/__mocks__/default.json';
import SystemOutageModalProps from '../../SystemOutageModal/__mocks__/default.json';
import DeleteNotificationModal from './DeleteNotificationModal';

import line from '../../../images/dottedLine.svg';
import sort from '../../../images/sort.svg';
import { appConstants } from '../../../helper/client/constant';
import MoreInfoNotification from '../MoreInfoNotification/MoreInfoNotification';
import { useDispatch } from 'react-redux';

const AllNotificationsSearch = (props) => {
  const [APIError, setAPIError] = useState({});
  const [serviceDownModal, setServiceDownModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [notificationsData, setNotificationsData] = useState({
    notiifications: [],
  });
  const [showInfo, setShowInfo] = useState(false);
  const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
  const [infoProps, setInfoProps] = useState();
  const [filtervalue, setfilterValue] = useState(null);
  const [filter, setFilter] = useState('notificationDate');
  const [showDeleteModal, setShowDeleteModal] = useState([false, {}]);

  const dispatch = useDispatch();

  const UnReadNoficationsCount = (allNotifications) => {
    let count = 0;
    allNotifications !== undefined &&
      allNotifications.map((notification) => {
        if (notification.isRead === false) {
          count += 1;
        }
      });
    return count;
  };

  //get notifications
  const GetNotificationList = () => {
    let URL = process.env.REACT_APP_GET_NOTIFICATION;
    URL = getURL(URL);
    axios
      .get(URL, {
        withCredentials: true,
      })
      .then((res) => {
        setNotificationsData((prevState) => {
          return {
            ...prevState,
            notifications: res.data.notifications,
          };
        });
        const unreadCount = UnReadNoficationsCount(res.data.notifications);
        dispatch(setNotificationsRead(unreadCount));
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };

  //put/update notifications
  const updateNotificationItem = (notificationId, flag, notificationType) => {
    let URL =
      process.env.REACT_APP_CURRENT === appConstants.REACT_APP_LOCAL_ENV
        ? process.env.REACT_APP_PUT_NOTIFICATION + notificationId + '/' + notificationType
        : process.env.REACT_APP_PUT_NOTIFICATION + notificationId + '?notificationtype=' + notificationType;
    //body that needs to be sent
    const data = {
      isRead: flag,
    };
    URL = getURL(URL);
    axios
      .put(URL, data, {
        withCredentials: true,
      })
      .then((res) => {
        //make another get call to get latest notification
        GetNotificationList();
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };

  //delete notification
  const deleteNotificationItem = (notification, notificationId, notificationType) => {
    let URL =
      process.env.REACT_APP_CURRENT === appConstants.REACT_APP_LOCAL_ENV
        ? process.env.REACT_APP_DELETE_NOTIFICATION + notificationId + '/' + notificationType
        : process.env.REACT_APP_DELETE_NOTIFICATION + notificationId + '?notificationtype=' + notificationType;
    URL = getURL(URL);
    axios
      .delete(URL, {
        withCredentials: true,
      })
      .then((res) => {
        //Make another api get call to referesh the api call
        GetNotificationList();
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };

  useEffect(() => {
    GetNotificationList();
  }, []);

  //Modal for delete option
  const deleteOpenPopUp = (notification) => {
    setShowDeleteModal([true, notification]);
  };

  const deleteClosePopUp = () => {
    setShowDeleteModal([false, {}]);
  };

  //need to pass ID, flag and i
  const handlerOpen = (i, notificationId, notificationType, flag) => {
    setShowInfo(true);
    setInfoProps(i);
    //call updateReadNotification function

    updateReadNotification(i, notificationId, notificationType, flag);
  };

  const handlerClose = () => {
    setShowInfo(false);
  };

  //View Button
  const handlerOpenFlag = (i, notificationId, notificationType, flag) => {
    updateReadNotification(i, notificationId, notificationType, false);
  };

  //delete notification
  const deleteNotification = (notification, notificationId, notificationType) => {
    //props.onDeleteNotificationChange(notification, notificationId);
    deleteNotificationItem(notification, notificationId, notificationType);
    setShowDeleteModal([false, {}]);
  };

  //update notification
  const updateReadNotification = (notification, notificationId, notificationType, flag) => {
    //props.onReadNotificationFlagChange(notificationId, !flag);

    updateNotificationItem(
      notificationId,

      !flag,
      notificationType,
    );
  };

  //Filter notifications
  const filterFunc = (result) => {
    if (filtervalue !== null) {
      const tags = result.tags.map((tag) => {
        return tag.value;
      });
      //For each notification go through its tags
      const tagCheck = tags.map((tag) => {
        let upperTag = tag.toUpperCase();
        if (upperTag === filtervalue) {
          return true;
        } else {
          return false;
        }
      });
      if (tagCheck.includes(true)) {
        return result;
      }
    } else {
      return result;
    }
  };

  //Determining the colour of the pill
  const DetermineStatus = (currentStatus) => {
    const upperStatus = currentStatus.toUpperCase();

    if (upperStatus === 'URGENT') {
      return (
        <Badge pill bg="defencealerturgent" className="text-white px-2">
          URGENT
        </Badge>
      );
    } else {
      return (
        <Badge pill bg="white" className=" text-black px-2 pillStyles">
          {upperStatus}
        </Badge>
      );
    }
  };

  //Sort by title
  //To compare first Item to second Item
  const sortFunc = (firstItem, secondItem) => {
    switch (filter) {
      case 'notificationTitle':
        const TitleforFirstNotification = firstItem.notificationTitle;
        const TitleforSecondNotification = secondItem.notificationTitle;
        if (TitleforFirstNotification < TitleforSecondNotification) {
          return -1;
        }
        if (TitleforFirstNotification > TitleforSecondNotification) {
          return 1;
        }
        return 0;
      case 'notificationDate':
        if (firstItem.notificationDate < secondItem.notificationDate) {
          return 1;
        }
        if (firstItem.notificationDate > secondItem.notificationDate) {
          return -1;
        }
        return 0;
      default:
        return null;
    }
  };

  //Tile item
  const Item = (props) => {
    const date = new Date(props.notification.notificationDate);
    const formattedDate = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();

    return (
      <>
        <Modal
          show={showDeleteModal[0] === true}
          animation={false}
          dialogClassName="modal-lg modalPadding px-lg-0 py-4"
          contentClassName="p-lg-4 modal-75w mobilecolumn"
          className=""
        >
          <Modal.Title className="px-lg-5 pt-lg-5 px-2 modalTitle">
            Are you sure you want to delete this notification?
          </Modal.Title>

          <Modal.Body>
            <Row className="px-4 d-flex flex-row">
              <Col lg={2} className="pe-3">
                <Button
                  variant="standard"
                  className="px-5"
                  onClick={() => {
                    deleteNotification(
                      showDeleteModal[1],
                      showDeleteModal[1].notificationId,
                      showDeleteModal[1].notificationType,
                    );
                  }}
                >
                  Yes
                </Button>
              </Col>
              <Col>
                <Button
                  variant="standard"
                  className="px-5"
                  //onClick={deleteClosePopUp}
                  onClick={() => {
                    deleteClosePopUp();
                  }}
                >
                  No
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Container data-testid="item" className="m-0">
          <Container className="pb-2">
            <Col className="mb-1">
              <Card className={props.notification.isRead ? 'bg-white border-0' : 'bg-defencewhisperlightgrey border-0'}>
                <Image alt="separator" src={line} />
                <p className="mobile px-4 pt-2 badgeSpace float-end">{formattedDate}</p>

                <Card.Body className="px-4 pt-3 pb-3">
                  {props.notification.tags.map((value, key) => {
                    return (
                      <Container key={key} className="badgeSpace pe-2">
                        {DetermineStatus(value.value)}
                      </Container>
                    );
                  })}

                  <Button
                    onClick={() =>
                      updateReadNotification(
                        props.notification,
                        props.notification.notificationId,
                        props.notification.notificationType,
                        props.notification.isRead,
                      )
                    }
                    variant="link"
                  >
                    {props.notification.isRead ? 'Mark as unread' : 'Mark as read'}
                  </Button>
                  <p className="desktop badgeSpace float-end">{formattedDate}</p>
                  <Card.Title className="pt-4">
                    <h3>{props.notification.notificationTitle}</h3>
                  </Card.Title>
                  <Card.Text
                    className="text-black"
                    dangerouslySetInnerHTML={{
                      __html:
                        props.notification.shortMessage.length > 128
                          ? `${props.notification.shortMessage.substring(0, 128)}...`
                          : props.notification.shortMessage,
                    }}
                  ></Card.Text>
                  <Button
                    onClick={() => {
                      deleteOpenPopUp(props.notification);
                    }}
                    className="p-0 binText delete"
                    variant="link"
                  >
                    Delete
                  </Button>
                  <Button
                    variant="link"
                    className="goButton float-end"
                    onClick={() =>
                      handlerOpen(
                        props.notification,
                        props.notification.notificationId,
                        props.notification.notificationType,
                        props.notification.isRead,
                      )
                    }
                  >
                    <Container>
                      <Container className="float-end">
                        <Button
                          onClick={() =>
                            handlerOpenFlag(
                              props.notification,
                              props.notification.notificationId,
                              props.notification.notificationType,
                              props.notification.isRead,
                            )
                          }
                          variant="link"
                        >
                          <p className="fw-bold arrow text-defenceorange mb-0 float-end">View</p>
                        </Button>
                      </Container>
                    </Container>
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Container>
        </Container>
      </>
    );
  };

  const handleFilter = (service) => {
    setfilterValue(service);
  };

  const deleteAllHandler = (actionId) => {
    let URL = process.env.REACT_APP_DELETE_ALL_NOTIFICATION + actionId;
    URL = getURL(URL);
    axios
      .delete(URL, {
        withCredentials: true,
      })
      .then((res) => {
        //Make another api get call to referesh the api call
        GetNotificationList();
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
    setShowDeleteAllModal(false);
  };

  const readAllHandler = (actionId) => {
    let URL = process.env.REACT_APP_READ_ALL_NOTIFICATION + actionId;
    URL = getURL(URL);
    axios
      .post(
        URL,
        {},
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        //Make another api get call to referesh the api call
        GetNotificationList();
        dispatch(setNotificationsRead(0));
      })
      .catch((err) => {
        let error = { ...err };
        error.response = error.response || { response: { data: [] } };
        setAPIError(error);
        if (error.response.status === 403 || error.response.status === 401) {
          setShowModal(true);
        } else if (error.response.status === 500) {
          setServiceDownModal(true);
        } else {
          setErrorModal(true);
        }
      });
  };

  return (
    <>
      <DeleteNotificationModal
        show={showDeleteAllModal}
        closeAction={() => setShowDeleteAllModal(false)}
        deleteAction={() => deleteAllHandler('deleteAll')}
      />
      {errorModal && (
        <GenericErrorModal
          title={GenericErrorModalProps.title}
          ctaText={GenericErrorModalProps.ctaText}
          error={APIError}
        />
      )}

      {serviceDownModal && (
        <SystemOutageModal
          title={SystemOutageModalProps.title}
          description={SystemOutageModalProps.description}
          ctaText={SystemOutageModalProps.ctaText}
        />
      )}

      {showModal && (
        <AuthenticationModal
          title={AuthenticationModalProps.title}
          description={AuthenticationModalProps.description}
          ctaText={AuthenticationModalProps.ctaText}
        />
      )}

      <Container fluid="true" className="py-lg-4">
        <Container className="px-lg-6 px-4 landingPageWidth">
          {!showInfo ? (
            <Container className="mt-3">
              {/* HEADER */}
              <Container className="d-flex justify-content-between mb-4">
                {/* LEFT BUTTONS */}
                <Row className="d-flex flex-row">
                  <Col className="pb-2">
                    <Dropdown className="p-lg-0 me-3" as={ButtonGroup}>
                      <Dropdown.Toggle className="rounded-0 px-4" variant="standard">
                        Filter
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleFilter('MY BASE')}>My Base</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleFilter('MY SERVICE REQUEST')}>
                          My ServiceRequest
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleFilter('FORMS')}>My Forms</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col className="pb-2">
                    <Container className="me-3">
                      <Button
                        variant="standard"
                        className="rounded-0 filterButton"
                        onClick={() => readAllHandler('readAll')}
                      >
                        Mark all as Read
                      </Button>
                    </Container>
                  </Col>

                  <Col className="pb-2">
                    <Button
                      variant="standard"
                      className="rounded-0 filterButton"
                      onClick={() => setShowDeleteAllModal(true)}
                    >
                      Delete all
                    </Button>
                  </Col>
                </Row>

                {filtervalue === null ? (
                  <></>
                ) : (
                  <Button variant="secondary" className="ms-2" onClick={() => setfilterValue(null)}>
                    Clear
                  </Button>
                )}

                <Dropdown className="p-lg-0" as={ButtonGroup}>
                  <Dropdown.Toggle className="border-0 pe-3 me-3 bg-white rounded-0" variant="sort">
                    Sort <Image alt="reverse sort" src={sort} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setFilter('notificationDate')}>Date</Dropdown.Item>
                    <Dropdown.Item onClick={() => setFilter('notificationTitle')}>Title</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Container>
              <Container className="pt-1 pb-3 justify-content-start">
                {props.notificationList(notificationsData.notifications)}
                {notificationsData.notifications !== undefined &&
                  notificationsData.notifications
                    .sort(sortFunc)
                    .filter(filterFunc)
                    .map((item, index) => <Item key={index} notification={item} />)}
              </Container>
            </Container>
          ) : (
            <MoreInfoNotification show={showInfo} infoProps={infoProps} close={handlerClose} />
          )}
        </Container>
      </Container>
    </>
  );
};

export default AllNotificationsSearch;
