import * as React from 'react';
import { SVGProps } from 'react';
const PIRComm = (props: SVGProps<SVGSVGElement>) => (
  <svg width="36" height="45" viewBox="0 0 36 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M25.6252 1.14408L25.6256 1.14369L25.6156 1.13599C25.385 0.958104 25.1034 0.85666 24.8121 0.850039V0.85H24.8087H3.86938V0.849983L3.86715 0.850017C3.03445 0.862384 2.24327 1.21482 1.67474 1.82253L1.67457 1.82271C1.10694 2.43145 0.810186 3.2437 0.854305 4.07489V40.9251C0.810167 41.7563 1.10692 42.5685 1.67457 43.1773L1.67474 43.1775C2.24327 43.7851 3.03445 44.1376 3.86714 44.15H3.86938L31.9246 44.15L31.9268 44.15C32.7595 44.1376 33.5507 43.7852 34.1192 43.1775L34.1194 43.1773C34.687 42.5686 34.9838 41.7563 34.9397 40.9252V10.2224C34.9711 9.91333 34.8611 9.60674 34.6399 9.39007L34.6399 9.39001L34.6361 9.38655L25.6252 1.14408ZM25.7252 9.24059V4.35555L31.5319 9.55113H26.0357H26.0252L26.0148 9.55259C25.9366 9.56358 25.8561 9.5367 25.7988 9.47944C25.7419 9.4225 25.7139 9.34123 25.7241 9.25892L25.7252 9.24979V9.24059ZM26.0372 11.8989H32.5937V40.9289C32.5937 41.1901 32.5337 41.412 32.4235 41.5644C32.3266 41.6984 32.1847 41.788 31.9814 41.8019L31.9245 41.8019L3.86927 41.8039C3.63498 41.8039 3.47582 41.7109 3.37022 41.5653C3.26012 41.4134 3.20013 41.1921 3.20013 40.9309V4.07062C3.20013 3.80942 3.26013 3.58808 3.37022 3.43627C3.47582 3.29065 3.63498 3.19763 3.86928 3.19763H3.86929L23.3773 3.19569V9.23892C23.3632 9.94942 23.6396 10.6349 24.1404 11.1358C24.6433 11.6386 25.3288 11.913 26.0372 11.8989Z"
        fill="#CF4928"
        stroke="#CF4928"
        strokeWidth="0.3"
      />
      <path
        d="M18.0466 13.7964C18.0466 13.4862 17.923 13.1874 17.7029 12.9672C17.4827 12.747 17.1839 12.6234 16.8737 12.6234H8.17045C7.52144 12.6234 6.99551 13.149 6.99551 13.7964V18.9151C6.99551 19.2272 7.119 19.526 7.33928 19.7463C7.55955 19.9665 7.85838 20.09 8.17043 20.09H16.8737C17.1839 20.09 17.4827 19.9664 17.7029 19.7463C17.9231 19.526 18.0466 19.2272 18.0466 18.9151L18.0466 13.7964ZM9.34342 14.9713H15.6988V17.7422H9.34342V14.9713Z"
        fill="#CF4928"
        stroke="#CF4928"
        strokeWidth="0.3"
      />
      <path
        d="M29.3093 13.7964C29.3093 13.4862 29.1857 13.1874 28.9655 12.9672C28.7452 12.7469 28.4464 12.6234 28.1344 12.6234H19.9449C19.2959 12.6234 18.7699 13.149 18.7699 13.7964V18.9151C18.7699 19.2272 18.8934 19.526 19.1137 19.7463C19.334 19.9665 19.6328 20.09 19.9448 20.09H28.1343C28.4464 20.09 28.7452 19.9665 28.9655 19.7463C29.1858 19.526 29.3093 19.2272 29.3093 18.9151L29.3093 13.7964ZM26.9614 17.7422H21.1178V14.9713H26.9614V17.7422Z"
        fill="#CF4928"
        stroke="#CF4928"
        strokeWidth="0.3"
      />
      <path
        d="M29.3089 22.4995C29.3089 22.1872 29.1852 21.8904 28.9651 21.6703C28.7449 21.4501 28.446 21.3266 28.134 21.3266H8.17045C7.52163 21.3266 6.99551 21.85 6.99551 22.4995V26.5954C6.99551 26.9056 7.11911 27.2044 7.33928 27.4245C7.55955 27.6448 7.85838 27.7683 8.17043 27.7683H28.134C28.446 27.7683 28.7449 27.6448 28.9651 27.4245C29.1853 27.2044 29.3089 26.9056 29.3089 26.5954L29.3089 22.4995ZM26.961 25.4204H9.34337V23.6725H26.961V25.4204Z"
        fill="#CF4928"
        stroke="#CF4928"
        strokeWidth="0.3"
      />
      <path
        d="M8.06693 31.8649H17.4871C18.1364 31.8649 18.66 31.339 18.66 30.6919C18.66 30.0431 18.1367 29.517 17.4871 29.517H8.06693C7.41951 29.517 6.89395 30.0429 6.89395 30.6919C6.89395 31.3392 7.41967 31.8649 8.06693 31.8649Z"
        fill="#CF4928"
        stroke="#CF4928"
        strokeWidth="0.3"
      />
      <path
        d="M27.727 33.101H8.06693C7.41969 33.101 6.89395 33.6267 6.89395 34.274C6.89395 34.923 7.41948 35.4489 8.06693 35.4489H27.727C28.3744 35.4489 28.8999 34.923 28.8999 34.274C28.8999 33.6267 28.3742 33.101 27.727 33.101Z"
        fill="#CF4928"
        stroke="#CF4928"
        strokeWidth="0.3"
      />
      <path
        d="M27.727 36.683H8.06693C7.41951 36.683 6.89395 37.2089 6.89395 37.8579C6.89395 38.5052 7.41967 39.0309 8.06693 39.0309H27.727C28.3742 39.0309 28.8999 38.5052 28.8999 37.8579C28.8999 37.2089 28.3744 36.683 27.727 36.683Z"
        fill="#CF4928"
        stroke="#CF4928"
        strokeWidth="0.3"
      />
      <path
        d="M8.06693 10.3649H17.4871C18.1366 10.3649 18.66 9.83882 18.66 9.18998C18.66 8.54293 18.1365 8.01699 17.4871 8.01699H8.06693C7.41969 8.01699 6.89395 8.54272 6.89395 9.18998C6.89395 9.83898 7.41948 10.3649 8.06693 10.3649Z"
        fill="#CF4928"
        stroke="#CF4928"
        strokeWidth="0.3"
      />
    </g>
  </svg>
);
export default PIRComm;
