import * as React from 'react';
import { SVGProps } from 'react';
const Hamburger = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_5_4)">
      <path
        fill="white"
        d="M0.551608 0C0.399206 0.0249094 0.25914 0.11987 0.157629 0.267105C0.0561177 0.414339 0.00012207 0.603754 0.00012207 0.799892C0.00012207 0.99603 0.0561177 1.18544 0.157629 1.33268C0.25914 1.47991 0.399206 1.57487 0.551608 1.59978H15.4476C15.6 1.57487 15.7401 1.47991 15.8416 1.33268C15.9431 1.18544 15.9991 0.99603 15.9991 0.799892C15.9991 0.603754 15.9431 0.414339 15.8416 0.267105C15.7401 0.11987 15.6 0.0249094 15.4476 0H0.551608ZM0.551608 7.20011C0.399206 7.22502 0.25914 7.31998 0.157629 7.46721C0.0561177 7.61445 0.00012207 7.80386 0.00012207 8C0.00012207 8.19614 0.0561177 8.38555 0.157629 8.53279C0.25914 8.68002 0.399206 8.77498 0.551608 8.79989H15.4476C15.6 8.77498 15.7401 8.68002 15.8416 8.53279C15.9431 8.38555 15.9991 8.19614 15.9991 8C15.9991 7.80386 15.9431 7.61445 15.8416 7.46721C15.7401 7.31998 15.6 7.22502 15.4476 7.20011H0.551608ZM0.551608 14.4002C0.399206 14.4251 0.25914 14.5201 0.157629 14.6673C0.0561177 14.8146 0.00012207 15.004 0.00012207 15.2001C0.00012207 15.3962 0.0561177 15.5857 0.157629 15.7329C0.25914 15.8801 0.399206 15.9751 0.551608 16H15.4476C15.6 15.9751 15.7401 15.8801 15.8416 15.7329C15.9431 15.5857 15.9991 15.3962 15.9991 15.2001C15.9991 15.004 15.9431 14.8146 15.8416 14.6673C15.7401 14.5201 15.6 14.4251 15.4476 14.4002H0.551608Z"
      />
    </g>
    <defs>
      <clipPath>
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Hamburger;
