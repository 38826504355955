import React from 'react';
const Arrow = (props) => {
  return (
    <svg
      role="button"
      alt={props.alt}
      width="15.752"
      height="13.074"
      viewBox="0 0 15.752 13.074"
      className={props.className}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Mask"
            d="M5.977,15.542a.7.7,0,0,0,1,0l5.9-5.9a.7.7,0,1,0-.991-.991L7.192,13.346V.7a.7.7,0,0,0-1.4,0V13.352L1.2,8.674a.7.7,0,0,0-1,.981l5.777,5.887"
            transform="translate(0 0)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="RightArrow" transform="translate(0 13.074) rotate(-90)" clipPath="url(#clip-path)">
        <g id="Colour_Black-PC5" data-name="Colour/Black-PC5" transform="translate(-8.324 -6.256)">
          <rect id="Rectangle_2" data-name="Rectangle 2" width="29.421" height="29.421" />
        </g>
      </g>
    </svg>
  );
};
export default Arrow;
