import { ComponentStyleConfig } from '@chakra-ui/react';
const Modal: ComponentStyleConfig = {
  parts: ['dialog', 'header'],
  variants: { feedback: { header: { paddingBottom: 0 } } },
  sizes: {
    xlarge: { dialog: { width: { lg: '80%', base: '90%' } } },
    large: { dialog: { width: { lg: '60%', base: '90%' } } },
    small: { dialog: { width: { lg: '40%', base: '70%' } } },
  },
};

export default Modal;
