import React, { useEffect } from 'react';

import Header from '../LandingPage/Header/Header';
import content from '../content/content/Support.json';

import SupportOverview from '../LandingPage/SupportOverview/SupportOverview';
import SupportOverviewProps from '../LandingPage/SupportOverview/__mocks__/default.json';

import FAQs from '../LandingPage/FAQs/FAQs';
import { Container } from 'react-bootstrap';
const Support = () => {
  useEffect(() => {
    document.title = 'Support';
  });
  return (
    <>
      <Header
        mainHeading={content.header.mainHeading}
        subHeading={content.header.subHeading}
        breadcrumbs={content.header.breadcrumbs}
        breadcrumbItems={content.header.breadcrumbItems}
      />
      <SupportOverview title={SupportOverviewProps.title} description={SupportOverviewProps.description} />
      {content.FAQs.map((item, idx) => {
        return (
          <FAQs
            key={idx}
            expandButtonText={item.expandButtonText}
            title={item.title}
            FAQs={item.FAQs}
            odd={idx % 2 ? true : false}
          />
        );
      })}
      <Container className="mb-3 px-4 px-lg-6">
        <p className="text-defencered landingPageWidth">
          *Link accessible through the Defence network (Defence Protected Environment) only.
        </p>
      </Container>
    </>
  );
};

export default Support;
