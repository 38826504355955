import React from 'react';
import { Container, Button, Modal, Image } from 'react-bootstrap';
import './PlannedSystemOutageModal.scss';
import { useState } from 'react';
import warning from '../../images/warning.svg';

const PlannedSystemOutage = (props) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      dialogClassName="modal-lg modalPadding px-lg-0 py-4"
      className=""
    >
      <Image className="py-4 px-5 systemWarningIcon" src={warning} alt="" />
      <Modal.Title className="px-5 modalTitle">{props.title}</Modal.Title>

      <Modal.Body className="px-5">
        <p
          dangerouslySetInnerHTML={{
            __html: props.description,
          }}
        ></p>
      </Modal.Body>
      <Container className="px-5 pt-4 pb-6">
        <Button data-testid="OutageButton" className="rounded-0 reLoginButton" variant="primary" onClick={handleClose}>
          {props.ctaText}
        </Button>
      </Container>
    </Modal>
  );
};

export default PlannedSystemOutage;
