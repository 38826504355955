import React from 'react';
import {
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { FormElement, TextProps } from '../FormElementType';
import { PrimitiveControl } from '../PrimitiveControl';
const FormTextInput: React.FC<FormElement & TextProps> = (props: FormElement & TextProps): JSX.Element => {
  return (
    <>
      {props.visible && (
        <PrimitiveControl
          disabled={props.disabled}
          isInvalid={props.isInvalid}
          required={props.required}
          desktopWidth={props.desktopWidth}
          mobileWidth={props.mobileWidth}
        >
          <InputGroup flexDirection="column">
            {props.label && (
              <FormLabel fontWeight="normal" key={`${props.id}-label`}>
                {props.label}
              </FormLabel>
            )}
            {/* TODO */}
            {props?.showTooltip && <>Tooltip here</>}
            {props.icon &&
              (props.iconPosition === 'left' ? (
                <InputLeftElement h="100%" pointerEvents="none" me="0.5rem">
                  {props.icon}
                </InputLeftElement>
              ) : (
                <InputRightElement h="100%" pointerEvents="none" me="0.25rem">
                  {props.icon}
                </InputRightElement>
              ))}
            {props.isInvalid}
            <Input
              onKeyDown={(e) => e.key === 'Enter' && props.onEnter && props.onEnter()}
              data-testid="input"
              variant="primary"
              onBlur={(e) => props.onBlur(e.target.value)}
              onChange={(e) => props.onBlur(e.target.value)}
              type={props.type}
              max={props.maxLength ?? ''}
              placeholder={props.placeholder}
              value={String(props.value)}
              min={props?.minLength}
              maxLength={props?.maxLength}
              readOnly={props?.readOnly}
              key={`${props.id}-input`}
            />
            <FormErrorMessage variant="primary" key={`${props.id}-error`}>
              {props.errorMessage}
            </FormErrorMessage>
            {props.helpText && <Text id={`helptext-${props.reference}`}>{props?.helpText}</Text>}
          </InputGroup>
        </PrimitiveControl>
      )}
    </>
  );
};

export default FormTextInput;
